import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import applyRulesLogo from "assets/logo/apply-rules-logo.svg";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "./index.css";
import { Tooltip } from "primereact/tooltip";
// import applyMasterRule from "models/applyRuleMaster";
import { Editor } from "@monaco-editor/react";
import { InputNumber } from "primereact/inputnumber";
import { useSourceApi } from "hooks/sourceConfig";
import { Divider } from "primereact/divider";

const ApplyRuleSidebar = ({
  visible,
  onHide,
  onRowClickData,
  activeIndex,
  onApplyRules,
}) => {
  const [isAddRuleActive, setIsAddRuleActive] = useState(false);
  const [isAddRuleError, setIsAddRuleError] = useState(false);
  const [applyRuleItemsOption, setApplyRuleItemsOption] = useState();
  const [selectedRule, setSelectedRule] = useState();

  const [value, setValue] = useState("");
  const [numberValue, setNumberValue] = useState();
  const [items, setItems] = useState([]);
  const [storeDeleteItem, setStoreDeleteItem] = useState([]);
  const [rules, setRules] = useState([]);
  const [code, setCode] = useState();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [formData, setFormData] = useState({
    dropdownValue: null,
    inputTextValue: "",
  });
  const tabs = ["cleansing", "formatting", "standardizing", "validation"];
  const { getMasterData } = useSourceApi();

  const draggingPos = useRef(null);
  const dragOverPos = useRef(null);

  async function getApplyRuleItemsData() {
    try {
      const masterData = await getMasterData();
      const transformedRules =
        (masterData.find((item) => item.key === "dataProcessingRules") || {})
          .data || [];
      setRules(transformedRules);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      setRules([]);
    }
  }

  useEffect(() => {
    setSelectedRule([]);
    const transformedRules = rules
      .filter((item) => item.category === tabs[activeIndex])
      .map((ruleItem) => {
        const inputConfiglabels = ruleItem?.inputConfig?.map((config) => {
          return config.label ? config.label : null;
        });
        const inputTypes = ruleItem?.inputConfig?.map((config) => config.type);
        const isMultiType = inputTypes?.length > 1;
        return {
          label: ruleItem?.ruleLabel,
          value: ruleItem?.ruleName,
          category: ruleItem?.category,
          ruleId: ruleItem?.id,
          type: isMultiType ? "MultiType" : inputTypes ? inputTypes[0] : null,
          inputConfiglabels:
            inputConfiglabels?.length > 0 ? inputConfiglabels : null,
        };
      });

    // if (tabs[activeIndex] === "cleansing")
    //   setSelectedRule({
    //     label: "NaN Default",
    //     type: "input",
    //     value: "nan_default",
    //     ruleId: 5,
    //   });
    // else {
    //   setSelectedRule();
    // }

    setApplyRuleItemsOption(transformedRules);
  }, [rules, activeIndex]);

  useEffect(() => {
    getApplyRuleItemsData();
  }, []);

  const handleSidebarHide = () => {
    if (onHide) onHide();
    setIsAddRuleActive(false);
    setItems([]);
    setStoreDeleteItem([]);
    setValue("");
    setNumberValue();
    setCode();
    setDropdownOptions([]);
  };

  const handleSidebarClearRule = () => {
    let ClearItem = [];
    if (items?.length > 0) {
      ClearItem = items.map((item) => item.id);
    }
    setItems([]);
    setStoreDeleteItem(ClearItem);
    setIsAddRuleActive(false);
    setValue("");
    setNumberValue();
    setCode();
    setDropdownOptions([]);
  };

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    dragOverPos.current = position;
    const newItems = [...items];
    const draggingItem = newItems[draggingPos.current];
    if (!draggingItem) return;

    newItems.splice(draggingPos.current, 1);
    newItems.splice(dragOverPos.current, 0, draggingItem);

    const reorderedItems = newItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    draggingPos.current = position;
    dragOverPos.current = null;

    setItems(reorderedItems);
  };

  useEffect(() => {
    if (onRowClickData && onRowClickData?.data.rulesApplied) {
      setSelectedRule([]);
      const rulesArray = onRowClickData?.data.rulesApplied.filter(
        (rule) => rule.ruleLabel !== "No Rules Applied"
      );
      const newItems = rulesArray.map((rule, index) => {
        const multiInfoData =
          rule?.config && rule.config.length > 1
            ? {
              inputTextValue: rule.config[0],
              dropdownValue: rule.config[1],
            }
            : null;

        return {
          id: rule?.id,
          category: rule?.category,
          isPredefined: rule?.isPredefined,
          label: rule?.ruleLabel,
          ruleName: rule?.ruleName,
          multiInfoData: multiInfoData,
          info: multiInfoData ? null : rule?.config,
          ruleId: rule?.ruleId,
          order: items?.length + index,
        };
      });

      setItems((prevItems) => [...prevItems, ...newItems]);
    } else {
      setItems([]);
      setStoreDeleteItem([]);
      setSelectedRule([]);
    }
  }, [onRowClickData]);

  useEffect(() => {
    const transformedRules = rules.filter(
      (item) => item.ruleName === selectedRule?.value
    );
    if (
      selectedRule?.type === "dropdown" ||
      selectedRule?.type === "MultiType"
    ) {
      const config = transformedRules?.[0]?.inputConfig;
      const dropdownConfig = config?.find((item) => item.type === "dropdown");
      if (dropdownConfig) {
        setDropdownOptions(dropdownConfig.values || []);
      }
    }
  }, [selectedRule]);

  const handleApplyRules = () => {
    onApplyRules({ items, storeDeleteItem });
  };

  const handleAddRule = () => {
    if (selectedRule) {
      if (selectedRule.type === "input" && value?.length === 0) {
        setIsAddRuleError(true);
      } else if (selectedRule.type === "number" && numberValue === undefined) {
        setIsAddRuleError(true);
      } else if (
        selectedRule.type === "dropdown" &&
        selectedRule?.value === "case_conversion" &&
        value?.length === 0
      ) {
        setIsAddRuleError(true);
      } else {
        setIsAddRuleError(false);
        const newItem = {
          label: selectedRule.label,
          ruleName: selectedRule.value,
          category: selectedRule?.category,
          ruleId: selectedRule?.ruleId,
          isPredefined: false,
          info:
            selectedRule?.type === "code"
              ? code
              : selectedRule?.type === "number"
                ? numberValue
                : value.length > 0
                  ? value
                  : null,
          multiInfoData: selectedRule?.type === "MultiType" ? formData : null,
          order: items.length,
        };
        setItems([...items, newItem]);
        setIsAddRuleActive(false);
        setIsAddRuleError(false);
        setValue("");
        setNumberValue();
        setCode();
        setSelectedRule([]);
      }
    }
  };

  const handleNanDefaultValue = (e) => {
    if (selectedRule.type === "input" && e.target.value === 0) {
      setIsAddRuleError(true);
    } else if (selectedRule?.type === "MultiType") {
      setFormData({
        ...formData,
        inputTextValue: e.target.value,
      });
    } else {
      setValue(e.target.value);
      setIsAddRuleError(false);
    }
  };

  const handleNumberInputValue = (e) => {
    if (e.value <= 0) {
      setIsAddRuleError(true);
    } else {
      setNumberValue(e.value);
      setIsAddRuleError(false);
    }
  };

  const handleDropdownChange = (e) => {
    if (selectedRule?.value === "convert_date_to_standard_format") {
      setFormData({
        ...formData,
        dropdownValue: e.value,
      });
    } else {
      setValue(e.value);
      setIsAddRuleError(false);
    }
  };

  const handleDeleteItem = (index) => {
    const deleteItem = items.filter((_, i) => i !== index);
    setStoreDeleteItem((prevStoreDeleteItem) => [
      ...prevStoreDeleteItem,
      items[index]?.id,
    ]);
    setItems(deleteItem);
  };

  const customHeader = () => {
    if (!isAddRuleActive) {
      return (
        <div className="">
          <h2 className="font-bold text-2xl">Apply Rule</h2>
          <p style={{fontSize:'17px', fontWeight:'400'}}>You can add multiple rules at once</p>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <Button
            icon="pi pi-arrow-left"
            className="mr-2 font-bold text-black"
            size="large"
            onClick={() => {
              setIsAddRuleActive(false);
              setIsAddRuleError(false);
              setValue("");
              setNumberValue();
              setCode();
            }}
            style={{backgroundColor:'white', border:'none',color:'#666666'}}
          />
          <div>
            <h2 className="font-bold text-2xl">Add Rule</h2>
            <p style={{fontSize:'17px', fontWeight:'400'}}>Add all the required rules</p>
          </div>
        </div>
      );
    }
  };

  const ToolTipHandler = (multiItemsData) => {
    return (
      <div>
        <div className="flex flex-col">
          <h4>Datetime Format:</h4>
          <p>{multiItemsData.inputTextValue}</p>
        </div>
        <div className="flex flex-col">
          <h4>Timezone:</h4>
          <p>{multiItemsData.dropdownValue}</p>
        </div>
      </div>
    );
  };

  const applyRulesSidebarPage = (
    <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
      {items.length > 0 ? (
        <div>
          {items.map((item, index) => (
            <div key={index} style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
              <Tooltip
                target={`.custom-target-icon-${index}`}
                mouseTrack
                content={
                  item.multiInfoData
                    ? ToolTipHandler(item.multiInfoData)
                    : item?.info
                }
              />
              <Tooltip
                position="bottom"
                target={`.custom-tooltip-btn-${index}`}
              >
                {item?.multiInfoData ? (
                  <div className="">
                    <div className="flex flex-col">
                      <h4>Datetime Format:</h4>
                      <p className=" font-bold">
                        {item?.multiInfoData.inputTextValue}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h4>Timezone:</h4>
                      <p className=" font-bold">
                        {item?.multiInfoData.dropdownValue}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>{item.info}</div>
                )}
              </Tooltip>
              <div
                className="item flex grow mr-5"
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragEnter={() => handleDragEnter(index)}
                onDragOver={(e) => e.preventDefault()}
                style={{display:'flex', flexDirection:'row', width:'100%'}}
              >
                <i className="pi pi-bars dragItem"></i>
                <div className="item-label">{item.label}</div>
                {(item?.info || item?.multiInfoData) && (
                  <i
                    className={`pi pi-info-circle text-lg pt-1 ml-auto custom-tooltip-btn-${index}`}
                  />
                )}
              </div>
              <i
                className="pi pi-trash  text-xl text-red-500 cursor-pointer"
                onClick={() => handleDeleteItem(index)}
              ></i>
            </div>
          ))}
        </div>
      ) : (
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', justifyContent:'center', marginTop:'30px'}}>
          <Image src={applyRulesLogo} alt="Image" width="200" />
          <h3 className=" font-extrabold text-2xl pt-1">No Rule Added</h3>
          <p>Start Adding Some Rules.</p>
        </div>
      )}

      <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />
      <div className="flex justify-center w-full">
        <Button
          label="Add Rule"
          className="border border-blue-500 text-blue-500 w-full py-3"
          outlined
          onClick={() => setIsAddRuleActive(true)}
        />
      </div>
    </div>
  );

  const addRulesSidebarPage = (
    <div style={{ flexGrow: 1 }}>
      <h3 className=" font-bold text-xl">String Function</h3>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }} className="card">
        <Dropdown
          value={selectedRule?.value}
          onChange={(e) => {
            setSelectedRule(
              applyRuleItemsOption.find((option) => option.value === e.value)
            );
          }}
          options={applyRuleItemsOption}
          optionLabel="label"
          placeholder="Select"
          className="w-full  border"
        />
        <>
          {selectedRule?.value === "nan_default" &&
            tabs[activeIndex] === "cleansing" && (
              <p className=" mt-5" style={{fontSize:'16px', fontWeight:'200'}}>
                “Nan Default” replaces missing or undefined values ( NaN) with a
                specified default value.
              </p>
            )}
        </>

        <div>
          {selectedRule?.type === "dropdown" ? (
            <>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'flex-end',
                flexDirection: 'column'
              }}>
                <Dropdown
                  className={`w-full h-10 border mt-7 ${isAddRuleError ? "border-red-500" : ""
                    }`}
                  options={dropdownOptions}
                  value={value}
                  onChange={handleDropdownChange}
                  placeholder="Select a value"
                />
                {isAddRuleError && (
                  <p className="text-red-500 mt-2">
                    Please select a valid option.
                  </p>
                )}
              </div>
            </>
          ) : selectedRule?.type === "input" ? (
            <>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'flex-end',
                flexDirection: 'column'
              }} className="card">
                <InputText
                  style={{
                    width: '100%',
                    height: '40px',
                    border: isAddRuleError ? '1px solid #EF4444' : '1px solid #e5e7eb',
                    marginTop: '28px'
                  }}
                  value={value}
                  placeholder={selectedRule?.inputConfiglabels}
                  onChange={handleNanDefaultValue}
                />
                {isAddRuleError && (
                  <p style={{ 
                    color: '#EF4444',
                    marginTop: '8px'
                  }}>
                    Please enter a valid value.
                  </p>
                )}
              </div>
            </>
          ) : selectedRule?.type === "number" ? (
            <>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'flex-end',
                flexDirection: 'column'
              }} className="card">
                <InputNumber
                  style={{
                    width: '100%',
                    height: '40px',
                    border: isAddRuleError ? '1px solid #EF4444' : '1px solid #e5e7eb',
                    marginTop: '28px'
                  }}
                  value={numberValue}
                  placeholder={selectedRule?.inputConfiglabels}
                  onChange={handleNumberInputValue}
                />
                {isAddRuleError && (
                  <p style={{ 
                    color: '#EF4444',
                    marginTop: '8px'
                  }}>
                    Please enter a valid value.
                  </p>
                )}
              </div>
            </>
          ) : selectedRule?.type === "MultiType" ? (
            <div className="flex flex-col">
              <InputText
                value={formData.inputTextValue}
                onChange={handleNanDefaultValue}
                className="border mt-2 h-10"
                placeholder="Date Format"
              />
              <Dropdown
                value={formData.dropdownValue}
                className="border mt-2 h-10"
                options={dropdownOptions}
                onChange={handleDropdownChange}
                placeholder="Select an option"
              />
            </div>
          ) : selectedRule?.type === "code" ? (
            <div className="flex flex-col">
              <Editor
                height="400px"
                defaultLanguage="python"
                defaultValue="# Write your Python code here"
                value={code}
                className="border mt-2"
                onChange={(value) => setCode(value)}
                options={{
                  selectOnLineNumbers: true,
                  fontSize: 14,
                  minimap: { enabled: false },
                  automaticLayout: true,
                }}
              />
            </div>
          ) : null}
        </div>

        <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />

        <div className="flex justify-end w-full">
          <Button
            label="Add Rule"
            className="border border-blue-500  py-3 px-8 bg-blue-500 text-white"
            outlined
            onClick={handleAddRule}
          />
        </div>
      </div>
    </div>
  );
  return (
    <Sidebar
      visible={visible}
      onHide={handleSidebarHide}
      position="right"
      className="w-2/6  apply-rule-sidebar"
      header={customHeader}
      dismissable={false}
    >
      <>
        <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />
        <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
          {isAddRuleActive ? addRulesSidebarPage : applyRulesSidebarPage}
          {!isAddRuleActive && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              borderTop: '1px solid #d1d5db',
              paddingTop: '16px',
              position: 'sticky',
              bottom: '0',
              backgroundColor:'white'
            }}>
              <Button
                label="Clear All Rules"
                className="border border-red-500  py-3 text-red-500"
                disabled={items?.length === 0}
                onClick={() => handleSidebarClearRule()}
                style={{backgroundColor:'white'}}
              />
              <Button
                label="Apply Rules"
                className="border border-blue-500 py-3 bg-blue-500 text-white"
                onClick={() => handleApplyRules()}
              />
            </div>
          )}
        </div>
      </>
    </Sidebar>
  );
};

export default ApplyRuleSidebar;
