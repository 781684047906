import useApi from "services/api";
import { API_URL } from "../constants";
import { useSharedState } from "../context/ContextWrapper";

const GetUsersList = () => {
  const { data, loading, error, get, del } = useApi();
  const { setActiveAdUsers } = useSharedState();
  const getUsers = async () => {
    const data = await get(`${API_URL}/users/?inactive_required=true`);
    return data;
  };

  const deactivateUser = async (id) => {
    const data = await del(`${API_URL}/users/deactivate/${id}`);
    return data;
  };

  const getUsersList = async (currentPage, rowsPerPage, email, sortField, sortOrder) => {
    const data = await get(
      `${API_URL}/users/credit_summary?page=${currentPage}&page_size=${rowsPerPage}&order_by=${sortField ? sortField : "created_at"}&order=${sortOrder ? sortOrder : "desc"}${email ? `&email=${email}` : ""}`,
    );
    return data;
  };

  const getUserDetails = async (id) => {
    const data = await get(`${API_URL}/intelliapp/user/` + id);
    return data;
  };

  const getUserfromMicrosoft = async (provider) => {
    const data = await get(`${API_URL}/get_users_from_ad?broker_name=${provider}`);
    setActiveAdUsers(data?.results);
  };

  return {
    getUsers,
    getUserDetails,
    getUsersList,
    deactivateUser,
    getUserfromMicrosoft,
    data,
    loading,
    error,
  };
};

export default GetUsersList;
