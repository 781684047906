import React from 'react'

const Investigated = () => {
  return (
  <svg width="14" height="16" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 10L7.35 7.85M8.5 5C8.5 7.20914 6.70914 9 4.5 9C2.29086 9 0.5 7.20914 0.5 5C0.5 2.79086 2.29086 1 4.5 1C6.70914 1 8.5 2.79086 8.5 5Z" stroke="#1DA750" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default Investigated