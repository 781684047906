// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  .layout-container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: calc(100vh - 60px);
    background-color: white;
  }
  .tasks-container > div {
    background-color: #fafafa;
    border: 1px solid #dfe7ef;
    /* height: 7.8rem; */
  }

  .credit .p-datepicker-trigger {
    border: none;
  }

  .p-button.p-highlight {
    background-color: var(--surface-100);
    border-color: var(--surface-300);
  }
}

.calendar-container {
  position: relative;
  display: inline-block;
}

.calendar-popup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DocumentEngine/Dashboard/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B;IAC5B,6BAA6B;IAC7B,0BAA0B;IAC1B,uBAAuB;EACzB;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,oBAAoB;EACtB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,oCAAoC;IACpC,gCAAgC;EAClC;AACF;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".dashboard-container {\n  .layout-container {\n    border-top-left-radius: 12px;\n    border-top-right-radius: 12px;\n    height: calc(100vh - 60px);\n    background-color: white;\n  }\n  .tasks-container > div {\n    background-color: #fafafa;\n    border: 1px solid #dfe7ef;\n    /* height: 7.8rem; */\n  }\n\n  .credit .p-datepicker-trigger {\n    border: none;\n  }\n\n  .p-button.p-highlight {\n    background-color: var(--surface-100);\n    border-color: var(--surface-300);\n  }\n}\n\n.calendar-container {\n  position: relative;\n  display: inline-block;\n}\n\n.calendar-popup {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: 1000;\n  background-color: white;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n  border-radius: 4px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
