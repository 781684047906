import React from 'react'

const StatusIcon = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 3.16634H9.33333M6.66667 3.16634H2M14 8.49967H8M5.33333 8.49967H2M14 13.833H10.6667M8 13.833H2M9.33333 1.83301V4.49967M5.33333 7.16634V9.83301M10.6667 12.4997V15.1663" stroke="#191A1C" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export default StatusIcon