import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ChevronRight from "./icons/ChevronRight";
import SourceConfigBookIcon from "./icons/SourceConfigBookIcon";
// import SettingsCog from "./icons/SettingsBreadcrumbCog";
// import HeaderRight from "../WorkspaceChat/ChatUi/ChatHeader/HeaderRight";

export default function SourcePulseBreadcrumb({
  pulseConfig,
  pulseConfigStream,
  isRuleConfig = false,
}) {
  const [openUserProfile, setOpenUserProfile] = React.useState(false);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handlePulseConfigClick = () => {
    isRuleConfig
      ? window.history.back()
      : navigate("/pulse/stream-source"); // Navigate to the desired path
  };

  return (
    <div
      className={`flex relative gap-5 items-start justify-between pb-2 items-center ml-5`}
    >
      <div className="flex flex-row items-center">
        <div>
          <SourceConfigBookIcon />
        </div>
        <p className="text-md whitespace-nowrap text-black overflow-hidden ml-3">
          Pulse
        </p>
        <div className="ml-3">
          <span>/</span>
        </div>
        <p
          className={`text-md whitespace-nowrap overflow-hidden ml-3 cursor-pointer ${pulseConfigStream ? "text-black" : "text-gray-400"
            }`}
          onClick={pulseConfigStream && handlePulseConfigClick} // Make it clickable
        >
          {pulseConfig}
        </p>
        {pulseConfigStream && (
          <>
            <div className="ml-3 text-gray-400">
              <span>/</span>
            </div>
            <p
              className={`text-md whitespace-nowrap overflow-hidden ml-3 cursor-pointer ${isRuleConfig ? "text-black" : "text-gray-400"
                }`}
              onClick={pulseConfigStream && handlePulseConfigClick} // Make it clickable
            >
              {pulseConfigStream}
            </p>
          </>
        )}
      </div>
      {/* <HeaderRight /> */}
    </div>
  );
}
