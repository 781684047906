import React from 'react'

const NoAlerts = () => {
  return (
        <svg width="150" height="161" viewBox="0 0 150 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z" fill="#FAFAFA"/>
        <g filter="url(#filter0_d_484_33777)">
        <path d="M118 43H32C29.2386 43 27 45.2386 27 48V153C27 155.761 29.2386 158 32 158H118C120.761 158 123 155.761 123 153V48C123 45.2386 120.761 43 118 43Z" fill="white"/>
        </g>
        <path d="M65 58H39C37.3431 58 36 59.3431 36 61C36 62.6569 37.3431 64 39 64H65C66.6569 64 68 62.6569 68 61C68 59.3431 66.6569 58 65 58Z" fill="#E0E0E0"/>
        <path d="M83 71H39C37.3431 71 36 72.3431 36 74C36 75.6569 37.3431 77 39 77H83C84.6569 77 86 75.6569 86 74C86 72.3431 84.6569 71 83 71Z" fill="#EEEEEE"/>
        <path d="M65 85H39C37.3431 85 36 86.3431 36 88C36 89.6569 37.3431 91 39 91H65C66.6569 91 68 89.6569 68 88C68 86.3431 66.6569 85 65 85Z" fill="#E0E0E0"/>
        <path d="M83 98H39C37.3431 98 36 99.3431 36 101C36 102.657 37.3431 104 39 104H83C84.6569 104 86 102.657 86 101C86 99.3431 84.6569 98 83 98Z" fill="#EEEEEE"/>
        <path d="M65 112H39C37.3431 112 36 113.343 36 115C36 116.657 37.3431 118 39 118H65C66.6569 118 68 116.657 68 115C68 113.343 66.6569 112 65 112Z" fill="#E0E0E0"/>
        <path d="M83 125H39C37.3431 125 36 126.343 36 128C36 129.657 37.3431 131 39 131H83C84.6569 131 86 129.657 86 128C86 126.343 84.6569 125 83 125Z" fill="#EEEEEE"/>
        <defs>
        <filter id="filter0_d_484_33777" x="21" y="34" width="108" height="127" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="-3"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_33777"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_33777" result="shape"/>
        </filter>
        </defs>
        </svg>
  )
}

export default NoAlerts