import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../Sidebar/sidebar.css";
import useMediaQuerry from "../../hooks/useMediaQuerry";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { Image } from "primereact/image";
import { useKeycloak } from "@react-keycloak/web";
import { ROLES_KEY } from "constants";

const SidebarComponent = ({
  menuItems,
  settingsItemsConfig,
  selectedItem,
  selectedSettingsItem,
  setSelectedItem,
  setSelectedSettingsItem,
  view,
}) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log("selectedItem", selectedItem);
    const handleClickOutside = (event) => {
      const sidebar = document.querySelector(".sidebar-container");
      if (sidebar && !sidebar.contains(event.target)) {
        if (selectedItem === "settings") {
          console.log("selectedSettingsItem", selectedSettingsItem);

          // setSelectedItem(selectedSettingsItem);
          // setSelectedSettingsItem(selectedSettingsItem);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedItem]);

  const { isMobile } = useMediaQuerry();
  const isAdmin = keycloak.tokenParsed?.resource_access?.[ROLES_KEY]?.roles?.includes("admin");

  const regularMenuItems = menuItems?.filter((item) => item.label.toLowerCase() !== "settings");
  const settingsMenuItem = isAdmin
    ? menuItems?.find((item) => item.label.toLowerCase() === "settings")
    : null;

  const isSettingsItemSelected = (path) => {
    return location.pathname.includes(path.split("/").pop());
  };

  return (
    <div className="sidebar-container">
      <div
        className={`${isMobile ? "custom-sidebar-mobile" : "custom-sidebar"} ${view === "dashboard" ? "border-noround w-full" : ""}  ${visible ? "open" : "closed"}`}
      >
        <div
          className={` flex-col ${view === "dashboard" ? "mt-0" : isMobile ? "flex" : "grid mt-3"}`}
        >
          {view === "dashboard" && (
            <Image src={logo} className={`gap-3 text-xs mt-2 sidebar-button`} />
          )}
          {regularMenuItems.map((item, index) => (
            <Button
              key={index}
              label={item.label}
              icon={item.icon}
              className={`${selectedItem === item.label.toLowerCase() ? "text-blue-500 bg-white" : "text-white"} text-center gap-3 text-xs mt-2 sidebar-button`}
              onClick={item.command}
            />
          ))}
        </div>

        {settingsMenuItem && (
          <div className="settings-trigger mt-auto mb-4">
            <Button
              label={settingsMenuItem.label}
              icon={settingsMenuItem.icon}
              className={`${selectedItem === "settings" ? "text-blue-500 bg-white" : "text-white"} text-center gap-3 text-xs mt-2 sidebar-button`}
              onClick={settingsMenuItem.command}
            />
          </div>
        )}
      </div>

      {/* Settings submenu */}
      {selectedItem === "settings" && (
        <div className={`${view === "dashboard" ? "left-5rem" : ""} settings-submenu`}>
          {settingsItemsConfig
            .filter((item) => !(!isAdmin && item.label.toLowerCase() === "users"))
            .map((item, index) => {
              console.log("item", item);
              const isSelected = isSettingsItemSelected(item.path);
              return (
                <Button
                  key={index}
                  label={item.label}
                  icon={item.icon}
                  className={`${isSelected ? "text-blue-500" : "text-white"} text-center gap-3 text-xs mt-2 settings-button`}
                  style={{
                    backgroundColor: isSelected ? "white" : "transparent",
                  }}
                  onClick={() => {
                    const lowerCaseLabel = item.label.toLowerCase();
                    setSelectedItem(lowerCaseLabel);
                    navigate(item.path);
                  }}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default SidebarComponent;
