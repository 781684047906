// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-summary {
  .p-button.p-highlight {
    background-color: var(--surface-100);
    border-color: var(--surface-300);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/CreditSummary/creditSummary.css"],"names":[],"mappings":"AAAA;EACE;IACE,oCAAoC;IACpC,gCAAgC;EAClC;AACF","sourcesContent":[".credit-summary {\n  .p-button.p-highlight {\n    background-color: var(--surface-100);\n    border-color: var(--surface-300);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
