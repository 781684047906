import React from 'react'

const ActivityResolved = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#E1FAEA"/>
        <circle cx="12" cy="12" r="10" fill="#00C14D"/>
        <path d="M13.571 10.9915L11.4375 13.125L10.7102 12.3977M12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ActivityResolved