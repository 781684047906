
import StructuredSources from "./StructuredSources";
import DocumentSources from "./DocumentSources";
import NewSource from "./NewSource";
import EditSource from "./EditSource";

const withSourceConfigWrapper = (Component) => {
  return (props) => (
    <div id="source-config-container">
      <Component {...props} />
    </div>
  );
};

const WrappedStructuredSources = withSourceConfigWrapper(StructuredSources);
const WrappedDocumentSources = withSourceConfigWrapper(DocumentSources);
const WrappedNewSource = withSourceConfigWrapper(NewSource);
const WrappedEditSource = withSourceConfigWrapper(EditSource);

export {
  WrappedStructuredSources as StructuredSources,
  WrappedDocumentSources as DocumentSources,
  WrappedNewSource as NewSource,
  WrappedEditSource as EditSource,
};
