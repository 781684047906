// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[class*="sourcetablesfieldsmodal"] div.p-checkbox-box {
  border: 2px solid #ced4da;
}
div[class*="sourcetablesfieldsmodal"]
  div.indeterminate-checkbox
  div.p-checkbox-box {
  border: 0px;
}

div[class*="sourcetablesfieldsmodal"] div.p-checkbox-box.p-highlight {
  background-color: #3c82f6;
  border: 0px;
}

/* CustomCheckbox.css */
.indeterminate-checkbox .p-checkbox-box {
  background-color: #3c82f6;
  border: 0px;
  /* border: 2px solid #CED4DA */
}

.indeterminate-checkbox .p-checkbox-box::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 1px;
  background: white; /* Customize the color */
  transform: translate(-50%, -50%);
  border: 0px;
  /* border: 2px solid #CED4DA */
}
.sourcetablesfieldsmodal {
  .table-acc div[data-pc-name="checkbox"] div[data-p-focus="true"] {
    outline: none !important;
    box-shadow: none !important;
}
}

input[class*="search-table-fields"] {
  outline: none !important;
  box-shadow: none !important;
}

div[class*="table-acc"] span[class*="p-accordion-header-text"] > div {
  align-items: center;
}

.sourcetablesfieldsmodal div[class*="p-checkbox p-component p-highlight"] div[class*="p-checkbox-box"] {
border:0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/SourceConfig/StructuredSources/SourceTablesFieldsModal/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;;;EAGE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA,uBAAuB;AACvB;EACE,yBAAyB;EACzB,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,WAAW;EACX,iBAAiB,EAAE,wBAAwB;EAC3C,gCAAgC;EAChC,WAAW;EACX,8BAA8B;AAChC;AACA;EACE;IACE,wBAAwB;IACxB,2BAA2B;AAC/B;AACA;;AAEA;EACE,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;AACA,UAAU;AACV","sourcesContent":["div[class*=\"sourcetablesfieldsmodal\"] div.p-checkbox-box {\n  border: 2px solid #ced4da;\n}\ndiv[class*=\"sourcetablesfieldsmodal\"]\n  div.indeterminate-checkbox\n  div.p-checkbox-box {\n  border: 0px;\n}\n\ndiv[class*=\"sourcetablesfieldsmodal\"] div.p-checkbox-box.p-highlight {\n  background-color: #3c82f6;\n  border: 0px;\n}\n\n/* CustomCheckbox.css */\n.indeterminate-checkbox .p-checkbox-box {\n  background-color: #3c82f6;\n  border: 0px;\n  /* border: 2px solid #CED4DA */\n}\n\n.indeterminate-checkbox .p-checkbox-box::before {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 10px;\n  height: 1px;\n  background: white; /* Customize the color */\n  transform: translate(-50%, -50%);\n  border: 0px;\n  /* border: 2px solid #CED4DA */\n}\n.sourcetablesfieldsmodal {\n  .table-acc div[data-pc-name=\"checkbox\"] div[data-p-focus=\"true\"] {\n    outline: none !important;\n    box-shadow: none !important;\n}\n}\n\ninput[class*=\"search-table-fields\"] {\n  outline: none !important;\n  box-shadow: none !important;\n}\n\ndiv[class*=\"table-acc\"] span[class*=\"p-accordion-header-text\"] > div {\n  align-items: center;\n}\n\n.sourcetablesfieldsmodal div[class*=\"p-checkbox p-component p-highlight\"] div[class*=\"p-checkbox-box\"] {\nborder:0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
