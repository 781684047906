import React, { useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { isMobile } from "react-device-detect";
import InnerSidebar from "layouts/InnerSidebar";
import SourcePulseBreadcrumb from "components/SourcePulseBreadcrumb";
import { Toast } from "primereact/toast";
import { useLocation } from "react-router-dom";
import AddRuleSidebar from "./addRuleSidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import AlertTab from "../AlertTab";
import { toTitleCase } from "utils/helper";
import AlertTabDetailsSlider from "./AlertTabDetailsSlider";
import "./index.css";
import DynamicDialog from "assets/DialogBox/dialogBox";
import { Menu } from "primereact/menu";
import { Skeleton } from "primereact/skeleton";
import NoAlerts from "../AlertTab/AlertTabList/icons/NoAlerts";
import Raised from "../AlertTab/AlertTabList/icons/Raised";
import Acknowledged from "../AlertTab/AlertTabList/icons/Acknowledged";
// import Investigated from "../AlertTab/AlertTabList/icons/Investigated";
import Escalated from "../AlertTab/AlertTabList/icons/Escalated";
import Notified from "../AlertTab/AlertTabList/icons/Notified";
import Resolved from "../AlertTab/AlertTabList/icons/Resolved";
import NotificationConfigTab from "../NotificationConfig";
import { usePulseApi } from "hooks/pulseConfig";
import { useWorkspaceApi } from "hooks/workspaceConfig";
// import { RULES_LIST } from "./mockRules";
import { format } from "date-fns";
import GetUsersList from "hooks/getUsers";

const StreamDetailRule = ({ }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [isUsingInModalOpen, setUsingInModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [sourceRulesData, setSourceRulesData] = useState([]);
  const [allRulesListData, setAllRulesListData] = useState([]);
  const [alerts, setAlerts] = useState([]); // State to store fetched alerts
  const [selectedAlertId, setSelectedAlertId] = useState();
  const [isAlertModalOpen, setAlertModalOpen] = useState(false); // Track modal visibility
  const [dialogVisible, setDialogVisible] = useState({});
  const [selectedDeletedItem, setSelectedDeletedItem] = useState();
  const [allUserData, setAllUserData] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [selectedRuleId, setSelectedRuleId] = useState(null);

  const toast = useRef(null);
  const location = useLocation();
  const op = useRef(null);
  const { getSourceRuleList, updatedRuleStatus, deleteRule } = usePulseApi();
  const { getAllRootKnowledgeSpaces } = useWorkspaceApi();
  const { getUsers } = GetUsersList();

  // const getAllUser = getUsers; // Or directly use getUsers elsewhere

  useEffect(() => { }, [selectedAlertId]);
  useEffect(() => {
    setAllRulesListData([]);
  }, [isActive]);

  const CardSubtitle = ({
    id,
    created_at,
    alert_message,
    raised,
    notified,
    acknowledged,
    // investigated,
    resolved,
    escalated,
  }) => {
    const getAlertType = ({
      raised = false,
      notified = false,
      acknowledged = false,
      // investigated = false,
      resolved = false,
      escalated = false,
    }) => {
      if (resolved) return "resolved";
      if (acknowledged) return "acknowledged";
      if (escalated) return "escalated";
      // if (investigated) return "investigated";
      if (notified) return "notified";
      if (raised) return "raised";
      return "raised"; // Default to 'raised' if no other status is true
    };
    const alertType = getAlertType({
      raised,
      notified,
      acknowledged,
      // investigated,
      resolved,
      escalated,
    });

    const getIconByType = (type) => {
      switch (type) {
        case "raised":
          return <Raised />;
        case "acknowledged":
          return <Acknowledged />;
        case "escalated":
          return <Escalated />;
        // case "investigated":
        //   return <Investigated />;
        case "notified":
          return <Notified />;
        case "resolved":
          return <Resolved />;
        default:
          return null;
      }
    };

    const alertIcon = getIconByType(alertType);

    const formatAlertTime = (time) => {
      const alertDate = new Date(time);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // If the alert is from today, format as HH:mm
      if (alertDate.toDateString() === today.toDateString()) {
        return alertDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      }

      // // If the alert is from yesterday, show "A day ago"
      // if (alertDate.toDateString() === yesterday.toDateString()) {
      //   return 'A day ago';
      // }

      // Otherwise, show the full date
      return alertDate.toLocaleDateString("en-GB"); // DD/MM/YYYY format
    };

    const alertTime = formatAlertTime(created_at);

    return (
      <div className="flex flex-row justify-between items-center pb-2 border-solid border-b-[1px] border-[#DFE7EF]">
        <div className="flex flex-col">
          <div className="flex flex-row items-center mt-1 text-md font-light font-sans text-black-900">
            ID {id}
            <div className="flex flex-row bg-[#F5F5F5] items-center ml-[20px] p-[1px] pl-[4px] pr-[4px] rounded-2xl">
              <div className="ml-2">{alertIcon}</div>
              <Badge 
                text={alertType.charAt(0).toUpperCase() + alertType.slice(1)}
                className="text-gray-600" 
              />
            </div>
          </div>
          <div className="flex flex-row mt-2 mb-2">
            <span className="text-sm text-[#72777B] font-light">
              {alert_message}
            </span>
          </div>
        </div>
        <div className="flex flex-row text-[#72777B] items-center ml-[20px] pl-[5px] pr-[10px] rounded-2xl">
          {/* {formatDate(format(new Date(created_at), "MM/dd/yyyy HH:mm:ss"))}{" "} */}
          {alertTime}
          {/* Using date-fns to format the date */}
        </div>
      </div>
    );
  };

  const Badge = ({ text, className = '' }) => {
    return (
      <span className={`inline-flex text-left bg-[#F5F5F5] text-sm mt-1 w-fit px-2 py-1 rounded-full text-xs md:text-sm ml-1 ${className}`}>
        {text}
      </span>
    );
  };  

  const fetchData = async (activeState = isActive) => {
    const payload = {
      namespace_id: `${location?.state?.sourceRowData?.namespace_id !== null
        ? location?.state?.sourceRowData?.namespace_id
        : location?.state?.sourceId !== null
          ? location?.state?.sourceId
          : "26869bef-3bd3-47c9-a1c2-g65e2002w9mn"
        }`,
      asset_id: `${location?.state?.sourceRowData?.asset_id !== null
        ? location?.state?.sourceRowData?.asset_id
        : location?.state?.sourceRowData?.id !== null
          ? location?.state?.sourceRowData?.id
          : "HI-001-0001"
        } `,
      active: activeState,
    };
    setLoading(true);
    const response = await getSourceRuleList(payload);
    const _getUserRole = await getUsers()
    const allFetchedKS = getAllRootKnowledgeSpaces();

    let knowledgeSpaceOptionsList = [];
    (async () => {
      try {
        const alkData = await Promise.allSettled([allFetchedKS]);
        knowledgeSpaceOptionsList = alkData?.[0].value.map((item) => ({
          label: item?.space_name,
          value: item?.id,
        }));
        setAllUserData((prevState) => ({
          ...prevState,
          knowledgeSpace: knowledgeSpaceOptionsList,
        }));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    })();
    console.log('_getUserRole , ', _getUserRole)
    const userOptionsList = _getUserRole?.results?.users
      ?.filter((item) => item.firstname && item.firstname.trim() !== "")
      .map((item) => ({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      }));
    setAllUserData((prevState) => ({
      ...prevState,
      userList: userOptionsList || [],
    }));

    const transformedData = response?.results?.data?.rules?.map((item) => ({
      id: item.id,
      severity: item.severity.toLowerCase(),
      alerts: item.active_alerts_count,
      remainingObject: { ...item },
    }));

    setSourceRulesData(transformedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSuccess = (success) => {
    if (success?.type) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Rule created successfully!",
      });
      setAllRulesListData([]);
      fetchData();
      setSelectedRuleId(null);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: success?.message,
      });
    }
  };

  const handleActiveDeactivateList = () => {
    // setLoading(true)
    setIsActive((prev) => {
      const updatedActiveState = !prev;
      fetchData(updatedActiveState);
      // setLoading(false);
      return updatedActiveState;
    });
  };

  const handleUpdateStatus = async () => {
    const payload = {
      rule_id: selectedDeletedItem,
      action: !isActive ? "activate" : "deactivate",
    };
    const response = await updatedRuleStatus(payload);
    if (response?.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Rule updated successfully!",
      });
      fetchData();
      setAllRulesListData([]);
      setSelectedRuleId(null);
      setDialogVisible({});
    } else if (response?.status === 400) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response?.message,
      });
    }
  };

  const openSidebar = (key) => {
    setActiveTab(key);
    if (key === "ruleDefinition") {
      setVisible(true);
    }
    // else {
    //   setEnableEditSidebar(true);
    // }
  };
  const handleRuleClick = async (rule) => {
    // TODO: Comment rule notification will use this code in different tab
    // let alert_rule_id = rule?.id;

    // let namespace_id = `${location?.state?.sourceId}`;
    // let asset_id = `${location?.state?.sourceRowData?.id}`;
    // let ruleQuerySearch = "";

    // const ruleData = await Feed.getRulesForNotification(
    //   asset_id,
    //   namespace_id,
    //   ruleQuerySearch,
    //   alert_rule_id
    // );

    // const ruleNotificationData = (
    //   ruleData?.results?.data?.alert_definitions || []
    // )?.map((alert_definitions) => ({
    //   alert_rule_id: alert_rule_id,
    //   definition_id: alert_definitions?.id,
    //   alert_message: alert_definitions?.alert_message,
    //   escalationTime: alert_definitions.escalation_time,
    //   level1Respondents:
    //     alert_definitions?.primary_spoc
    //       ?.map((spocId) =>
    //         allUserData?.userList?.find((user) => user.value === spocId)
    //       )
    //       ?.filter(Boolean) || [], // Filters out any non-matches
    //   level2Respondents:
    //     alert_definitions?.secondary_spoc
    //       ?.map((spocId) =>
    //         allUserData?.userList?.find((user) => user.value === spocId)
    //       )
    //       ?.filter(Boolean) || [],
    //   level3Respondents:
    //     alert_definitions?.tertiary_spoc
    //       ?.map((spocId) =>
    //         allUserData?.userList?.find((user) => user.value === spocId)
    //       )
    //       ?.filter(Boolean) || [],
    //   knowledgeSpace:
    //     alert_definitions.knowledge_space
    //       ?.map((spaceId) =>
    //         allUserData.knowledgeSpace.find((space) => space?.value === spaceId)
    //       )
    //       ?.filter(Boolean)
    //       ?.map((space) => space.label) || [],
    // }));
    setSelectedRuleId(rule.id);
    setAllRulesListData((prevState) => ({
      ...prevState,
      ruleDefinationData: rule?.remainingObject?.meta_details || [],
      severity: rule?.severity,
      rule_id: rule?.id,
    }));
    setAlerts(rule?.remainingObject?.active_alerts);
  };

  const handleCloseModal = () => {
    setAlertModalOpen(false);
    setSelectedAlertId(null);
  };

  const rightSideRuleData = () => {
    if (!allRulesListData || allRulesListData.length === 0) return null;
    return (
      <div className="rule-definition">
        <div className="flex items-center justify-between mb-2">
          <div className="flex flex-row">
            <span className="text-lg font-bold">
              Rule ID {allRulesListData?.rule_id}
            </span>
            <span className="bg-[#F5F5F5]  text-sm font-semibold py-1 px-3 ml-5 rounded-full text-gray-600">
              {toTitleCase(allRulesListData?.severity)} Severity
            </span>
          </div>
          <Button
            icon="pi pi-ellipsis-v"
            className="p-button-rounded p-button-text font-semibold text-title_black ml-auto focus:outline-none focus:ring-2"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDeletedItem(allRulesListData?.rule_id);
              op.current.toggle(e); // Toggle the menu
            }}
            aria-controls="popup_menu"
            aria-haspopup
          />
          {/* Menu with Options */}
          <Menu
            model={menuItems}
            popup
            ref={op} // Ref for controlling menu visibility
            id="popup_menu"
            className="z-50"
          />
        </div>
        <div className="mb-4">
          <span className="font-light">
            Duration{" "}
            <span className="text-md font-thin text-[#4F4D55] font-sans">
              ➔
            </span>
          </span>
          <span className="ml-2 font-light">
            {allRulesListData?.ruleDefinationData?.setDuration?.startDate} to{" "}
            {allRulesListData?.ruleDefinationData?.setDuration?.endDate}
          </span>
        </div>
        <Divider className="border" />
        <Accordion>
          <AccordionTab
            className="stream-details-rule-accordion"
            header={
              <div className="flex justify-between items-center">
                <span>Rule Definition</span>
                <i
                  className="pi pi-pencil cursor-pointer text-blue-500 hover:text-gray-700"
                  onClick={() => openSidebar("ruleDefinition")}
                />
              </div>
            }
            className="max-h-[35vh] overflow-y-auto"
          >
            {/* Render typeOfRule */}
            <div className="flex flex-col mb-4">
              <span className="text-sm text-title_black">Rule Type</span>
              <Badge text={allRulesListData?.ruleDefinationData?.typeOfRule || "N/A"} />
            </div>

            {/* Render conditions */}
            {allRulesListData?.ruleDefinationData?.conditions?.map(
              (condition, index) => {
                const conditionData = condition?.condition || condition;

                return (
                  <div key={index} className="mb-6">
                    <span className="text-lg font-semibold block mb-2">
                      Condition {index + 1}
                      {condition?.logic ? ` (${condition.logic})` : ""}
                    </span>

                    {/* Subtype and Operator Row */}
                    <div>
                      {conditionData?.defineX ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                          {/* Subtype */}
                          <div
                            className="flex flex-col mb-4"
                            style={{
                              gridColumn:
                                conditionData?.subtype?.length > 30
                                  ? "span 2"
                                  : "span 1",
                            }}
                          >
                            <span className="text-sm ml-1 text-title_black mt-1">
                              Subtype
                            </span>
                            <Badge text={conditionData?.subtype || "N/A"} />
                          </div>

                          {/* Define "X" and Operator */}
                          <div className="flex space-x-4">
                            <div
                              className="flex flex-col mr-14"
                              style={{
                                gridColumn:
                                  conditionData?.defineX?.length > 30
                                    ? "span 2"
                                    : "span 1",
                              }}
                            >
                              <span className="text-sm ml-1 text-title_black">
                                Define "X"
                              </span>
                              <Badge text={conditionData?.defineX} />
                            </div>

                            <div className="flex flex-col">
                              <span className="text-sm ml-1 text-title_black">
                                Operator
                              </span>
                              <Badge text={conditionData?.operator || "N/A"} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                          <div
                            className="flex flex-col md:mr-8"
                            style={{
                              gridColumn:
                                conditionData?.subtype?.length > 30
                                  ? "span 2"
                                  : "span 1",
                            }}
                          >
                            <span className="text-sm ml-1 text-title_black">
                              Subtype
                            </span>
                            <Badge text={conditionData?.subtype || "N/A"} />
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm ml-1 text-title_black">
                              Operator
                            </span>
                            <Badge text={conditionData?.operator || "N/A"} />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Threshold Value and Unit Row */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
                      <div className="flex flex-col items-start rounded-md mr-8">
                        <span className="text-sm ml-1 text-title_black">
                          Threshold Value
                        </span>
                        <Badge text={conditionData?.thresholdValue || "N/A"} />
                      </div>

                      <div className="flex flex-col items-start rounded-md">
                        <span className="text-sm ml-1 text-title_black">
                          Unit
                        </span>
                        <Badge text={conditionData?.unit || "N/A"} />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </AccordionTab>
          <AccordionTab header="Alerts" className="active-alerts">
            {alerts?.length > 0 ? (
              <p>Active Alerts ({alerts?.length})</p>
            ) : (
              <div className="flex items-center  text-[#3bb38c]">
                <i className="pi pi-check text-[#3bb38c] text-xl  mr-2"></i>
                <span className="text-sm">No Alerts</span>
              </div>
            )}
            {alerts?.map((alert) => (
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  marginTop: "20px",
                }}
              >
                <div
                  key={alert.id}
                  onClick={() => {
                    setSelectedAlertId(alert.id);
                    setAlertModalOpen(true); // Open the modal
                  }} // Set selected alert
                  style={{
                    width: "100%",
                    // background: selectedAlertId === alert.id ? '#F2F6FB' : 'white', // Conditional background color
                    // borderLeft: selectedAlertId === alert.id ? '3px solid #1581ED' : 'none', // Conditional left border
                    background:
                      selectedAlertId === alert.id ? "#F2F6FB" : "white",
                    paddingLeft: "10px",
                    cursor: "pointer", // Add pointer to indicate it's clickable
                  }}
                  className="mb-2" // Add padding and margin for better appearance
                >
                  <CardSubtitle {...alert} />
                </div>
              </div>
            ))}
          </AccordionTab>
        </Accordion>
        {isAlertModalOpen && (
          <AlertTabDetailsSlider
            selectedAlertId={selectedAlertId} // Pass the selected alert ID to the modal
            visible={isAlertModalOpen} // Control modal visibility
            onClose={handleCloseModal} // Handle modal close
          />
        )}
      </div>
    );
  };

  const menuItems = [
    {
      label: (
        <div className="flex items-center justify-center w-fit">
          {!isActive ? (
            <span className="flex items-center justify-center">Activate</span>
          ) : (
            <span className="flex items-center justify-center">Deactivate</span>
          )}
        </div>
      ),
      command: (e) => {
        e.originalEvent.stopPropagation(); // Prevent row click
        setDialogVisible({
          isActive: true,
          type: "deactivate",
        });
      },
    },
    {
      label: (
        <div className="flex items-center justify-center w-fit text-red-400">
          <span className="flex items-center justify-center">Delete</span>
        </div>
      ),
      command: (e) => {
        e.originalEvent.stopPropagation(); // Prevent row click
        setDialogVisible({
          isActive: true,
          type: "delete",
        });
      },
    },
  ];

  const ButtonSection = () => {
    return (
      <div className="flex flex-row items-center space-x-4">
        <div className="switch-button-pair flex w-fit h-fit text-sm bg-[#FAFAFA] p-1 rounded-lg justify-between items-center">
          <Button
            onClick={() => handleActiveDeactivateList()}
            className={`py-2 px-4 h-full rounded-lg transition-colors mr-2 duration-300 ${
              isActive
                ? "switch-button-active bg-white text-black font-medium custom-shadow"
                : "switch-button-inactive text-gray-600"
            }`}
          >
            Active
          </Button>

          <Button
            onClick={() => handleActiveDeactivateList()}
            className={`py-2 px-4 h-full rounded-lg transition-colors duration-300 ${!isActive
              ? "switch-button-active bg-white text-black font-medium custom-shadow"
              : "switch-button-inactive text-gray-600"
              }`}
          >
            Deactivated
          </Button>
        </div>
        <div className="w-1/3"></div>

        {isActive && sourceRulesData && sourceRulesData.length > 0 && (
          <div className="w-2/3 flex justify-end h-12">
            <Button
              className="w-fit px-4 py-1 text-xs font-thin bg-[#1581ED] rounded-lg text-white"
              style={{display:'flex', whiteSpace:'nowrap'}}
              label="Add New Rule"
              size="small"
              onClick={() => {
                setActiveTab("");
                setVisible(true);
              }}
            />
          </div>
        )}
      </div>
    );
  };
  const ruleData = () => {
    return (
      <>
        <div className="flex space-x-4 max-h-[32rem]">
          {loading ? (
            <div className="flex flex-col w-2/5 p-4">
              <ButtonSection />
              <div className="mt-7 flex flex-col space-y-4 overflow-y-auto">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
              </div>
            </div>
          ) : sourceRulesData && sourceRulesData.length > 0 ? (
            <div className="flex flex-col w-2/5 p-4">
              <ButtonSection />
              <div className="mt-3 overflow-y-auto">
                {sourceRulesData.map((rule, index) => (
                  <React.Fragment key={rule.id}>
                    <div
                      className={`rule-list-unit mb-3 cursor-pointer px-4 pb-3 rounded-sm border-l-2 h-auto transition-colors duration-200 shadow-sm ${
                        selectedRuleId === rule.id
                          ? "bg-[#f2f6fb] border-l-[#1581ED]"
                          : "bg-white border-l-transparent"
                      }`}
                      onClick={() => handleRuleClick(rule)}
                    >
                      <div className="flex flex-row items-center mt-2 justify-between">
                        <div className="flex flex-col">
                          <div className="flex items-center pt-4">
                            <span className="text-md font-light text-black-900 font-sans">
                              Rule ID {rule.id}
                            </span>
                            <span className="bg-[#F5F5F5]  text-sm font-semibold py-1 px-3 ml-5 rounded-full text-gray-600">
                              {toTitleCase(rule.severity)} Severity
                            </span>
                            <Menu
                              model={menuItems}
                              popup
                              ref={op}
                              id="popup_menu"
                            />
                          </div>
                          <p className="text-sm text-[#72777B] font-light pb-1 mt-2">
                            {rule.alerts} Active Alerts
                          </p>
                        </div>
                        {selectedRuleId === rule.id && (
                          <Button
                            icon="pi pi-ellipsis-v"
                            className="p-button-rounded mt-1 p-button-text ml-auto font-semibold text-title_black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedDeletedItem(rule.id);
                              op.current.toggle(e);
                            }}
                            aria-controls="popup_menu"
                            aria-haspopup
                          />
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-full p-4">
              <ButtonSection />
              <div className="flex flex-col items-center justify-center mr-[20%]">
                <div className="ml-[10%]">
                  <NoAlerts />
                </div>
                <span className="text-xl ml-[10%] font-semibold mt-[2vh]">
                  {isActive ? "No Rules" : "No Deactivated Rules"}
                </span>
                <Button
                  className="w-fit bg-[#1581ED] font-normal rounded-md px-3 py-2 text-white mt-[2vh] text-sm add-new-rule-btn"
                  onClick={() => {
                    setActiveTab("");
                    setVisible(true);
                  }}
                >
                  Add New Rule
                </Button>
              </div>
            </div>
          )}
          {sourceRulesData && sourceRulesData.length > 0 && (
            <>
              <div className="w-[1px] bg-[#dee2e6]"></div>
              <div className="w-3/5 p-4">{rightSideRuleData()}</div>
            </>
          )}
        </div>
      </>
    );
  };

  const alertData = () => {
    return (
      <div className="flex flex-col">
        {/* <div className="border border-[#DEE2E6] mt-[-20px]"></div> */}
        <AlertTab streamId={location?.state?.sourceRowData?.stream_id} />
      </div>
    );
  };
  const nortificationData = () => {
    return (
      <div className="flex flex-col notification-config">
        <div className="border border-[#DEE2E6] mt-[-20px]"></div>
        <NotificationConfigTab
          userData={allUserData}
          allRuleData={sourceRulesData}
        />
      </div>
    );
  };

  const handleDeleteRule = async () => {
    let deleteRuleResponse = await deleteRule(selectedDeletedItem);
    if (deleteRuleResponse?.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Rule deleted successfully!",
      });
      fetchData();
      setAllRulesListData([]);
      setSelectedRuleId(null);
    } else if (deleteRuleResponse?.status === 400)
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: deleteRuleResponse?.message,
      });
    setDialogVisible({});
  };

  const dialogFooter = (
    <div className="flex justify-end stream-detail-rules-dialog-footer">
      <Button
        className="border border-gray-500 h-12 px-9 mr-3"
        icon="pi pi-times"
        label="No"
        onClick={() => setDialogVisible({})}
      />
      <Button
        className="border h-12 px-9 bg-red-500 text-white"
        icon="pi pi-check"
        label="Yes"
        onClick={
          dialogVisible?.type === "deactivate"
            ? handleUpdateStatus
            : handleDeleteRule
        }
      />
    </div>
  );

  const tabContent = (index) => {
    if (index === 0) {
      return ruleData();
    } else if (index === 1) {
      return nortificationData();
    }
    return alertData();
  };
  return (
    <>
      <div className="new-source-setup">
        <div className="w-full h-screen overflow-hidden bg-sidebar flex">
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="flex flex-col items-around mt-3 transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full"
          >
            {/* <SourcePulseBreadcrumb
              routes={[
                "Pulse",
                "Stream Data Sources",
                decodeURIComponent(location?.pathname?.split("/")[4]),
                location?.state?.sourceRowData?.stream_id,
              ]}
            />
            <div
              style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
            ></div> */}
            <div className="source-list ">
              {/* {isUsingInModalOpen && (
                <SourceUsingInModal
                  source={selectedRow}
                  onClose={() => setUsingInModal(false)}
                  visible={isUsingInModalOpen}
                />
              )} */}
              {visible && (
                <AddRuleSidebar
                  visible={visible}
                  setVisible={setVisible}
                  onSuccess={handleSuccess}
                  allRulesListData={allRulesListData}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              )}

              <div className="mt-2">
                <h2 className="font-semibold text-xl text-[#191A1C] ml-4">
                  {location?.state?.sourceRowData?.stream_id}
                </h2>
                <div className="card">
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    className="alert-details-tabview"
                  >
                    {["Rules", "Notification Configuration", "Alerts"].map(
                      (header, index) => (
                        <TabPanel
                          key={index}
                          header={
                            <span
                              className={`py-2 px-4 transition-colors duration-200 ${activeIndex === index
                                ? "border-b-2 border-[#3B82F6] !text-[#3B82F6]"
                                : ""
                                }`}
                            >
                              {header}
                            </span>
                          }
                        >
                          {tabContent(index)}{" "}
                        </TabPanel>
                      )
                    )}
                  </TabView>
                </div>
                <Toast ref={toast} position="top-right" />
              </div>
            </div>
            {/* <Divider /> */}
          </div>
        </div>
      </div>
      <DynamicDialog
        header={
          dialogVisible?.type === "deactivate" ? (
            <>{!isActive ? <h4>Activate Rule</h4> : <>Deactivate Rule</>}</>
          ) : (
            <h4>Delete Rule</h4>
          )
        }
        visible={dialogVisible?.isActive ? dialogVisible?.isActive : false}
        footer={dialogFooter}
        draggable={false}
        content={
          dialogVisible?.type === "deactivate" ? (
            <div className="flex flex-row items-center">
              <i
                className="pi pi-exclamation-triangle p-3 rounded"
                style={{
                  fontSize: "2rem",
                }}
              ></i>
              {isActive ? (
                <p className="whitespace-pre-line px-4">
                  Are you sure you want to deactivate the rule?
                </p>
              ) : (
                <p className="whitespace-pre-line px-4">
                  Are you sure you want to activate the rule?
                </p>
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <i
                className="pi pi-exclamation-triangle p-3 rounded"
                style={{
                  fontSize: "2rem",
                }}
              ></i>
              <p className="whitespace-pre-line px-4">
                Deleting this rule will remove all associated alerts (if any).
                Are
                <br />
                you sure you want to proceed?
              </p>
            </div>
          )
        }
        onHide={() => setDialogVisible({})}
      />
    </>
  );
};

export default StreamDetailRule;
