import useUser from "hooks/useUser";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useState, useRef } from "react";
import ManageAllocation from "./ManageAllocation";
import GetUsersList from "hooks/getUsers";
import { useRefresh, useActiveAdUsers } from "context/ContextWrapper";
import { useKeycloak } from "@react-keycloak/web";
import { Dropdown } from "primereact/dropdown";
import Authentication from "hooks/authentication";
import useCreditSummary from "hooks/useCreditSummary";
const groups = [
  { name: "Marketing", id: 1 },
  {
    name: "Market Research Team",
    id: 2,
  },
  {
    name: "Market Development",
    id: 3,
  },
];

const defaultValue = {
  allocation_type: "one_time",
  credits_to_be_allotted: 100,
  is_daily_limit_enabled: false,
  daily_limit: "",
  frequency: "",
  frequency_unit: "",
};

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const AddNewUser = ({ isVisible, setIsVisible, toast }) => {
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [allocationType, setAllocationType] = React.useState(defaultValue);
  const { setRefresh } = useRefresh();
  const { getUsersList, getMicrosoftToken } = GetUsersList();
  const { createUser } = useUser();
  const { keycloak } = useKeycloak();
  const userInfo = keycloak?.tokenParsed;
  const { getOrganizations } = Authentication();
  const { getTotalCredits } = useCreditSummary();
  const [users, setUsers] = useState();
  const { activeAdUsers } = useActiveAdUsers();
  const adminId = keycloak.tokenParsed?.user_id;

  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    getTotalCredits()
      .then((data) => {
        setAdmin(data?.results?.data);
      })
      .catch((err) => {
        // console.log("detail", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error fetching credit pool",
          life: 3000,
        });
      });
  }, [adminId]);
  const onClose = () => {
    setEmail("");
    setAllocationType(defaultValue);
    setSelectedGroups([]);
    setIsVisible(false);
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!email) {
        setErrors((prev) => ({ ...prev, email: "Email is required" }));
        return;
      }
      if (!emailRegex.test(email)) {
        setErrors((prev) => ({ ...prev, email: "Invalid email address" }));
        return;
      }
      let user = {
        email,
        ...allocationType,
        credits_to_be_allotted: Number(allocationType.credits_to_be_allotted),
        frequency: Number(allocationType.frequency) || null,
        frequency_unit: allocationType.frequency_unit || null,
        daily_limit: Number(allocationType.daily_limit),
      };

      if (allocationType.allocation_type === "unlimited") {
        user = {
          email,
          allocation_type: "unlimited",
        };
      }
      await createUser(user);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User added successfully!",
      });
      onClose();
      setRefresh(true);
      getUsersList(1, 10, "", "email", "desc");
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: e?.response?.data?.message || "Something went wrong. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      className="bg-white w-26rem"
      onHide={onClose}
      pt={{
        header: {
          className: "pb-0",
        },
        content: {
          className: "p-0",
        },
      }}
      header={<h2 className="text-2xl font-semibold m-0">Add New User</h2>}
    >
      <Divider />
      <div className="flex flex-column">
        <div
          className="px-4 pt-2"
          style={{
            height: "calc(100vh - 11rem)",
            overflowY: "auto",
          }}
        >
          <p className="text-sm m-0 mb-2">User Email</p>
          <Dropdown
            className="w-full text-sm"
            placeholder="Select Email"
            value={email}
            options={activeAdUsers?.length ? activeAdUsers : [{ userPrincipalName: "Loading...", value: null }]}
            onChange={(e) => setEmail(e.value)}
            optionLabel="userPrincipalName"
            optionValue="userPrincipalName"
            filter
            showClear={!!email}
            virtualScrollerOptions={{ itemSize: 38 }}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          />
          {errors.email && <small className="p-error">{errors.email}</small>}

          <h2 className="text-sm mt-5 font-semibold mb-0">Allocate Credits</h2>
          <p className="text-xs font-light m-0 mt-1 text-500">
            New users are allocated a minimum of 100 credits. You can adjust this amount as needed.
          </p>

          <div className="mt-4 pl-3">
            <ManageAllocation
              errors={errors}
              setErrors={setErrors}
              allocationType={allocationType}
              setAllocationType={setAllocationType}
              email={email}
              creditPool={admin?.credit_balance}
            />
          </div>

          <div className="mt-5">
            <p className="text-sm m-0 mb-2">Add in Groups (Optional)</p>
            <MultiSelect
              display="chip"
              value={selectedGroups}
              filter
              //show placeholder for the selected items
              selectedItemTemplate={(value) => {
                if (!selectedGroups.length)
                  return (
                    <p className="p-1 m-0 flex gap-2 text-500">
                      <span className="pi pi-search"></span>
                      Search Groups
                    </p>
                  );
                return value === selectedGroups[0] ? (
                  <p className="p-2 m-0 flex gap-2 text-500">
                    <span className="pi pi-search"></span>
                    Search Groups
                  </p>
                ) : (
                  ""
                );
              }}
              onChange={(e) => setSelectedGroups(e.value)}
              optionLabel="name"
              optionValue="id"
              placeholder="Search Groups"
              className="w-full text-sm"
              options={groups}
            />

            <div className="mt-3 gap-2 flex flex-wrap">
              {selectedGroups.length > 0 &&
                selectedGroups.map((group) => (
                  <Chip
                    style={{
                      fontSize: "10.5px",
                      fontWeight: 600,
                    }}
                    key={group}
                    label={groups.find((g) => g.id === group).name}
                    className=" surface-100 border-1 border-400"
                    removable
                    removeIcon="pi pi-times text-xs"
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="w-full">
          <Divider />
          <div className="flex justify-content-end gap-3 pr-3">
            <Button label="Cancel" text size="small" className="w-20 text-500" />
            <Button
              label="Add User"
              size="small"
              className="w-20"
              onClick={handleSubmit}
              disabled={!email || Object.keys(errors).length > 0}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default AddNewUser;
