import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chip } from "primereact/chip";
import "./index.css";
import React from "react";
import GetUsersList from "hooks/getUsers";
import { useRefresh } from "context/ContextWrapper";
import useSpaces from "hooks/useSpace";

/**
 * Component for setting up a new space.
 * Allows users to input space details, select users, and assign permissions.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.visible - Controls the visibility of the sidebar
 * @param {Function} props.setVisible - Function to toggle the visibility of the sidebar
 * @param {Object} props.toast - Reference to the toast notification component
 * @returns {JSX.Element} The NewSpaceSetup component
 */
const NewSpaceSetup = ({ visible, setVisible, toast }) => {
  const [spaceName, setSpaceName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]); // MultiSelect for users
  const [users, setUsers] = useState([]); // Simulating user options
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [filterText, setFilterText] = useState("");
  const [previousUsers, setPreviousUsers] = useState([]);
  const [tempSelectedUsers, setTempSelectedUsers] = useState([]); // Temporary state for selections
  const [permission, setPermission] = useState("read"); // Default can be 'read' or 'write'
  const [permissionsMap, setPermissionsMap] = useState({}); // Map for user permissions
  const multiSelectRef = useRef(null); // Initialize the ref
  const navigate = useNavigate();
  const { getUsers } = GetUsersList();
  const { refresh, setRefresh } = useRefresh();
  const { createSpace } = useSpaces();

  // Fetches the list of users
  const getUserList = () => {
    getUsers().then((res) => {
      setUsers(res?.results?.users);
    });
  };

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    console.log("permissions map ", permissionsMap);
  }, [permissionsMap]);

  /**
   * Handles the submission of the new space form.
   * Validates inputs and sends a request to create a new space.
   */
  const handleSubmit = async () => {
    const newErrors = {};
    if (!spaceName) newErrors.spaceName = "Space name is required";
    if (!description) newErrors.description = "Description is required";

    const reqBody = {
      name: spaceName,
      description: description,
      user_access: selectedUsers.map((user) => ({
        user_id: user.id,
        access_type: permissionsMap[user.id],
      })),
    };

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      const res = await createSpace(reqBody);
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Space '${spaceName}' created successfully!`,
      });
      setVisible(false);
      setRefresh(!refresh);
      setSpaceName("");
      setDescription("");
      setSelectedUsers([]);
    } catch (error) {
      console.log("error ", error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message || "Failed to create space",
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Updates the permission for a specific user.
   *
   * @param {string} userId - The ID of the user
   * @param {string} newPermission - The new permission to assign
   */
  const updatePermission = (userId, newPermission) => {
    console.log("userid ", userId);
    setPermissionsMap((prev) => ({
      ...prev,
      [userId]: newPermission,
    }));
  };

  // Checks if the form is valid
  const isFormValid = spaceName.trim() && description.trim();

  // Filter out already selected users
  const availableUsers = users.filter(
    (user) => !selectedUsers.some((selectedUser) => selectedUser.id === user.id),
  );

  const resetFields = () => {
    setTempSelectedUsers([]);
    setSelectedUsers([]);
    setSpaceName("");
    setDescription("");
    setErrors({});
    setPermissionsMap({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      className="bg-white w-26rem newspacesetup"
      onHide={() => {
        setVisible(false);
        resetFields();
      }}
      pt={{
        header: { className: "pb-0" },
        content: { className: "p-0" },
      }}
      header={<h2 className="text-2xl font-semibold m-0">New Space Set up</h2>}
    >
      <div>
        <Divider />
        <div className="p-4" style={{ height: "75vh", overflow: "auto", overflowX: "hidden" }}>
          {/* Space Name */}
          <p className="text-sm m-0 mb-2">Space Name</p>
          <input
            type="text"
            className="p-inputtext w-full"
            value={spaceName}
            maxLength={50}
            onChange={(e) => setSpaceName(e.target.value)}
          />
          <p className="text-xs text-500 text-right">Character {spaceName.length}/50</p>
          {errors.spaceName && <small className="p-error">{errors.spaceName}</small>}

          {/* Description */}
          <p className="text-sm m-0 mb-2 mt-4">Description</p>
          <textarea
            className="p-inputtext w-full"
            rows="4"
            value={description}
            maxLength={250}
            onChange={(e) => setDescription(e.target.value)}
          />
          <p className="text-xs text-500 text-right">Character {description.length}/250</p>
          {errors.description && <small className="p-error">{errors.description}</small>}

          {/* User Access */}
          <p className="text-sm m-0 mb-2 mt-4">User Access</p>

          <div className="flex flex-col">
            <div className="flex w-full">
              {/* MultiSelect occupying 70% */}
              <div className="w-full">
                <MultiSelect
                  ref={multiSelectRef}
                  style={{ height: "6vh", fontSize: "small" }}
                  value={tempSelectedUsers}
                  options={availableUsers}
                  onChange={(e) => setTempSelectedUsers(e.value)}
                  fixedPlaceholder={true}
                  placeholder="Search Users"
                  className="w-full text-sm"
                  filter
                  filterBy="email"
                  optionLabel="email"
                  onShow={() => {
                    setTempSelectedUsers(selectedUsers);
                  }}
                  panelFooterTemplate={
                    <div className="p-2 flex justify-content-end">
                      <Button
                        label="Cancel"
                        className="p-button-sm p-button-text"
                        onClick={() => {
                          resetFields();
                          multiSelectRef.current.hide();
                        }}
                      />
                      <Button
                        label="Add"
                        className="p-button-sm"
                        onClick={() => {
                          const newlyAddedUsers = tempSelectedUsers.filter(
                            (tempUser) =>
                              !selectedUsers.some(
                                (selectedUser) => selectedUser.id === tempUser.id,
                              ),
                          );

                          setSelectedUsers(tempSelectedUsers);

                          setPermissionsMap((prev) => {
                            const updatedMap = { ...prev };
                            newlyAddedUsers.forEach((user) => {
                              if (!updatedMap[user.id]) {
                                updatedMap[user.id] = permission;
                              }
                            });
                            return updatedMap;
                          });

                          multiSelectRef.current.hide();
                        }}
                      />
                    </div>
                  }
                />
              </div>
              {/* Dropdown occupying 30% */}
              <div className="w-1/10 ml-2">
                <Dropdown
                  options={[
                    { label: "Can Use", value: "read" },
                    { label: "Can Edit", value: "write" },
                  ]}
                  value={permission} // Manage selected permission state
                  onChange={(e) => setPermission(e.value)} // Update selected permission
                  className="w-full" // Full width inside this container
                  style={{ height: "6vh", fontSize: "small" }}
                  placeholder="Select Permission"
                />
              </div>
            </div>
          </div>

          {errors.selectedUsers && <small className="p-error">{errors.selectedUsers}</small>}

          <div className="flex flex-wrap mt-4 w-full">
            <div className="flex flex-wrap w-full">
              {selectedUsers?.length > 0 && <p className="m-0 text-sm">Users</p>}
              {selectedUsers.map((user) => (
                <div
                  key={user.id}
                  className="text-sm w-full"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "60% 30% 10%",
                    gap: "0.5rem",
                    alignItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: "block",
                    }}
                    className="hover:cursor-default"
                    title={user.email}
                  >
                    {user.email}
                  </span>
                  <Dropdown
                    value={permissionsMap[user.id]}
                    options={[
                      { label: "Can Use", value: "read" },
                      { label: "Can Edit", value: "write" },
                    ]}
                    onChange={(e) => updatePermission(user.id, e.value)}
                    style={{ height: "6vh", fontSize: "small" }}
                    size="small"
                  />
                  <button
                    onClick={() => {
                      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
                      setPermissionsMap((prevMap) => {
                        const newMap = { ...prevMap };
                        delete newMap[user.id];
                        return newMap;
                      });
                    }}
                    className="p-button p-button-danger p-button-text text-xs px-2"
                  >
                    <i className="pi pi-times"></i>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className=" flex justify-content-end gap-2 mb-5 mr-3">
        <Button
          label="Cancel"
          className="p-button-text"
          onClick={() => {
            setVisible(false);
            resetFields();
          }}
        />
        <Button
          label="Set up"
          className="p-button"
          onClick={handleSubmit}
          loading={loading}
          disabled={!isFormValid}
        />
      </div>
    </Sidebar>
  );
};

export default NewSpaceSetup;
