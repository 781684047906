import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import SourceTablesFieldsModal from "../StructuredSources/SourceTablesFieldsModal";
import { useNavigate, useParams } from "react-router-dom";
// import Source from "@/models/source";
import { Toast } from "primereact/toast";
import { useSourceApi } from "hooks/sourceConfig";
import { Divider } from "primereact/divider";

const SourceCredentials = ({ setImported }) => {
  const [sourceName, setSourceName] = useState("");
  const [connectionType, setConnectionType] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedConnectionOption, setSelectedConnectionOption] =
    useState(null);
  const [placeholderText, setPlaceholderText] = useState("Select an Option");
  const [isTablesFieldsModalOpen, setTablesFieldsModal] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [useSSH, setUseSSH] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const [testSuccessful, setTestSuccessful] = useState(false);
  const [schemaOptions, setSchemaOptions] = useState([]);
  const [backStep, setBackStep] = useState(false);
  const sourceData = {
    name: sourceName,
    clientId: 1, // Replace with actual client ID
    connectionType: connectionType,
    runEveryMinutes: 60, // Replace with actual value if needed
    uuid: "e1db318c-b8ce-47d0-954b-87fe204c0be7", // Replace with actual UUID
  };
  const [errors, setErrors] = useState({});
  const { getSchemas, testDbConnection } = useSourceApi();

  useEffect(() => {
    const prefetchSource = async () => {
      console.log('prefetching')
      const sourceDetails = await JSON.parse(
        localStorage.getItem("sourceDetails")
      );
      if (sourceDetails) {
        const { name, connectionType, definition } = sourceDetails;
        const { database, databaseHost, databaseType, port, username } =
          definition;
        setSourceName(name);
        setConnectionType(connectionType);
        setSelectedConnectionOption(databaseType);
        setHost(databaseHost);
        setDatabaseName(database);
        setPort(port);
        setUsername(username);
        setBackStep(true);
      }
    };
    prefetchSource();
  }, []);

  const connectionTypes = [
    // { label: "Cloud", value: "cloud" },
    { label: "Database", value: "database" },
  ];

  const handleConnectionTypeChange = (e) => {
    const selectedType = e.value;
    setConnectionType(selectedType);
    setSelectedConnectionOption(null);
    setTestSuccessful(false);

    if (selectedType === "cloud") {
      setOptions([
        { label: "AWS", value: "AWS" },
        { label: "Azure", value: "Azure" },
      ]);
      setPlaceholderText("Select Cloud Type");
    } else if (selectedType === "database") {
      setOptions([{ label: "Postgres", value: "postgres" }]);
      setPlaceholderText("Select DB Type");
    } else {
      setOptions([]);
      setPlaceholderText("Select an Option");
    }
  };
  const getConnectionDetails = () => {
    return {
      databaseHost: host,
      port,
      databaseType: selectedConnectionOption,
      database: databaseName,
      username,
      password,
    };
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!sourceName || sourceName.trim().length===0) newErrors.sourceName = "Source Name is required";
    if (!host || host.trim().length === 0) newErrors.host = "Host is required";
    if (!port || port.trim().length === 0) newErrors.port = "Port is required";
    if (!databaseName || databaseName.trim().length === 0) newErrors.databaseName = "Database is required";
    if (!username || username.trim().length === 0) newErrors.username = "Username is required";
    if (!password || password.trim().length === 0) newErrors.password = "Password is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleTestConnection = async () => {
    if (validateForm()) {
      const connectionDetails = getConnectionDetails();
      setTestingConnection(true);
      try {
        const response = await testDbConnection(connectionDetails);
        if (response === true) {
          toast.current.show({
            severity: "success",
            summary: "Connection Successful",
            detail: "The connection to the database was successful.",
            life: 3000,
          });
          setTestSuccessful(true);

          const schemas = await getSchemas(connectionDetails);
          if (schemas && schemas.length > 0) {
            const schemaOptions = schemas.map((schema) => ({
              label: schema,
              value: schema,
            }));
            setSchemaOptions(schemaOptions);
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Connection Failed",
            detail: "Failed to connect to the database.",
            life: 3000,
          });
          setTestSuccessful(false);
        }
      } catch (error) {
        console.error("Error during connection test:", error);
      } finally {
        setTestingConnection(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    const newErrors = {};
    e.preventDefault();
    if (!sourceName || sourceName.trim().length === 0) newErrors.sourceName = "Source Name is required";
    if (validateForm()) {
      try {
        setTablesFieldsModal(true);
      } catch (error) {
        console.error("Error during source creation:", error);
      }
    }
  };

  const renderPostgresForm = () => {
    return (
      <div className="mt-7 bg-[#F5F5F5] w-[28vw] ml-[335px] pl-[20px] pt-[20px] pb-[20px] rounded-md">
        <div className="flex flex-col">
          <label className="w-[300px] text-sm font-semibold">
            Set Database Credentials
          </label>
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="host">
            Host <span className="text-red-500">*</span>
          </label>
          <InputText
            id="host"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.host
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            placeholder="Enter Host"
            value={host}
            onChange={(e) => {
              const value = e.target.value;
              setTestSuccessful(false);
              if (value.length <= 50) {
                setHost(value);
              }
            }}
            required
          />
          {errors.host && <small className="p-error">{errors.host}</small>}
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="port">
            Port <span className="text-red-500">*</span>
          </label>
          <InputText
            id="port"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.port
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            placeholder="Enter Port"
            value={port}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
              setTestSuccessful(false);
              if (value.length <= 5) {
                setPort(value);
              }
            }}
            required // Mandatory field
          />
          {errors.port && <small className="p-error">{errors.port}</small>}
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="databaseName">
            Database <span className="text-red-500">*</span>
          </label>
          <InputText
            id="databaseName"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.databaseName
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            placeholder="Enter Database Name"
            value={databaseName}
            onChange={(e) => {
              const value = e.target.value;
              setTestSuccessful(false);

              if (value.length <= 50) {
                setDatabaseName(value);
              }
            }}
            required // Mandatory field
          />
          {errors.databaseName && (
            <small className="p-error">{errors.databaseName}</small>
          )}
        </div>
        <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />
        <div className="flex flex-col mt-5">
          <label className="w-[300px] text-sm font-semibold">
            Authentication
          </label>
        </div>

        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="username">
            Username <span className="text-red-500">*</span>
          </label>
          <InputText
            id="username"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.username
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            value={username}
            placeholder="Enter Username"
            onChange={(e) => {
              const value = e.target.value;
              setTestSuccessful(false);
              if (value.length <= 50) {
                // Max length 50
                setUsername(value);
              }
            }}
            required // Mandatory field
          />
          {errors.username && (
            <small className="p-error">{errors.username}</small>
          )}
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="password">
            Password <span className="text-red-500">*</span>
          </label>
          <InputText
            id="password"
            type="password"
            className={`w-[95%] h-[6vh] mt-2 mt-2 p-3 text-sm ${errors.username
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            value={password}
            placeholder="Enter Password"
            onChange={(e) => {
              const value = e.target.value;
              setTestSuccessful(false);
              if (value.length <= 50) {
                setPassword(value);
              }
            }}
            required
          />
          {errors.password && (
            <small className="p-error">{errors.password}</small>
          )}
        </div>
        <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />

        <Button
          label="Test Connection"
          type="button"
          className="mt-3 p-3 text-xs text-white"
          style={{ backgroundColor: '#A855F7', border:'0px' }}
          onClick={handleTestConnection}
          loading={testingConnection}
        />
        <Toast ref={toast} position={"top-right"} />
      </div>
    );
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col ml-[30px] new-source-form relative"
      style={{ height: '100%' }}
    >
      <div className="flex flex-col overflow-y-auto pb-[80px]"> {/* Adjusted height with padding */}
        {isTablesFieldsModalOpen && (
          <SourceTablesFieldsModal
            visible={isTablesFieldsModalOpen}
            onClose={() => setTablesFieldsModal(false)}
            setImported={setImported}
            connectionDetails={getConnectionDetails()}
            sourceData={sourceData}
            editMode={backStep}
          />
        )}
        <label className="w-[300px] text-lg text-md font-semibold">
          Add Source Details
        </label>
        <div className="flex flex-row mt-5">
          <label className="w-[300px] mt-5" htmlFor="connectionType">
            Source Name <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col mt-3">
            <InputText
              id="sourceName"
              className={`ml-5 w-[28vw] h-[6vh] mt-2 mt-2 p-4 ${errors.sourceName
                  ? "border-red-500 border-solid border-[1px]"
                  : "border-solid border-[1px] border-gray-300"
                }`}
              value={sourceName}
              onChange={(e) => {
                const value = e.target.value;

                if (value.length <= 50) {
                  setSourceName(value);
                }
              }}
              required
            />
            {errors.sourceName && (
              <small className="p-error ml-5">{errors.sourceName}</small>
            )}
          </div>
        </div>
        <div className="flex flex-row mt-3">
          <label className="w-[300px] mt-5" htmlFor="connectionType">
            Connection Type <span className="text-red-500">*</span>
          </label>
          <Dropdown
            id="connectionType"
            className="ml-5 w-[28vw] h-[6vh] mt-2 border-solid border-[1px] border-gray-300 new-source-form mt-3"
            value={connectionType}
            options={connectionTypes}
            onChange={handleConnectionTypeChange}
            placeholder={
              connectionType ? connectionType : `Select a Connection Type`
            }
            disabled={backStep ? true : false}
          />
          {errors.connectionType && (
            <small className="p-error ml-5">{errors.connectionType}</small>
          )}
        </div>
        {connectionType === "database" && (
          <div className="flex flex-row mt-5 ml-[12px]">
            <Dropdown
              id="specificOption"
              className=" w-[28vw] h-[6vh] mt-2 border-solid border-[1px] border-gray-300 ml-[320px] new-source-form"
              value={selectedConnectionOption}
              options={options}
              onChange={(e) => {
                setSelectedConnectionOption(e.value);
                setTestSuccessful(false);
              }}
              placeholder={backStep ? selectedConnectionOption : placeholderText}
              disabled={backStep ? true : false}
            />
          </div>
        )}

        {selectedConnectionOption === "postgres" && renderPostgresForm()}
      </div>

      {/* Updated sticky button section */}
      <div className="fixed bottom-0 right-0 left-0 bg-white py-4 px-5 flex justify-end border-t border-gray-200">
        <Button
          label="Back"
          type="button"
          className="bg-white text-xs"
          onClick={() => {
            setTimeout(() => {
              navigate("/source-configuration/source");
            }, 100);
          }}
          style={{ height: '5vh', color:'#3B82F6' }}
        />
        <Button
          label="Next Step"
          type="submit"
          className="bg-[#3B82F6] text-xs text-white ml-5"
          disabled={!testSuccessful}
          style={{ height: '5vh' }}
        />
      </div>
    </form>
  );

};

export default SourceCredentials;