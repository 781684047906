import React from "react";

const DeleteExclamationWarningIcon = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8037 25.3788H1.19632C0.986579 25.3782 0.780566 25.3233 0.598285 25.2196C0.416005 25.1158 0.263653 24.9667 0.156022 24.7867C0.0537078 24.6032 0 24.3966 0 24.1865C0 23.9764 0.0537078 23.7698 0.156022 23.5863L12.9597 1.17988C13.0736 1.00821 13.2282 0.867393 13.4097 0.769977C13.5912 0.672562 13.794 0.621582 14 0.621582C14.206 0.621582 14.4088 0.672562 14.5903 0.769977C14.7718 0.867393 14.9264 1.00821 15.0403 1.17988L27.844 23.5863C27.9463 23.7698 28 23.9764 28 24.1865C28 24.3966 27.9463 24.6032 27.844 24.7867C27.7363 24.9667 27.584 25.1158 27.4017 25.2196C27.2194 25.3233 27.0134 25.3782 26.8037 25.3788ZM3.26091 22.9781H24.7391L14 4.18875L3.26091 22.9781Z"
        fill="#495057"
      />
      <path
        d="M14.0001 16.5765C13.6831 16.5724 13.3802 16.4446 13.156 16.2204C12.9317 15.9961 12.8039 15.6932 12.7998 15.3762V9.77456C12.7998 9.45621 12.9263 9.1509 13.1514 8.92579C13.3765 8.70068 13.6818 8.57422 14.0001 8.57422C14.3185 8.57422 14.6238 8.70068 14.8489 8.92579C15.074 9.1509 15.2005 9.45621 15.2005 9.77456V15.3762C15.1963 15.6932 15.0686 15.9961 14.8443 16.2204C14.6201 16.4446 14.3172 16.5724 14.0001 16.5765Z"
        fill="#495057"
      />
      <path
        d="M14.0001 21.3777C13.6831 21.3735 13.3802 21.2457 13.156 21.0215C12.9317 20.7973 12.8039 20.4944 12.7998 20.1773V19.3771C12.7998 19.0588 12.9263 18.7534 13.1514 18.5283C13.3765 18.3032 13.6818 18.1768 14.0001 18.1768C14.3185 18.1768 14.6238 18.3032 14.8489 18.5283C15.074 18.7534 15.2005 19.0588 15.2005 19.3771V20.1773C15.1963 20.4944 15.0686 20.7973 14.8443 21.0215C14.6201 21.2457 14.3172 21.3735 14.0001 21.3777Z"
        fill="#495057"
      />
    </svg>
  );
};

export default DeleteExclamationWarningIcon;
