import { Dialog } from "primereact/dialog";

const DynamicDialog = ({ header, visible, footer, content, onHide }) => {
  return (
    <Dialog
      header={header}
      visible={visible}
      onHide={onHide}
      footer={footer}
      keepInViewport
    >
      {content}
    </Dialog>
  );
};

export default DynamicDialog;
