import useApi from "services/api";
import { API_NEIO_ING } from "../constants";

export const useSourceApi = () => {
  const { get, post, del, loading, error } = useApi();

  // Function to fetch the source list
  const getSourceList = async (params) => {
    try {
      const queryParams = new URLSearchParams({
        page: params.page?.toString() || "1",
        limit: params.limit?.toString() || "10",
        search: params.search || "",
        sort: params.sort || "updatedAt desc",
        status: params.status || "",
        connectionType: params.connectionType || "",
        createdAtStartDate: params.createdAtStartDate || "",
        createdAtEndDate: params.createdAtEndDate || "",
      }).toString();

      // console.log(`API call to -> ${API_NEIO_ING}/source?${queryParams}`);
      const response = await get(`${API_NEIO_ING}/source?${queryParams}`);
      return response;
    } catch (error) {
      console.error("Failed to fetch source list:", error);
      return { success: false, error: error.message };
    }
  };

  // Function to fetch a source by ID
  const getSourceById = async (sourceId) => {
    try {
      const response = await get(`${API_NEIO_ING}/source/${sourceId}`);
      return response;
    } catch (error) {
      console.error("Failed to fetch source by ID:", error);
      return { success: false, error: error.message };
    }
  };

  const testDbConnection = async (dbConfig) => {
    try {
      // console.log(`Testing DB connection with config:`, dbConfig);
      const response = await post(`${API_NEIO_ING}/general/test-db-connection`, dbConfig);
      return response;
    } catch (error) {
      console.error("Failed to test DB connection:", error);
      return { success: false, error: error.message };
    }
  };

  const getSchemas = async (dbConfig) => {
    try {
      // console.log(`Fetching schemas with config:`, dbConfig);
      const response = await post(`${API_NEIO_ING}/general/database/schemas`, dbConfig);
      return response;
    } catch (error) {
      console.error("Failed to fetch schemas:", error);
      return { success: false, error: error.message };
    }
  };

  const getTables = async (dbConfig) => {
    try {
      // console.log(`Fetching tables with config:`, dbConfig);
      const response = await post(`${API_NEIO_ING}/general/database/schemas/tables`, dbConfig);
      return response;
    } catch (error) {
      console.error("Failed to fetch tables:", error);
      return { success: false, error: error.message };
    }
  };
  const getFields = async (dbConfig) => {
    try {
      // console.log(`Fetching fields with config:`, dbConfig);
      const response = await post(
        `${API_NEIO_ING}/general/database/schemas/tables/fields`,
        dbConfig,
      );
      return response;
    } catch (error) {
      console.error("Failed to fetch fields:", error);
      return { success: false, error: error.message };
    }
  };

  const createSource = async (sourceData) => {
    try {
      const formData = new FormData();
      formData.append("name", sourceData.name);
      formData.append("clientId", sourceData.clientId);
      formData.append("sourceSystem", sourceData.sourceSystem);
      formData.append("connectionType", sourceData.connectionType);
      formData.append("runEveryMinutes", sourceData.runEveryMinutes);
      formData.append("uuid", sourceData.uuid);
      formData.append("definition", JSON.stringify(sourceData.definition));

      // console.log("Creating source with data:", sourceData);

      const response = await post(`${API_NEIO_ING}/source`, formData);
      return response;
    } catch (error) {
      console.error("Failed to create source:", error);
      return { success: false, error: error?.response?.data?.message || error.message };
    }
  };

  const updateSource = async (sourceData) => {
    try {
      const formData = new FormData();
      formData.append("id", sourceData.id);
      formData.append("name", sourceData.name);
      formData.append("clientId", sourceData.clientId);
      formData.append("sourceSystem", sourceData.sourceSystem);
      formData.append("connectionType", sourceData.connectionType);
      formData.append("runEveryMinutes", sourceData.runEveryMinutes);
      formData.append("uuid", sourceData.uuid);
      formData.append("definition", JSON.stringify(sourceData.definition));
      formData.append("step", 2);

      // console.log("Updating source with data:", sourceData);

      const response = await post(`${API_NEIO_ING}/source`, formData);
      return response;
    } catch (error) {
      console.error("Failed to update source:", error);
      return { success: false, error: error?.response?.data?.message || error.message };
    }
  };

  const mapFields = async (sourceId, fieldsData) => {
    try {
      const response = await post(`${API_NEIO_ING}/source/${sourceId}/map-fields`, fieldsData);
      return response;
    } catch (error) {
      console.error("Failed to map fields:", error);
      return { success: false, error: error?.response?.data?.message || error.message };
    }
  };

  const getMasterData = async () => {
    try {
      const response = await get(`${API_NEIO_ING}/masters`);
      return response;
    } catch (error) {
      console.error("Failed to fetch master data:", error);
      return { success: false, error: error?.message || error };
    }
  };

  const postApplyRule = async (sourceId, rulesBody) => {
    try {
      const response = await post(`${API_NEIO_ING}/source/${sourceId}/map-rules`, rulesBody);
      if (response.status === 404 || response.status === 500) {
        return { group: null, error: "Something went wrong" };
      }
      return response.status;
    } catch (error) {
      return { group: null, error: error?.message || error };
    }
  };

  const finishSource = async (sourceData, frequencyConfig) => {
    // console.log("Source data on API call:", sourceData, "and", frequencyConfig);

    try {
      // Modify the definition to append frequencyConfig
      sourceData.definition = {
        ...sourceData.definition, // Spread the existing definition
        frequencyConfig: frequencyConfig, // Append the frequencyConfig
      };

      console.log("Modified sourceData:", sourceData);

      // Prepare form data
      const formData = new FormData();
      formData.append("id", sourceData.id);
      formData.append("name", sourceData.name);
      formData.append("clientId", sourceData.clientId);
      formData.append("sourceSystem", sourceData.sourceSystem);
      formData.append("connectionType", sourceData.connectionType);
      formData.append("runEveryMinutes", sourceData.runEveryMinutes);
      formData.append("uuid", sourceData.uuid);

      // Convert the definition object to a JSON string
      formData.append("definition", JSON.stringify(sourceData.definition));

      // Send the POST request
      const response = await post(`${API_NEIO_ING}/source`, formData);

      return response;
    } catch (error) {
      console.error("Error finishing source:", error);
      return {
        success: false,
        error: error?.response?.message || error.message,
      };
    }
  };

  const setupConnection = async (sourceId) => {
    try {
      const response = await post(`${API_NEIO_ING}/airbyte/setup-connection/${sourceId}`);
      return response.data;
    } catch (error) {
      console.error("Error setting up connection:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const deleteSourceById = async (sourceId) => {
    try {
      // Make the DELETE request to the API
      const response = await del(`${API_NEIO_ING}source/${sourceId}`);

      return response.data;
    } catch (error) {
      console.error("Error deleting source:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };


  return {
    getSourceList,
    getSourceById,
    testDbConnection,
    getSchemas,
    getTables,
    createSource,
    updateSource,
    getFields,
    mapFields,
    getMasterData,
    postApplyRule,
    finishSource,
    setupConnection,
    deleteSourceById,
    loading,
    error,
  };
};
