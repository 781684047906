import React from "react";

const SquarePenIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 2.50015H3.83333C3.47971 2.50015 3.14057 2.64063 2.89052 2.89068C2.64048 3.14072 2.5 3.47986 2.5 3.83348V13.1668C2.5 13.5204 2.64048 13.8596 2.89052 14.1096C3.14057 14.3597 3.47971 14.5002 3.83333 14.5002H13.1667C13.5203 14.5002 13.8594 14.3597 14.1095 14.1096C14.3595 13.8596 14.5 13.5204 14.5 13.1668V8.50015M12.75 2.25015C13.0152 1.98493 13.3749 1.83594 13.75 1.83594C14.1251 1.83594 14.4848 1.98493 14.75 2.25015C15.0152 2.51537 15.1642 2.87508 15.1642 3.25015C15.1642 3.62522 15.0152 3.98493 14.75 4.25015L8.74133 10.2595C8.58303 10.4176 8.38747 10.5334 8.17267 10.5962L6.25733 11.1562C6.19997 11.1729 6.13916 11.1739 6.08127 11.1591C6.02339 11.1442 5.97055 11.1141 5.9283 11.0719C5.88604 11.0296 5.85593 10.9768 5.8411 10.9189C5.82627 10.861 5.82727 10.8002 5.844 10.7428L6.404 8.82748C6.46702 8.61285 6.58302 8.41752 6.74133 8.25948L12.75 2.25015Z"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SquarePenIcon;
