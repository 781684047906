import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import "./index.css";
// import Admin from "models/admin";
import { Message } from "primereact/message";
import { useSourceApi } from "hooks/sourceConfig";
import GetUsersList from "hooks/getUsers";

const SetFrequency = ({ setAppliedRules, setFinishSetup }) => {
  const [frequency, setFrequency] = useState();
  const [every, setEvery] = useState("");
  const [updatedSource, setUpdatedSource] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [preselectedUsers, setPreselectedUsers] = useState([]);
  const multiSelectRef = useRef(null);
  const [usersData, setUsersData] = useState([]);
  const toast = useRef(null);
  const [error, setError] = useState("");
  const [finishingSetup, setFinishingSetup] = useState(false);
  const {finishSource, setupConnection}= useSourceApi();
  const {getUsers} = GetUsersList();

  useEffect(() => {
    const sourceFromStorage = localStorage.getItem("sourceDetails");
    if (sourceFromStorage) {
      try {
        const parsedSource = JSON.parse(sourceFromStorage);
        setUpdatedSource(parsedSource);
      } catch (error) {
        console.error("Error parsing source from localStorage:", error);
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to load source details",
        });
      }
    }

    const fetchUsers = async () => {
      try {
        const _users = await getUsers();
        if (_users) {
          setUsersData(_users);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (updatedSource?.definition?.frequencyConfig) {
      setFrequency(updatedSource.definition.frequencyConfig.frequency?.interval);
      setEvery(updatedSource.definition.frequencyConfig.frequency?.every);
    }
  }, [updatedSource]);

  const dropdownInputCss =
    "source-control-modal border-solid border-[1px] border-gray-300 h-[50px] border-solid border-[1px] border-gray-300";
  // "source-control-modal flex flex-row bg-white border text-black text-sm shadow-inputNormal rounded-lg focus:ring-0 focus:border-2 focus:border-spacing-2 focus:outline-none focus:shadow-inputFocus block p-2.5 mt-5 ml-5 w-[560px] h-[50px]";

  const handleClosePanel = () => {
    if (multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
  };

  const getSelectedItemsLabel = (value) => {
    if (value != null) {
      const count = value.length;
      return `${count} ${count === 1 ? "user" : "users"} selected`;
    }
    return null;
  };

  // const onRemove = (index) => {
  //   const newSelectedUsers = selectedUsers.filter((ele, i) => i !== index);
  //   setSelectedUsers(newSelectedUsers);
  //   setPreselectedUsers(newSelectedUsers);
  //   const newUnSelected = unSelected.map((ele) => {
  //     if (ele.data === selectedUsers[index].data) {
  //       return { ...ele, status: "unchecked" };
  //     } else {
  //       console.log("couldnt find");
  //     }
  //     return ele;
  //   });
  //   setUnSelected(newUnSelected);
  // };

  const frequencyOptions = [
    { label: "Minute(s)", value: "minute(s)" },
    { label: "Hour(s)", value: "hour(s)" },
    { label: "Day(s)", value: "day(s)" },
    { label: "Week(s)", value: "week(s)" },
    { label: "Month(s)", value: "month(s)" },
  ];

  const triggerPolicyOptions = [
    { label: "Policy 1", value: "policy1" },
    { label: "Policy 2", value: "policy2" },
    { label: "Policy 3", value: "policy3" },
  ];

  const notificationOptions = [
    { label: "Email", value: "email" },
    { label: "SMS", value: "sfms" },
    { label: "NeIO Pulse", value: "neio" },
  ];

  const handleFinishSetup = async () => {
    setError("");

    if (!frequency) {
      setError("Frequency must be selected.");
      return;
    }
    if (every === "") {
      setError("Value for 'every' cannot be empty.");
      return;
    }
    if (every === "0") {
      setError("Value for 'every' cannot be 0.");
      return;
    }
    if (every?.length > 2) {
      setError("Value for 'every' cannot exceed 2 digits.");
      return;
    }

    setFinishingSetup(true);
    
    if (!updatedSource) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Source details not found",
      });
      setFinishingSetup(false);
      return;
    }

    const frequencyConfig = {
      frequency: {
        every: every,
        interval: frequency,
      },
    };

    try {
      const response = await finishSource(updatedSource, frequencyConfig);
      
      if (response.success || response.sourceId?.length > 0 || response.status === 201) {
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Frequency setup successfully",
          life: 3000,
        });

        const sourceId = localStorage.getItem("source_id");
        if (!sourceId) {
          throw new Error("Source ID not found");
        }

        const finalResponse = await setupConnection(sourceId);
        if (finalResponse) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Source created successfully",
            life: 2000,
          });
          setTimeout(() => {
            setFinishSetup(true);
          }, 500);
        } else {
          throw new Error("Setup connection failed");
        }
      } else {
        throw new Error(response.error || "Failed to apply frequency");
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "An error occurred during setup",
      });
      console.error("Error during source creation:", error);
    } finally {
      setFinishingSetup(false);
    }
  };

  return (
    <form className="ml-7 ">
      <div className="flex flex-row">
        <label className="w-[300px] text-lg text-md font-semibold">
          Set Frequency
        </label>
        {error && (
          <Message
            className="text-red-500 ml-auto mr-5 flex text-left text-pretty justify-start"
            severity="error"
            text={error}
          />
        )}
      </div>

      <div className="mt-5 flex flex-row items-center">
        <label htmlFor="frequency" className="w-[200px] text-[#495057]">
          Frequency <span className="text-red-500">*</span>
        </label>
        <Dropdown
          id="frequency"
          className="ml-2 w-[250px] h-[50px] border-solid border-[1px] border-gray-300"
          value={frequency}
          options={frequencyOptions}
          onChange={(e) => {
            setFrequency(e.value);
            setError("");
          }}
          placeholder="Select Frequency"
        />
         <label htmlFor="every" className="ml-8 w-[150px] text-[#495057]">
              Every <span className="text-red-500">*</span>
            </label>
            <InputText
              id="every"
              className="ml-2 w-[115px] h-[50px] border-solid border-[1px] border-gray-300 p-4"
              type="number"
              min={1}
              max={4}
              value={every}
              onChange={(e) => {
                setEvery(e.target.value);
                setError("");
              }}
            />
            <label htmlFor="every" className="ml-5 text-[#495057]">
              {frequency && frequency}
            </label>
      </div>
      {/* <div className="p-field mt-5 flex flex-row items-center">
        <label htmlFor="triggerPolicy" className="w-[200px] text-[#495057]">
          Trigger Policy
        </label>
        <Dropdown
          id="triggerPolicy"
          value={triggerPolicy}
          className="ml-5 w-[560px] h-[50px] border-solid border-[1px] border-gray-300"
          options={triggerPolicyOptions}
          onChange={(e) => setTriggerPolicy(e.value)}
          placeholder="Select a Policy"
        />
      </div>

      <div className="p-field mt-5 flex flex-row items-center">
        <label htmlFor="notification" className="w-[200px] text-[#495057]">
          Notification
        </label>
        <Dropdown
          id="notification"
          value={notification}
          className="ml-5 w-[560px] h-[50px] border-solid border-[1px] border-gray-300"
          options={notificationOptions}
          onChange={(e) => setNotification(e.value)}
          placeholder="Select a Notification"
        />
      </div>
      <div className="p-field mt-5 flex flex-row items-start">
        <label htmlFor="notification" className="w-[200px] text-[#495057] mt-3">
          Users
        </label>
        <div className="flex flex-col w-[560px] ml-5">
          <MultiSelect
            name="users"
            value={preselectedUsers}
            ref={multiSelectRef}
            onChange={(e) => {
              setPreselectedUsers(e.value);
            }}
            pt={{
              panel: { id: "context-filter-panel" },
            }}
            onShow={() => {
              const zoomLevel = (window.outerWidth / window.innerWidth) * 100;
              const multiselectPanel = document.querySelector(
                ".p-multiselect-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done"
              );
              const multiSelectWrapper = document.querySelector(
                ".p-multiselect-items-wrapper"
              );
              const bottomCornerY =
                document
                  .querySelector('div[class*="p-multiselect-label-container"]')
                  .getBoundingClientRect().top +
                window.pageYOffset +
                document.querySelector(
                  'div[class*="p-multiselect-label-container"]'
                ).offsetHeight;
              if (multiselectPanel) {
                multiselectPanel.style.top = `${bottomCornerY}px`;
                if (zoomLevel >= 110) {
                  multiSelectWrapper.style.height = "60px";
                } else if (zoomLevel >= 100) {
                  multiSelectWrapper.style.height = "100px";
                } else if (zoomLevel >= 90) {
                  multiSelectWrapper.style.height = "110px";
                } else if (zoomLevel >= 80) {
                  multiSelectWrapper.style.height = "160px";
                } else if (zoomLevel >= 70) {
                  multiSelectWrapper.style.height = "200px";
                } else {
                  multiSelectWrapper.style.height = "250px";
                }
              }
            }}
            onHide={() => {
              setPreselectedUsers(selectedUsers);
              getSelectedItemsLabel(selectedUsers);
            }}
            options={usersData.filter((option) =>
              option.firstname
                ?.toLowerCase()
                .includes(filterText?.toLowerCase())
            )}
            optionLabel={(option) => (
              <div className="flex flex-row items-center">
                <div className="justify-center cursor-pointer px-1.5 py-0.5 rounded-full aspect-square text-blue-700 bg-[#f6f8fe] ml-5 mt-1">
                  {option.firstname.charAt(0).toUpperCase()}
                  {option.lastname.charAt(0).toUpperCase()}
                </div>
                <div className="ml-5">
                  <span>{`${option.firstname} ${option.lastname}`}</span>
                </div>
              </div>
            )}
            filter
            filterTemplate={
              <MultiselectHeader
                filterText={filterText}
                setFilterText={setFilterText}
              />
            }
            panelFooterTemplate={
              <MultiselectFooter
                setPreselectedUsers={setPreselectedUsers}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                preselectedUsers={preselectedUsers}
                handleClosePanel={handleClosePanel}
              />
            }
            filterBy="firstname"
            selectedItemsLabel={getSelectedItemsLabel(preselectedUsers)}
            placeholder="Enter user name or email"
            maxSelectedLabels={0}
            className={dropdownInputCss}
          />
          <div className="flex flex-wrap mt-2">
            {selectedUsers.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className="flex items-center m-0.5 gap-0.5 p-1.5 text-xs leading-5 rounded-md bg-zinc-100 font-[475] text-neutral-600"
                >
                  <div>
                    {elem.firstname} {elem.lastname}{" "}
                  </div>
                  <span
                    className="cursor-pointer"
                    onClick={() => onRemove(index)}
                  >
                    <CancleAddedSpaceIcon />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
      <div className="flex flex-row justify-end mr-5 mb-5">
        <Button
          label="Back"
          type="button"
          className="mt-5 bg-white p-3 text-xs text-[#3B82F6] border border-solid border-[#3B82F6] mr-5"
          onClick={() => setAppliedRules(false)}
          disabled={finishingSetup}
          style={{backgroundColor:"#fff", color:"#3B82F6"}}
        />

        <Button
          label={finishingSetup ? "Applying frequency" : "Finish Setup"}
          type="button"
          className="mt-5 bg-[#3B82F6] p-3 text-xs text-white"
          onClick={handleFinishSetup}
          loading={finishingSetup}
        />
      </div>
      <Toast ref={toast} position={"top-right"} />
    </form>
  );
};

export default SetFrequency;
