const AgentIcon = ({ selectedItem }) => {
    return selectedItem === "agent" ? (
        <svg width="25" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.58856 14.9671C2.47048 13.849 2.47048 12.0362 3.58856 10.9182C4.70664 9.80009 6.5194 9.80009 7.63748 10.9182L9.66193 12.9426L7.63748 14.9671C6.5194 16.0852 4.70664 16.0852 3.58856 14.9671ZM9.97551 8.5801C8.85743 7.46203 8.85743 5.64926 9.97551 4.53119C11.0936 3.41311 12.9063 3.41311 14.0244 4.53119C15.1425 5.64926 15.1425 7.46203 14.0244 8.5801L12 10.6046L9.97551 8.5801ZM9.97551 21.3541C8.85743 20.236 8.85743 18.4232 9.97551 17.3052L12 15.2807L14.0244 17.3052C15.1425 18.4232 15.1425 20.236 14.0244 21.3541C12.9063 22.4721 11.0936 22.4721 9.97551 21.3541ZM14.338 12.9426L16.3625 10.9182C17.4805 9.80009 19.2933 9.80009 20.4114 10.9182C21.5295 12.0362 21.5295 13.849 20.4114 14.9671C19.2933 16.0852 17.4805 16.0852 16.3625 14.9671L14.338 12.9426Z" stroke="#3b82f6" stroke-width="1.5" />
        </svg>
    ) : (
        <svg width="25" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.58856 14.9671C2.47048 13.849 2.47048 12.0362 3.58856 10.9182C4.70664 9.80009 6.5194 9.80009 7.63748 10.9182L9.66193 12.9426L7.63748 14.9671C6.5194 16.0852 4.70664 16.0852 3.58856 14.9671ZM9.97551 8.5801C8.85743 7.46203 8.85743 5.64926 9.97551 4.53119C11.0936 3.41311 12.9063 3.41311 14.0244 4.53119C15.1425 5.64926 15.1425 7.46203 14.0244 8.5801L12 10.6046L9.97551 8.5801ZM9.97551 21.3541C8.85743 20.236 8.85743 18.4232 9.97551 17.3052L12 15.2807L14.0244 17.3052C15.1425 18.4232 15.1425 20.236 14.0244 21.3541C12.9063 22.4721 11.0936 22.4721 9.97551 21.3541ZM14.338 12.9426L16.3625 10.9182C17.4805 9.80009 19.2933 9.80009 20.4114 10.9182C21.5295 12.0362 21.5295 13.849 20.4114 14.9671C19.2933 16.0852 17.4805 16.0852 16.3625 14.9671L14.338 12.9426Z" stroke="#DADAFC" stroke-width="1.5" />
        </svg>
    );
};

export default AgentIcon;
