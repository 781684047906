import React, { useState, useEffect, useRef, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Chip } from "primereact/chip";
import "../DocumentEngine/TemplateSection/newTemplate.css";
import { TieredMenu } from "primereact/tieredmenu";
import { Toast } from "primereact/toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "primereact/hooks";
import { useRefresh } from "context/ContextWrapper";
import InputSwitchIcon from "assets/SpaceIcons/InputSwitch.svg";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import useSpaces from "hooks/useSpace";
import GetUsersList from "hooks/getUsers";
import { MultiSelect } from "primereact/multiselect";

/**
 * Main component for displaying and managing spaces.
 * Handles the display of space data in a table format with filtering, pagination, and actions.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.fetchData - Indicates if data is being fetched
 * @param {Function} props.setFetchData - Function to set the fetch data state
 * @param {Function} props.setTotalSpaces - Function to set the total number of spaces
 * @returns {JSX.Element} A complete view of spaces with table and filters
 */
export default function SpaceList({ fetchData, setFetchData, setTotalSpaces }) {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [pagination, setPagination] = useState(null);
  const [search, debouncedSearch, setSearch] = useDebounce("", 400);
  const { refresh, setRefresh } = useRefresh();
  const op = useRef(null);
  const [filters, setFilters] = useState({});
  const { getSpaces, deleteSpace } = useSpaces();
  const [usersList, setUsersList] = useState([]);
  const [filter, setFilter] = useState({});

  const { getUsers } = GetUsersList();

  /**
   * Fetches spaces data from the server.
   *
   * @param {number} page - Current page number
   * @param {number} pageSize - Number of rows per page
   * @param {string} search - Search query
   * @param {string} sortBy - Field to sort by
   * @param {string} sortOrder - Order of sorting (asc/desc)
   * @param {Object} filters - Filters to apply
   */
  const fetchSpaces = async (page, pageSize, search, sortBy, sortOrder, filters) => {
    try {
      setFetchData(true);
      const data = await getSpaces(page, pageSize, search, sortBy, sortOrder, filters);
      setData(data.items);
      setPagination({
        total: data.total,
        totalPages: data.totalPages,
        page: data.page,
        pageSize: data.pageSize,
      });
      setTotalSpaces(data.total);
    } catch (err) {
      console.error(err);
    } finally {
      setFetchData(false);
    }
  };

  /**
   * Handles sorting of the data table.
   *
   * @param {Object} event - Sort event
   */
  function handleOnSort(event) {
    const { sortField, sortOrder } = event;
    setSortField(sortField);
    const order = sortOrder === 1 ? "asc" : "desc";
    setSortOrder(order);
  }

  useEffect(() => {
    fetchSpaces(currentPage, rowsPerPage, debouncedSearch, sortField, sortOrder, filter);
  }, [currentPage, rowsPerPage, debouncedSearch, refresh, sortField, sortOrder, filter]);

  useEffect(() => {
    getUsers().then((res) => {
      setUsersList(res?.results?.users);
    });
  }, []);

  /**
   * Handles the deletion of selected spaces.
   *
   * @param {Array} spacesToDelete - List of spaces to delete
   */
  const handleDelete = (spacesToDelete) => {
    confirmDialog({
      message: "Are you sure you want to delete the selected items?",
      contentClassName: "text-sm",
      acceptIcon: "pi pi-check",
      rejectIcon: "pi pi-times",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger text-sm",
      rejectClassName: "text-sm p-button-text",
      accept: async () => {
        try {
          setFetchData(true);
          // delete spaces asynchronously and wait for all spaces to be deleted
          await Promise.all(spacesToDelete.map((space) => deleteSpace(space.id)));
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Spaces deleted successfully!",
            life: 3000,
          });
          setSelectedSpaces([]);
          // refresh Data
          await fetchSpaces(
            currentPage,
            rowsPerPage,
            debouncedSearch,
            sortField,
            sortOrder,
            filter,
          );
        } catch (err) {
          console.error(err);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to delete spaces",
            life: 3000,
          });
        } finally {
          setFetchData(false);
        }
      },
    });
  };

  /**
   * Applies the selected filters.
   *
   * @param {Object} filters - Filters to apply
   */
  const handleFilterApply = (filters) => {
    // hide filter popup
    op.current.hide();
    setFilter(filters);
  };

  /**
   * Renders the header of the data table.
   *
   * @returns {JSX.Element} The header component
   */
  const renderHeader = () => {
    return (
      <div className="flex items-center w-full bg-white justify-content-between">
        <div className="f flex align-items-center gap-1 font-normal">
          {/* <div className="flex align-items-center gap-2 cursor-pointer" onClick={handleDelete}>
            <i className="pi pi-trash text-sm" />
            <span className="text-sm">Delete</span>
          </div> */}
          <Button
            text
            disabled={selectedSpaces.length === 0}
            icon="pi pi-trash"
            pt={{
              label: {
                className: "text-sm font-normal text-color",
              },
              icon: {
                className: "text-color",
              },
            }}
            onClick={() => handleDelete(selectedSpaces)}
            label="Delete"
          />
          {/* <div className="flex align-items-center gap-2 cursor-pointer">
            <i className="pi pi-comment text-sm" />
            <span className="text-sm">New Chat</span>
          </div> */}
          <Button
            text
            icon="pi pi-comment"
            pt={{
              label: {
                className: "text-sm font-normal text-color",
              },
              icon: {
                className: "text-color",
              },
            }}
            label="New Chat"
          />
        </div>
        <div className="flex justify-end gap-1 font-semibold align-items-center">
          {/* <div className="flex align-items-center gap-2 cursor-pointer">
            <i className="pi pi-sliders-h text-sm" />
            <span className="text-sm">Filter</span>
          </div> */}
          <Button
            onClick={(e) => op.current.toggle(e)}
            text
            icon="pi pi-sliders-h"
            className="text-color"
            pt={{
              label: {
                className: "text-sm font-semibold text-color",
              },
            }}
            label="Filter"
          />
          <Filters
            filters={filters}
            setFilters={setFilters}
            op={op}
            handleFilterApply={handleFilterApply}
            usersList={usersList}
          />

          <IconField iconPosition="left">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              value={search}
              className="text-sm w-18rem"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search by space or file name"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  /**
   * Template for displaying the 'Referred By' column.
   *
   * @param {Object} rowData - Data of the current row
   * @returns {JSX.Element} The template for the 'Referred By' column
   */
  const referredByTemplate = (rowData) => {
    const { referred_by = [], id } = rowData;
    const accessToLength = referred_by && referred_by.length > 1;
    const isExpanded = expandedRows[id];

    if (isExpanded && accessToLength) {
      return referred_by.map((name, index) => (
        <Chip key={index} label={name} className="accessto text-sm" />
      ));
    } else {
      return <> - </>;
    }
  };

  /**
   * Handles the preview click action.
   *
   * @param {Object} rowData - Data of the current row
   */
  const handlePreviewClick = (rowData) => {};

  /**
   * Template for displaying action buttons in the table.
   *
   * @param {Object} rowData - Data of the current row
   * @returns {JSX.Element} The action buttons template
   */
  const actionTemplate = (rowData) => {
    return (
      <ActionTemplate
        rowData={rowData}
        handleDelete={handleDelete}
        handlePreviewClick={handlePreviewClick}
      />
    );
  };

  /**
   * Template for displaying the 'Created At' column.
   *
   * @param {Object} rowData - Data of the current row
   * @returns {JSX.Element} The template for the 'Created At' column
   */
  const createdOnTemplate = (rowData) => {
    const { created_at } = rowData;
    const timestamp = moment(created_at).format("DD/MM/YYYY, HH:mm:ss");
    return (
      <div className="flex flex-column">
        <span className="text-sm">
          {usersList.find((user) => user?.id === rowData.created_by)?.email || " - "}
        </span>
        <span className="text-xs text-400 mt-1">{timestamp}</span>
      </div>
    );
  };

  /**
   * Handles page change in the paginator.
   *
   * @param {Object} e - Event object
   */
  const handlePageChange = (e) => {
    setCurrentPage(e.page + 1);
    setRowsPerPage(e.rows);
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog className="w-5" />
      <div className="card mb-5 mt-3 spaceTable" style={{ height: "68vh" }}>
        <DataTable
          value={data}
          rowsPerPageOptions={[5, 10, 20]}
          onPage={handlePageChange}
          first={(currentPage - 1) * rowsPerPage}
          loading={fetchData}
          inSort={false}
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={handleOnSort}
          emptyMessage="No Spaces found"
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedSpaces}
          onSelectionChange={(e) => {
            setSelectedSpaces(e.value || []);
          }}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column
            field="name"
            header="Space Name"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => (
              <span 
                className="font-light text-label cursor-pointer" 
                style={{ color: "#1581ED" }}
                onClick={() => navigate(`/spaces/${rowData.id}`)}
              >
                {rowData.name}
              </span>
            )}
          ></Column>

          <Column
            field="description"
            header="Description"
            style={{ width: "25%" }}
            body={(rowData) => <span className="text-label">{rowData.description}</span>}
          ></Column>

          <Column
            field="referred_by"
            header="Referred By"
            body={referredByTemplate}
            style={{ width: "20%" }}
          ></Column>

          <Column
            field="created_at"
            header="Created by"
            body={createdOnTemplate}
            style={{ width: "25%" }}
            sortable
          ></Column>

          <Column
            field="actions"
            header="Actions"
            style={{ width: "5%" }}
            className="w-full"
            body={(rowData) => actionTemplate(rowData)}
          ></Column>
        </DataTable>
        <Paginator
          first={(currentPage - 1) * rowsPerPage || 0}
          rows={rowsPerPage}
          totalRecords={pagination?.total || 0}
          className="text-sm"
          template={{
            layout:
              "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={[5, 10, 20, 30]}
          onPageChange={handlePageChange}
        ></Paginator>
      </div>
    </Fragment>
  );
}

/**
 * Component for rendering filters in the overlay panel.
 *
 * @param {Object} props - Component props
 * @param {Object} props.filters - Current filters
 * @param {Function} props.setFilters - Function to set filters
 * @param {Object} props.op - Overlay panel reference
 * @param {Function} props.handleFilterApply - Function to apply filters
 * @param {Array} props.usersList - List of users
 * @returns {JSX.Element} The filters component
 */
const Filters = ({ filters, setFilters, op, handleFilterApply, usersList }) => {
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <OverlayPanel
      ref={op}
      className="w-19rem filterPopup"
      pt={{ content: { className: "px-4 py-3" } }}
    >
      <div className="flex flex-column gap-4">
        <div className="flex justify-content-between gap-2 align-items-center">
          <p className="text-base font-semibold p-0 m-0">Filter Space</p>
          <span className="text-sm font-semibold cursor-pointer" onClick={() => op.current.hide()}>
            <i className="pi pi-times" />
          </span>
        </div>

        <div className="flex justify-content-center flex-column">
          <label htmlFor="created_at" className="block mb-2 text-label">
            Select Date Range
          </label>
          <Calendar
            id="created_at"
            name="created_at"
            value={filters.created_at}
            onChange={onInputChange}
            showIcon
            selectionMode="range"
            placeholder="dd/mm/yyyy - dd/mm/yyyy"
            maxDate={new Date()}
          />
        </div>
        <div className="flex justify-content-center flex-column">
          <label htmlFor="owner" className="block mb-2 text-label">
            Owner
          </label>
          <MultiSelect
            value={filters.created_by}
            onChange={(e) => setFilters((prev) => ({ ...prev, created_by: e.value }))}
            options={usersList}
            optionLabel="email"
            optionValue="keycloak_user_id"
            filter
            placeholder="Select Owner"
            maxSelectedLabels={10}
            className="text-label w-full"
            filterBy="email"
            showClear
          />
        </div>
        <div className="flex justify-content-center flex-column">
          <label htmlFor="referredBy" className="block mb-2 text-label">
            Referred By
          </label>
          <IconField iconPosition="right">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              id="referredBy"
              className="text-label w-full"
              placeholder="Search"
              onChange={onInputChange}
              value={filters.referredBy}
              name="referredBy"
            />
          </IconField>
        </div>

        <div className="flex justify-content-between gap-2 align-items-center">
          <Button
            label="Reset"
            severity="danger"
            text
            onClick={() => {
              setFilters({});
              handleFilterApply({});
            }}
          />
          <Button
            label="Apply"
            className="text-sm"
            onClick={() => {
              if (Object.keys(filters).length > 0) {
                const filtersToApply = { ...filters };
                // if only one date is selected, set it to both start and end date
                if (
                  filtersToApply?.created_at &&
                  filtersToApply?.created_at?.length === 2 &&
                  !filtersToApply?.created_at[1]
                ) {
                  filtersToApply.created_at = [
                    filtersToApply.created_at[0],
                    filtersToApply.created_at[0],
                  ];

                  setFilters(filtersToApply);
                }
                console.log({ filtersToApply });
                handleFilterApply(filtersToApply);
              }
            }}
          />
        </div>
      </div>
    </OverlayPanel>
  );
};

/**
 * Component for rendering chat icon and vertical dots menu in the data table.
 *
 * @param {Object} props - Component props
 * @param {Object} props.rowData - Data of the current row
 * @param {Function} props.handleDelete - Function to handle delete action
 * @param {Function} props.handlePreviewClick - Function to handle preview click
 * @returns {JSX.Element} The chat icon and vertical dots menu component
 */
const ActionTemplate = ({ rowData, handleDelete, handlePreviewClick }) => {
  const menu = useRef(null);
  const items = [
    {
      label: (
        <>
          Edit
          <span className="edit-info-icon ml-3 pi pi-info-circle text-800 text-xs"></span>
          <Tooltip target={".edit-info-icon"} position="top" className="w-16rem">
            Selected schemas, Tables, Columns are not editable
          </Tooltip>
        </>
      ),
      command: () => {},
      icon: "pi pi-pen-to-square",
    },
    //divider
    {
      separator: true,
    },

    {
      label: "Deactivate",
      command: () => {},
      icon: (
        <>
          <img src={InputSwitchIcon} className="mr-2" />
        </>
      ),
    },
    {
      label: "Delete",
      command: () => {
        handleDelete([rowData]);
      },
      icon: "pi pi-trash",
      className: "p-delete-menuitem",
    },
  ];
  const previewIcon = (
    <Button
      type="button"
      icon={"pi pi-comment"}
      // style={{ color: "#3B82F6" }}
      onClick={() => {
        handlePreviewClick(rowData);
      }}
      className="p-button-rounded p-button-text p-button-secondary  w-2rem h-2rem"
    />
  );

  const moreMenu = (
    <div className="card flex justify-content-center">
      <TieredMenu
        model={items}
        popup
        ref={menu}
        breakpoint="767px"
        className="max-w-10rem text-sm"
      />

      <Button
        type="button"
        icon={"pi pi-ellipsis-v"}
        onClick={(e) => {
          menu.current.toggle(e);
          // remove focus from the menu first child
          setTimeout(() => {
            menu?.current?.getElement()?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
          }, 100);
        }}
        className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
      />
    </div>
  );

  return (
    <div className="text-right inline-flex">
      {previewIcon} {moreMenu}
    </div>
  );
};
