import React from 'react'

const Escalated = () => {
  return (
  <svg width="14" height="16" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.86495 1.50018C6.77705 1.34864 6.65087 1.22286 6.49906 1.13542C6.34726 1.04798 6.17514 1.00195 5.99995 1.00195C5.82476 1.00195 5.65265 1.04798 5.50084 1.13542C5.34903 1.22286 5.22286 1.34864 5.13495 1.50018L1.13495 8.50018C1.04723 8.65213 1.00102 8.82447 1.00098 8.99992C1.00093 9.17537 1.04705 9.34775 1.13469 9.49974C1.22234 9.65173 1.34843 9.77798 1.50031 9.86582C1.65219 9.95366 1.8245 10 1.99995 10.0002H9.99995C10.1754 10 10.3477 9.95366 10.4996 9.86582C10.6515 9.77798 10.7776 9.65173 10.8652 9.49974C10.9529 9.34775 10.999 9.17537 10.9989 8.99992C10.9989 8.82447 10.9527 8.65213 10.865 8.50018L6.86495 1.50018Z" stroke="#D9342B" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default Escalated