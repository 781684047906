import { useCallback, useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
// import Feed from "@/models/feed";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import "./index.css";
import { Timeline } from "primereact/timeline";
import { convertDateTimeWithSeconds } from "utils/helper";
import { usePulseApi } from "hooks/pulseConfig";
import { useWorkspaceApi } from "hooks/workspaceConfig";

const RuleNotificationSidebar = ({
  visible,
  setVisible,
  allUserData,
  onSuccess,
  allRuleData,
  updateSidebarData,
  setUpdateSidebarData,
  getNamespaceAsset,
}) => {
  // Local state for notification form data
  const [formDataNotification, setFormDataNotification] = useState({
    alert_message: "",
    alert_rule_ids: "",
    definition_id: 0,
    knowledge_space: "",
    primary_spoc: [],
    secondary_spoc: { escalation_time: "", users: [] },
    tertiary_spoc: { escalation_time: "", users: [] },
  });

  const [isValidationEnable, setIsValidationEnable] = useState(false);
  const [showSecondarySpoc, setShowSecondarySpoc] = useState(false);
  const [showTertiarySpoc, setShowTertiarySpoc] = useState(false);
  const { postRulesForNotification, patchRulesForNotification } = usePulseApi();
  const { getTenantId } = useWorkspaceApi();

  useEffect(() => {
    if (updateSidebarData) {
      setFormDataNotification({
        alert_message: updateSidebarData?.alert_message || "",
        alert_rule_ids: updateSidebarData?.alert_rules?.map((item) => item.id) || [],
        definition_id: updateSidebarData?.id,
        knowledge_space: updateSidebarData?.knowledge_space?.map((item) => item.value) || [],
        primary_spoc: updateSidebarData?.primary_spoc?.map((item) => item.value) || [],
        secondary_spoc: {
          escalation_time: convertDateTimeWithSeconds(
            updateSidebarData?.secondary_spoc?.escalation_time || 0,
          ),
          users: updateSidebarData?.secondary_spoc?.users?.map((item) => item.value) || [],
        },
        tertiary_spoc: {
          escalation_time: convertDateTimeWithSeconds(
            updateSidebarData?.tertiary_spoc?.escalation_time || 0,
          ),
          users: updateSidebarData?.tertiary_spoc?.users?.map((item) => item.value) || [],
        },
      });
      if (
        updateSidebarData?.secondary_spoc?.escalation_time > 0 ||
        updateSidebarData?.secondary_spoc?.users?.length > 0
      ) {
        setShowSecondarySpoc(true);
      }

      if (
        updateSidebarData?.tertiary_spoc?.escalation_time > 0 ||
        updateSidebarData?.tertiary_spoc?.users?.length > 0
      ) {
        setShowTertiarySpoc(true);
      }
    }
  }, [updateSidebarData]);

  // Handle MultiSelect change for primary, secondary, tertiary, and knowledge_space
  const handleSelectChange = (e, field) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [field]: e.value,
    }));
  };

  const handleCancelForm = () => {
    setVisible(false);
    setFormDataNotification({
      alert_message: "",
      alert_rule_ids: "",
      definition_id: 0,
      knowledge_space: "",
      primary_spoc: [],
      secondary_spoc: { escalation_time: "", users: [] },
      tertiary_spoc: { escalation_time: "", users: [] },
    });
    setUpdateSidebarData(null);
    setIsValidationEnable(false);
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsValidationEnable(true);
    let namespace_id = `${getNamespaceAsset?.namespace_id}`;
    let asset_id = `${getNamespaceAsset?.asset_id}`;

    const isPrimarySpocValid = formDataNotification.primary_spoc?.length > 0;
    const isAlertMessageValid = formDataNotification.alert_message !== "";

    // Validation for secondary_spoc
    const isSecondarySpocValid =
      formDataNotification.secondary_spoc.escalation_time !== "" &&
      formDataNotification.secondary_spoc.users.length > 0 &&
      formDataNotification.secondary_spoc.escalation_time !== null &&
      convertDateTimeWithSeconds(formDataNotification?.secondary_spoc?.escalation_time) > 0 &&
      showSecondarySpoc;

    // Validation for tertiary_spoc
    const isTertiarySpocValid =
      formDataNotification.tertiary_spoc.escalation_time !== "" &&
      formDataNotification.tertiary_spoc.users.length > 0 &&
      formDataNotification.secondary_spoc.escalation_time !== null &&
      convertDateTimeWithSeconds(formDataNotification?.tertiary_spoc?.escalation_time) > 0 &&
      showTertiarySpoc;

    if (
      isPrimarySpocValid &&
      isAlertMessageValid &&
      (!showSecondarySpoc || isSecondarySpocValid) &&
      (!showTertiarySpoc || isTertiarySpocValid)
    ) {
      try {
        let ruleNotificationData;
        if (
          formDataNotification?.definition_id === 0 ||
          formDataNotification?.definition_id === undefined
        ) {
          const payload = {
            description: "Notification CREATION",
            alert_message: formDataNotification.alert_message || "Timeout error",
            additional_info: {},
            active: true,
            asset_id: asset_id,
            namespace_id: namespace_id,
            primary_spoc: formDataNotification.primary_spoc || [],
            secondary_spoc: {
              escalation_time: formDataNotification.secondary_spoc.escalation_time
                ? convertDateTimeWithSeconds(formDataNotification.secondary_spoc.escalation_time)
                : 0,

              users:
                formDataNotification.secondary_spoc.users &&
                formDataNotification.secondary_spoc.users.length > 0
                  ? formDataNotification.secondary_spoc.users
                  : [],
            },

            tertiary_spoc: {
              escalation_time: formDataNotification.tertiary_spoc.escalation_time
                ? convertDateTimeWithSeconds(formDataNotification.tertiary_spoc.escalation_time)
                : 0,

              users:
                formDataNotification.tertiary_spoc.users &&
                formDataNotification.tertiary_spoc.users.length > 0
                  ? formDataNotification.tertiary_spoc.users
                  : [],
            },
            knowledge_space: formDataNotification.knowledge_space || [],
            alert_rule_ids: formDataNotification.alert_rule_ids || [],
            region: "westus",
            tenant_id: await getTenantId(),
          };
          ruleNotificationData = await postRulesForNotification(payload);
        } else {
          const payload = {
            description: "Notification CREATION",
            alert_message: formDataNotification.alert_message || "Timeout error",
            definition_id: formDataNotification?.definition_id,
            additional_info: {},
            active: true,
            asset_id: asset_id,
            namespace_id: namespace_id,
            primary_spoc: formDataNotification.primary_spoc || [],
            secondary_spoc: {
              escalation_time: formDataNotification.secondary_spoc.escalation_time
                ? convertDateTimeWithSeconds(formDataNotification.secondary_spoc.escalation_time)
                : 0,

              users:
                formDataNotification.secondary_spoc.users &&
                formDataNotification.secondary_spoc.users.length > 0
                  ? formDataNotification.secondary_spoc.users
                  : [],
            },

            tertiary_spoc: {
              escalation_time: formDataNotification.tertiary_spoc.escalation_time
                ? convertDateTimeWithSeconds(formDataNotification.tertiary_spoc.escalation_time)
                : 0,

              users:
                formDataNotification.tertiary_spoc.users &&
                formDataNotification.tertiary_spoc.users.length > 0
                  ? formDataNotification.tertiary_spoc.users
                  : [],
            },
            knowledge_space: formDataNotification.knowledge_space || [],
            alert_rule_ids: formDataNotification.alert_rule_ids || [],
            region: "westus",
            tenant_id: await getTenantId(),
          };
          ruleNotificationData = await patchRulesForNotification(payload);
        }

        if (ruleNotificationData?.status === 200) {
          setVisible(false);
          if (onSuccess) {
            onSuccess(ruleNotificationData?.message);
            setFormDataNotification({
              alert_message: "",
              alert_rule_id: "",
              definition_id: 0,
              knowledge_space: "",
              primary_spoc: [],
              secondary_spoc: { escalation_time: "", users: [] },
              tertiary_spoc: { escalation_time: "", users: [] },
            });
          }
          setUpdateSidebarData(null);
          setShowSecondarySpoc(false);
          setShowTertiarySpoc(false);
        }
      } catch (error) {
        if (onSuccess) {
          onSuccess(false);
        }
      }
      setVisible(false);
    }
  };
  const handleChangeNotification = (e) => {
    const { name, value } = e.target;
    if (name === "alert_message" && value.length <= 60) {
      setFormDataNotification((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUserChange = (e, level) => {
    const newUsers = e.value;
    setFormDataNotification((prev) => ({
      ...prev,
      [`${level}_spoc`]: {
        ...prev[`${level}_spoc`],
        users: newUsers,
      },
    }));
  };

  const handleTimeChange = (e, type) => {
    const newTime = e.value;
    setFormDataNotification((prev) => ({
      ...prev,
      [`${type}_spoc`]: {
        ...prev[`${type}_spoc`],
        escalation_time: newTime,
      },
    }));
  };
  const handleDelete = (level) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [`${level}_spoc`]: { escalation_time: "", users: [] },
    }));
    if (level === "secondary") {
      setShowSecondarySpoc(false);
    } else if (level === "tertiary") {
      setShowTertiarySpoc(false);
    }
  };

  const customMarker = ({ events }) => (
    <div className="flex flex-col items-center" style={{ color: "#ABC9FB" }}>
      {/* Circle marker */}
      <span className="w-1 h-2 bg-[#1581ED] border-[#1581ED] rounded-full"></span>
      {/* Conditionally show a longer line if there's only one event */}
      {events?.length === 1 ? (
        <span className="single-event-line h-24 w-px bg-[#ABC9FB]"></span>
      ) : (
        <span className="multi-event-line  w-px bg-[#ABC9FB]"></span>
      )}
    </div>
  );
  const characterCount = formDataNotification.alert_message?.length;

  return (
    <Sidebar
      visible={visible}
      position="right"
      className="p-sidebar-md rule-notification-sidebar"
      dismissable={false}
      onHide={() => handleCancelForm()}
      content={({ closeIconRef, hide }) => (
        <div style={{ overflowY: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "12px",
              paddingTop: "16px",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 10,
              height: "8.5vh",
            }}
            className="rule-notification-sidebar-header"
          >
            <span
              style={{
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#495057",
                paddingLeft: "20px",
              }}
              className="heading"
            >
              {updateSidebarData
                ? "Edit Notification Configuration"
                : "Add Notification Configuration"}
            </span>
            <button
              type="button"
              onClick={() => handleCancelForm()}
              ref={closeIconRef}
              className="p-2 rounded-full transition-colors"
              style={{ backgroundColor: "white", border: "0px", marginRight: "2vw" }}
              aria-label="Close"
            >
              <i className="pi pi-times text-[#6b7280] hover:text-[#212121] text-lg font-light"></i>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              padding: "1.5rem",
              backgroundColor: "white",
              borderRadius: "0.5rem",
              // marginTop:'4vh'
            }}
          >
            <div className="space-y-1">
              <label
                htmlFor="alert_message"
                className="block text-sm font-normal text-title-black-light"
              >
                Notification Message
              </label>
              <InputText
                id="alert_message"
                name="alert_message"
                className={`w-full border rounded-md py-2 px-3 mt-2 focus:outline-none ${
                  formDataNotification.alert_message === "" && isValidationEnable
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                value={formDataNotification.alert_message}
                onChange={handleChangeNotification}
                style={{ height: "6vh", border:'1px solid #d8d4dc' }}
              />
              <div className="flex flex-col">
                {formDataNotification.alert_message === "" && isValidationEnable && (
                  <p className="text-red-500 text-xs" style={{marginTop:'2px',marginBottom:'2px'}}>Notification Message field is required</p>
                )}
              </div>
              <div className="text-left text-gray-500 text-sm mr-auto" style={{marginTop:'2px'}}>
                Character limit : {characterCount}/60
              </div>
            </div>

            <div className="sidebar-tab flex justify-start items-start">
              <Timeline
                value={[
                  {
                    label: "L1 Respondent",
                    content: (
                      <>
                        <div className="multi-select-data">
                          <h4 className="text-sm font-normal">Level 1 Respondent</h4>
                          <MultiSelect
                            value={formDataNotification.primary_spoc}
                            filter
                            options={allUserData?.userList}
                            onChange={(e) =>
                              setFormDataNotification({
                                ...formDataNotification,
                                primary_spoc: e.value,
                              })
                            }
                            className={`w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 ${
                              formDataNotification.primary_spoc?.length === 0 && isValidationEnable
                                ? "border-red-500"
                                : "border-gray-400"
                            }`}
                            placeholder="Select"
                            selectedItemTemplate={() => null}
                            style={{ height: "6vh" ,border:'1px solid #d8d4dc'}}
                          />
                          {formDataNotification.primary_spoc?.length === 0 &&
                            isValidationEnable && (
                              <p className="text-red-500 text-xs mt-1">
                                Level 1 Respondent field is required
                              </p>
                            )}
                          <div className="mt-2 flex flex-wrap gap-2">
                            {formDataNotification.primary_spoc?.length > 0 && (
                              <div className="flex flex-wrap gap-2 mt-2">
                                {formDataNotification.primary_spoc?.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "0.25rem",
                                      padding: "0.25rem",
                                      paddingLeft: "0.75rem",
                                      paddingRight: "0.75rem",
                                      border: "1px solid #E5E7EB",
                                      backgroundColor: "#F3F4F6",
                                      borderRadius: "1rem",
                                      fontSize: "0.875rem",
                                      fontWeight: "600",
                                      color: "#1F2937",
                                    }}
                                  >
                                    <span>
                                      {allUserData?.userList.find((u) => u.value === item)?.label}
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setFormDataNotification({
                                          ...formDataNotification,
                                          primary_spoc: formDataNotification.primary_spoc?.filter(
                                            (spoc) => spoc !== item,
                                          ),
                                        });
                                      }}
                                      style={{
                                        backgroundColor: "#F3F4F6",
                                        border: "0px",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i
                                        style={{
                                          fontSize: "0.7rem",
                                          paddingTop: "0.25rem",
                                        }}
                                        className="pi pi-times"
                                      ></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <Divider
                            style={{
                              margin: "0.5rem 0", // my-2
                              backgroundColor: "#d1d5db", // border-gray-300
                            }}
                          />
                        </div>
                        {!showSecondarySpoc && (
                          <Button
                            label="Add L2 Respondent"
                            icon="pi pi-plus"
                            onClick={() => setShowSecondarySpoc(true)}
                            className="text-title-blue text-sm mt-2 text-blue-500 self-end bg-white border-0 p-0"
                          />
                        )}
                      </>
                    ),
                  },
                  showSecondarySpoc && {
                    label: "L2 Escalation",
                    content: (
                      <>
                        <div className="multi-select-data-secondary">
                          <div
                            className="flex justify-between items-center"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                            }}
                          >
                            <h4 className="text-sm font-semibold mb-6">Level 2 Escalation</h4>
                            {!showTertiarySpoc && (
                              <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-danger text-red-500"
                                style={{ backgroundColor: "white", border: "0px" }}
                                onClick={() => handleDelete("secondary")}
                              />
                            )}
                          </div>
                          <div>
                            <span className="text-sm font-normal mb-1">L2 Escalation Time</span>
                            <Calendar
                              id="secondary-spoc-calendar"
                              style={{ marginTop: "1vh", width: "25%", }}
                              placeholder="HH : MM : SS"
                              value={formDataNotification.secondary_spoc.escalation_time}
                              className={`border w-32 h-12 flex justify-center rounded ${
                                (formDataNotification.secondary_spoc.escalation_time === "" ||
                                  formDataNotification.secondary_spoc.escalation_time === null ||
                                  convertDateTimeWithSeconds(
                                    formDataNotification?.secondary_spoc?.escalation_time,
                                  ) === 0) &&
                                isValidationEnable &&
                                showSecondarySpoc
                                  ? "border-red-500 rule-notification-sidebar-border-red"
                                  : "border-gray-400"
                              }`}
                              onChange={(e) => handleTimeChange(e, "secondary")}
                              timeOnly
                              showSeconds
                            />
                            {(formDataNotification.secondary_spoc.escalation_time === "" ||
                              formDataNotification.secondary_spoc.escalation_time === null ||
                              convertDateTimeWithSeconds(
                                formDataNotification?.secondary_spoc?.escalation_time,
                              ) === 0) &&
                              showSecondarySpoc &&
                              isValidationEnable && (
                                <p className="text-red-500 text-xs mt-1">
                                  L2 Escalation Time field is required
                                </p>
                              )}
                          </div>
                          <div style={{ marginTop: "2vh" }}>
                            <span className="text-sm font-normal mb-1">L2 Respondent</span>
                            <MultiSelect
                              value={formDataNotification.secondary_spoc.users}
                              filter
                              options={allUserData?.userList}
                              onChange={(e) => handleUserChange(e, "secondary")}
                              placeholder="Select"
                              className={`w-full border rounded-md h-12 focus:outline-none focus:ring-2 placeholder:font-normal text-[#6C757D] opacity-60 ${
                                formDataNotification.secondary_spoc.users.length === 0 &&
                                isValidationEnable &&
                                showSecondarySpoc
                                  ? "border-red-500"
                                  : "border-gray-400"
                              }`}
                              selectedItemTemplate={() => null}
                              style={{ height: "6vh", marginTop: "1vh" }}
                            />
                            {formDataNotification.secondary_spoc.users?.length === 0 &&
                              isValidationEnable &&
                              showSecondarySpoc && (
                                <p className="text-red-500 text-xs mt-1">
                                  Level 2 Respondent field is required
                                </p>
                              )}
                            <div className=" flex flex-wrap gap-2">
                              {formDataNotification.secondary_spoc.users?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                  {formDataNotification.secondary_spoc.users?.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.25rem",
                                        padding: "0.25rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        border: "1px solid #E5E7EB",
                                        backgroundColor: "#F3F4F6",
                                        borderRadius: "1rem",
                                        fontSize: "0.875rem",
                                        fontWeight: "600",
                                        color: "#1F2937",
                                      }}
                                    >
                                      <span>
                                        {allUserData?.userList.find((u) => u.value === item)?.label}
                                      </span>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setFormDataNotification({
                                            ...formDataNotification,
                                            secondary_spoc: {
                                              ...formDataNotification.secondary_spoc,
                                              users:
                                                formDataNotification.secondary_spoc.users?.filter(
                                                  (spoc) => spoc !== item,
                                                ),
                                            },
                                          });
                                        }}
                                        className="text-gray-600 hover:text-gray-700"
                                        style={{
                                          backgroundColor: "#F3F4F6",
                                          border: "0px",
                                          fontStyle: "normal",
                                          fontWeight: "300",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i
                                          style={{
                                            fontSize: "0.7rem",
                                            paddingTop: "0.25rem",
                                          }}
                                          className="pi pi-times"
                                        ></i>
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>

                          <Divider
                            style={{
                              margin: "0.5rem 0", // my-2
                              backgroundColor: "#d1d5db", // border-gray-300
                            }}
                          />
                        </div>
                        {!showTertiarySpoc && (
                          <Button
                            label="Add L3 Respondent"
                            icon="pi pi-plus"
                            onClick={() => setShowTertiarySpoc(true)}
                            className="text-title-blue text-sm mt-2 text-blue-500 self-end bg-white border-0 p-0"
                          />
                        )}
                      </>
                    ),
                  },
                  showTertiarySpoc && {
                    label: "L3 Escalation",
                    content: (
                      <>
                        <div className="multi-select-data-tr">
                          <div
                            className="flex justify-between items-center"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                            }}
                          >
                            {" "}
                            <h4 className="text-sm font-semibold">Level 3 Escalation</h4>
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-danger text-red-500"
                              style={{ backgroundColor: "white", border: "0px" }}
                              onClick={() => handleDelete("tertiary")}
                            />
                          </div>
                          <div className="my-2">
                            <span className="text-sm font-normal mb-1">L3 Escalation Time</span>
                            <Calendar
                              id="tertiary-spoc-calendar"
                              style={{ marginTop: "1vh", width: "25%" }}
                              placeholder="HH : MM : SS"
                              value={formDataNotification.tertiary_spoc.escalation_time}
                              className={`border w-32 h-12 flex justify-center rounded ${
                                (formDataNotification.tertiary_spoc.escalation_time === "" ||
                                  formDataNotification.tertiary_spoc.escalation_time === null ||
                                  convertDateTimeWithSeconds(
                                    formDataNotification?.tertiary_spoc?.escalation_time,
                                  ) === 0) &&
                                isValidationEnable &&
                                showTertiarySpoc
                                  ? "border-red-500 rule-notification-sidebar-border-red"
                                  : "border-gray-400"
                              }`}
                              onChange={(e) => handleTimeChange(e, "tertiary")}
                              timeOnly
                              showSeconds
                            />
                            {(formDataNotification.tertiary_spoc.escalation_time === "" ||
                              formDataNotification.tertiary_spoc.escalation_time === null ||
                              convertDateTimeWithSeconds(
                                formDataNotification?.tertiary_spoc?.escalation_time,
                              ) === 0) &&
                              isValidationEnable &&
                              showTertiarySpoc && (
                                <p className="text-red-500 text-xs mt-1">
                                  L3 Escalation Time field is required
                                </p>
                              )}
                          </div>
                          <div>
                            <span className="text-sm font-normal mb-1">L3 Respondent</span>
                            <MultiSelect
                              value={formDataNotification.tertiary_spoc.users}
                              options={allUserData?.userList}
                              filter
                              display="chip"
                              onChange={(e) => handleUserChange(e, "tertiary")}
                              placeholder="Select"
                              className={`w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 ${
                                formDataNotification.tertiary_spoc.users.length === 0 &&
                                isValidationEnable &&
                                showTertiarySpoc
                                  ? "border-red-500"
                                  : "border-gray-400"
                              }`}
                              selectedItemTemplate={() => null}
                              style={{ height: "6vh", marginTop: "1vh" }}
                            />
                            {formDataNotification.tertiary_spoc.users?.length === 0 &&
                              isValidationEnable &&
                              showTertiarySpoc && (
                                <p className="text-red-500 text-xs mt-1">
                                  Level 3 Respondent field is required
                                </p>
                              )}
                            <div className="mt-2 flex flex-wrap gap-2">
                              {formDataNotification.tertiary_spoc.users?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                  {formDataNotification.tertiary_spoc.users?.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.25rem",
                                        padding: "0.25rem",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        border: "1px solid #E5E7EB",
                                        backgroundColor: "#F3F4F6",
                                        borderRadius: "1rem",
                                        fontSize: "0.875rem",
                                        fontWeight: "600",
                                        color: "#1F2937",
                                      }}
                                    >
                                      <span>
                                        {allUserData?.userList.find((u) => u.value === item)?.label}
                                      </span>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setFormDataNotification({
                                            ...formDataNotification,
                                            tertiary_spoc: {
                                              ...formDataNotification.tertiary_spoc,
                                              users:
                                                formDataNotification.tertiary_spoc.users?.filter(
                                                  (spoc) => spoc !== item,
                                                ),
                                            },
                                          });
                                        }}
                                        style={{
                                          backgroundColor: "#F3F4F6",
                                          border: "0px",
                                          fontStyle: "normal",
                                          fontWeight: "300",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i
                                          style={{
                                            fontSize: "0.7rem",
                                            paddingTop: "0.25rem",
                                          }}
                                          className="pi pi-times"
                                        ></i>{" "}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ].filter(Boolean)}
                align="left"
                content={(item) => item.content}
                className="flex justify-start items-stretch left-aligned"
                marker={(item) => (
                  <span
                    className={`w-2 h-2 border-2 rounded-full inline-block 
                     
                    `
                  }
                  ></span>
                )}
              />
            </div>

            {/* Associate Rule (Opional) */}
            <div className="space-y-1">
              <label
                htmlFor="alert_rule_ids"
                className="block text-sm font-normal text-title-black-light"
              >
                Associated Rule (Optional)
              </label>
              <MultiSelect
                value={formDataNotification.alert_rule_ids}
                filter
                className={
                  "w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 border-gray-400"
                }
                options={allRuleData}
                placeholder="Select"
                selectedItemTemplate={() => null}
                onChange={(e) => handleSelectChange(e, "alert_rule_ids")}
                style={{ height: "6vh", marginTop: "1vh", border:'1px solid #d8d4dc'}}
              />
              <div className="mt-2 flex flex-wrap gap-2">
                {formDataNotification.alert_rule_ids?.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {formDataNotification.alert_rule_ids?.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.25rem",
                          padding: "0.25rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid #E5E7EB",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "1rem",
                          fontSize: "0.875rem",
                          fontWeight: "600",
                          color: "#1F2937",
                        }}
                      >
                        <span>{allRuleData.find((u) => u.value === item)?.label}</span>
                        <button
                          type="button"
                          onClick={() => {
                            setFormDataNotification({
                              ...formDataNotification,
                              alert_rule_ids: formDataNotification.alert_rule_ids?.filter(
                                (spoc) => spoc !== item,
                              ),
                            });
                          }}
                          className="text-gray-600 hover:text-gray-700"
                          style={{
                            backgroundColor: "#F3F4F6",
                            border: "0px",
                            fontStyle: "normal",
                            fontWeight: "300",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{
                              fontSize: "0.7rem",
                              paddingTop: "0.25rem",
                            }}
                            className="pi pi-times"
                          ></i>{" "}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Select Knowledge Space */}
            <div className="space-y-1 mb-10" style={{ marginBottom: "8vh" }}>
              <label
                htmlFor="knowledge_space"
                className="block text-sm font-normal text-title-black-light"
              >
                Provide Context for Queries (Optional)
              </label>
              <MultiSelect
                value={formDataNotification.knowledge_space}
                filter
                selectedItemTemplate={() => null}
                className="w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 border-gray-400"
                options={allUserData?.knowledgeSpace}
                placeholder="Select"
                onChange={(e) => handleSelectChange(e, "knowledge_space")}
                style={{ height: "6vh", marginTop: "1vh" }}
              />
              {formDataNotification.knowledge_space.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {formDataNotification.knowledge_space?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                        padding: "0.25rem",
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        border: "1px solid #E5E7EB",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "1rem",
                        fontSize: "0.875rem",
                        fontWeight: "600",
                        color: "#1F2937",
                      }}
                    >
                      <span>
                        {allUserData?.knowledgeSpace.find((u) => u.value === item)?.label}
                      </span>
                      <button
                        type="button"
                        onClick={() => {
                          setFormDataNotification({
                            ...formDataNotification,
                            knowledge_space: formDataNotification.knowledge_space?.filter(
                              (spoc) => spoc !== item,
                            ),
                          });
                        }}
                        className="text-gray-600 hover:text-gray-700"
                        style={{
                          backgroundColor: "#F3F4F6",
                          border: "0px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "0.7rem",
                            paddingTop: "0.25rem",
                          }}
                          className="pi pi-times"
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Footer with Clear and Apply Buttons */}
            <div
              className="flex absolute bg-white bottom-0 left-0 ml-auto right-0 px-5 justify-end rule-notification-sidebar-footer"
              style={{ marginTop: "5vh", justifyContent: "end" }}
            >
              <div style={{ paddingTop: "1vh", paddingBottom: "0.5vh" }}>
                <Button
                  onClick={handleCancelForm}
                  className="h-12 px-3 mr-2 w-fit text-gray-500"
                  style={{ backgroundColor: "white", border: "0px" }}
                  label="Cancel"
                  type="button"
                />
                <Button
                  label={updateSidebarData ? "Update" : "Configure"}
                  className="border px-4 bg-blue-500 text-white"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    ></Sidebar>
  );
};

export default RuleNotificationSidebar;
