// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[class*="chat-now-btn"] {
    display: flex;
    flex-direction: row;
    align-items: center;
}
div[class*="chat-now-btn"] button span{
    margin-left: 0.5vw;
    align-self:flex-start;
    align-items: center;
    margin-top: 0.3vh;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Spaces/CustomerInsights.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":["div[class*=\"chat-now-btn\"] {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\ndiv[class*=\"chat-now-btn\"] button span{\n    margin-left: 0.5vw;\n    align-self:flex-start;\n    align-items: center;\n    margin-top: 0.3vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
