import { useAtom } from "jotai";
import { isEqual, omit } from "lodash";
import { useEffect } from "react";
import { getSource } from "../StructuredSources/source.service";
import {
  contractAtom,
  fieldRulesAtom,
  labelsMappingForEntityAtom,
  labelsMappingForTargetFieldAtom,
  mappedTargetEntitiesAndInstanceAtom,
  relationshipConfigAtom,
  shouldRefreshSourceAtom,
  sourceAtom,
  sourceFieldMappingsDraftAtom,
  sourceFieldsAtom,
  sourceLoadingAtom,
  sourceProcessingRulesAtom,
  targetEntityFieldsAtom,
  toastAtom,
} from "../StructuredSources/store";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

export const useQueryParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  return Object.fromEntries(params);
};

const useSourceData = (id, step) => {
  const navigate = useNavigate();
  const [toast] = useAtom(toastAtom);
  const [source, setSource] = useAtom(sourceAtom);
  const [sourceFields, setSourceFields] = useAtom(sourceFieldsAtom);
  const [fieldRules, setFieldRules] = useAtom(fieldRulesAtom);
  const [targetEntityFields] = useAtom(targetEntityFieldsAtom);
  const [, setLabelsMappingForTargetField] = useAtom(
    labelsMappingForTargetFieldAtom
  );
  const [processingRules, setSourceProcessingRules] = useAtom(
    sourceProcessingRulesAtom
  );
  const [, setRelationshipConfig] = useAtom(relationshipConfigAtom);
  const [contractDetails, setContractDetails] = useAtom(contractAtom);
  const [, setMappedTargetEntities] = useAtom(
    mappedTargetEntitiesAndInstanceAtom
  );
  const [loading, setLoading] = useAtom(sourceLoadingAtom);
  const [, setLabelsMappingForEntity] = useAtom(labelsMappingForEntityAtom);
  const [shouldRefreshSource, setShouldRefreshSource] = useAtom(
    shouldRefreshSourceAtom
  );

  const [, setSourceFieldMappingsDraft] = useAtom(sourceFieldMappingsDraftAtom);
  const queryParams = useQueryParams();
  useEffect(() => {
    (async () => {
      if (queryParams?.cloneReferenceId) {
        setLoading(true);
        try {
          const response = await getSource(queryParams?.cloneReferenceId);
          setSource({
            ...response.data.source,
            cloneReferenceId: queryParams?.cloneReferenceId,
            step: 1,
            sourceFilePath: null,
            streamName: null,
            uuid: uuidv4(),
          });
          setLoading(false);
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              error?.response?.data?.message ||
              "Something went wrong please try again",
          });
          setLoading(false);
        }
      }
    })();
  }, [queryParams?.cloneReferenceId]);

  useEffect(() => {
    return () => {
      setSource(null);
      setSourceFields([]);
      setFieldRules([]);
      setMappedTargetEntities([]);
      setSourceProcessingRules([]);
      setContractDetails(null);
      setRelationshipConfig([]);
      setLabelsMappingForTargetField({});
    };
  }, []);

  useEffect(() => {
    if (id !== "add") {
      init();
    } else {
      if (!queryParams?.cloneReferenceId) {
        setLoading(false);
      }
    }
  }, [id, step, queryParams?.cloneReferenceId]);

  useEffect(() => {
    return () => {
      setShouldRefreshSource(true);
    };
  }, [id]);

  const init = async () => {
    if (shouldRefreshSource) {
      setShouldRefreshSource(false);
    } else {
      return;
    }
    setLoading(true);
    try {
      const response = await getSource(id);
      setSource({ ...response.data.source, cloneReferenceId: null });

      setSourceFieldMappingsDraft((sourceFieldMappingsDraft) => {
        if (sourceFieldMappingsDraft[id]) {
          const { sourceFields, mappedTargetEntities, relationshipConfig } =
            sourceFieldMappingsDraft[id];
          setSourceFields(sourceFields);
          setMappedTargetEntities(mappedTargetEntities);
          setRelationshipConfig(relationshipConfig);
        } else if (response.data.fieldsMapping) {
          setSourceFields(response.data.fieldsMapping);
        }
        return sourceFieldMappingsDraft;
      });
      if (response.data.fieldRules) {
        setFieldRules((prev) => {
          return response.data.fieldRules.map((v) => {
            const rules = (prev.find((x) => x.id === v.id)?.rules || [])
              .filter((e) => !e.isPredefined)
              .map((e) => {
                return {
                  id: e.id,
                  ruleId: e.rule.id,
                  config: e.rule.config,
                  rule: omit(e.rule, ["config"]),
                };
              });
            const rulesFromAPI = v?.rules || [];
            const newRules = [...(v.rules || [])];

            rules.map((rule) => {
              if (
                !rulesFromAPI.find((e) => {
                  return rule?.id
                    ? e.id === rule.id
                    : isEqual(omit(e, "id", "isPredefined"), omit(rule, "id"));
                })
              ) {
                newRules.push(rule);
              }
            });

            return {
              ...v,
              rules: newRules,
            };
          });
        });
      }

      if (response.data.source.mappedEntities) {
        setMappedTargetEntities(response.data.source.mappedEntities);
        const mapObj = {};
        for (const a of response.data.source.mappedEntities) {
          mapObj[a.id] = {};
          if (a.labels && Object.keys(a.labels).length) {
            for (let b in a.labels) {
              mapObj[a.id][b] = a.labels[b];
            }
          }
        }
        const obj = {};
        const entityObj = {};
        for (let eachMap of response.data.fieldRules) {
          const splitArr = eachMap.targetField.split("_");
          const entity = targetEntityFields.find((v) => v.id == splitArr[2]);
          const field = entity.fields.find((v) => v.id == splitArr[0]);
          const entityLabel = `${entity.label} ${
            mapObj?.[splitArr[2]]?.[splitArr[1]]
              ? `(${mapObj?.[splitArr[2]]?.[splitArr[1]]})`
              : ""
          }`;
          entityObj[`${entity.id}_${splitArr[1]}`] = entityLabel;
          obj[eachMap.targetField] = `${entityLabel} / ${field?.fieldLabel}`;
        }
        setLabelsMappingForTargetField(obj);
        setLabelsMappingForEntity(entityObj);
      }
      if (response.data.relationshipConfig) {
        setRelationshipConfig(response.data.relationshipConfig);
      }
      if (response.data.processingRules) {
        setSourceProcessingRules(() => {
          const newData = response.data.processingRules.map((v) => {
            return { ...v, ...v.config };
          });
          return newData;
        });
      }
      if (response.data.contract) {
        setContractDetails(response.data.contract);
      }
      if (response.data.error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          life: 8000,
          detail:
            response.data.error.message ||
            "Something went wrong please try again",
        });
        if (step) {
          navigate(`/source/${response.data.source.id}/1`);
        }
      }
    } catch (error) {
      setLoading(true);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return {
    source,
    sourceFields,
    fieldRules,
    targetEntityFields,
    processingRules,
    contractDetails,
    loading,
  };
};

export default useSourceData;
