import useApi from "services/api";
import { API_NEIO_ING } from "../constants";

const useFileUpload = () => {
  const { upload } = useApi();

  /**
   * Uploads files to the server with specified parameters
   * @param {Object} params - Upload parameters
   * @param {File[]} params.files - Array of files to upload
   * @param {string} params.spaceId - ID of the space
   * @param {string} params.connectorSlug - Slug of the connector
   * @param {string} params.connectionSourceType - Type of connection source
   * @returns {Promise} Promise that resolves with the upload response
   */
  const uploadFiles = async ({ files, spaceId, connectorSlug, connectionSourceType = 'unstructured' }, onUploadProgress) => {
    const formData = new FormData();

    // Add each file to the FormData
    files.forEach(file => {
      formData.append('files', file);
    });

    // Add other required fields
    formData.append('connectionSourceType', connectionSourceType);
    formData.append('spaceId', spaceId);
    formData.append('connectorSlug', connectorSlug);

    try {
      const response = await upload(
        `${API_NEIO_ING}/connection-setups/setup`,
        formData,
        {
           'accept': '*/*',
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress
      );
      return response;
    } catch (error) {
      console.error('File upload failed:', error);
      throw error;
    }
  };

  return { uploadFiles };
};

export default useFileUpload; 