import React, { useEffect, useRef, useState } from "react";
import GroupsList from "hooks/useGroupsTask";
import moment from "moment";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import useMediaQuerry from "hooks/useMediaQuerry";
import GetUsersList from "hooks/getUsers";

const BasicInfoTab = ({ userInfo, setUserData }) => {
  const toast = useRef();
  const [visible, setVisible] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [removedgroups, setRemovedGroups] = useState([]);
  const onBoardedOn = moment(userInfo?.created_at).format("DD/MM/YYYY");
  const [groupsList, setGroupList] = useState([]);
  const { getGroupDetails, loading, editUserGroup } = GroupsList();
  const { getUserDetails } = GetUsersList();
  const { isMobile } = useMediaQuerry();

  const fetchGroups = async () => {
    try {
      const data = await getGroupDetails("", "");
      if (data.success && data.results && data.results.data) {
        setGroupList(data.results.data);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Could not fetch groups list, Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [visible]);

  useEffect(() => {
    if (groupsList && visible) {
      const grouplist = userInfo?.groups
        ?.map((userGroup) => {
          const group = groupsList.find((group) => {
            return group?.name === userGroup;
          });
          return group ? { name: group.name } : null;
        })
        .filter(Boolean);
      setSelectedGroups(grouplist);
    }
  }, [visible, userInfo?.groups]);

  const handleGroupsList = async () => {
    try {
      const payload = {
        groups_to_add: selectedGroups?.map((group) => group?.name),
        groups_to_remove: removedgroups?.map((group) => group?.name),
      };
      const response = await editUserGroup(userInfo?.id, payload);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response?.message,
        life: 1000,
      });
      handleCloseSidebar();
      const updatedUser = await getUserDetails(userInfo?.id);
      setUserData(updatedUser?.results);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 1000,
      });
    }
  };

  const handleRemoveGroup = (groupToRemove) => {
    setSelectedGroups(selectedGroups.filter((group) => group.name !== groupToRemove.name));
    setRemovedGroups((prevUsers) => [...prevUsers, groupToRemove]);
  };

  const handleCloseSidebar = () => {
    setRemovedGroups([]);
    setSelectedGroups([]);
    setVisible(false);
  };

  const associatedGroupsSidebar = (
    <Sidebar
      visible={visible}
      position="right"
      onHide={() => {
        setVisible(false);
      }}
      header="Associated Groups"
      className="bg-white w-full md:w-20rem lg:w-30rem "
    >
      <div className="flex flex-column flex-grow">
        <MultiSelect
          value={selectedGroups}
          onChange={(e) => {
            setSelectedGroups(e.value);
          }}
          options={groupsList}
          fixedPlaceholder={true}
          optionLabel="name"
          filter
          placeholder="Select Groups"
          maxSelectedLabels={3}
          className="w-full md:w-full text-sm"
        />
        <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {selectedGroups.map((user) => (
            <Chip
              key={user.id}
              label={user.name}
              className="text-sm"
              removable
              onRemove={() => {
                handleRemoveGroup(user);
              }}
            />
          ))}
        </div>
      </div>
      <div
        className={`w-full flex justify-content-end gap-2  ${isMobile ? "sidebar-mobile" : "sidebar-desktop"}`}
      >
        <Button
          label="Cancel"
          onClick={handleCloseSidebar}
          className="text-sm text-gray-500 bg-white border-0"
        />
        <Button
          label="Edit Groups"
          className="text-sm bg-blue-500"
          onClick={handleGroupsList}
          loading={loading}
        />
      </div>
    </Sidebar>
  );

  return (
    <>
      <Toast ref={toast} />
      <div className="m-0 pb-2 text-lg font-bold">Basic Info</div>
      <div className="flex-col mt-3">
        <div className="flex items-center mb-2 gap-7">
          <span className="w-32">Onboarded on:</span>
          <span className="ml-4">{onBoardedOn}</span>
        </div>
        <div className="flex mt-6">
          <div className="flex gap-7">
            <div style={{ alignContent: "center" }}>Associated Group:</div>
            <div style={{ alignContent: "center" }}>
              {userInfo?.groups?.map((group, idx) => (
                <Chip key={idx} label={group} className="mr-2 text-sm" />
              ))}
            </div>
            {associatedGroupsSidebar}
          </div>
          <Button
            rounded
            text
            icon="pi pi-pencil"
            className="text-blue-500"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfoTab;
