// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul[id*="alert-status-menu_list"] li span[class*="p-menuitem-text"] {
    margin-left: 10px;
}
.refresh-alerts:focus {
    outline: none; /* Removes the focus outline */
    box-shadow: none; /* Removes any additional shadow styles */
  }

.refresh-alerts span[class*="p-button-label p-c"]{
    font-weight: 350;
  }

.refresh-alerts-no-alerts span[class*="p-button-label p-c"]{
    font-weight: 350;
  }

  .refresh-alerts-no-alerts span[class*="p-button-icon-left"]{
    margin-left: 3vw;
  }

`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Pulse/AlertTab/AlertTabList/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa,EAAE,8BAA8B;IAC7C,gBAAgB,EAAE,yCAAyC;EAC7D;;AAEF;IACI,gBAAgB;EAClB;;AAEF;IACI,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":["ul[id*=\"alert-status-menu_list\"] li span[class*=\"p-menuitem-text\"] {\n    margin-left: 10px;\n}\n.refresh-alerts:focus {\n    outline: none; /* Removes the focus outline */\n    box-shadow: none; /* Removes any additional shadow styles */\n  }\n\n.refresh-alerts span[class*=\"p-button-label p-c\"]{\n    font-weight: 350;\n  }\n\n.refresh-alerts-no-alerts span[class*=\"p-button-label p-c\"]{\n    font-weight: 350;\n  }\n\n  .refresh-alerts-no-alerts span[class*=\"p-button-icon-left\"]{\n    margin-left: 3vw;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
