import React from "react";

const ControlAccessIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16634 10.5H4.49967C3.79243 10.5 3.11415 10.781 2.61406 11.281C2.11396 11.7811 1.83301 12.4594 1.83301 13.1667V14.5M14.9663 11.4333L14.3663 11.2333M10.633 9.76667L10.033 9.56667M11.5663 12.9667L11.7663 12.3667M13.233 8.63333L13.433 8.03333M13.5663 12.9667L13.2997 12.3M11.6997 8.7L11.433 8.03333M10.033 11.5667L10.6997 11.3M14.2997 9.7L14.9663 9.43333M14.4997 10.5C14.4997 11.6046 13.6042 12.5 12.4997 12.5C11.3951 12.5 10.4997 11.6046 10.4997 10.5C10.4997 9.39543 11.3951 8.5 12.4997 8.5C13.6042 8.5 14.4997 9.39543 14.4997 10.5ZM9.16634 5.16667C9.16634 6.63943 7.97243 7.83333 6.49967 7.83333C5.02692 7.83333 3.83301 6.63943 3.83301 5.16667C3.83301 3.69391 5.02692 2.5 6.49967 2.5C7.97243 2.5 9.16634 3.69391 9.16634 5.16667Z"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ControlAccessIcon;
