import { useEffect } from "react";

export const MultiselectFooter = ({
  setPreselectedUsers,
  selectedUsers,
  setSelectedUsers,
  preselectedUsers,
  handleClosePanel,
}) => {
  useEffect(() => {
    setPreselectedUsers(selectedUsers);
  }, []);
  return (
    <div className="multiselect-footer">
      <div className="flex gap-2 justify-end self-stretch py-2 pr-4 pl-20 text-sm tracking-normal leading-5 whitespace-nowrap bg-white border-t border-solid border-t-[color:var(--Neutral-grey-500,#E6E6E6)] font-[475]">
        <button
          className="justify-center px-3.5 py-2.5 bg-white rounded-md border border-solid shadow-sm aspect-[1.85] border-[color:var(--Global-Neutral-Grey-500,#E6E6E6)] text-neutral-600"
          onClick={() => {
            handleClosePanel();
            setPreselectedUsers(selectedUsers);
          }}
        >
          Cancel
        </button>
        <button
          className="justify-center px-3.5 py-2.5 text-white bg-blue-700 rounded-md aspect-[1.38]"
          onClick={() => {
            setSelectedUsers(preselectedUsers);
            handleClosePanel();
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
};
