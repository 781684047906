import SpaceList from "components/Spaces/SpacesList";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import "./spacesList.css";
import NewSpaceSetup from "components/Spaces/NewSpaceSetup";
import React from "react";
/**
 * Main component for managing and displaying spaces in the admin panel.
 * Provides functionality to view, add, and manage spaces with responsive design.
 *
 * @returns {JSX.Element} The Spaces component with a list of spaces and options to add new spaces
 */
const Spaces = () => {
  const [visible, setVisible] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [totalSpaces, setTotalSpaces] = useState(0);
  const { isMobile } = useMediaQuerry();
  const toast = useRef(null);

  return (
    <div className={`surface-0 spacesContainer`}>
      <div
        className={`flex ${!isMobile && "align-items-center"} flex-column lg:justify-content-between lg:flex-row`}
      >
        <Toast ref={toast} />
        <div className="spacesHeader">
          <p className="font-semibold" style={{ fontSize: "1.313rem" }}>
            Spaces ({totalSpaces})
          </p>
        </div>
        <div className="mt-3 lg:mt-0">
          <Button
            label="New Space Set up"
            icon="pi pi-plus"
            className="text-sm"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>

      <SpaceList
        fetchData={fetchData}
        visible={visible}
        setVisible={setVisible}
        setFetchData={setFetchData}
        setTotalSpaces={setTotalSpaces}
      />

      <NewSpaceSetup visible={visible} setVisible={setVisible} toast={toast} />
    </div>
  );
};

export default Spaces;
