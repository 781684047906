import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import Raised from './icons/Raised';
import Acknowledged from './icons/Acknowledged';
import Escalated from './icons/Escalated';
// import Investigated from './icons/Investigated';
import Notified from './icons/Notified';
import Resolved from './icons/Resolved';
import { Skeleton } from 'primereact/skeleton';
import NoAlerts from './icons/NoAlerts';
import StatusIcon from './icons/StatusIcon';
import { ALERTS_LIST } from './mockAlerts';
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import {usePulseApi} from "hooks/pulseConfig";
import './index.css'

const AlertStatusDropdown = ({ menuRef,setSelectedStatus }) => {
  const getMenuItems = () => [
    { label: "All", command: () => setSelectedStatus(null) },
    { label: "Raised", icon: () => <Raised />, command: () => setSelectedStatus("Raised") },
    { label: "Notified", icon: () => <Notified />, command: () => setSelectedStatus("Notified") },
    { label: "Acknowledged", icon: () => <Acknowledged />, command: () => setSelectedStatus("Acknowledged") },
    { label: "Escalated", icon: () => <Escalated />, command: () => setSelectedStatus("Escalated") },
    // { label: "Investigated", icon: () => <Investigated />, command: () => setSelectedStatus("Investigated") },
    { label: "Resolved", icon: () => <Resolved />, command: () => setSelectedStatus("Resolved") },
  ];

  return (
    <Menu model={getMenuItems()} popup ref={menuRef} id="alert-status-menu" />
  );
};

const CardSubtitle = ({ id, created_at, alert_message, raised, notified, acknowledged, resolved, escalated }) => {
  const alertStatus = { raised, notified, acknowledged, resolved, escalated };

  function formatAlertTime(inputDate) {
    const date = new Date(inputDate);
    const now = new Date();

    // Check if the input date is the same as the current date
    const isSameDate =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    if (isSameDate) {
      // Format time as HH:MM in 24-hour format
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    } else {
      // Format date as MM/dd/yyyy
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    }
  }

  const alertTime = formatAlertTime(created_at);

  const getAlertType = () => {
    if (alertStatus.resolved) return 'Resolved';
    // if (alertStatus.investigated) return 'Investigated';
    if (alertStatus.acknowledged) return 'Acknowledged';
    if (alertStatus.escalated) return 'Escalated';
    if (alertStatus.notified) return 'Notified';
    if (alertStatus.raised) return 'Raised';
    return 'Raised';
  };
  const alertType = getAlertType();

  const getIconByType = (type) => {
    switch (type.toLowerCase()) {
      case 'resolved': return <Resolved />; break;
      // case 'investigated': return <Investigated />; break;
      case 'acknowledged': return <Acknowledged />; break;
      case 'escalated': return <Escalated />; break;
      case 'notified': return <Notified />; break;
      case 'raised': return <Raised />; break;
      default: return null;
    }
  };

  const alertIcon = getIconByType(alertType);

  return (
    <div className='flex flex-row justify-between items-center'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center mt-1 text-md font-light font-sans text-black-900'>
          ID {id}
          <div className='flex flex-row bg-[#F5F5F5] items-center ml-[20px] p-[1px] pl-[4px] pr-[4px] rounded-2xl' style={{borderRadius:'15px'}} >
            <div className='ml-2'>
              {alertIcon}
            </div>
            <span className='bg-[#F5F5F5] text-sm py-1 px-2 rounded-full text-gray-800' style={{fontWeight:'500'}}>
              {alertType}
            </span>
          </div>
        </div>
        <div className='flex flex-row mt-2 mb-2'>
          <span className='text-sm text-[#72777B] font-light'>{alert_message}</span>
        </div>
      </div>
      <div className='flex flex-row text-[#72777B] items-center ml-[20px] pl-[5px] pr-[10px] rounded-2xl'>
        {alertTime}
      </div>
    </div>
  );
};

const AlertTabList = ({ selectedAlertId, setSelectedAlertId }) => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredAlerts,setFilteredAlerts] = useState([]);
  const menuRef = useRef(null);
  const getAlertTypeForStatus = (alertStatus) => {
    if (alertStatus.resolved) return 'Resolved';
    // if (alertStatus.investigated) return 'Investigated';
    if (alertStatus.acknowledged) return 'Acknowledged';
    if (alertStatus.escalated) return 'Escalated';
    if (alertStatus.notified) return 'Notified';
    if (alertStatus.raised) return 'Raised';
    return 'Raised';
  };
  const location = useLocation();
  const fetchData = async () => {
    setLoading(true);
    try {
      // Call the getPulseAlertList API
      const response = await getPulseAlertList({namespace_id:location?.state?.sourceRowData?.namespace_id,asset_id: location?.state?.sourceRowData?.asset_id});

      // Remove duplicate alerts based on the 'id' property
      const uniqueAlerts = response?.results?.data.feeds.filter((alert, index, self) => 
        index === self.findIndex(a => a.id === alert.id)
      );
  
      // Set the unique alerts in state
      setAlerts(uniqueAlerts);
      
    } catch (error) {
      console.log('error is ',error)
    } finally {
      // setInnerLoading(false);
      setLoading(false);
    }
  };

  const {getPulseAlertList}=usePulseApi();
  useEffect(() => {
    fetchData();
    // setAlerts(ALERTS_LIST)
    // console.log('initial render ',selectedStatus)
    // selectedStatus ? setFilteredAlerts(alerts.filter(alert => getAlertTypeForStatus(alert) === selectedStatus))
    // : setFilteredAlerts(alerts);
  }, []);

  useEffect(()=>{
    console.log('re render ')
    selectedStatus ? setFilteredAlerts(alerts.filter(alert => getAlertTypeForStatus(alert) === selectedStatus))
    : setFilteredAlerts(alerts);
  },[selectedStatus])

  const AlertsHeader=()=>{
    return (<div className='flex flex-row justify-between mt-6'>
          <div className='flex flex-row justify-center items-center'>
                <span className='text-2xl font-semibold text-black-900'>Alerts</span>
                {/* <Button
              label=""
              icon="pi pi-refresh"
              className="text-[#3B82F6] w-[2vw] ml-2"
              iconPos="right"
              severity="info"
              outlined
              onClick={() => {
                setLoading(true);
                // const isFilter = isObjectWithNonEmptyValues(filterOption);
                fetchData();      
              }}
            /> */}
          </div>
          <div className='flex flex-row items-center mr-2 cursor-pointer' onClick={(e) => menuRef.current.toggle(e)}>
            {console.log('loading is ',loading)}
          <Button
            label={`${loading? "Refreshing": "Refresh"}`}
            icon="pi pi-refresh"
            style={{
              color: loading ? '#9CA3AF' : '#495057',
              marginRight: '2vw',
              backgroundColor:'white',
              border:'0px',
              fontWeight:'400',
              fontSize:'16px'
            }}
            className="refresh-alerts"
            disabled={loading}
            iconPos="left"
            size="large"
            severity="info"
            // outlined
            onClick={() => {
              console.log('refresh button clicked')
              setLoading(true);
              setSelectedAlertId(null);
              fetchData();
            }}
          />
            <StatusIcon />
            <span className='text-md ml-2 font-semibold'>Status { (selectedStatus !== null) && `(${selectedStatus})`}</span>
            </div>
          <AlertStatusDropdown menuRef={menuRef} setSelectedStatus={setSelectedStatus} />
        </div>)
  }

  if (loading) {
    return (
      <div className='flex flex-col'>
        <AlertsHeader/>
        {[...Array(6)].map((_, i) => (
          <Skeleton key={i} width="100%" height="4rem" className="mt-5" />
        ))}
      </div>
    );

  }
  return (
    <div className='flex flex-col h-[80vh]'>
      {alerts && <AlertsHeader/>}
      {console.log(' selected status is ',selectedStatus)}
      <div className='flex flex-col mt-5 overflow-scroll mb-7'>
      {(selectedStatus ? filteredAlerts : alerts)?.length > 0 ? (
        (selectedStatus ? filteredAlerts : alerts)?.map((alert) => (
          <div
            key={alert.id}
            onClick={() => setSelectedAlertId(alert.id)}
            style={{
              borderBottom: '1px solid #ddd',
              width: '100%',
              background: selectedAlertId === alert.id ? '#F2F6FB' : 'white',
              borderLeft: selectedAlertId === alert.id ? '3px solid #1581ED' : 'none',
              cursor: 'pointer'
            }}
            className="p-3 alert-list-unit"
          >
            <CardSubtitle {...alert} />
          </div>
        ))
      ) : (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '40%',
          width: '15%',
          marginTop: '10vh',
          textAlign: 'center'
        }}>
          <div className="ml-[5%]">
            <NoAlerts />
          </div>
          <span className="text-xl mr-[10%] font-semibold mt-[2vh]">No {selectedStatus && selectedStatus} Alerts</span>
          <span className="text-md font-normal mt-[2vh] text-[#6C757D]">
            No {selectedStatus && selectedStatus} alerts to show for now
          </span>
          <Button
            label={`${loading ? "Refreshing" : "Refresh"}`}
            icon="pi pi-refresh"
            style={{
              width: 'fit-content',
              marginTop: '2vh',
              marginLeft: '2vw',
              color: loading ? '#9CA3AF' : '#495057',  // text-gray-400 : text-[#495057]
              backgroundColor:'white'
            }}
            className="refresh-streams-no-alerts"  // keeping this class if it's needed for other purposes
            onClick={async () => {
              await setLoading(true);
              // const isFilter = isObjectWithNonEmptyValues(filterOption);
              fetchData();      
            }}
          />

        </div>
      )}

      </div>
    </div>
  );
};

export default AlertTabList;