import React from "react";
import { format, parse } from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import "./workspace.css";

const Filter = ({
  handleFilterApply,
  onCloseFilters,
  selectFormatOptions,
  filters,
  setFilters,
  initialFilters,
  setInitialFilters,
}) => {
  const statusOptions = [
    { name: "Completed", value: "Completed" },
    { name: "Ongoing", value: "Ongoing" },
    { name: "Error", value: "Error" },
    { name: "Terminated", value: "Terminated" },
  ];

  const onJobIdChange = (e) => {
    const { value } = e.target;
    setFilters((prev) => ({ ...prev, jobId: value }));
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateRange") {
      if (!value?.length) {
        setFilters((prev) => ({ ...prev, [name]: [] }));
        return;
      }
      let updatedValue = [...value]; // Create a new array reference  
      if (updatedValue.length === 1) {
        // Single date: Use the same date as both start and end
        updatedValue.push(updatedValue[0]);
      }
  
      if (updatedValue.length === 2) {
        const [start, end] = updatedValue;
        if (end) {
          updatedValue[1] = new Date(new Date(end).setHours(23, 59, 59, 999));
        }
      }
  
      setFilters((prev) => ({ ...prev, [name]: updatedValue }));
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };
  const onOutputFormatChange = (e) => {
    const { value } = e.target;
    setFilters((prev) => ({ ...prev, outputFormat: value }));
  };

  const onApplyClick = () => {
    const { dateRange, ...otherFilters } = filters;
    
    let date_gt = dateRange[0] ? format(new Date(dateRange[0]), "yyyy-MM-dd") : "";
    let date_lt = dateRange[1]
      ? format(new Date(new Date(dateRange[1]).setDate(new Date(dateRange[1]).getDate() + 1)), "yyyy-MM-dd")
      : "";
  
    handleFilterApply({ ...otherFilters, date_gt, date_lt });
    onCloseFilters();
  };
  
  return (
    <div className="sticky card-box">
      <Card
        className="filterPopup mx-3"
        pt={{
          content: { className: "pt-2" },
          body: { className: "pt-0" },
        }}
      >
        <div className="flex flex-column gap-3">
          <div className="flex justify-content-between align-items-center">
            <h1 className="text-xl">Filter Tasks</h1>
            <i className="pi pi-times cursor-pointer" onClick={onCloseFilters}></i>
          </div>
          <div className="flex justify-content-center flex-column">
            <label htmlFor="dateRange" className="block mb-2">
              Select Date Range
            </label>
            <Calendar
              id="dateRange"
              name="dateRange"
              value={filters.dateRange}
              onChange={onInputChange}
              showIcon
              selectionMode="range"
              placeholder="Select Date Range"
              maxDate={new Date()}
            />
          </div>
          <div className="flex justify-content-center flex-column">
            <label htmlFor="jobId" className="block mb-2">
              Job ID
            </label>

            <IconField iconPosition="right" className="w-full">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                v-model="value1"
                value={filters.jobId}
                placeholder="Search"
                type="number"
                className="w-full"
                onChange={onJobIdChange}
              />
            </IconField>
          </div>

          <div className="flex justify-content-center flex-column">
            <label htmlFor="outputFormat" className="block mb-2">
              Output Format
            </label>
            <Dropdown
              options={selectFormatOptions}
              optionLabel="output_format"
              optionValue="id"
              name="outputFormat"
              placeholder="Select Output Format"
              className="w-full"
              value={filters.outputFormat}
              onChange={onOutputFormatChange}
            />
          </div>

          <div className="flex justify-content-center flex-column">
            <label htmlFor="status" className="block mb-2">
              Status
            </label>
            <Dropdown
              options={statusOptions}
              optionLabel="name"
              name="status"
              placeholder="Select Status"
              className="w-full"
              value={filters.status}
              onChange={onInputChange}
            />
          </div>

          <div className="flex justify-content-between mt-5">
            <Button
              label="Reset"
              className="w-4"
              text
              severity="danger"
              onClick={() => {
                setFilters(initialFilters);
                handleFilterApply({
                  current_page: 1,
                  page_size: 10
                });
                onCloseFilters();
              }}
            />
            <Button
              label="Apply"
              className="w-4"
              severity="primary"
              onClick={onApplyClick}
              disabled={Object.values(filters).every((filter) =>
                Array.isArray(filter) ? !filter?.length : !filter,
              )}
              style={{ backgroundColor: "var(--theme_primary_button)" }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

Filter.propTypes = {
  handleApply: PropTypes.func,
  onCloseFilters: PropTypes.func,
};

export default Filter;
