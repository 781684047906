import React from "react";

const MicrosoftLogo = ({ className, style }) => (
    <svg width="20" height="21" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_490_14944)">
            <path d="M24.9881 13.0322C24.9881 11.984 24.903 11.219 24.7189 10.4258H12.749V15.157H19.7751C19.6335 16.3328 18.8686 18.1035 17.1687 19.2934L17.1448 19.4518L20.9295 22.3837L21.1917 22.4099C23.5998 20.1858 24.9881 16.9136 24.9881 13.0322Z" fill="#4285F4" />
            <path d="M12.7485 25.498C16.1907 25.498 19.0805 24.3647 21.1912 22.41L17.1682 19.2935C16.0916 20.0442 14.6467 20.5684 12.7485 20.5684C9.37715 20.5684 6.51572 18.3444 5.4957 15.2705L5.34619 15.2832L1.41084 18.3288L1.35938 18.4719C3.45586 22.6365 7.76221 25.498 12.7485 25.498Z" fill="#34A853" />
            <path d="M5.49619 15.2703C5.22705 14.4771 5.07129 13.6271 5.07129 12.7488C5.07129 11.8705 5.22705 11.0206 5.48203 10.2273L5.4749 10.0584L1.49023 6.96387L1.35986 7.02588C0.495801 8.7541 0 10.6948 0 12.7488C0 14.8028 0.495801 16.7435 1.35986 18.4717L5.49619 15.2703Z" fill="#FBBC05" />
            <path d="M12.7485 4.92959C15.1425 4.92959 16.7573 5.96367 17.6781 6.82783L21.2762 3.31475C19.0664 1.26074 16.1907 0 12.7485 0C7.76221 0 3.45586 2.86143 1.35938 7.02607L5.48154 10.2275C6.51572 7.15361 9.37715 4.92959 12.7485 4.92959Z" fill="#EB4335" />
        </g>
        <defs>
            <clipPath id="clip0_490_14944">
                <rect width="25" height="25.5859" fill="white" />
            </clipPath>
        </defs>
    </svg>

);

export default MicrosoftLogo;
