import React from 'react'

const Acknowledged = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.03125 4.67369C0.989582 4.56143 0.989582 4.43795 1.03125 4.32569C1.4371 3.34162 2.12601 2.50021 3.01064 1.90814C3.89527 1.31607 4.93577 1 6.00025 1C7.06473 1 8.10524 1.31607 8.98987 1.90814C9.87449 2.50021 10.5634 3.34162 10.9693 4.32569C11.0109 4.43795 11.0109 4.56143 10.9693 4.67369C10.5634 5.65776 9.87449 6.49917 8.98987 7.09124C8.10524 7.68331 7.06473 7.99938 6.00025 7.99938C4.93577 7.99938 3.89527 7.68331 3.01064 7.09124C2.12601 6.49917 1.4371 5.65776 1.03125 4.67369Z" stroke="#F97316" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.00025 5.99969C6.82868 5.99969 7.50025 5.32812 7.50025 4.49969C7.50025 3.67126 6.82868 2.99969 6.00025 2.99969C5.17183 2.99969 4.50025 3.67126 4.50025 4.49969C4.50025 5.32812 5.17183 5.99969 6.00025 5.99969Z" stroke="#F97316" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Acknowledged