// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[class*="rules-table"] thead tr th:nth-child(2) {
  width: 50%;
  white-space: nowrap;
}
div[class*="rules-table"] tbody tr td:nth-child(2) {
  margin-left: -5px;
  white-space: nowrap;
}

div[class*="rules-table"] tbody tr td:nth-child(3) {
  display: flex;
  flex-direction: row;
  margin-top: -15px;
}

.item {
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px;
  padding: 10px 8px;
  background-color: #eceef1;
  display: flex;
  flex-direction: row;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.dragItem {
  margin-right: 10px;
  padding-top: 5px;
  color: gray;
}

.new-source-setup ul[class*="p-tabview-nav"] span[class*="p-tabview-title"]>span {
border: 0px;
}

.new-source-setup table[class*="p-treetable-table"] tr[class*="p-row-odd"] td{
  padding: 0.75rem 0.75rem;
}

div[class*="apply-rule-sidebar"] {
  width: 35vw;
}

div[class*="apply-rule-sidebar"] div[class*="p-sidebar-header"] {
  padding-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/SourceConfig/ApplyRules/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;AACA,WAAW;AACX;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["div[class*=\"rules-table\"] thead tr th:nth-child(2) {\n  width: 50%;\n  white-space: nowrap;\n}\ndiv[class*=\"rules-table\"] tbody tr td:nth-child(2) {\n  margin-left: -5px;\n  white-space: nowrap;\n}\n\ndiv[class*=\"rules-table\"] tbody tr td:nth-child(3) {\n  display: flex;\n  flex-direction: row;\n  margin-top: -15px;\n}\n\n.item {\n  font-family: Arial, Helvetica, sans-serif;\n  border-radius: 8px;\n  padding: 10px 8px;\n  background-color: #eceef1;\n  display: flex;\n  flex-direction: row;\n}\n\n.item:not(:last-child) {\n  margin-bottom: 8px;\n}\n\n.dragItem {\n  margin-right: 10px;\n  padding-top: 5px;\n  color: gray;\n}\n\n.new-source-setup ul[class*=\"p-tabview-nav\"] span[class*=\"p-tabview-title\"]>span {\nborder: 0px;\n}\n\n.new-source-setup table[class*=\"p-treetable-table\"] tr[class*=\"p-row-odd\"] td{\n  padding: 0.75rem 0.75rem;\n}\n\ndiv[class*=\"apply-rule-sidebar\"] {\n  width: 35vw;\n}\n\ndiv[class*=\"apply-rule-sidebar\"] div[class*=\"p-sidebar-header\"] {\n  padding-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
