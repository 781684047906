import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
// import Source from "models/source";
import { Toast } from "primereact/toast";
import "./index.css";
import { useSourceApi } from "hooks/sourceConfig";

const SourceTablesFieldsModal = (props) => {
  const {
    onClose,
    visible,
    setImported,
    connectionDetails,
    sourceData,
    editMode,
  } = props;

  const [schemasResponse, setSchemasResponse] = useState([]);
  const [searchSchemaText, setSearchSchemaText] = useState("");
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [tablesResponse, setTablesResponse] = useState([]);
  const [searchTableText, setSearchTableText] = useState("");
  const [selectedTable, setSelectedTable] = useState(null);
  const [fieldsResponse, setFieldsResponse] = useState({});
  const [searchFieldText, setSearchFieldText] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedSchemas, setCheckedSchemas] = useState({});
  const [importing, setImporting] = useState(false);
  const [allTables, setAllTables] = useState([]);
  const [selectAllTables, setSelectAllTables] = useState({});
  const [selectAllFields, setSelectAllFields] = useState({});
  const [tablesLoading, setTablesLoading] = useState(false);
  const [fieldsLoading, setFieldsLoading] = useState(false);
  const [disableCheckboxes, setDisableCheckboxes] = useState(false);
  const {getSchemas,getTables,getFields,createSource,updateSource,mapFields} = useSourceApi();

  const toast = useRef(null);

  useEffect(() => {
    // Retrieve fieldsMapping from local storage
    const storedFieldsMapping =
      JSON.parse(localStorage.getItem("fieldsMapping")) || [];

    const newCheckedItems = {};

    if (storedFieldsMapping.length > 0) {
      console.log(setDisableCheckboxes, " is disabledCheckboxes");
      setDisableCheckboxes(true);
    } else {
      setDisableCheckboxes(false);
    }

    // Iterate over fieldsMapping to build the checkedItems state
    storedFieldsMapping.forEach((mapping) => {
      const { schemaName, tableName, fieldName } = mapping;

      const fieldKey = `${schemaName}->${tableName}->${fieldName}`;
      const tableKey = `${schemaName}->${tableName}`;

      console.log(fieldKey, " is fieldkey");

      // Mark field as checked
      newCheckedItems[fieldKey] = true;

      // Also mark table as checked if any field in the table is selected
      newCheckedItems[tableKey] = true;

      // Optionally, mark schema as checked if any table is selected
      newCheckedItems[schemaName] = true;
    });

    setCheckedItems(newCheckedItems);
  }, []);

  useEffect(() => {
    // Fetch schemas on load
    getSchemas(connectionDetails)
      .then((schemas) => setSchemasResponse(schemas))
      .catch((error) => console.error("Error fetching schemas:", error));
  }, [connectionDetails]);

  useEffect(() => {
    if (selectedSchema) {
      console.log("setting loader to true");
      setTablesLoading(true);
      // Fetch tables when schema is selected
      getTables({ ...connectionDetails, schema: selectedSchema })
        .then((tables) => {
          setTablesResponse(tables);
          setAllTables(tables);
        })
        .catch((error) => console.error("Error fetching tables:", error))
        .finally(() => {
          setTablesLoading(false);
          setSelectedTable(null);
        });

      // Fetch fields
      setFieldsLoading(true);
      getFields({ ...connectionDetails, schema: selectedSchema })
        .then((fields) => {
          // console.log(' fields are ',fields[selectedTable])
          setFieldsResponse(fields);
        })
        .catch((error) => console.error("Error fetching fields:", error))
        .finally(() => {
          setFieldsLoading(false);
        });
    }
  }, [selectedSchema, connectionDetails]);

  const IndeterminateCheckbox = ({ level = "schema", tableName = null }) => {
    const checkboxRef = useRef(null);

    useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = true; // This ensures that the checkbox is rendered as indeterminate
      }
    }, []);

    const handleIndeterminateClick = () => {
      if (level === "table") {
        // Unselect the table
        setCheckedItems((prevState) => ({
          ...prevState,
          [`${selectedSchema}->${tableName}`]: false,
        }));

        // Unselect all fields under that table
        setCheckedItems((prevState) => {
          const updatedState = { ...prevState };

          // Access the array of fields for the selected table within the schema
          fieldsResponse[tableName]?.forEach((field) => {
            updatedState[`${selectedSchema}->${tableName}->${field}`] = false;
          });

          return updatedState;
        });

        // Optionally, uncheck the 'Select All Fields' for that table
        setSelectAllFields((prevState) => ({
          ...prevState,
          [tableName]: false,
        }));

        // Update schema checkbox based on the tables' checkboxes
        setCheckedItems((prevState) => {
          const updatedState = { ...prevState };

          // Check if any table within the schema is still checked
          const isAnyTableChecked = Object.keys(prevState).some((key) => {
            return (
              key.startsWith(`${selectedSchema}->`) && prevState[key] === true
            );
          });

          // Set the schema checkbox state
          updatedState[`${selectedSchema}`] = isAnyTableChecked;

          return updatedState;
        });

        // Ensure the schema checkbox is set to false if it was indeterminate
        setCheckedItems((prevState) => {
          const updatedState = { ...prevState };

          // Check if all tables within the schema are unchecked
          const areAllTablesUnchecked = Object.keys(prevState).every((key) => {
            return !key.startsWith(`${selectedSchema}->`) || !prevState[key];
          });

          // If all tables are unchecked, set the schema checkbox to false
          if (areAllTablesUnchecked) {
            updatedState[`${selectedSchema}`] = false;
          }

          return updatedState;
        });
      } else if (level === "schema") {
        // Unselect all tables within the selected schema
        const tablesInSchema = Object.keys(fieldsResponse);

        setCheckedItems((prevState) => ({
          ...prevState,
          [`${selectedSchema}`]: false,
        }));

        // Unselect each table and its fields
        setCheckedItems((prevState) => {
          const updatedState = { ...prevState };

          tablesInSchema.forEach((tableName) => {
            // Unselect the table
            updatedState[`${selectedSchema}->${tableName}`] = false;

            // Unselect all fields under that table
            fieldsResponse[tableName]?.forEach((field) => {
              updatedState[`${selectedSchema}->${tableName}->${field}`] = false;
            });

            // Optionally, uncheck the 'Select All Fields' for that table
            setSelectAllFields((prevState) => ({
              ...prevState,
              [tableName]: false,
            }));
          });

          return updatedState;
        });

        // Optionally, uncheck the 'Select All Tables' for the schema
        setSelectAllTables((prevState) => ({
          ...prevState,
          [selectedSchema]: false,
        }));

        // Optionally, uncheck the 'Select All Fields' for the schema
        setSelectAllFields((prevState) => ({
          ...prevState,
          [selectedSchema]: false,
        }));
      }
    };

    return (
      <div
        className={`indeterminate-checkbox`}
        onClick={() => {
          if (!disableCheckboxes) {
            handleIndeterminateClick(); // Call the function only if not disabled
          }
        }}
      >
        <Checkbox ref={checkboxRef} disabled={disableCheckboxes} />
      </div>
    );
  };

  const handleFieldCheckboxChange = (e, table, field) => {
    const { checked, value } = e.target;
    const key = `${selectedSchema}->${table}->${field}`;

    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState, [key]: checked };

      // Check if all fields in the table are selected
      const allFieldsSelected = fieldsResponse[table]?.every(
        (fieldItem) =>
          updatedCheckedItems[`${selectedSchema}->${table}->${fieldItem}`]
      );

      // Update the "Select All Fields" checkbox state
      setSelectAllFields((prevState) => ({
        ...prevState,
        [table]: allFieldsSelected,
      }));

      // Check if all fields are unchecked
      const allFieldsUnchecked = fieldsResponse[table]?.every(
        (fieldItem) =>
          !updatedCheckedItems[`${selectedSchema}->${table}->${fieldItem}`]
      );

      // Update the table checkbox state
      if (allFieldsUnchecked) {
        updatedCheckedItems[`${selectedSchema}->${table}`] = false;
      } else if (allFieldsSelected) {
        updatedCheckedItems[`${selectedSchema}->${table}`] = true;
      } else {
        updatedCheckedItems[`${selectedSchema}->${table}`] = "indeterminate";
      }
      const allTablesSelected = tablesResponse.every(
        (tableItem) =>
          updatedCheckedItems[`${selectedSchema}->${tableItem}`] === true
      );

      const someTablesSelected = tablesResponse.some(
        (tableItem) =>
          updatedCheckedItems[`${selectedSchema}->${tableItem}`] === true ||
          (typeof updatedCheckedItems[`${selectedSchema}->${tableItem}`] !==
            "boolean" &&
            updatedCheckedItems[`${selectedSchema}->${tableItem}`]?.includes(
              "indeterminate"
            ))
      );
      setSelectAllTables((prevState) => ({
        ...prevState,
        [selectedSchema]: allTablesSelected,
      }));

      updatedCheckedItems[selectedSchema] = allTablesSelected
        ? true
        : someTablesSelected
        ? "indeterminate"
        : false;

      return updatedCheckedItems;
    });
  };

  const handleTableCheckboxChange = (e, table, schema) => {
    const { checked } = e.target;
    const key = `${schema}->${table}`;

    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState, [key]: checked };

      if (checked) {
        // Select all fields in the table
        fieldsResponse[table]?.forEach((field) => {
          updatedCheckedItems[`${schema}->${table}->${field}`] = true;
        });
        setSelectAllFields((prevState) => ({
          ...prevState,
          [table]: true,
        }));
      } else {
        // Deselect all fields in the table
        fieldsResponse[table]?.forEach((field) => {
          updatedCheckedItems[`${schema}->${table}->${field}`] = false;
        });
        setSelectAllFields((prevState) => ({
          ...prevState,
          [table]: false,
        }));
      }

      // Update schema checkbox state based on tables
      const allTablesSelected = tablesResponse.every(
        (tableItem) => updatedCheckedItems[`${schema}->${tableItem}`] === true
      );

      const someTablesSelected = tablesResponse.some(
        (tableItem) =>
          updatedCheckedItems[`${schema}->${tableItem}`] === true ||
          (typeof updatedCheckedItems[`${schema}->${tableItem}`] !==
            "boolean" &&
            updatedCheckedItems[`${schema}->${tableItem}`]?.includes(
              "indeterminate"
            ))
      );

      updatedCheckedItems[schema] = allTablesSelected
        ? true
        : someTablesSelected
        ? "indeterminate"
        : false;

      // Update the "Select All Fields" checkbox state
      setSelectAllTables((prevState) => ({
        ...prevState,
        [schema]: allTablesSelected,
      }));

      return updatedCheckedItems;
    });
  };

  const handleSchemaCheckboxChange = (e, schema) => {
    const isChecked = e.target.checked;

    // Only allow unchecking, block checking
    if (!isChecked) {
      setCheckedItems((prevState) => {
        const newCheckedItems = { ...prevState };

        // Uncheck the schema itself
        newCheckedItems[schema] = isChecked;

        // Uncheck all tables and fields under the schema
        tablesResponse.forEach((table) => {
          newCheckedItems[`${schema}->${table}`] = isChecked;

          // Initialize or update the "Select All Fields" state
          newCheckedItems[table] = false;

          // Uncheck all fields under the table
          const fields = fieldsResponse[table] || [];
          fields.forEach((field) => {
            newCheckedItems[`${schema}->${table}->${field}`] = isChecked;
          });
        });

        return newCheckedItems;
      });

      // Initialize or update the "Select All Tables" state for the schema
      setSelectAllTables((prevState) => ({
        ...prevState,
        [schema]: false, // Ensure "Select All Tables" is unchecked
      }));

      // Update the "Select All Fields" state
      setSelectAllFields((prevState) => {
        const newSelectAllFields = { ...prevState };

        tablesResponse.forEach((table) => {
          newSelectAllFields[table] = false;
        });

        return newSelectAllFields;
      });
    }
  };

  const handleSelectAllFields = (e, table) => {
    const isChecked = e.target.checked;

    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };
      const fields = fieldsResponse[table] || [];
      const allFieldsSelected = isChecked;

      // Update the state for all fields in the table
      fields.forEach((field) => {
        updatedCheckedItems[`${selectedSchema}->${table}->${field}`] =
          allFieldsSelected;
      });

      // Update the "Select All Fields" checkbox state
      setSelectAllFields((prevState) => ({
        ...prevState,
        [table]: allFieldsSelected,
      }));

      // Update the table checkbox state
      const allFieldsUnchecked = !allFieldsSelected;
      if (allFieldsUnchecked) {
        updatedCheckedItems[`${selectedSchema}->${table}`] = false;
      } else if (allFieldsSelected) {
        updatedCheckedItems[`${selectedSchema}->${table}`] = true;
      } else {
        updatedCheckedItems[`${selectedSchema}->${table}`] = "indeterminate";
      }

      // Update the schema checkbox state
      const allTablesSelected = tablesResponse.every(
        (tableItem) =>
          updatedCheckedItems[`${selectedSchema}->${tableItem}`] === true
      );

      const someTablesSelected = tablesResponse.some(
        (tableItem) =>
          updatedCheckedItems[`${selectedSchema}->${tableItem}`] === true ||
          (typeof updatedCheckedItems[`${selectedSchema}->${tableItem}`] !==
            "boolean" &&
            updatedCheckedItems[`${selectedSchema}->${tableItem}`]?.includes(
              "indeterminate"
            ))
      );

      setSelectAllTables((prevState) => ({
        ...prevState,
        [selectedSchema]: allTablesSelected,
      }));

      // Update the schema checkbox
      updatedCheckedItems[selectedSchema] = allTablesSelected
        ? true
        : someTablesSelected
        ? "indeterminate"
        : false;

      return updatedCheckedItems;
    });
  };

  const handleSelectAllTables = (e, schema) => {
    const checked = e.checked;

    // Update the "Select All Tables" state for the schema
    setSelectAllTables((prevState) => ({
      ...prevState,
      [schema]: checked,
    }));

    // Update the checked items for all tables in the selected schema
    const newCheckedItems = { ...checkedItems };
    setCheckedItems(newCheckedItems);

    // Update the "Select All Fields" state for all tables
    const newSelectAllFields = { ...selectAllFields };

    tablesResponse.forEach((table) => {
      newCheckedItems[`${schema}->${table}`] = checked;

      // Ensure the "Select All Fields" checkbox for the table is updated
      newSelectAllFields[table] = checked;

      // Update the fields under the table
      const fields = fieldsResponse[table] || [];
      fields.forEach((field) => {
        newCheckedItems[`${schema}->${table}->${field}`] = checked;
      });
    });

    setSelectAllFields(newSelectAllFields);

    // Update schema checkbox state based on the tables' selection
    const allTablesSelected = tablesResponse.every(
      (table) => newCheckedItems[`${schema}->${table}`] === true
    );

    const someTablesSelected = tablesResponse.some(
      (table) =>
        newCheckedItems[`${schema}->${table}`] === true ||
        (typeof newCheckedItems[`${schema}->${table}`] !== "boolean" &&
          newCheckedItems[`${schema}->${table}`] === "indeterminate")
    );

    // Update the schema's checkbox state
    setCheckedItems((prevState) => ({
      ...prevState,
      [schema]: allTablesSelected
        ? true
        : someTablesSelected
        ? "indeterminate"
        : false,
    }));
    // Ensure "Select All Tables" is checked if all tables are selected
    if (allTablesSelected) {
      setSelectAllTables((prevState) => ({
        ...prevState,
        [schema]: true,
      }));
    }
  };

  const importCols = async () => {
    setImporting(true);

    const parsedData = Object.entries(checkedItems).reduce(
      (acc, [key, value]) => {
        // Only consider items that are true and have schema->table->field structure
        if (value === true) {
          const parts = key.split("->");
          if (parts.length === 3) {
            const [schema, table, field] = parts;
            // Initialize the schema and table if not already present
            if (!acc[schema]) {
              acc[schema] = {};
            }
            if (!acc[schema][table]) {
              acc[schema][table] = [];
            }
            acc[schema][table].push(field);
          }
        }
        return acc;
      },
      {}
    );

    // Create the definition structure based on parsed data
    const definition = {
      ...connectionDetails,
      schemas: Object.keys(parsedData).map((schemaName) => ({
        schemaName,
        tables: Object.keys(parsedData[schemaName]).map((tableName) => ({
          tableName,
        })),
      })),
    };

    // Create the fieldsMapping array based on parsed data
    const fieldsMapping = [];
    for (const [schemaName, tables] of Object.entries(parsedData)) {
      for (const [tableName, fields] of Object.entries(tables)) {
        fields.forEach((fieldName) => {
          // Exclude if fieldName is null or empty
          if (fieldName && fieldName.trim()) {
            fieldsMapping.push({
              schemaName,
              tableName,
              fieldName,
              field: fieldName,
              formula: [{ formula: "=", operation: "" }],
              value: "",
              targetFields: [null],
              type: "original",
            });
          }
        });
      }
    }

    console.log("checked items are ", checkedItems);

    if (checkedItems && Object.keys(checkedItems).length !== 0) {
      try {
        const response = editMode
          ? await updateSource({
              ...sourceData,
              definition,
              // id: localStorage.getItem("source_id"),
              step: 2,
            })
          : await createSource({ ...sourceData, definition });

        if (response.sourceId) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: editMode
              ? "Source updated successfully"
              : "Source created successfully",
            life: 3000,
          });

          const fieldsObj = {
            fieldMappings: fieldsMapping,
            deletedMappings: [{}],
            mappedEntities: [],
            relationshipConfig: [],
          };

          try {
            const mapResponse = await mapFields(
              response.sourceId,
              fieldsObj
            );
            localStorage.setItem("source_id", response.sourceId);

            if (mapResponse.success) {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Fields mapped successfully",
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: mapResponse.error || "Error mapping fields",
                life: 3000,
              });
            }
          } catch (mapError) {
            console.error("Error while mapping fields:", mapError);
          } finally {
            setImported(true);
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.error,
          });
        }
      } catch (error) {
        console.log("Error during source creation:", error);
      } finally {
        setImporting(false);
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please select columns to proceed",
        life: 3000,
      });
      setImporting(false);
    }
  };
  const proceedWithoutImportCols = async () => {
    setImporting(true);

    const parsedData = Object.entries(checkedItems).reduce(
      (acc, [key, value]) => {
        // Only consider items that are true and have schema->table->field structure
        if (value === true) {
          const parts = key.split("->");
          if (parts.length === 3) {
            const [schema, table, field] = parts;
            // Initialize the schema and table if not already present
            if (!acc[schema]) {
              acc[schema] = {};
            }
            if (!acc[schema][table]) {
              acc[schema][table] = [];
            }
            acc[schema][table].push(field);
          }
        }
        return acc;
      },
      {}
    );

    // Create the definition structure based on parsed data
    const definition = {
      ...connectionDetails,
      schemas: Object.keys(parsedData).map((schemaName) => ({
        schemaName,
        tables: Object.keys(parsedData[schemaName]).map((tableName) => ({
          tableName,
        })),
      })),
    };

    // Create the fieldsMapping array based on parsed data
    const fieldsMapping = [];
    for (const [schemaName, tables] of Object.entries(parsedData)) {
      for (const [tableName, fields] of Object.entries(tables)) {
        fields.forEach((fieldName) => {
          // Exclude if fieldName is null or empty
          if (fieldName && fieldName.trim()) {
            fieldsMapping.push({
              schemaName,
              tableName,
              fieldName,
              field: fieldName,
              formula: [{ formula: "=", operation: "" }],
              value: "",
              targetFields: [null],
              type: "original",
            });
          }
        });
      }
    }

    try {
      const response = editMode
        ? await updateSource({
            ...sourceData,
            definition,
            // id: localStorage.getItem("source_id"),
            step: 2,
          })
        : await createSource({ ...sourceData, definition });

      if (response.sourceId) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Source edited successfully",
          life: 3000,
        });
        localStorage.setItem("source_id", response.sourceId);

      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (error) {
      console.log("Error during source creation:", error);
    } finally {
      setImporting(false);
      setImported(true);
    }
  };

  const renderMessage = () => {
    if (!selectedSchema && !selectedTable) {
      return <div>Select a schema to view the tables.</div>;
    } else if (selectedSchema && tablesResponse.length === 0 && tablesLoading) {
      return <div>Loading tables and fields...</div>;
    } else if (selectedSchema && tablesResponse.length === 0) {
      return <div>No tables exist for the selected schema.</div>;
    } else if (selectedSchema && tablesResponse.length > 0 && !selectedTable) {
      return <div> Select a table to view the data fields.</div>;
    } else if (!fieldsResponse[selectedTable]) {
      return <div> Select a table to view the data fields.</div>;
    }
    return null;
  };
  const dialogHeader = (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'flex-start', 
      marginLeft: '8px',
      paddingBottom:'20px',
      padding:'0px',
      alignItems: 'center' 
    }}>
      <h3>Import Data</h3>
      {disableCheckboxes && (
        <span style={{ 
          fontSize: '14px',
          fontWeight: 'normal',
          marginLeft: '20px',
          backgroundColor: '#FFF1DA',
          padding: '12px',
          borderRadius: '8px',
          border: '1px solid #FFE9BA'
        }}>
          Selected schemas, Tables, Columns are not editable
        </span>
      )}
    </div>
  );
  const dialogFooter = (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'flex-end',
      marginRight: '20px',
      marginBottom: '10px',
      paddingTop:'0px'
    }}>
      <Button
        label="Back"
        type="button"
        style={{ 
          marginTop: '20px',
          backgroundColor: 'white',
          padding: '12px',
          fontSize: '12px',
          color: '#3B82F6',
          border: '1px solid #3B82F6',
          marginRight: '20px'
        }}
        onClick={onClose}
      />
      <Button
        label={disableCheckboxes ? `Proceed` : `Import`}
        type="submit"
        style={{ 
          marginTop: '20px',
          backgroundColor: '#3B82F6',
          padding: '12px',
          fontSize: '12px',
          color: 'white'
        }}
        onClick={() =>
          disableCheckboxes ? proceedWithoutImportCols() : importCols()
        }
        loading={importing}
      />
    </div>
  );
  return (
    <Dialog
      header={dialogHeader}
      visible={visible}
      draggable={false}
      style={{ width: "70vw", height: "90vh" }}
      onHide={onClose}
      footer={dialogFooter}
    >
      <div className="flex flex-row w-full h-full sourcetablesfieldsmodal">
        {/* Left: Schema Selection */}
        <div
          className="flex flex-col w-1/3 border-r p-3"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '33.333333%',
            borderRight: '1px solid #e5e7eb',
            padding: '12px'
          }}
        >
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            border: '1px solid #ced4da',
            backgroundColor: 'white',
            borderRadius: '5px'
          }}>
            <InputText
              value={searchSchemaText}
              onChange={(e) => setSearchSchemaText(e.target.value)}
              placeholder="Search Schema"
              style={{
                width: '15vw',
                paddingRight: '20px',
                paddingTop: '12px',
                paddingBottom: '12px',
                marginLeft: '20px',
                border: '0'
              }}
              className="pulse-search-component"
              maxLength={35}
            />
            <span className="p-input-icon-left" style={{marginRight:'3vw',color:'#4b5563'}}>
              <i style={{ 
                paddingLeft: '0px',
                fontWeight: '200',
                color: '#4b5563',
                opacity: '0.8'
              }} className="pi pi-search" />
            </span>
          </div>

          <ul style={{ 
            overflowY: 'auto', 
            maxHeight: '75vh', 
            marginTop: '12px',
            overflowX: 'hidden',
            padding:'0px'
          }}>
            {schemasResponse?.filter((schema) =>
              schema.toLowerCase().includes(searchSchemaText.toLowerCase())
            ).length === 0 ? (
              <li style={{ 
                paddingTop:'10px',
                paddingBottom:'10px',
                marginTop: '12px', 
                color: '#6B7280',
                borderTop:'1px solid #E5E7EB'
              }}>No Results found</li>
            ) : (
              schemasResponse
                ?.filter((schema) =>
                  schema.toLowerCase().includes(searchSchemaText.toLowerCase())
                )
                .map((schema) => (
                  <li
                    key={schema}
                    title={schema}
                    className={`flex flex-row cursor-pointer p-2 mt-3 border-b cursor-pointer`}
                    style={{
                      borderRadius: selectedSchema === schema ? "6px" : "",
                      border:
                        selectedSchema === schema
                          ? "1px solid var(--Lara-Accordion-accordionHeaderBorder, #DEE2E6)"
                          : "",
                      background:
                        selectedSchema === schema
                          ? "var(--Lara-Accordion-accordionHeaderBg, #F8F9FA)"
                          : "",
                    }}
                    onClick={() => setSelectedSchema(schema)}
                  >
                    <label>{schema}</label>
                    <div className="flex items-center gap-3 ml-3">
                      {checkedItems[schema] === "indeterminate" ? (
                        <IndeterminateCheckbox />
                      ) : (
                        checkedItems[schema] === true && (
                          <Checkbox
                            checked={checkedItems[schema] === true}
                            value={schema}
                            onChange={(e) =>
                              handleSchemaCheckboxChange(e, schema)
                            }
                            disabled={disableCheckboxes}
                          />
                        )
                      )}
                    </div>
                  </li>
                ))
            )}
          </ul>
        </div>

        {selectedSchema && tablesResponse.length !== 0 ? (
          <>
            {/* Center: Tables for selected schema */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '33.333333%',
              borderRight: '1px solid #e5e7eb',
              padding: '12px'
            }}>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                border: '1px solid #ced4da',
                backgroundColor: 'white',
                borderRadius: '5px',
                marginTop:'4px'
              }}>
                <InputText
                  value={searchTableText}
                  onChange={(e) => setSearchTableText(e.target.value)}
                  placeholder="Search Table"
                  style={{
                    width: '15vw',
                    paddingLeft: '0px',
                    paddingRight: '20px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    marginLeft: '20px',
                    border: '0'
                  }}
                  className="pulse-search-component"
                  maxLength={35}
                />
                <span className="p-input-icon-left" style={{marginRight:'3vw',color:'#4b5563'}}>
                  <i style={{ 
                    paddingLeft: '0px',
                    fontWeight: '200',
                    color: '#4b5563',
                    opacity: '0.8'
                  }} className="pi pi-search" />
                </span>
              </div>
              <ul style={{ 
                padding:'0px',
                marginTop: '12px', 
                overflowY: 'auto', 
                maxHeight: '75vh', 
                overflowX: 'hidden' 
              }}>
                {/* Select All Checkbox */}
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'row', 
                  cursor: 'pointer', 
                  padding: '8px', 
                  marginTop: '12px', 
                  borderBottom: '1px solid #E5E7EB' 
                }}>
                  <Checkbox
                    checked={selectAllTables?.[selectedSchema]}
                    onChange={(e) => handleSelectAllTables(e, selectedSchema)}
                    disabled={disableCheckboxes}
                  />
                  <label style={{ marginLeft: '12px' }}>Select All</label>
                </div>

                {/* Conditional rendering for no results */}
                {tablesResponse?.filter((table) =>
                  table.toLowerCase().includes(searchTableText.toLowerCase())
                ).length === 0 ? (
                  <li className="p-2 mt-3 text-gray-500">No Results found</li>
                ) : (
                  tablesResponse
                    ?.filter((table) =>
                      table
                        .toLowerCase()
                        .includes(searchTableText.toLowerCase())
                    )
                    .map((table) => (
                      <li
                        key={table}
                        title={table}
                        className={`flex flex-row cursor-pointer p-2 mt-3 border-b cursor-pointer`}
                        style={{
                          borderRadius: selectedTable === table ? "6px" : "",
                          border:
                            selectedTable === table
                              ? "1px solid var(--Lara-Accordion-accordionHeaderBorder, #DEE2E6)"
                              : "",
                          background:
                            selectedTable === table
                              ? "var(--Lara-Accordion-accordionHeaderBg, #F8F9FA)"
                              : "",
                        }}
                        onClick={() => setSelectedTable(table)}
                      >
                        <div className="flex items-center gap-3">
                          {checkedItems[`${selectedSchema}->${table}`] ===
                          "indeterminate" ? (
                            <IndeterminateCheckbox
                              level="table"
                              tableName={selectedTable}
                            />
                          ) : (
                            <Checkbox
                              checked={
                                checkedItems[`${selectedSchema}->${table}`] ===
                                true
                              }
                              onChange={(e) =>
                                handleTableCheckboxChange(
                                  e,
                                  table,
                                  selectedSchema
                                )
                              }
                              value={table}
                              disabled={disableCheckboxes}
                            />
                          )}
                          <label>{table}</label>
                        </div>
                      </li>
                    ))
                )}
              </ul>
            </div>
            {selectedTable && fieldsResponse.length !== 0 ? (
              <>
                {/* Right: Fields for selected table */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '33.333333%',
                  padding: '12px'
                }}>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    border: '1px solid #ced4da',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    marginTop:'4px'
                  }}>
                    <InputText
                      value={searchFieldText}
                      onChange={(e) => setSearchFieldText(e.target.value)}
                      placeholder="Search Columns"
                      style={{
                        width: '15vw',
                        paddingLeft: '0px',
                        paddingRight: '20px',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        marginLeft: '20px',
                        border: '0'
                      }}
                      className="pulse-search-component"
                      maxLength={35}
                    />
                    <span className="p-input-icon-left" style={{marginRight:'3vw',color:'#4b5563'}}>
                      <i style={{ 
                        paddingLeft: '0px',
                        fontWeight: '200',
                        color: '#4b5563',
                        opacity: '0.8'
                      }} className="pi pi-search" />
                    </span>
                  </div>
                  <ul style={{ 
                    overflowY: 'auto', 
                    maxHeight: '75vh', 
                    marginTop: '12px',
                    padding:'0px'
                  }}>
                    {fieldsResponse[selectedTable] && (
                      <>
                        <div style={{ 
                          display: 'flex', 
                          flexDirection: 'row', 
                          cursor: 'pointer', 
                          padding: '8px', 
                          marginTop: '12px', 
                          borderBottom: '1px solid #E5E7EB' 
                        }}>
                          <Checkbox
                            checked={selectAllFields[selectedTable]}
                            onChange={(e) =>
                              handleSelectAllFields(e, selectedTable)
                            }
                            disabled={disableCheckboxes}
                          />
                          <label style={{ marginLeft: '12px' }}>Select All</label>
                        </div>
                      </>
                    )}
                    {/* Conditional rendering for no results */}
                    {fieldsResponse[selectedTable]?.filter((field) =>
                      field
                        .toLowerCase()
                        .includes(searchFieldText.toLowerCase())
                    ).length === 0 ? (
                      <li className="p-2 mt-3 text-gray-500">
                        No Results found
                      </li>
                    ) : (
                      fieldsResponse[selectedTable]
                        ?.filter((field) =>
                          field
                            .toLowerCase()
                            .includes(searchFieldText.toLowerCase())
                        )
                        .map((field) => (
                          <li
                            key={field}
                            className={`flex flex-row cursor-pointer p-2 mt-3 border-b
                        ${
                          checkedItems[
                            `${selectedSchema}->${selectedTable}->${field}`
                          ]
                            ? "selected-field"
                            : ""
                        }`}
                          >
                            <Checkbox
                              checked={
                                checkedItems[
                                  `${selectedSchema}->${selectedTable}->${field}`
                                ]
                              }
                              onChange={(e) =>
                                handleFieldCheckboxChange(
                                  e,
                                  selectedTable,
                                  field
                                )
                              }
                              value={`${selectedSchema}->${selectedTable}->${field}`}
                              disabled={disableCheckboxes}
                            />
                            <label className="ml-3">{field}</label>
                          </li>
                        ))
                    )}

                    {/* Optional fallback message for when fieldsResponse[selectedTable] is missing */}
                    {!fieldsResponse[selectedTable] && (
                      <div style={{ 
                        marginLeft: '5vw', 
                        marginTop: '20vh', 
                        width: '80%' 
                      }}>
                        {renderMessage()}
                      </div>
                    )}
                  </ul>
                </div>
              </>
            ) : (
              <div style={{ 
                marginLeft: '5vw', 
                marginTop: '25vh', 
                width: '30%' 
              }}>
                {renderMessage()}
              </div>
            )}
          </>
        ) : (
          <div style={{ 
            marginLeft: '15vw', 
            marginTop: '25vh', 
            width: '30%' 
          }}>
            {renderMessage()}
          </div>
        )}
      </div>
      <Toast ref={toast} position="top-right" />
    </Dialog>
  );
};

SourceTablesFieldsModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  setImported: PropTypes.func,
  connectionDetails: PropTypes.object,
  sourceData: PropTypes.object,
  editMode: PropTypes.bool,
};

export default SourceTablesFieldsModal;
