import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { AgentsList, IntelliAppList } from "constants/AgentsList";
import "./Agents.css";
import { Chip } from 'primereact/chip';


const Agents = () => {
    const navigate = useNavigate();

    const AppCard = ({ agent }) => {
        return (
            <div key={agent.id} className="p-3 mb-3 inline-block border-round-2xl mr-3 max-w-25rem w-full app-card">
                <img src={agent.image} alt={agent.name} className="w-full max-w-3rem h-auto mb-3" />
                <div className="text-900 text-xl font-bold">{agent.name}</div>
                <p className="text-500 text-sm">{agent.description}</p>
                {agent.tags && <div className="flex flex-wrap mb-2">
                    {agent.tags.map((tag) => (
                        <Chip label={tag} className="mr-2" />
                    ))}
                </div>}
                {agent.buttonText && <Button
                    label={agent.buttonText}
                    className="text-sm font-normal"
                    onClick={() => {
                        if (agent.buttonLink.startsWith('http://') || agent.buttonLink.startsWith('https://')) {
                            window.location.href = agent.buttonLink;
                        } else {
                            navigate(agent.buttonLink);
                        }
                    }}
                />}
            </div>
        );
    }

    return (
        <div className="grid text-800 pt-5 h-full agents-page">
            <div className="col-8">
                <h1 className="text-2xl font-bold">Agents ({AgentsList.length})</h1>
                {AgentsList.map((agent) => (
                    <AppCard agent={agent} />
                ))}
            </div>
            <div className="col-4 border-left-1 border-300 pl-4">
                <h1 className="text-2xl font-bold">Intelliapps ({IntelliAppList.length})</h1>
                {IntelliAppList.map((agent) => (
                    <AppCard agent={agent} />
                ))}
            </div>
        </div>
    );
}

export default Agents