import React from 'react'

const ActivityEscalated = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#FFF9D0"/>
        <circle cx="12" cy="12" r="10" fill="#F7E66E"/>
        <path d="M13.4 8.30643L16.7505 13.8869C17.232 14.6884 16.9955 15.7429 16.222 16.2419C15.9624 16.41 15.6598 16.4996 15.3505 16.4999H8.649C7.7385 16.4999 7 15.7349 7 14.7904C7 14.4709 7.0865 14.1584 7.249 13.8869L10.6 8.30643C11.081 7.50493 12.098 7.25943 12.8715 7.75843C13.0855 7.89643 13.2665 8.08393 13.4 8.30643ZM12.29 8.72593C12.2288 8.68656 12.1602 8.66 12.0885 8.64787C12.0167 8.63573 11.9432 8.63827 11.8725 8.65533C11.8017 8.67239 11.7352 8.70361 11.6768 8.74711C11.6185 8.79061 11.5695 8.84549 11.533 8.90843L8.1825 14.4899C8.12807 14.5809 8.09939 14.6849 8.0995 14.7909C8.0995 15.1059 8.3455 15.3609 8.6495 15.3609H15.35C15.453 15.3609 15.5535 15.3309 15.6405 15.2749C15.7653 15.192 15.8534 15.0641 15.8862 14.9178C15.9191 14.7716 15.8943 14.6183 15.817 14.4899L12.4665 8.90843C12.4226 8.83494 12.3623 8.77251 12.2905 8.72593H12.29ZM12 14.4999C11.8674 14.4999 11.7402 14.4473 11.6464 14.3535C11.5527 14.2597 11.5 14.1325 11.5 13.9999C11.5 13.8673 11.5527 13.7401 11.6464 13.6464C11.7402 13.5526 11.8674 13.4999 12 13.4999C12.1326 13.4999 12.2598 13.5526 12.3536 13.6464C12.4473 13.7401 12.5 13.8673 12.5 13.9999C12.5 14.1325 12.4473 14.2597 12.3536 14.3535C12.2598 14.4473 12.1326 14.4999 12 14.4999ZM12 9.99993C12.1326 9.99993 12.2598 10.0526 12.3536 10.1464C12.4473 10.2401 12.5 10.3673 12.5 10.4999V12.4999C12.5 12.6325 12.4473 12.7597 12.3536 12.8535C12.2598 12.9473 12.1326 12.9999 12 12.9999C11.8674 12.9999 11.7402 12.9473 11.6464 12.8535C11.5527 12.7597 11.5 12.6325 11.5 12.4999V10.4999C11.5 10.3673 11.5527 10.2401 11.6464 10.1464C11.7402 10.0526 11.8674 9.99993 12 9.99993Z" fill="#645A13"/>
    </svg>
  )
}

export default ActivityEscalated