import React from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import ExternalLinkIcon from "assets/SpaceIcons/ExternalLinkIcon";

/**
 * Header component for the connection info panel with a title and close button.
 * 
 * @param {Object} props - The component props
 * @param {Function} props.onHide - Callback function to hide/close the panel
 * @returns {JSX.Element} Header section with title and close button
 */
const ConnectionInfoHeader = ({ onHide }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="text-lg font-bold text-neutral-700">Connection Info</div>
      <Button icon="pi pi-times" rounded text onClick={onHide} />
    </div>
  );
};

/**
 * Renders a vertical list of email addresses.
 * 
 * @param {Object} props - The component props
 * @param {string[]} props.emails - Array of email addresses to display
 * @returns {JSX.Element} Vertically stacked list of emails
 */
const EmailList = ({ emails }) => {
  return (
    <div className="flex flex-col flex-1" style={{ display: "flex", flexDirection: "column" }}>
      {emails.map((email, index) => (
        <div
          key={email}
          className={`${index > 0 ? "mt-1.5" : ""}`}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span style={{ fontSize: "14px", color: "#52525B", padding: "0.1rem" }}>{email}</span>
        </div>
      ))}
    </div>
  );
};

/**
 * Displays the hierarchical location/path of the connection source.
 * 
 * @returns {JSX.Element} Grid layout showing folder structure with external link
 */
const SourceLocation = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "200px 1fr",
        padding: "8px 0",
        marginTop: "20px",
      }}
    >
      <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Source Location</div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          color: "#52525B",
          fontSize: "14px",
        }}
      >
        <span>Customer_Support</span>
        <i className="pi pi-folder" />
        <span>Folder 12</span>
        <span style={{ color: "#71717A" }}>...</span>
        <i className="pi pi-folder" />
        <span>Folder12345</span>
        {/* <i className="pi pi-chevron-right" /> */}
        <div style={{ cursor: "pointer", marginLeft: "0.2rem" }}>
          <ExternalLinkIcon />
        </div>
      </div>
    </div>
  );
};

/**
 * Displays connection rules including date range, sender emails, and labels.
 * 
 * @returns {JSX.Element} Section containing rule configuration details
 */
const RuleSection = () => {
  const emails = ["user123@gmail.com", "user12342@gmail.com", "user12343@gmail.com"];
  const labels = ["Inbox", "Starred", "Snoozed"];

  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px 1fr", padding: "8px 0" }}>
      <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Rule</div>

      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Date Range Section */}
        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Starting Date</div>
          <div style={{ fontSize: "14px", color: "#52525B" }}>15/12/2024</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Ending Date</div>
          <div style={{ fontSize: "14px", color: "#52525B" }}>1/1/2025</div>
        </div>

        {/* Sender Section */}
        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B", marginTop: "0.2rem" }}>
            Sender
          </div>
          <EmailList emails={emails} />
        </div>

        {/* Label Section */}
        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div
            style={{ fontSize: "14px", fontWeight: 600, color: "#52525B", paddingTop: "0.2rem" }}
          >
            Label
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            {labels.map((label) => (
              <Tag
                key={label}
                value={label}
                pt={{
                  root: {
                    style: {
                      backgroundColor: "#f4f4f5",
                      fontSize: "12px",
                      color: "#495057",
                      borderRadius: "15px",
                      fontWeight: "400",
                    },
                  },
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Shows synchronization settings including status, frequency, and notifications.
 * 
 * @returns {JSX.Element} Section containing sync policy configuration
 */
const SyncPolicy = () => {
  const emails = ["user123@gmail.com", "user12342@gmail.com", "user12343@gmail.com"];

  return (
    <div style={{ display: "grid", gridTemplateColumns: "200px 1fr", padding: "8px 0" }}>
      <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Sync Policy</div>

      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Sync Status</div>
          <div style={{ fontSize: "14px", color: "#52525B" }}>Enabled</div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Frequency</div>
          <div style={{ fontSize: "14px", color: "#52525B" }}>Every 8 days</div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Notification</div>
          <div style={{ fontSize: "14px", color: "#52525B" }}>SMS</div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
          <div style={{ fontSize: "14px", fontWeight: 600, color: "#52525B" }}>Users</div>
          <EmailList emails={emails} />
        </div>
      </div>
    </div>
  );
};

/**
 * Main panel component that displays connection information and settings.
 * 
 * @param {Object} props - The component props
 * @param {Function} props.onHide - Callback function to hide/close the panel
 * @returns {JSX.Element} Modal-like panel containing all connection information
 */
const ConnectionInfoPanel = ({ onHide }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "16px",
        width: "720px",
        padding: "1rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ConnectionInfoHeader onHide={onHide} />
        <SourceLocation />
        <Divider />
        <RuleSection />
        <Divider />
        <SyncPolicy />
      </div>
    </div>
  );
};

export default ConnectionInfoPanel;
