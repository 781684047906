// export const API_URL = window.VITE_API_URL || import.meta.env.VITE_API_URL;
// export const KEYCLOCK_API_URL =
//   window.VITE_KEYCLOCK_API_URL || import.meta.env.VITE_KEYCLOCK_API_URL;
// console.log("KEYCLOCK_API_URL", KEYCLOCK_API_URL);
// console.log("API_URL", API_URL);
export const SOURCE_FETCH_URL = "/api/v1/source/:id";
export const connectionType = [
  { name: "UI Upload", id: "ui_upload" },
  { name: "Database", id: "database" },
  // { name: 'HTTP GET', id: 'http_get' },
  { name: "Webhook (Incoming)", id: "webhook" },
  { name: "SFTP", id: "sftp" },
  { name: "Snowflake", id: "snowflake" },
  { name: "Salesforce", id: "salesforce" },
  { name: "Salesforce SQL", id: "sf_sql" },
];
