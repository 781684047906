// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:focus,
.p-inputtext:focus,
.p-inputswitch-slider {
  outline: none;
  box-shadow: none;
}

li.p-multiselect-item {
  background-color: white;
  color: black;
}

/* [data-pc-name='checkbox'] > .p-checkbox-box {
  border: 1px solid white;
} */

/* div[id='context-filter-panel'] > div[data-pc-section='checkbox']{
  border: 1px solid white;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/SourceConfig/StructuredSources/SourceControlAccessModal/index.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;;GAEG;;AAEH;;GAEG","sourcesContent":["input:focus,\n.p-inputtext:focus,\n.p-inputswitch-slider {\n  outline: none;\n  box-shadow: none;\n}\n\nli.p-multiselect-item {\n  background-color: white;\n  color: black;\n}\n\n/* [data-pc-name='checkbox'] > .p-checkbox-box {\n  border: 1px solid white;\n} */\n\n/* div[id='context-filter-panel'] > div[data-pc-section='checkbox']{\n  border: 1px solid white;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
