import useApi from "services/api";
import { API_URL } from "../constants";

/**
 * Custom hook to interact with the SDAI Dashboard API.
 * Provides methods to fetch dashboard statistics.
 */
const useSDAIDashboard = () => {
  const { get } = useApi();

  /**
   * Fetches dashboard statistics from the API.
   * @returns {Promise<Object>} A promise that resolves to the dashboard statistics data.
   */
  const getDashboardStats = async () => {
    const data = await get(`${API_URL}/summary_jobs/dashboard-stats`);
    return data;
  };

  const getAdminDashboardStats = async (dateRange) => {
    //Date range in the format 'YYYY-MM-DD,YYYY-MM-DD'

    const data = await get(`${API_URL}/summary_jobs/admin-dashboard-stats?date_range=${dateRange}`);
    return data;
  };

  return { getDashboardStats, getAdminDashboardStats };
};

export default useSDAIDashboard;
