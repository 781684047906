import React from 'react'

const PulseSourcesIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99996 16.667C11.7681 16.667 13.4638 15.9646 14.714 14.7144C15.9642 13.4641 16.6666 11.7684 16.6666 10.0003C16.6666 8.23222 15.9642 6.53652 14.714 5.28628C13.4638 4.03604 11.7681 3.33366 9.99996 3.33366M9.99996 16.667C8.23185 16.667 6.53616 15.9646 5.28591 14.7144C4.03567 13.4641 3.33329 11.7684 3.33329 10.0003M9.99996 16.667V18.3337M9.99996 3.33366C8.23185 3.33366 6.53616 4.03604 5.28591 5.28628C4.03567 6.53652 3.33329 8.23222 3.33329 10.0003M9.99996 3.33366V1.66699M3.33329 10.0003H1.66663M11.6666 10.0003C11.6666 10.4424 11.491 10.8663 11.1785 11.1788C10.8659 11.4914 10.442 11.667 9.99996 11.667C9.55793 11.667 9.13401 11.4914 8.82145 11.1788C8.50889 10.8663 8.33329 10.4424 8.33329 10.0003C8.33329 9.5583 8.50889 9.13437 8.82145 8.82181C9.13401 8.50925 9.55793 8.33366 9.99996 8.33366C10.442 8.33366 10.8659 8.50925 11.1785 8.82181C11.491 9.13437 11.6666 9.5583 11.6666 10.0003ZM11.6666 10.0003H18.3333M14.1666 17.217L13.3333 15.7753M9.16663 8.55866L5.83329 2.78366M17.2166 14.167L15.775 13.3337M2.78329 5.83366L4.22496 6.66699M17.2166 5.83366L15.775 6.66699M2.78329 14.167L4.22496 13.3337M14.1666 2.78366L13.3333 4.22533M9.16663 11.442L5.83329 17.217" stroke="#191A1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default PulseSourcesIcon