import { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Checkbox } from "primereact/checkbox";
import microsoftLogo from "../../assets/Login/microsoftLogo";
import googleLogo from "../../assets/Login/googleLogo";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useSearchParams } from "react-router-dom";
import Authentication from "hooks/authentication";
import { Toast } from "primereact/toast";
import { useUserStatusProvider } from "context/userStatusContext";
import Disclaimer from "components/Login/Disclaimer";
import { isSettlementDemand } from "constants";
import { Divider } from 'primereact/divider';
import successImage from "../../assets/Login/thumbs-up.svg";
import settlementDemand from "../../assets/IntelliappLogo/settlementdemand.png";
import buttonImage from "../../assets/Login/bulletPoint.svg";
import { Dialog } from 'primereact/dialog';
import { InputOtp } from "primereact/inputotp";
import { jwtDecode } from "jwt-decode";
import { setKeycloakSession } from "utils/utils";

const LoginSDAIScreen = ({ currentState }) => {
  const toast = useRef();
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');
  const initialState = {
    allocation_type: "one_time",
    credits_to_be_allotted: 0,
    daily_limit: 0,
    frequency: 0,
    frequency_unit: "days",
    first_name: "",
    last_name: "",
    email: "",
    organization: "",
    department: "",
    designation: "",
    plan: ""
  }
  const [signUpForm, setSignUpForm] = useState(initialState);
  const { emailVerification, getOrganizations, signupWithEmail, initiateEmailVerification, getLoginToken } = Authentication();
  const { setUserStatus } = useUserStatusProvider();
  const [cuurentState, setCurrentState] = useState(currentState);
  const [emailSignup, setEmailSignup] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpButtonLoading, setOtpButtonLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [otpDialog, setOtpDialog] = useState(false);
  const [errors, setErrors] = useState({
    loginUser: null,
    signUpUser: null
  });
  const [timer, setTimer] = useState(60);
  const [otpCode, setOtpCode] = useState("");

  useEffect(() => {
    if (otpDialog) {
      let interval;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }
      return () => clearInterval(interval);
    }
  }, [timer, otpDialog]);

  useEffect(() => {
    if (plan === "enterprise") {
      setEmailSignup(true);
    }
  }, [plan])

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      navigate("/");
    }
  }, [initialized, keycloak.authenticated, navigate]);

  const userVerification = async (email) => {
    setSignupLoading(true);
    if (plan === "enterprise") {
      await sendOTP();
    } else {
      const response = await emailVerification(email);
      if (response?.status !== null) {
        setErrors(prev => ({ ...prev, signUpUser: true }));
      } else {
        setErrors(prev => ({ ...prev, signUpUser: null }));
        await sendOTP();
      }
    }
    setSignupLoading(false);
  }

  if (initialized && keycloak.authenticated) {
    return null; // Render nothing while redirecting
  }

  const login = () => {
    return (
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        {/* {isSettlementDemand && (
          <Button text label="Back" icon="pi pi-angle-left" className="text-sm mb-4 pl-0" />
        )} */}
        <h1 className="text-2xl font-bold mb-3">Account Login</h1>
        <div>
          <p className="text-gray-600 mb-4 text-base">
            Welcome back! Please enter your email to log in.
          </p>
          <div className="mt-4 flex flex-col gap-2">
            <Button
              iconPos="right"
              className="w-full sso-button"
              icon={googleLogo}
              label="Sign in with Google"
            />
            <Button
              iconPos="right"
              className="w-full sso-button"
              icon={microsoftLogo}
              label="Sign in with Microsoft"
            />
          </div>
          <Divider align="center">
            <div className="inline-flex align-items-center text-gray-500 text-sm">
              <b>or</b>
            </div>
          </Divider>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Email address
            </label>
            <InputText
              id="email"
              value={signUpForm.email}
              onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
              onClear={() => setErrors(prev => ({ ...prev, loginUser: null }))}
              className="w-full p-inputtext p-component"
              placeholder="Enter email"
              style={{ ':focus': { borderColor: '#3b82f6' } }}
            />
            {errors.loginUser === false && <p className="text-red-500 text-sm">We couldn't find an account with that email. Double-check and try again, or <span className="text-blue-500 cursor-pointer" onClick={() => setCurrentState("signup")}>create a new account.</span></p>}
          </div>
          <Button
            label="Next"
            className="w-full bg-blue-500 p-button"
            onClick={handleLogin}
            disabled={!signUpForm.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpForm.email)}
            loading={loginLoading}
          />
          <p className="text-gray-500 text-sm">Don't have an account? <span className="text-blue-500 cursor-pointer" onClick={() => { navigate("/signup"); setCurrentState("signup") }}>Sign up</span></p>
        </div>
      </div >
    )
  }

  const signUp = () => {
    return (
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md overflow-y-auto">
        {currentState === "signup" && plan !== null && (
          <Button text label="Back" onClick={() => { navigate("/signup"); setCurrentState("signup"); setEmailSignup(false); setSignUpForm(initialState) }} icon="pi pi-angle-left" className="text-sm mb-4 pl-0" />
        )}
        <h1 className="text-2xl font-bold mb-3">{emailSignup && (plan === "team" || plan === "individual") ? "Signup with Email" : plan === "team" ? "Team Plan" : plan === "enterprise" ? "Enterprise Plan" : "Individual Plan"}</h1>
        <div>
          <p className="text-gray-600 mb-4 text-base">
            {emailSignup && (plan === "team" || plan === "individual") ? "Signup with your email" : plan === "enterprise" ? "Fillup the form so our sales team can get back to you" : "Choose an option to continue"}
          </p>
          {emailSignup ? (
            <div>
              <div className="flex flex-col gap-2 w-full mb-4">
                <div className="flex-col flex-1">
                  <label htmlFor="firstName" className="block text-gray-700 mb-2">
                    First Name
                  </label>
                  <InputText
                    id="firstName"
                    value={signUpForm.first_name}
                    onChange={(e) => setSignUpForm({ ...signUpForm, first_name: e.target.value })}
                    className="w-full p-inputtext p-component"
                    placeholder="Enter first name"
                    style={{ ':focus': { borderColor: '#3b82f6' } }}
                  />
                </div>
                <div className="flex-col flex-1">
                  <label htmlFor="lastName" className="block text-gray-700 mb-2">
                    Last Name (Optional)
                  </label>
                  <InputText
                    id="lastName"
                    value={signUpForm.last_name}
                    onChange={(e) => setSignUpForm({ ...signUpForm, last_name: e.target.value })}
                    className="w-full p-inputtext p-component"
                    placeholder="Enter last name"
                    style={{ ':focus': { borderColor: '#3b82f6' } }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  Email address
                </label>
                <InputText
                  id="email"
                  value={signUpForm.email}
                  onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
                  onClear={() => setErrors(prev => ({ ...prev, signUpUser: false }))}
                  className="w-full p-inputtext p-component"
                  placeholder="Enter email"
                  style={{ ':focus': { borderColor: '#3b82f6' } }}
                  invalid={errors.signUpUser}
                />
                {errors.signUpUser && <p className="text-red-500 text-sm mt-1 mb-0">User already exists!!</p>}
              </div>
              {plan === "enterprise" && (
                <>
                  <div className="mb-4">
                    <label htmlFor="organizationName" className="block text-gray-700 mb-2">
                      Organization Name
                    </label>
                    <InputText
                      id="organizationName"
                      value={signUpForm.organization}
                      onChange={(e) => setSignUpForm({ ...signUpForm, organization: e.target.value })}
                      className="w-full p-inputtext p-component"
                      placeholder="Enter organization name"
                      style={{ ':focus': { borderColor: '#3b82f6' } }}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="department" className="block text-gray-700 mb-2">
                      Department
                    </label>
                    <InputText
                      id="department"
                      value={signUpForm.department}
                      onChange={(e) => setSignUpForm({ ...signUpForm, department: e.target.value })}
                      className="w-full p-inputtext p-component"
                      placeholder="Enter department"
                      style={{ ':focus': { borderColor: '#3b82f6' } }}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="designation" className="block text-gray-700 mb-2">
                      Designation
                    </label>
                    <InputText
                      id="designation"
                      value={signUpForm.designation}
                      onChange={(e) => setSignUpForm({ ...signUpForm, designation: e.target.value })}
                      className="w-full p-inputtext p-component"
                      placeholder="Enter designation"
                      style={{ ':focus': { borderColor: '#3b82f6' } }}
                    />
                  </div>
                </>
              )}
              <Button
                label="Signup"
                className="w-full border-blue-500 bg-transparent p-button text-blue-500 hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-colors duration-200"
                loading={signupLoading}
                disabled={
                  !signUpForm.first_name ||
                  !signUpForm.email ||
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpForm.email) ||
                  (plan === "enterprise" && (!signUpForm.organization || !signUpForm.department || !signUpForm.designation))
                }
                onClick={() => userVerification(signUpForm.email)}
              />
            </div>
          ) : (
            <>
              <div className="mt-4 flex flex-col gap-2">
                <Button
                  iconPos="right"
                  className="w-full sso-button"
                  icon={googleLogo}
                  label="Sign up with Google"
                />
                <Button
                  iconPos="right"
                  className="w-full sso-button"
                  icon={microsoftLogo}
                  label="Sign up with Microsoft"
                />
              </div>
              <Divider align="center">
                <div className="inline-flex align-items-center text-gray-500 text-sm">
                  <b>or</b>
                </div>
              </Divider>
              <Button
                label="Sign up with email"
                className="w-full border-blue-500 bg-transparent p-button text-blue-500"
                onClick={() => setEmailSignup(true)}
              />
            </>
          )}
        </div>
      </div>
    )
  }

  const rightPanel = (currentState) => {
    switch (currentState) {
      case "login":
        return login();
      case "signup":
        return signUp();
      case "success":
        return <div className="w-full max-w-md bg-white p-8 rounded shadow-md flex flex-column justify-content-center">
          <img src={successImage} alt="success" className="w-1" />
          <h1 className="text-2xl font-bold mb-1">Thanks for your interest</h1>
          <div>
            <p className="text-gray-600 mb-4 text-base">
              We will get in touch with you shortly, please keep an eye on your inbox.
            </p>
          </div>
        </div>
      default:
        return login();
    }
  }

  const planSelection = () => {
    const plans = [
      {
        name: "Individual",
        price: "USD 99 /month",
        features: ["Single User", "1 Free Settlement Demand Credit*", "Pay for each additional Demand**"],
        path: "individual"
      },
      {
        name: "Team",
        price: "USD 349 /month",
        features: ["Up to 5 Users", "5 Free Settlement Demand Credit*", "Pay for each additional Demand**", "Priority Support"],
        path: "team"
      },
      {
        name: "Enterprise",
        price: "Custom Plan",
        features: ["5+ Users", "Custom Starting Credits*", "Pay for each additional Demand**", "Priority Suport", "Enterprise SSO Integration", "Account Manager"],
        path: "enterprise"
      }
    ]
    return (
      <div className="flex flex-column w-full max-w-md bg-white p-3 rounded shadow-md signup-plan h-screen">
        <img src={settlementDemand} alt="Settlement Demand" className="w-1" />
        <h1 className="text-2xl font-bold mb-3 text-center mt-5">Select a plan to start with</h1>
        <div className="flex px-5 flex-grow h-full gap-5">
          {plans.map((plan, index) => (
            <div className="flex flex-column gap-2 flex-1 border-round-lg p-4 bg-white plans cursor-pointer">
              <p className="text-gray-700 text-sm mb-0">{plan.name}</p>
              <h2 className="font-medium mt-2 mb-3">{plan.price}</h2>
              <ul className="list-none p-0 m-0 ">
                {plan.features.map((feature, index) => (
                  <li className="flex items-center gap-2 mb-3 text-gray-700"><img src={buttonImage} alt="" className="my-auto" />{feature}</li>
                ))}
              </ul>
              <Button label="Get Started" onClick={() => { navigate("/signup?plan=" + plan.path); setSignUpForm(initialState) }} className="w-full bg-transparent border-700 text-700 mt-auto hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-colors duration-200" />
            </div>
          ))}
        </div>
        <p className="text-sm mt-4 px-5 text-left">* 1 Settlement Demand Generation requires 1 Credit<br></br>
          ** See Credits Pricing section to see the tiers for cost per credit.</p>
      </div>
    );
  }

  const fetchOrganizations = async (domain) => {
    try {
      const response = await getOrganizations(domain);
      if (response?.status === 200) {
        return response?.results !== false ? response?.results?.idp_alias : false;
      }
    } catch (error) {
      console.error("Failed to fetch organizations:", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setErrors(prev => ({ ...prev, loginUser: null }));
    try {
      const response = await emailVerification(signUpForm.email);
      if (response?.status !== null) {
        const domain = signUpForm.email.split("@")[1];
        const idpAlias = await fetchOrganizations(domain);
        if (idpAlias) {
          keycloak.login({
            idpHint: idpAlias,
            loginHint: signUpForm.email,
          });
        } else {
          await sendOTP();
        }
        setUserStatus(response?.data?.status);
      } else {
        setErrors(prev => ({ ...prev, loginUser: false }));
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.message,
        life: 3000,
      });
    } finally {
      setLoginLoading(false);
    }
  }

  const sendOTP = async () => {
    try {
      await initiateEmailVerification(signUpForm.email);
      setOtpDialog(true);
      setTimer(60);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to send OTP. Please try again.",
        life: 3000
      });
    }
  }

  const handleOTPVerification = async () => {
    try {
      setOtpButtonLoading(true)
      if (currentState === "signup") {
        const data = {
          ...signUpForm,
          plan: plan,
          otp: otpCode
        }
        const response = await signupWithEmail(data);
        if (response?.status === 200) {
          if (plan === "enterprise") {
            setCurrentState("success");
          } else if (plan === "team" || plan === "individual") {
            navigate("/login");
            setCurrentState("login");
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Your account has been created successfully. Please login to continue.",
              life: 3000
            });
          }
          setOtpDialog(false);
          setSignUpForm(initialState);
          setOtpCode("");
        } else {
          throw new Error("Invalid OTP");
        }
      } else {
        const response = await getLoginToken(signUpForm.email, otpCode)
        if (response?.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Successfully logged in",
            life: 3000
          });
          setOtpDialog(false);
          setSignUpForm(initialState);
          setOtpCode("");

          const decoded = jwtDecode(response?.results?.access_token)
          const session = {
            token: response?.results?.access_token,
            refreshToken: response?.results?.refresh_token,
            idToken: response?.results?.id_token,
            tokenParsed: decoded,
            authenticated: true
          };
          sessionStorage.setItem('keycloakSession', JSON.stringify(session))
          setKeycloakSession(response, decoded);
          navigate("/");
        } else {
          throw new Error("Invalid OTP");
        }
      }
    } catch (error) {
      console.log(error)
      let summary = "Error";
      let detail = "Something went wrong. Please try again.";

      if (error.response?.data?.message === 'Invalid OTP') {
        summary = "Invalid OTP";
        detail = "Please enter correct verification code";
      } else if (error.response?.data?.message === 'OTP expired') {
        summary = "OTP expired";
        detail = "Please request a new OTP";
      } else {
        summary = "Error";
        detail = error.response?.data?.message || "Something went wrong. Please try again.";
      }

      toast.current.show({
        severity: "error",
        summary: summary,
        detail: detail,
        life: 3000
      });
    } finally {
      setOtpButtonLoading(false)
    }
  };

  const customInput = ({ events, props }) => {
    return <><input {...events} {...props} type="text" className="custom-otp-input-sample my-2" />
      {props.id === 2 && <div className="px-3">
        <i className="pi pi-minus" />
      </div>}
    </>
  };

  const init = () => {
    if (cuurentState === "signup" && plan === null) {
      return planSelection();
    }
    return (
      <div
        className={`flex flex-col lg:flex-row h-screen text-xs ${!isSettlementDemand ? "justify-content-center" : ""}`}
        id="login-container"
      >
        <Toast ref={toast} />
        {isSettlementDemand && <Disclaimer />}
        {isSettlementDemand && (
          <div className="lg:w-1/2 bg-blue-50 flex justify-center items-center overflow-hidden p-0 login-left-panel" />
        )}
        <div className="flex flex-col justify-center items-center lg:w-1/2 h-screen">
          {rightPanel(cuurentState)}
        </div>
        <Dialog header={false} visible={otpDialog} style={{ width: '35vw' }} closable={false} headerClassName="p-0" className="otp-dialog">
          <div className="p-4">
            <h3 className="text-gray-900 my-2 text-2xl">Please Enter the code</h3>
            <p className="text-gray-500 text-xs line-height-3">
              We’ve sent a verification code on the email you entered. Please enter the verification code here.
            </p>
            <InputOtp value={otpCode} onChange={(e) => setOtpCode(e.value)} inputTemplate={customInput} length={6} style={{ gap: "0.50rem" }} integerOnly mask />
            <Button label="Verify" loading={otpButtonLoading} disabled={otpCode.length !== 6} onClick={() => handleOTPVerification()} className="w-full border-blue-500 bg-transparent p-button text-blue-500 hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-colors duration-200 my-2" />
            {timer > 0 ? (
              <p className="text-sm mt-2 text-gray-500">Didn't receive the email? Please check your spam folder or wait {timer} seconds to Resend.</p>
            ) : (
              <div className="flex items-center gap-2 mt-2">
                <p className="text-sm text-gray-500">Didn't receive the email? <span className="text-blue-500 cursor-pointer" onClick={() => { sendOTP(); setOtpCode(""); setTimer(60); }}>Resend</span></p>
              </div>
            )}
          </div>
        </Dialog>
      </div>
    )
  }

  return (
    init()
  );
};

export default LoginSDAIScreen;
