import useApi from "services/api";
import { API_URL } from "../constants";

const useCreditSummary = () => {
  const { get, loading } = useApi();

  const getCreditSummary = async (params) => {
    const queryParams = new URLSearchParams({
      page: params.page?.toString(),
      page_size: params.page_size?.toString(),
      order_by: "updated_at",
      order: "desc",
      ...(params.email && { email: params.email }),
      ...(params.allocation_type && { allocation_type: params.allocation_type }),
    }).toString();

    const data = await get(`${API_URL}/users/credit_summary?${queryParams}`);
    return data;
  };

  const getCreditHistory = async (params) => {
    const queryParams = new URLSearchParams({
      page: params.page?.toString(),
      page_size: params.page_size?.toString(),
      ...(params.email && { email: params.email }),
      ...(params.end_date && { end_date: params.end_date }),
      ...(params.start_date && { start_date: params.start_date }),
    }).toString();

    const data = await get(`${API_URL}/org/credit_history?${queryParams}`);
    return data;
  };

  const getCreditUsage = async (params) => {
    const queryParams = new URLSearchParams(params).toString();

    const data = await get(`${API_URL}/org/usage?${queryParams}`);
    return data;
  };

  const getTotalCredits = async () => {
    const data = await get(`${API_URL}/current-user-credit-balance`);
    return data;
  };

  const getCreditGraph = async (params) => {
    const queryParams = new URLSearchParams(params).toString();

    const data = await get(`${API_URL}/org/credit_summary?${queryParams}`);
    return data;
  };

  return {
    getCreditSummary,
    getCreditUsage,
    getCreditGraph,
    getTotalCredits,
    getCreditHistory,
    loading,
  };
};

export default useCreditSummary;
