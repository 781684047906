import React from "react";

const ChevronLeft = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.66602L6.66667 7.99935L10 11.3327"
        stroke="#7F7D83"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronLeft;
