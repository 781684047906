const PulseIcon = ({ selectedItem }) => {
    return selectedItem === "pulse" ? (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_967_33449)">
                <path d="M18.3327 10.943H16.266C15.9018 10.9422 15.5474 11.0607 15.2569 11.2804C14.9665 11.5001 14.756 11.809 14.6577 12.1596L12.6993 19.1263C12.6867 19.1696 12.6604 19.2076 12.6243 19.2346C12.5883 19.2617 12.5444 19.2763 12.4993 19.2763C12.4543 19.2763 12.4104 19.2617 12.3743 19.2346C12.3383 19.2076 12.312 19.1696 12.2993 19.1263L7.69935 2.75962C7.68673 2.71635 7.66041 2.67833 7.62435 2.65129C7.58829 2.62424 7.54443 2.60962 7.49935 2.60962C7.45427 2.60962 7.41041 2.62424 7.37435 2.65129C7.33829 2.67833 7.31197 2.71635 7.29935 2.75962L5.34102 9.72629C5.24307 10.0756 5.03383 10.3834 4.74507 10.603C4.4563 10.8225 4.10378 10.9419 3.74102 10.943H1.66602" stroke="#3b82f6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_967_33449">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.942627)" />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_967_33449)">
                <path d="M18.3327 10.943H16.266C15.9018 10.9422 15.5474 11.0607 15.2569 11.2804C14.9665 11.5001 14.756 11.809 14.6577 12.1596L12.6993 19.1263C12.6867 19.1696 12.6604 19.2076 12.6243 19.2346C12.5883 19.2617 12.5444 19.2763 12.4993 19.2763C12.4543 19.2763 12.4104 19.2617 12.3743 19.2346C12.3383 19.2076 12.312 19.1696 12.2993 19.1263L7.69935 2.75962C7.68673 2.71635 7.66041 2.67833 7.62435 2.65129C7.58829 2.62424 7.54443 2.60962 7.49935 2.60962C7.45427 2.60962 7.41041 2.62424 7.37435 2.65129C7.33829 2.67833 7.31197 2.71635 7.29935 2.75962L5.34102 9.72629C5.24307 10.0756 5.03383 10.3834 4.74507 10.603C4.4563 10.8225 4.10378 10.9419 3.74102 10.943H1.66602" stroke="#DADAFC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_967_33449">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.942627)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PulseIcon;
