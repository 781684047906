const ExternalLinkIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.25 16.1562H2.75C2.11182 16.1562 1.49978 15.9027 1.04852 15.4515C0.597265 15.0002 0.34375 14.3882 0.34375 13.75V3.25C0.34375 2.61182 0.597265 1.99978 1.04852 1.54852C1.49978 1.09726 2.11182 0.84375 2.75 0.84375H8C8.17405 0.84375 8.34097 0.91289 8.46404 1.03596C8.58711 1.15903 8.65625 1.32595 8.65625 1.5C8.65625 1.67405 8.58711 1.84097 8.46404 1.96404C8.34097 2.08711 8.17405 2.15625 8 2.15625H2.75C2.45992 2.15625 2.18172 2.27148 1.9766 2.4766C1.77148 2.68172 1.65625 2.95992 1.65625 3.25V13.75C1.65625 14.0401 1.77148 14.3183 1.9766 14.5234C2.18172 14.7285 2.45992 14.8438 2.75 14.8438H13.25C13.5401 14.8438 13.8183 14.7285 14.0234 14.5234C14.2285 14.3183 14.3438 14.0401 14.3438 13.75V8.5C14.3438 8.32595 14.4129 8.15903 14.536 8.03596C14.659 7.91289 14.826 7.84375 15 7.84375C15.174 7.84375 15.341 7.91289 15.464 8.03596C15.5871 8.15903 15.6562 8.32595 15.6562 8.5V13.75C15.6562 14.3882 15.4027 15.0002 14.9515 15.4515C14.5002 15.9027 13.8882 16.1562 13.25 16.1562Z"
        fill="#1581ED"
      />
      <path
        d="M15 5.65625C14.8267 5.65398 14.6611 5.58412 14.5385 5.46153C14.4159 5.33895 14.346 5.17334 14.3438 5V2.15625H11.5C11.326 2.15625 11.159 2.08711 11.036 1.96404C10.9129 1.84097 10.8438 1.67405 10.8438 1.5C10.8438 1.32595 10.9129 1.15903 11.036 1.03596C11.159 0.91289 11.326 0.84375 11.5 0.84375H15C15.1733 0.846016 15.3389 0.915884 15.4615 1.03847C15.5841 1.16105 15.654 1.32666 15.6562 1.5V5C15.654 5.17334 15.5841 5.33895 15.4615 5.46153C15.3389 5.58412 15.1733 5.65398 15 5.65625Z"
        fill="#1581ED"
      />
      <path
        d="M9.31262 7.84376C9.14379 7.83085 8.98674 7.75232 8.87512 7.62501C8.7675 7.50469 8.70801 7.34893 8.70801 7.18751C8.70801 7.02608 8.7675 6.87033 8.87512 6.75001L14.5626 1.06251C14.6227 0.998032 14.6951 0.946317 14.7756 0.910449C14.8561 0.874581 14.943 0.855295 15.0312 0.85374C15.1193 0.852185 15.2068 0.868394 15.2885 0.9014C15.3702 0.934406 15.4445 0.983532 15.5068 1.04585C15.5691 1.10817 15.6182 1.1824 15.6512 1.26411C15.6842 1.34582 15.7004 1.43335 15.6989 1.52146C15.6973 1.60958 15.678 1.69648 15.6422 1.77698C15.6063 1.85748 15.5546 1.92993 15.4901 1.99001L9.75012 7.62501C9.63849 7.75232 9.48144 7.83085 9.31262 7.84376Z"
        fill="#1581ED"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
