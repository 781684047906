import React from "react";

const StructuredSourcesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00033 4.99984H5.00866M5.00033 14.9998H5.00866M3.33366 1.6665H16.667C17.5875 1.6665 18.3337 2.4127 18.3337 3.33317V6.6665C18.3337 7.58698 17.5875 8.33317 16.667 8.33317H3.33366C2.41318 8.33317 1.66699 7.58698 1.66699 6.6665V3.33317C1.66699 2.4127 2.41318 1.6665 3.33366 1.6665ZM3.33366 11.6665H16.667C17.5875 11.6665 18.3337 12.4127 18.3337 13.3332V16.6665C18.3337 17.587 17.5875 18.3332 16.667 18.3332H3.33366C2.41318 18.3332 1.66699 17.587 1.66699 16.6665V13.3332C1.66699 12.4127 2.41318 11.6665 3.33366 11.6665Z"
        stroke="#495057"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default StructuredSourcesIcon;
