import useApi from "services/api";
import { API_URL } from "../constants";

const UserCreditInfo = () => {
  const { data, loading, error, get } = useApi();

  const userCreditHistory = async (id, filter, params) => {
    const data = await get(
      `${API_URL}/current-user-credit-history/${id}?filter_type=${filter}${filter === "specific_date" ? `&specific_date=${params}` : ""}`,
    );
    return data;
  };

  return {
    userCreditHistory,
    data,
    loading,
    error,
  };
};

export default UserCreditInfo;
