// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templatesHeader {
  .p-selectbutton {
    background-color: #fafafa;
    padding: 6px;
    border-radius: 6px;
  }

  .p-button {
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: none;
    font-size: 14px;
  }

  .p-highlight,
  .p-button:focus {
    background-color: #ffffff;
    border-radius: 6px;
    color: #495057;
    box-shadow: 0px 1.5px 4px -1px #0a090b99;
  }

  .p-selectbutton > .p-button,
  .p-togglebutton.p-button {
    transition:
      background-color 0.1s,
      border-color 0.1s,
      box-shadow 0.1s;
  }

  span:not(.p-button-icon) {
    color: #6c757d;
  }
}

.newTempButton {
  background-color: var(--theme_primary-button);
  border: none;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Usermangement/usermanagement.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;EACjB;;EAEA;;IAEE,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,wCAAwC;EAC1C;;EAEA;;IAEE;;;qBAGiB;EACnB;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE,6CAA6C;EAC7C,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".templatesHeader {\n  .p-selectbutton {\n    background-color: #fafafa;\n    padding: 6px;\n    border-radius: 6px;\n  }\n\n  .p-button {\n    padding: 0.5rem 1rem;\n    background-color: transparent;\n    border: none;\n    font-size: 14px;\n  }\n\n  .p-highlight,\n  .p-button:focus {\n    background-color: #ffffff;\n    border-radius: 6px;\n    color: #495057;\n    box-shadow: 0px 1.5px 4px -1px #0a090b99;\n  }\n\n  .p-selectbutton > .p-button,\n  .p-togglebutton.p-button {\n    transition:\n      background-color 0.1s,\n      border-color 0.1s,\n      box-shadow 0.1s;\n  }\n\n  span:not(.p-button-icon) {\n    color: #6c757d;\n  }\n}\n\n.newTempButton {\n  background-color: var(--theme_primary-button);\n  border: none;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
