import React from 'react'

const PlusIcon = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.98387 5.01613V0.983871C5.98387 0.85554 5.93289 0.732466 5.84215 0.641722C5.7514 0.550979 5.62833 0.5 5.5 0.5C5.37167 0.5 5.2486 0.550979 5.15785 0.641722C5.06711 0.732466 5.01613 0.85554 5.01613 0.983871V5.01613H0.983871C0.85554 5.01613 0.732466 5.06711 0.641722 5.15785C0.550979 5.2486 0.5 5.37167 0.5 5.5C0.5 5.62833 0.550979 5.7514 0.641722 5.84215C0.732466 5.93289 0.85554 5.98387 0.983871 5.98387H5.01613V10.0161C5.0178 10.1439 5.06932 10.266 5.1597 10.3564C5.25008 10.4468 5.37219 10.4983 5.5 10.5C5.62833 10.5 5.7514 10.449 5.84215 10.3583C5.93289 10.2675 5.98387 10.1445 5.98387 10.0161V5.98387H10.0161C10.1445 5.98387 10.2675 5.93289 10.3583 5.84215C10.449 5.7514 10.5 5.62833 10.5 5.5C10.4983 5.37219 10.4468 5.25008 10.3564 5.1597C10.266 5.06932 10.1439 5.0178 10.0161 5.01613H5.98387Z" fill="#64748B"/>
    </svg>
  )
}

export default PlusIcon