const DashboardIcon = ({ selectedItem }) => {
    return selectedItem === "dashboard" ? (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 11.25H15C14.4033 11.25 13.831 11.0129 13.409 10.591C12.9871 10.169 12.75 9.59674 12.75 9V6C12.75 5.40326 12.9871 4.83097 13.409 4.40901C13.831 3.98705 14.4033 3.75 15 3.75H18C18.5967 3.75 19.169 3.98705 19.591 4.40901C20.0129 4.83097 20.25 5.40326 20.25 6V9C20.25 9.59674 20.0129 10.169 19.591 10.591C19.169 11.0129 18.5967 11.25 18 11.25Z" fill="#1581ED" />
            <path d="M18 20.5H15C14.4033 20.5 13.831 20.2629 13.409 19.841C12.9871 19.419 12.75 18.8467 12.75 18.25V15.25C12.75 14.6533 12.9871 14.081 13.409 13.659C13.831 13.2371 14.4033 13 15 13H18C18.5967 13 19.169 13.2371 19.591 13.659C20.0129 14.081 20.25 14.6533 20.25 15.25V18.25C20.25 18.8467 20.0129 19.419 19.591 19.841C19.169 20.2629 18.5967 20.5 18 20.5Z" fill="#1581ED" />
            <path d="M9 11.25H6C5.40326 11.25 4.83097 11.0129 4.40901 10.591C3.98705 10.169 3.75 9.59674 3.75 9V6C3.75 5.40326 3.98705 4.83097 4.40901 4.40901C4.83097 3.98705 5.40326 3.75 6 3.75H9C9.59674 3.75 10.169 3.98705 10.591 4.40901C11.0129 4.83097 11.25 5.40326 11.25 6V9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25Z" fill="#1581ED" />
            <path d="M9 20.5H6C5.40326 20.5 4.83097 20.2629 4.40901 19.841C3.98705 19.419 3.75 18.8467 3.75 18.25V15.25C3.75 14.6533 3.98705 14.081 4.40901 13.659C4.83097 13.2371 5.40326 13 6 13H9C9.59674 13 10.169 13.2371 10.591 13.659C11.0129 14.081 11.25 14.6533 11.25 15.25V18.25C11.25 18.8467 11.0129 19.419 10.591 19.841C10.169 20.2629 9.59674 20.5 9 20.5Z" fill="#1581ED" />
        </svg>

    ) : (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 11.25H15C14.4033 11.25 13.831 11.0129 13.409 10.591C12.9871 10.169 12.75 9.59674 12.75 9V6C12.75 5.40326 12.9871 4.83097 13.409 4.40901C13.831 3.98705 14.4033 3.75 15 3.75H18C18.5967 3.75 19.169 3.98705 19.591 4.40901C20.0129 4.83097 20.25 5.40326 20.25 6V9C20.25 9.59674 20.0129 10.169 19.591 10.591C19.169 11.0129 18.5967 11.25 18 11.25Z" fill="#DADAFC" />
            <path d="M18 20.5H15C14.4033 20.5 13.831 20.2629 13.409 19.841C12.9871 19.419 12.75 18.8467 12.75 18.25V15.25C12.75 14.6533 12.9871 14.081 13.409 13.659C13.831 13.2371 14.4033 13 15 13H18C18.5967 13 19.169 13.2371 19.591 13.659C20.0129 14.081 20.25 14.6533 20.25 15.25V18.25C20.25 18.8467 20.0129 19.419 19.591 19.841C19.169 20.2629 18.5967 20.5 18 20.5Z" fill="#DADAFC" />
            <path d="M9 11.25H6C5.40326 11.25 4.83097 11.0129 4.40901 10.591C3.98705 10.169 3.75 9.59674 3.75 9V6C3.75 5.40326 3.98705 4.83097 4.40901 4.40901C4.83097 3.98705 5.40326 3.75 6 3.75H9C9.59674 3.75 10.169 3.98705 10.591 4.40901C11.0129 4.83097 11.25 5.40326 11.25 6V9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25Z" fill="#DADAFC" />
            <path d="M9 20.5H6C5.40326 20.5 4.83097 20.2629 4.40901 19.841C3.98705 19.419 3.75 18.8467 3.75 18.25V15.25C3.75 14.6533 3.98705 14.081 4.40901 13.659C4.83097 13.2371 5.40326 13 6 13H9C9.59674 13 10.169 13.2371 10.591 13.659C11.0129 14.081 11.25 14.6533 11.25 15.25V18.25C11.25 18.8467 11.0129 19.419 10.591 19.841C10.169 20.2629 9.59674 20.5 9 20.5Z" fill="#DADAFC" />
        </svg>
    );
};

export default DashboardIcon;
