export const DateTimeFormatter = (dateString) => {
  const pad = (num) => {
    return num < 10 ? "0" + num : num;
  };
  const currentDate = dateString ? new Date(dateString) : new Date();
  const month = pad(currentDate.getMonth() + 1);
  const day = pad(currentDate.getDate());
  const year = currentDate.getFullYear();
  const hours = pad(currentDate.getHours());
  const minutes = pad(currentDate.getMinutes());
  return `${month}/${day}/${year} ${hours}:${minutes}` + dateString;
};

export function convertDateTime(input) {
  if (input instanceof Date) {
    if (isNaN(input.getTime())) {
      throw new Error("Invalid date object.");
    }
    // Extract hours and minutes from the input date
    const hours = input.getHours();
    const minutes = input.getMinutes();

    // Convert hours and minutes into seconds
    return hours * 60 * 60 + minutes * 60;
  } else if (typeof input === "number") {
    let hours = Math.floor(input / 3600);
    let minutes = Math.floor((input % 3600) / 60);
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
  } else {
    throw new Error(
      "Invalid input type. Please provide a valid Date object or seconds."
    );
  }
}

// convert itme into seconds
export function convertDateTimeWithSeconds(input) {
  if (input instanceof Date) {
    if (isNaN(input.getTime())) {
      throw new Error("Invalid date object.");
    }
    console.log("its come herer", input);
    // Extract hours and minutes from the input date
    const hours = input.getHours();
    const minutes = input.getMinutes();
    const seconds = input.getSeconds();

    // Convert hours and minutes into seconds
    return hours * 60 * 60 + minutes * 60 + seconds;
  } else if (typeof input === "number") {
    let hours = Math.floor(input / 3600); // Calculate hours
    let minutes = Math.floor((input % 3600) / 60); // Calculate minutes
    let seconds = input % 60; // Calculate remaining seconds
    let date = new Date(); // Create a new Date object
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds); // Set seconds
    return date;
  } else {
    throw new Error(
      "Invalid input type. Please provide a valid Date object or seconds."
    );
  }
}

// convert seconds into time HH:MM
export const secondsToHoursMinutesSeconds = (dateInSeconds) => {
  const getValue = convertDateTime(dateInSeconds);
  const hours = getValue.getHours();
  const minutes = getValue.getMinutes();
  const timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return timeString;
};

// convert seconds into time HH:MM:SS
export const secondsToHoursMinutesWithSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export function toTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertToHyphen(inputString) {
  if (inputString?.length > 0) {
    return inputString.replace(/[^a-zA-Z0-9-_]/g, "_");
  }
  return "";
}

// Outputs: 2/03/2023, 09:15:00
export function formatDateWithTime(dateString) {
  const date = new Date(dateString);
  const options = {
    day: "numeric", // Day of the month
    month: "numeric", // Numeric month (1-12)
    year: "numeric", // Full year
    hour: "2-digit", // Hour (2 digits, 24-hour format)
    minute: "2-digit", // Minute (2 digits)
    second: "2-digit", // Second (2 digits)
    hour12: false, // Use 24-hour format
  };

  return date.toLocaleDateString("en-GB", options);
}
