import { useRef, useState, useEffect } from "react";
import { useDebounce } from "primereact/hooks";
import { isMobile } from "react-device-detect";
import InnerSidebar from "layouts/InnerSidebar";
import { SourceConfigBreadcrumb } from "components/SourceConfigBreadcrumb";
import { BreadCrumb } from "primereact/breadcrumb";

import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { MegaMenu } from "primereact/megamenu";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
// import { Images } from '../../services/ImageHelper';
import { Image } from "primereact/image";
import { Steps } from "primereact/steps";

import { useNavigate, useParams } from "react-router-dom";
// import {
//   fieldRulesAtom,
//   sourceAtom,
//   sourceFieldsAtom,
// } from "../StructuredSources/store";
import {
  extractErrorMessage,
  isObjectWithNonEmptyValues,
} from "../StructuredSources/utils";
import { GET_SOURCES } from "../StructuredSources/mockApi";

// import Processing from './Processing/Processing';
// import SourceApplyRules from './SourceApplyRules';
// import SourceBasicDetails from './SourceBasicDetails';
// import SourceContract from './SourceContract';
// import SourceMapFields from './SourceMapFields';
// import SourceFilterModal from "../StructuredSources/SourceFilterModal";
// import SourceControlAccessModal from "./SourceControlAccessModal";
import "./index.css";
// import eventEmitter from './eventEmitter';
// import SquarePenIcon from "./icons/SquarePenIcon";
// import ControlAccessIcon from "./icons/ControlAccessIcon";
import { ToggleButton } from "primereact/togglebutton";
import { InputSwitch } from "primereact/inputswitch";
// import DeleteSourceIcon from './icons/DeleteSourceIcon'

export const EmptyData = () => {
  return (
    <div
      className="flex justify-center align-items-center"
      style={{ height: "80vh" }}
    >
      <div className="block text-center">
        {/* <Image src={Images.bordedIcon} alt="Bored Icon" width="230" height="120" /> */}
        <h4 className="font-bold my-2">
          Looks like you haven’t added any source yet
        </h4>
        <p className="font-light text-sm">Start by Adding Some Sources</p>
      </div>
    </div>
  );
};

export default function DocumentSources() {
  const pageLimit = 10;

  const { id } = useParams();
  const navigate = useNavigate();
  const menu = useRef(null);
  const toast = useRef(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [, setSource] = useAtom(sourceAtom);
  // const [, setSourceFields] = useAtom(sourceFieldsAtom);
  // const [, setFieldRules] = useAtom(fieldRulesAtom);
  const [filterOption, setFilterOption] = useState(null);
  const [page, setPage] = useState(0);
  const [isFilerModalOpen, setFilterModal] = useState(false);
  const [isControlModalOpen, setControlModal] = useState(false);
  const [isFilerApply, setIsFilterApply] = useState(false);
  const [limit, setLimit] = useState(pageLimit);
  const [sortField, setSortField] = useState("updatedAt desc");
  const [searchText, searchDebouncedValue, setSearchInput] = useDebounce(
    "",
    300
  );
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sourceData, setSourceData] = useState({
    count: 0,
    rows: null,
  });
  const [viewSource, setViewSource] = useState(null);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (searchText) {
      setLimit(pageLimit);
    }
  }, [searchText]);
  useEffect(() => {
    if (id) {
      setViewSource({ id });
    }
  }, [id]);
  useEffect(() => {
    setInnerLoading(true);
    const isFilter = isObjectWithNonEmptyValues(filterOption);
    if (isFilter) {
      fetchData(filterOption);
    } else {
      fetchData();
    }
  }, [page, limit, searchDebouncedValue, sortField]);

  const fetchData = async (data) => {
    try {
      const payload = {
        page: page / limit + 1,
        limit: limit,
        search: searchDebouncedValue || "",
        sort: sortField,
      };
      if (data?.connectionType?.length > 0) {
        payload.connectionType = data?.connectionType?.toString();
      }
      if (data?.startDate) {
        payload.createdAtStartDate = moment(data?.startDate).format(
          "YYYY-MM-DD"
        );
      }
      if (data?.endDate) {
        payload.createdAtEndDate = moment(data?.endDate).format("YYYY-MM-DD");
      }
      if (data?.dataFormat?.length > 0) {
        payload.dataFormat = data?.dataFormat?.toString();
      }
      if (data?.client?.length > 0) {
        payload.clientId = data?.client?.toString();
      }
      if (data?.status?.length > 0) {
        payload.status = data?.status?.toString();
      }
      // const response = await getSources(payload);
      // const response= GET_SOURCES;
      setSourceData(GET_SOURCES);
      setFilterModal(false);
      const isFilter = isObjectWithNonEmptyValues(data);
      if (isFilter) {
        setFilterOption(data);
        setIsFilterApply(true);
      } else if (data === "clear") {
        setFilterOption(null);
        setIsFilterApply(false);
        setPage(0);
      }
    } catch (error) {
      if (isFilerModalOpen) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: extractErrorMessage(error),
          life: 3000,
        });
        return;
      }
      setError(error);
    } finally {
      setInnerLoading(false);
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    setPage(event.first);
    setLimit(event.rows);
  };

  const handelApplyFilter = (data) => {
    setInnerLoading(true);
    fetchData(data);
  };

  const onSort = (event) => {
    setSortField(
      `${event.sortField} ${event.sortOrder === 1 ? "asc" : "desc"}`
    );
  };

  const start = (
    <div className="inline-flex align-items-center">
      {/* <span className="pi pi-database source-icon" /> */}
      <span className="font-bold text-xl text-black-alpha-90">
        Document Sources
      </span>
    </div>
  );

  // const handleAccept = async (data) => {
  //   setInnerLoading(true);

  //   try {
  //     const newStatus = data.status === "inactive" ? "active" : "inactive";
  //     const response = await updateStatus(data.id, { status: newStatus });
  //     if (response.status === 200) {
  //       setSourceData((prevData) => {
  //         const updatedRows = prevData.rows.map((row) =>
  //           row.id === data.id ? { ...row, status: newStatus } : row
  //         );
  //         return { ...prevData, rows: updatedRows };
  //       });
  //       toast.current.show({
  //         severity: "success",
  //         summary: "Success",
  //         detail: "Status updated",
  //         life: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: extractErrorMessage(error),
  //       life: 3000,
  //     });
  //   } finally {
  //     setInnerLoading(false);
  //   }
  // };
  // const getActiveBatchCountForSource = async (sourceId) => {
  //   try {
  //     const response = await getActiveBatchForSource(sourceId);
  //     return response.data;
  //   } catch (error) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: extractErrorMessage(error),
  //     });
  //   }
  // };

  const confirmStatus = (data) => {
    // confirmDialog({
    //   message: (
    //     <div>
    //       <span>
    //         Are you sure you want to change the status to
    //         <strong>
    //           {" "}
    //           {data.status === "inactive" ? "Active" : "Inactive"}
    //         </strong>
    //         ?
    //       </span>
    //     </div>
    //   ),
    //   header: "Confirmation",
    //   icon: "pi pi-exclamation-triangle",
    //   // accept: () => handleAccept(data),
    // });
  };

  const renderStatus = (status) => {
    switch (status) {
      case "in-progress":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {" "}
            In Progress
          </span>
        );
        break;
      case "active":
        return (
          <span className="border-2 border-[#326FD1] text-[#326FD1] bg-[#f5f9ff] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {" "}
            Active
          </span>
        );
        break;
      case "inactive":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {" "}
            Inactive
          </span>
        );
        break;
      case "error":
        return (
          <div className="flex flex-row items-baseline border-2 border-[#B32B23] text-[#B32B23] bg-[#fff6f5] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {" "}
            Error
            <div
              className="inactive-notification"
              data-pr-tooltip={`This source has encountered an error`}
              data-pr-at="left-0 top-25"
              data-pr-my="left center-2"
              data-pr-classname="custom-tooltip-inactive"
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-[5px] ml-1.5 cursor-pointer"
              >
                <path
                  d="M5 5L5 7.25M5 3.33228V3.3125M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5Z"
                  stroke="#BC1C1C"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <Tooltip target=".inactive-notification" />
          </div>
        );
        break;
    }
  };
  const onMenuClick = (event, rowData) => {
    menu.current.toggle(event);
    setSelectedRow(rowData);
  };

  const columns = [
    {
      field: "name",
      header: "Name",
      sortable: true,
      style: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "200px",
        width: "20%",
      },
    },
    // {
    //   field: 'dataFormat',
    //   header: 'Source Type',
    //   sortable: true,
    //   width: '20%',
    //   body: (rowData) => {
    //     return rowData.dataFormat ? rowData.dataFormat.toUpperCase() : '-';
    //   },
    // },
    {
      field: "status",
      header: "Status",
      width: "15%",
      sortable: true,
      body: (rowData) => {
        // return rowData.status.toUpperCase();
        return renderStatus(rowData.status);
      },
    },
    {
      field: "connectionType",
      header: "Connection Type",
      sortable: true,
      body: (rowData) => {
        return rowData.connectionType !== "undefined"
          ? rowData.connectionType.replace(/_/g, " ")
          : "-";
      },
    },
    {
      field: "usingIn",
      header: "Using In",
      sortable: true,
      width: "10%",
      body: (rowData) => {
        return rowData.usingIn ? rowData.usingIn : "-";
      },
    },
    { field: "client.name", header: "Client", sortable: true, width: "20%" },
    {
      field: "accessTo",
      header: "Access To",
      sortable: true,
      body: (rowData) => {
        return rowData.accessTo ? rowData.accessTo : "-";
      },
    },
    {
      field: "createdAt",
      header: "Created At",
      sortable: true,
      body: (rowData) =>
        moment(rowData.createdAt).format("MM/DD/YYYY, HH:mm:ss"),
    },
    {
      header: "Actions",
      body: (rowData) => {
        return (
          <span className="p-4">
            <i
              className="pi pi-ellipsis-v cursor-pointer"
              onClick={(e) => onMenuClick(e, rowData)}
            />
          </span>
        );
      },
    },
  ];

  const header = (
    <div className="flex flex-wrap align-items-center justify-end gap-2">
      {isFilerApply && (
        <Button
          label="Clear"
          icon="pi pi-filter-slash"
          iconPos="right"
          severity="info"
          outlined
          loading={innerLoading}
          onClick={() => {
            setInnerLoading(true);
            fetchData("clear");
          }}
        />
      )}
      <div>
        {actionLoading ? (
          <ProgressSpinner style={{ width: "34px", height: "34px" }} />
        ) : null}
      </div>
      <Button
        label="Filters"
        icon="pi pi-filter"
        className="text-[#3B82F6] border border-[#3B82F6] pl-5 pr-5 pt-3 pb-3"
        iconPos="right"
        severity="info"
        outlined
        onClick={() => setFilterModal(!isFilerModalOpen)}
      />
      <div className="flex align-items-center justify-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            className="w-[316px] border border-[#CED4DA] pl-10 pr-5 pt-3 pb-3"
            placeholder="Search source"
            value={searchText}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </span>
      </div>
    </div>
  );

  const megaMenu = () => {
    return (
      <MegaMenu
        start={start}
        //   end={newSourceSetup}
        className="bg-transparent border-none border-noround pb-3"
      />
    );
  };

  if (loading) {
    return (
      <>
        {megaMenu()}
        <div
          className="flex justify-center align-center"
          style={{ height: "80vh" }}
        >
          <div className="block text-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );
  }

  // if (!sourceData.rows) {
  //   return (
  //     <>
  //       {megaMenu()}
  //       <EmptyData />
  //     </>
  //   );
  // }

  const getMenuItems = () => {
    const baseMenu = [
      {
        label: <span style={{ marginLeft: "12px" }}>Edit</span>,
        //   icon:()=>(<SquarePenIcon/>)
      },
      {
        label: <span style={{ marginLeft: "12px" }}>Control Access</span>,
        // icon: () => <ControlAccessIcon />,
        command: () => {
          setControlModal(true);
        },
      },
      {
        label: "",
        template: () => (
          <div
            style={{ borderBottom: "1px solid #ccc", margin: "4px 0" }}
          ></div>
        ),
      },
      {
        template: () => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "40px",
              alignItems: "center",
            }}
          >
            <InputSwitch
              checked={checked}
              onChange={(e) => setChecked(e.value)}
              style={{ marginLeft: "1px" }}
            />
            {checked ? <span>Deactivate</span> : <span>Activate</span>}
          </div>
        ),
      },
      {
        label: (
          <span style={{ marginLeft: "12px", color: "#e24c4c" }}>Delete</span>
        ),
        // icon: () => <DeleteSourceIcon />,
      },

      // selectedRow &&
      //   selectedRow.status !== 'in-progress' && {
      //     label: selectedRow?.status === 'inactive' ? 'Update to Active' : 'Update to Inactive',
      //     icon: 'pi pi-clone',
      //     command: () => {
      //       confirmStatus(selectedRow);
      //     },
      //   },

      // selectedRow &&
      //   selectedRow.status === 'active' && {
      //     label: 'Clone',
      //     icon: 'pi pi-clone',
      //     command: () => {
      //       navigate(`/source/add/1?cloneReferenceId=${selectedRow.id}`);
      //     },
      //   },
    ];

    return baseMenu.filter(Boolean);
  };

  const sortFieldSplit = sortField.split(" ");
  const tableSortField = sortField ? sortFieldSplit[0] : "";
  const tableSortOrder = sortField ? (sortFieldSplit[1] === "asc" ? 1 : -1) : 0;

  if (loading) {
    return (
      <div className="flex justify-center align-items-center h-29rem">
        <div className="block text-center">
          <ProgressSpinner />
        </div>
      </div>
    );
  }
  return (
    <div className="new-source-setup">
      <div className="w-full h-screen overflow-hidden bg-sidebar flex">
        {loading ? (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="transition-all duration-500 relative md:ml-[2px] md:mr-[8px] md:my-[16px] md:rounded-[26px] bg-main-gradient p-[18px] h-full overflow-y-scroll animate-pulse "
          >
            <div className="w-full h-full flex justify-center items-center">
              {/* <PreLoader /> */}
            </div>
          </div>
        ) : (
          <div
            style={{ height: isMobile && "100%" }}
            className="flex flex-col items-around transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full overflow-y-scroll"
          >
            {/* <SourceConfigBreadcrumb sourceConfig="Document Sources" />
            <div
              style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
            ></div> */}
            <div className="source-list">
              {/* <Menu
                model={getMenuItems()}
                onHide={() => {
                    setSelectedRow(null);
                }}
                popup
                ref={menu}
                id="popup_menu_left"
                /> */}
              {megaMenu()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
