import React, { useState, useRef, useEffect } from "react";
import { useDebounce } from "primereact/hooks";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Menu } from "primereact/menu";
import { MegaMenu } from "primereact/megamenu";
import InnerSidebar from "layouts/InnerSidebar";
import {
  extractErrorMessage,
  isObjectWithNonEmptyValues,
} from "../StructuredSources/utils";
import SourcePulseBreadcrumb from "components/SourcePulseBreadcrumb";
import { GET_SOURCE_DETAILS } from "../StructuredSources/pulseApi";
import PlusIcon from "./PlusIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateWithTime, toTitleCase } from "utils/helper";
import AddRuleSidebar from "../StreamDetailsRuleList/addRuleSidebar";
import HighAlertInfo from "./icons/HighAlertInfo";
import MediumAlertInfo from "./icons/MediumAlertInfo";
import LowAlertInfo from "./icons/LowAlertInfo";
import "./index.css";
import CriticalAlertInfo from "./icons/CriticalAlertInfo";
import { usePulseApi } from "hooks/pulseConfig";

const StreamDataSourcesDetails = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false); // Simulate loading state
  const [innerLoading, setInnerLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10); // Items per page
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewSource, setViewSource] = useState(null);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isFilerModalOpen, setFilterModal] = useState(false);
  const [isControlModalOpen, setControlModal] = useState(false);
  const [isUsingInModalOpen, setUsingInModal] = useState(false);
  const [sourceData, setSourceData] = useState(GET_SOURCE_DETAILS);
  const [sortField, setSortField] = useState("updatedAt desc"); // Assigning GET_SOURCES to state
  const [searchText, searchDebouncedValue, setSearchInput] = useDebounce(
    "",
    300
  );
  const sortFieldSplit = sortField.split(" ");
  const tableSortField = sortField ? sortFieldSplit[0] : "";
  const tableSortOrder = sortField ? (sortFieldSplit[1] === "asc" ? 1 : -1) : 0;
  const [filterOption, setFilterOption] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [getNamespaceAsset, setNamespaceAsset] = useState({
    namespace_id: "",
    asset_id: "",
  });
  const [activeTab, setActiveTab] = useState("");

  const toast = useRef(null);
  const { getPulseSourceDetailList } = usePulseApi();

  useEffect(() => {
    setInnerLoading(true);
    const isFilter = isObjectWithNonEmptyValues(filterOption);
    fetchData(filterOption);
  }, [page, limit, searchDebouncedValue, filterOption]);

  const onPageChange = (event) => {
    setPage(event.first);
    setLimit(event.rows);
  };

  const onSort = (event) => {
    setSortField(
      `${event.sortField} ${event.sortOrder === 1 ? "asc" : "desc"}`
    );
  };

  const handleDeleteSourceId = () => {
    setDeleteModal(false); // Close modal after deleting
    toast.current.show({
      severity: "success",
      summary: "Deleted",
      detail: "Source deleted successfully",
    });
  };

  const fetchData = async (data) => {
    try {
      const payload = {
        page: page / limit + 1,
        limit: limit,
        search: searchDebouncedValue || "",
        sort: sortField,
        dataSourceId: location.state.source.id,
      };
      const response = await getPulseSourceDetailList(payload); // Replaced mock API with actual API call
      setSourceData(response);
      setLimit(response.limit);
      setFilterModal(false);
      const isFilter = isObjectWithNonEmptyValues(data);
      if (isFilter) {
        setFilterOption(data);
        // setIsFilterApply(true);
      }
    } catch (error) {
      if (isFilerModalOpen) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: extractErrorMessage(error),
          life: 3000,
        });
        return;
      }
      setError(error);
    } finally {
      setInnerLoading(false);
      setLoading(false);
    }
  };

  // Table header
  const header = (
    <div className="flex flex-col bg-[#F8F9FA]">
      <div className="flex flex-wrap align-items-center justify-end gap-2 border-t-2 border-[#e5e7eb] py-4 pr-[1rem]">
        <Button
          label={`${innerLoading ? "Refreshing" : "Refresh"}`}
          icon="pi pi-refresh"
          className={`refresh-streams pl-5 pr-5 pt-3 pb-3 place-self-end ${innerLoading ? "text-gray-700 " : "text-[#495057]"
            }`}
          style={{fontWeight:'400', fontStyle:'normal'}}
          disabled={innerLoading}
          iconPos="left"
          size="large"
          severity="info"
          // outlined
          onClick={() => {
            setInnerLoading(true);
            // const isFilter = isObjectWithNonEmptyValues(filterOption);
            fetchData();
          }}
        />
        <div className="flex align-items-center justify-center" style={{ border: '1px solid #ced4da', backgroundColor: 'white', borderRadius: '5px' }}>
          <span className="p-input-icon-left">
            <i className="pi pi-search pl-3 font-extralight text-[#6C757D] opacity-80" />
          </span>
          <InputText
            type="search"
            className="w-[270px] pl-10 pr-5 pt-3 pb-3 placeholder:font-normal text-[#ced4da] ml-5 pulse-search-component border-0"
            placeholder="Search stream id"
            value={searchText}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>

      {/* <hr className="w-full mt-5 border-t border-[#CED4DA]" /> */}
    </div>
  );

  const start = (
    <div className="inline-flex align-items-center">
      <span className="font-semibold text-xl text-[#191A1C]">
        {location?.state?.source?.name}
      </span>
    </div>
  );

  const megaMenu = () => {
    return (
      <MegaMenu
        start={start}
        className="bg-transparent border-none border-noround pb-3"
      />
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case "in-progress":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            In Progress
          </span>
        );
      case "active":
      case "running":
        return (
          <span className="border-2 border-[#326FD1] text-[#326FD1] bg-[#f5f9ff] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      case "inactive":
      case "pending":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      case "error":
      case "stopped":
        return (
          <div className="flex flex-row max-w-20 items-baseline border-2 border-[#B32B23] text-[#B32B23] bg-[#fff6f5] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {toTitleCase(status)}
            <div
              className="inactive-notification"
              data-pr-tooltip="This source has encountered an error"
              data-pr-at="left-0 top-25"
              data-pr-my="left center-2"
              data-pr-classname="custom-tooltip-inactive"
            >
              {status === "error" && (
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-[5px] ml-1.5 cursor-pointer"
                >
                  <path
                    d="M5 5L5 7.25M5 3.33228V3.3125M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5Z"
                    stroke="#BC1C1C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
            <Tooltip target=".inactive-notification" />
          </div>
        );
      case "completed":
        return (
          <span className="border-2 bg-green-50 border-green-600 text-green-600 pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      default:
        return status;
    }
  };

  const handleClick = (item) => {
    setNamespaceAsset({
      namespace_id: item.namespace_id,
      asset_id: item.asset_id,
    });
    setVisible(true);
  };

  const columns = [
    { field: "stream_id", header: "Asset Name", sortable: true },
    {
      field: "rulesCount",
      header: "Rules",
      sortable: true,
      body: (rowData) => {
        return (
          <div className="flex flex-row">
            <div>{rowData.rulesCount}</div>
            <div className="stream-details-button">
              <Button
                label={<PlusIcon />}
                type="button"
                onClick={() => handleClick(rowData)}
                className="bg-[#FAFAFA] text-2xl text-[#64748B] font-extralight ml-4 p-[3px] border border-[#DFE7EF] rounded-[20%]"
              />
            </div>
          </div>
        );
      },
    },
    // {
    //   field: "status",
    //   header: "Status",
    //   sortable: true,
    //   body: (rowData) => {
    //     return renderStatus(rowData.status);
    //   },
    // },
    {
      field: "alertCounts",
      header: "Active Alerts",
      sortable: true,
      body: (rowData) => {
        const { alertCounts } = rowData;

        return (
          <div className="flex flex-row font-medium text-sm">
            {alertCounts && alertCounts.CRITICAL > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 rounded-xl mr-2">
                <CriticalAlertInfo />
                <span className="ml-2">Critical-{alertCounts.CRITICAL}</span>
              </div>
            )}
            {alertCounts && alertCounts.HIGH > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 rounded-xl">
                <HighAlertInfo />
                <span className="ml-2">High-{alertCounts.HIGH}</span>
              </div>
            )}
            {alertCounts && alertCounts.MEDIUM > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 mx-2 rounded-xl">
                <MediumAlertInfo />
                <span className="ml-2 ">Medium-{alertCounts.MEDIUM}</span>
              </div>
            )}
            {alertCounts && alertCounts.LOW > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 rounded-xl mr-2">
                <LowAlertInfo />
                <span className="ml-2">Low-{alertCounts.LOW}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "lastAlertRaisedAt",
      header: "Last Alert Raised at",
      body: (rowData) => {
        if (rowData?.lastAlertRaisedAt) return (formatDateWithTime(rowData?.lastAlertRaisedAt))
        return null;
      },
      sortable: true,
    },
    {
      header: "View & Edit",
      body: (rowData) => (
        <Button
          label={<i className="pi pi-eye" />}
          type="button"
          onClick={() => {
            setNamespaceAsset({});
            navigate(`${location?.pathname}/stream-detail-rules`, {
              state: {
                sourceRowData: rowData,
                sourceId: location?.state?.source?.id,
              },
            });
          }}
          className="pulse-view-button p-3 text-xs text-[#3B82F6] mr-5"
        />
      ),
    },
  ];
  const handleSuccess = (success) => {
    if (success?.type) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: success?.message,
      });
      setNamespaceAsset({});
      fetchData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: success?.message,
      });
    }
  };

  return (
    <div className="new-source-setup">
      <div className="w-full h-screen overflow-hidden bg-sidebar flex">
        {loading ? (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="transition-all duration-500 relative md:ml-[2px] md:mr-[8px] md:my-[16px] md:rounded-[26px] bg-main-gradient p-[18px] h-full overflow-y-scroll animate-pulse "
          >
            <div className="w-full h-full flex justify-center items-center">
              {/* <PreLoader />  */}
            </div>
          </div>
        ) : (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="flex flex-col items-around transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full overflow-y-scroll"
          >
            {/* <SourcePulseBreadcrumb
              routes={[
                "Pulse",
                "Stream Data Sources",
                location?.state?.source?.name,
              ]}
            />
            <div
              style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
            ></div> */}
            <div className="source-list">
              {megaMenu()}

              {/* {isUsingInModalOpen && (
                <SourceUsingInModal
                  source={selectedRow}
                  onClose={() => setUsingInModal(false)}
                  visible={isUsingInModalOpen}
                />
              )} */}
              <AddRuleSidebar
                visible={visible}
                setVisible={setVisible}
                onSuccess={handleSuccess}
                getNamespaceAsset={getNamespaceAsset}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <div className="mt-3 sticky-table-header mr-2">
                {header}
                <DataTable
                  scrollable
                  scrollHeight="calc(100vh - 45vh)"
                  value={sourceData?.items} // Accessing rows from sourceData
                  // header={header}
                  sortField={tableSortField} // Initial sort field
                  sortOrder={tableSortOrder} // Default sort order (1 = ascending, -1 = descending)
                  onSort={onSort}
                  className="stream-data-sources-details border-t-2 border-[#e5e7eb]"
                  tableStyle={{ minWidth: "50rem" }}
                  loading={innerLoading}
                  emptyMessage={
                    "Data not found." // Display this message when no data is found
                  }
                >
                  {columns.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      body={col.body}
                      style={col.style}
                      className=" border-b-2"
                      sortable={col.sortable}
                      headerClassName="border-b-2"
                    />
                  ))}
                </DataTable>
                <Paginator
                  first={page}
                  rows={limit}
                  totalRecords={sourceData.total}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageChange={onPageChange}
                  className="justify-end"
                  template={{
                    layout:
                      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
                  }}
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                />
                <Toast ref={toast} position="top-right" />
              </div>
            </div>
            <Divider />
          </div>
        )}
      </div>
    </div>
  );
};

export default StreamDataSourcesDetails;
