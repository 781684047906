import React from "react";

const CustomStepper = ({
  stepItems,
  activeStep,
  setImported,
  setAppliedRules,
  actualStep,
}) => {
  console.log("custom stepper ", actualStep);
  const Divider = () => {
    return (
      <div className="custom-stepper flex flex-col flex-1 shrink pt-3.5 h-px basis-0 w-[70px] mt-[2px] bg-zinc-500">
        <div className="flex w-full bg-zinc-200 min-h-[2px]" />
      </div>
    );
  };

  const ActiveStep = ({ stepIndex, label }) => {
    return (
      <div className="flex flex-col items-center w-[50px]">
        <div className="flex justify-center items-center w-10 h-10 text-base leading-7 text-blue-700 whitespace-nowrap bg-blue-50 rounded-3xl border-[2px] border-gray-200 border-solid min-h-[28px] cursor-pointer">
          <div className="self-stretch my-auto">{stepIndex + 1}</div>
        </div>
        <div className="mt-2 text-sm font-bold text-zinc-600 whitespace-nowrap">
          {label}
        </div>
      </div>
    );
  };

  const InactiveStep = ({ stepIndex, label }) => {
    return (
      <div className="flex flex-col items-center w-[50px]">
        <div
          className="flex justify-center items-center w-10 h-10 text-gray-500 leading-7 whitespace-nowrap bg-white rounded-3xl border-[2px] border-gray-200 border-solid min-h-[28px]"
          // onClick={() => {
          //   if (stepIndex + 1 <= actualStep) {
          //     switch (stepIndex + 1) {
          //       case 1:
          //         setImported(false);
          //         setAppliedRules(false);
          //         break;
          //       case 2:
          //         setImported(true);
          //         setAppliedRules(false);
          //         break;
          //       case 3:
          //         setImported(true);
          //         setAppliedRules(true);
          //     }
          //   }
          // }}
        >
          <div className="self-stretch my-auto">{stepIndex + 1}</div>
        </div>
        <div className="mt-2 text-sm text-gray-500 whitespace-nowrap">
          {label}
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-start mr-10">
      {stepItems.map((item, index) => (
        <div key={index} className="flex flex-1 shrink items-start basis-0">
          {activeStep - 1 === index ? (
            <>
              <ActiveStep stepIndex={index} label={item.label} />
              {index != stepItems.length - 1 && <Divider />}
            </>
          ) : (
            <>
              <InactiveStep stepIndex={index} label={item.label} />
              {index != stepItems.length - 1 && <Divider />}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomStepper;
