import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { useAtom } from "jotai";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

import { clientsAtom } from "../store";
import { isObjectWithNonEmptyValues } from "../utils";
import { connectionType } from "../constants";

import "./index.css";

const SourceFilterModal = (props) => {
  // const [clients] = useAtom(clientsAtom);
  // const [filters, setFilters] = useState({
  //   startDate: null,
  //   endDate: null,
  //   dataFormat: null,
  //   status: null,
  //   client: null,
  //   connectionType: null,
  // });

  useEffect(() => {
    const { filterOption } = props;
    const isDataExist = isObjectWithNonEmptyValues(filterOption);
    if (isDataExist) {
      props.setFilters((prevFilters) => ({
        ...prevFilters,
        ...filterOption,
      }));
    }
  }, []);

  useEffect(() => {
    console.log("filter option changed");
    if (props.filterOption === null) console.log(" filter options is ", null);
  }, [props.filterOption]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFilters({
      ...props.filters,
      [name]: value,
    });
  };

  const handleReset = () => {
    console.log(" resetting data");
    props.setFilters({
      startDate: null,
      endDate: null,
      dataFormat: null,
      status: null,
      client: null,
      connectionType: null,
    });
  };

  const footerContent = (
    <div className="flex flex-row justify-between" style={{justifyContent:'space-between'}}>
      <Button
        style={{
          backgroundColor: "white",
          color: "#EF4444",
          padding: "5px 10px 5px 10px",
          marginLeft: "5%",
          border:'0px'
        }}
        label={props.loading ? "Loading..." : "Reset"}
        // icon="pi pi-check"
        disabled={props.loading || !isObjectWithNonEmptyValues(props.filters)}
        // onClick={() => props?.handelApplyFilter(filters)}
        onClick={handleReset}
        autoFocus
      />
      <Button
        style={{
          backgroundColor: "#3B82F6",
          color: "white",
          padding: "6px 12px 6px 12px",
          marginBottom:'10px'
        }}
        label={props.loading ? "Loading..." : "Apply"}
        // icon="pi pi-check"
        disabled={props.loading || !isObjectWithNonEmptyValues(props.filters)}
        onClick={() => {
          // handleSubmit()
          props?.handelApplyFilter(props.filters);
        }}
        autoFocus
      />
    </div>
  );
  // const clientsList = clients.map((p) => ({
  //   name: p.name,
  //   code: p.id,
  // }));
  return (
    <Dialog
      header="Filter"
      visible={true}
      draggable={false}
      style={{ width: "30vw" }}
      onHide={props.handleModal}
      footer={footerContent}
      className="source-filter-modal-dialog"
    >
      <div className="formgrid grid source-filter-modal-dialog">
        <div className="field col-12 md:col-6" style={{width:'100%'}}>
          <label htmlFor="status">Status</label>
          {/* <MultiSelect
            showClear
            id="status context-filter-panel"
            value={props.filters.status}
            name="status"
            selectAllLabel="Select all"
            onChange={handleInputChange}
            options={[
              { name: 'ACTIVE', code: 'active' },
              { name: 'IN-ACTIVE', code: 'inactive' },
              { name: 'IN-PROGRESS', code: 'in-progress' },
            ]}
            optionLabel="name"
            optionValue="code"
            placeholder="Select"
            className="w-full"
            style={{
                border: '1px solid #CED4DA',
                marginTop: '5px',
                outline: 'none',
                boxShadow: 'none',
              }}
            /> */}
          <Dropdown
            id="status"
            value={props?.filters?.status}
            name="status"
            options={[
              { name: "ACTIVE", code: "active" },
              { name: "IN-ACTIVE", code: "inactive" },
              { name: "IN-PROGRESS", code: "in-progress" },
            ]}
            optionLabel="name"
            optionValue="code"
            placeholder="Select"
            className="w-full"
            onChange={handleInputChange}
            style={{
              border: "1px solid #CED4DA",
              height:'52px',
              paddingTop:'3px',
              marginTop: "5px",
              outline: "none",
              boxShadow: "none",
            }}
          />
        </div>

        <div className="field col-12 md:col-6 mt-2" style={{width:'100%'}}>
          <label htmlFor="connection-type">Connection Type</label>
          {/* <MultiSelect
            showClear
            value={props.filters.connectionType}
            name="connectionType"
            selectAllLabel="Select all"
            onChange={handleInputChange}
            options={connectionType}
            optionLabel="name"
            optionValue="id"
            placeholder="Select a connection type"
            className="w-full border-gray-500 mt-2"
            id="connection-type context-filter-panel"
            style={{'border':'1px solid #CED4DA', 'marginTop':'5px'}}
          /> */}
          <Dropdown
            id="connection-type"
            value={props.filters.connectionType}
            name="connectionType"
            options={connectionType}
            optionLabel="name"
            optionValue="id"
            placeholder="Select a connection type"
            className="w-full mt-1"
            onChange={handleInputChange}
            style={{ border: "1px solid #CED4DA", height:'52px', paddingTop:'3px' }}
          />
        </div>
        <div className="field col-12 md:col-6 mt-2 max-w-fit" style={{width:'100%'}}>
          <label htmlFor="created">Created At</label>
          <div
            className="flex gap-2 align-items-baseline"
            style={{
              border: "1px solid #CED4DA",
              borderRadius: "5px",
              marginTop: "5px",
              height: "55px",
            }}
          >
            <Calendar
              id="created-start"
              value={props.filters?.startDate}
              name="startDate"
              maxDate={props.filters?.endDate || new Date()}
              placeholder="Start date"
              onChange={handleInputChange}
              style={{ width: "40%" }}
            />
            <p>-</p>
            <Calendar
              id="created-end"
              value={props.filters?.endDate}
              name="endDate"
              minDate={props.filters?.startDate}
              maxDate={new Date()}
              placeholder="End date"
              onChange={handleInputChange}
              style={{ marginLeft: "10%" }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

SourceFilterModal.propTypes = {
  loading: PropTypes.bool,
  handleModal: PropTypes.func,
  filterOption: PropTypes.object,
  handelApplyFilter: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};
export default SourceFilterModal;
