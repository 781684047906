// import axios from "axios";
// import { API_URL } from "./constants";
// import keycloak from "./keycloak";
// const instance = axios.create({
//   baseURL: API_URL,
// });

// instance.interceptors.request.use(function (config) {
//   config.headers.Authorization = `Bearer ${keycloak.token}`;
//   return config;
// });
// instance.interceptors.response.use(
//   function (res) {
//     return res;
//   },
//   async (err) => {
//     if (err?.response?.status === 401 && keycloak) {
//       await keycloak.logout();
//       return Promise.reject();
//     }
//     return Promise.reject(err);
//   }
// );
// export default instance;
