import React from 'react'

const InfoIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-2'>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49961 12.9001C10.0342 12.9001 12.8996 10.0347 12.8996 6.5001C12.8996 2.96548 10.0342 0.100098 6.49961 0.100098C2.96499 0.100098 0.0996094 2.96548 0.0996094 6.5001C0.0996094 10.0347 2.96499 12.9001 6.49961 12.9001ZM7.29961 3.3001C7.29961 2.85827 6.94144 2.5001 6.49961 2.5001C6.05778 2.5001 5.69961 2.85827 5.69961 3.3001C5.69961 3.74193 6.05778 4.1001 6.49961 4.1001C6.94144 4.1001 7.29961 3.74193 7.29961 3.3001ZM7.29961 6.1001C7.29961 5.65827 6.94144 5.3001 6.49961 5.3001C6.05778 5.3001 5.69961 5.65827 5.69961 6.1001L5.69961 9.3001C5.69961 9.74193 6.05778 10.1001 6.49961 10.1001C6.94144 10.1001 7.29961 9.74193 7.29961 9.3001V6.1001Z" fill="#9E9E9E"/>
    </svg>
  )
}

export default InfoIcon