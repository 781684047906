import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "primereact/hooks";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { MultiselectHeader } from "./MultiSelectHeader";
import { MultiselectFooter } from "./MultiSelectFooter";
import { CancleAddedSpaceIcon } from "components/Icons";
// import Admin from "models/admin";
import GetUsersList from "hooks/getUsers";
import "./index.css";

const SourceControlAccessModal = ({ source, onClose, visible }) => {
  const [searchText, searchDebouncedValue, setSearchInput] = useDebounce(
    "",
    300
  );
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [preselectedUsers, setPreselectedUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const {getUsers}= GetUsersList();
  const multiSelectRef = useRef(null);
  useEffect(() => {
    async function fetchUsers() {
      const _users = await getUsers();
      setUsersData(_users);
    }
    fetchUsers();
    console.log("source is ", source);
  }, []);

  const getSelectedItemsLabel = (value) => {
    if (value != null) {
      const count = value.length;
      console.log(`${count} ${count === 1 ? "user" : "users"} selected`);
      return `${count} ${count === 1 ? "user" : "users"} selected`;
    }
    return null;
  };

  // const onRemove = (index) => {
  //   const newSelectedUsers = selectedUsers.filter((ele, i) => i !== index);
  //   setSelectedUsers(newSelectedUsers);
  //   setPreselectedUsers(newSelectedUsers);
  //   const newUnSelected = unSelected.map((ele) => {
  //     if (ele.data === selectedUsers[index].data) {
  //       return { ...ele, status: "unchecked" };
  //     } else {
  //       console.log("couldnt find");
  //     }
  //     return ele;
  //   });
  //   setUnSelected(newUnSelected);
  // };

  const handleClosePanel = () => {
    if (multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
  };

  const dropdownInputCss =
    "source-control-modal flex flex-row bg-white border text-black text-sm shadow-inputNormal rounded-lg focus:ring-0 focus:border-2 focus:border-spacing-2 focus:outline-none focus:shadow-inputFocus block w-full p-2.5 mt-5";

  return (
    <Dialog
      header="Control Access"
      visible={visible}
      draggable={false}
      style={{ width: "30vw" }}
      onHide={onClose}
    >
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex flex-col w-full">
          <div className="text-md text-zinc-500">Add Users</div>
          <div className="text-xs leading-3 text-gray-400 mt-1">
            Other users can view and use the shared Structured Sources, but they
            won't be able to edit or delete it.
          </div>
          <MultiSelect
            name="users"
            value={preselectedUsers}
            ref={multiSelectRef}
            onChange={(e) => {
              setPreselectedUsers(e.value);
            }}
            pt={{
              panel: { id: "context-filter-panel" },
            }}
            onShow={() => {
              const zoomLevel = (window.outerWidth / window.innerWidth) * 100;
              const multiselectPanel = document.querySelector(
                ".p-multiselect-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done"
              );
              const multiSelectWrapper = document.querySelector(
                ".p-multiselect-items-wrapper"
              );
              const bottomCornerY =
                document
                  .querySelector('div[class*="p-multiselect-label-container"]')
                  .getBoundingClientRect().top +
                window.pageYOffset +
                document.querySelector(
                  'div[class*="p-multiselect-label-container"]'
                ).offsetHeight;
              if (multiselectPanel) {
                multiselectPanel.style.top = `${bottomCornerY}px`;
                if (zoomLevel >= 110) {
                  multiSelectWrapper.style.height = "60px";
                } else if (zoomLevel >= 100) {
                  multiSelectWrapper.style.height = "100px";
                } else if (zoomLevel >= 90) {
                  multiSelectWrapper.style.height = "110px";
                } else if (zoomLevel >= 80) {
                  multiSelectWrapper.style.height = "160px";
                } else if (zoomLevel >= 70) {
                  multiSelectWrapper.style.height = "200px";
                } else {
                  multiSelectWrapper.style.height = "250px";
                }
              }
            }}
            onHide={() => {
              setPreselectedUsers(selectedUsers);
              getSelectedItemsLabel(selectedUsers);
            }}
            options={usersData.filter((option) =>
              option.firstname.toLowerCase().includes(filterText.toLowerCase())
            )}
            optionLabel={(option) => (
              <div className="flex flex-row items-center">
                <div className="justify-center cursor-pointer px-1.5 py-0.5 rounded-full aspect-square text-blue-700 bg-[#f6f8fe] ml-5 mt-1">
                  {option.firstname.charAt(0).toUpperCase()}
                  {option.lastname.charAt(0).toUpperCase()}
                </div>
                <div className="ml-5">
                  <span>{`${option.firstname} ${option.lastname}`}</span>
                </div>
              </div>
            )}
            filter
            filterTemplate={
              <MultiselectHeader
                filterText={filterText}
                setFilterText={setFilterText}
              />
            }
            panelFooterTemplate={
              <MultiselectFooter
                setPreselectedUsers={setPreselectedUsers}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                preselectedUsers={preselectedUsers}
                handleClosePanel={handleClosePanel}
              />
            }
            filterBy="firstname"
            selectedItemsLabel={getSelectedItemsLabel(preselectedUsers)}
            placeholder="Enter user name or email"
            maxSelectedLabels={0}
            className={dropdownInputCss}
          />
          <div className="flex flex-wrap mt-2">
            {selectedUsers.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className="flex items-center m-0.5 gap-0.5 p-1.5 text-xs leading-5 rounded-md bg-zinc-100 font-[475] text-neutral-600"
                >
                  <div>
                    {elem.firstname} {elem.lastname}{" "}
                  </div>
                  <span
                    className="cursor-pointer"
                    // onClick={() => onRemove(index)}
                  >
                    <CancleAddedSpaceIcon />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

SourceControlAccessModal.propTypes = {
  source: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default SourceControlAccessModal;
