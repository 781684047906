import React from "react";

const DeleteSourceIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 4.50016H14.5M13.1667 4.50016V13.8335C13.1667 14.5002 12.5 15.1668 11.8333 15.1668H5.16667C4.5 15.1668 3.83333 14.5002 3.83333 13.8335V4.50016M5.83333 4.50016V3.16683C5.83333 2.50016 6.5 1.8335 7.16667 1.8335H9.83333C10.5 1.8335 11.1667 2.50016 11.1667 3.16683V4.50016M7.16667 7.8335V11.8335M9.83333 7.8335V11.8335"
        stroke="#e24c4c"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteSourceIcon;
