import React from 'react'

const AlertCritical = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="8" fill="url(#paint0_linear_2254_24736)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9996 32.3996C25.9525 32.3996 32.3996 25.9525 32.3996 17.9996C32.3996 10.0467 25.9525 3.59961 17.9996 3.59961C10.0467 3.59961 3.59961 10.0467 3.59961 17.9996C3.59961 25.9525 10.0467 32.3996 17.9996 32.3996ZM16.1996 17.9996C16.1996 18.9937 17.0055 19.7996 17.9996 19.7996C18.9937 19.7996 19.7996 18.9937 19.7996 17.9996V10.7996C19.7996 9.8055 18.9937 8.99961 17.9996 8.99961C17.0055 8.99961 16.1996 9.8055 16.1996 10.7996V17.9996ZM16.1996 24.2996C16.1996 25.2937 17.0055 26.0996 17.9996 26.0996C18.9937 26.0996 19.7996 25.2937 19.7996 24.2996C19.7996 23.3055 18.9937 22.4996 17.9996 22.4996C17.0055 22.4996 16.1996 23.3055 16.1996 24.2996Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_2254_24736" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DC143C"/>
        <stop offset="1" stop-color="#FF8C00"/>
        </linearGradient>
        </defs>
</svg>
  )
}

export default AlertCritical