import React from "react";
import StreamDataSources from "./StreamDataSources";
import StreamDataSourcesDetails from "./StreamDataSourcesDetails";
import StreamDetailRuleList from "./StreamDetailsRuleList";

// Wrapper function to add a div with id="source-config"
const withSourceConfigWrapper = (Component) => {
  return (props) => (
    <div id="pulse-container">
      <Component {...props} />
    </div>
  );
};

// Wrapped components
const WrappedStreamDataSources = withSourceConfigWrapper(StreamDataSources);
const WrappedStreamDataSourcesDetails = withSourceConfigWrapper(StreamDataSourcesDetails);
const WrappedStreamDetailRuleList = withSourceConfigWrapper(StreamDetailRuleList);

export {
  WrappedStreamDataSources as StreamDataSources,
  WrappedStreamDataSourcesDetails as StreamDataSourcesDetails,
  WrappedStreamDetailRuleList as StreamDetailRuleList,
};