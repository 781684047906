import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "primereact/hooks";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MegaMenu } from "primereact/megamenu";
import { Tooltip } from "primereact/tooltip";
import {
  extractErrorMessage,
  isObjectWithNonEmptyValues,
} from "../StructuredSources/utils";
import { formatDateWithTime, toTitleCase } from "utils/helper";
import SourcePulseBreadcrumb from "components/SourcePulseBreadcrumb";
import { usePulseApi } from "hooks/pulseConfig";
import { useWorkspaceApi } from "hooks/workspaceConfig";
import "./index.css";

const StreamDataSources = () => {
  const [isMobile, setIsMobile] = useState(false); // Handle mobile responsiveness
  const [loading, setLoading] = useState(false); // Simulate loading state
  const [innerLoading, setInnerLoading] = useState(false); // Inner loading (e.g., for modals)
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10); // Items per page
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewSource, setViewSource] = useState(null);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isFilerModalOpen, setFilterModal] = useState(false);
  const [isControlModalOpen, setControlModal] = useState(false);
  const [isUsingInModalOpen, setUsingInModal] = useState(false);
  const [error, setError] = useState(null);
  const [sourceData, setSourceData] = useState({});
  const [sortField, setSortField] = useState("updatedAt desc");
  const [searchText, searchDebouncedValue, setSearchInput] = useDebounce(
    "",
    300
  );
  const [filterOption, setFilterOption] = useState(null);

  const toast = useRef(null);
  const navigate = useNavigate();
  const sortFieldSplit = sortField.split(" ");
  const tableSortField = sortField ? sortFieldSplit[0] : "";
  const tableSortOrder = sortField ? (sortFieldSplit[1] === "asc" ? 1 : -1) : 0;
  const { getPulseSourceList } = usePulseApi();
  const { getTenantId } = useWorkspaceApi();

  useEffect(() => {
    const initData = async () => {
      try {
        setInnerLoading(true);
        const tenantId = await getTenantId();  // Wait for tenantId
        console.log("tenantId", tenantId);
        await fetchData();  // Wait for data fetch
      } catch (error) {
        console.error("Error initializing data:", error);
      } finally {
        setInnerLoading(false);
      }
    };

    initData();  // Call the async function
  }, [page, limit, searchDebouncedValue]);

  // useEffect(() => {
  //   localStorage.setItem("sourceDetails", null);
  //   localStorage.setItem("fieldsMapping", null);
  // }, []);


  // useEffect(()=>{
  //   const res = await getTenantId();
  // },[])
  // Pagination change handler
  const onPageChange = (event) => {
    setPage(event.first);
    setLimit(event.rows);
    // Add logic to fetch more data here if needed
  };

  // Sorting handler
  const onSort = (event) => {
    setSortField(
      `${event.sortField} ${event.sortOrder === 1 ? "asc" : "desc"}`
    );
  };
  const handleViewDetails = (rowData) => {
    navigate(`/pulse/stream-source/${rowData?.name}`, {
      state: { source: rowData },
    });
  };

  // Handle source deletion
  const handleDeleteSourceId = () => {
    // Logic for deleting the source
    setDeleteModal(false); // Close modal after deleting
    toast.current.show({
      severity: "success",
      summary: "Deleted",
      detail: "Source deleted successfully",
    });
  };

  const fetchData = async (data) => {
    try {
      const payload = {
        page: page / limit + 1,
        limit: limit,
        search: searchDebouncedValue || "",
        sort: sortField,
      };
      const response = await getPulseSourceList(payload); // Replaced mock API with actual API call
      setSourceData(response);
      setFilterModal(false);
      const isFilter = isObjectWithNonEmptyValues(data);
      if (isFilter) {
        setFilterOption(data);
        // setIsFilterApply(true);
      }
    } catch (error) {
      if (isFilerModalOpen) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: extractErrorMessage(error),
          life: 3000,
        });
        return;
      }
      setError(error);
    } finally {
      setInnerLoading(false);
      setLoading(false);
    }
  };

  // Table header
  const header = (
    <div className="flex flex-wrap align-items-center justify-end gap-2 border-t-2 border-[#e5e7eb] py-3 bg-[#F8F9FA]">
      <div className="flex align-items-center justify-center mr-[1vw]" style={{ border: '1px solid #ced4da', backgroundColor: 'white', borderRadius: '5px' }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search pl-3 font-extralight text-[#6C757D] opacity-80" />
        </span>
        <InputText
          type="search"
          className="w-[270px] pl-10 pr-5 pt-3 pb-3 placeholder:font-normal text-[#ced4da] ml-5 pulse-search-component border-0"
          placeholder="Search Source"
          value={searchText}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
    </div>
  );

  const start = (
    <div className="inline-flex align-items-center">
      {/* <span className="pi pi-database source-icon" /> */}
      <span className="font-semibold text-xl text-[#191A1C] mt-2">
        Stream Data Sources ({sourceData?.total || 0})
      </span>
    </div>
  );

  const megaMenu = () => {
    return (
      <MegaMenu
        start={start}
        // end={newSourceSetup}
        className="bg-transparent border-none border-noround pb-3"
      />
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case "in-progress":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            In Progress
          </span>
        );
      case "active":
      case "running":
      case "created":
      case "updating":
        return (
          <span className="border-2 border-[#326FD1] text-[#326FD1] bg-[#f5f9ff] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      case "inactive":
      case "pending":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      case "error":
      case "stopped":
        return (
          <div className="flex flex-row max-w-20 items-baseline border-2 border-[#B32B23] text-[#B32B23] bg-[#fff6f5] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {toTitleCase(status)}
            <div
              className="inactive-notification"
              data-pr-tooltip="This source has encountered an error"
              data-pr-at="left-0 top-25"
              data-pr-my="left center-2"
              data-pr-classname="custom-tooltip-inactive"
            >
              {status === "error" && (
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-[5px] ml-1.5 cursor-pointer"
                >
                  <path
                    d="M5 5L5 7.25M5 3.33228V3.3125M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5Z"
                    stroke="#BC1C1C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
            <Tooltip target=".inactive-notification" />
          </div>
        );
      case "completed":
        return (
          <span className="border-2 bg-green-50 border-green-600 text-green-600 pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      default:
        return status;
    }
  };

  const sanitizeId = (id) => id.replace(/[^a-zA-Z0-9-_]/g, "");

  // Table columns
  const columns = [
    { field: "name", header: "Name", sortable: true },
    {
      field: "baseUrl",
      header: "Base URL",
      body: (rowData) => {
        const sanitizedId = sanitizeId(rowData.baseUrl);

        return (
          <>
            <a
              id={`tooltip-${sanitizedId}`} // Associate tooltip with this ID
              href={rowData.baseUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block max-w-[200px] truncate underline"
            >
              {rowData.baseUrl}
            </a>
            <Tooltip
              target={`#tooltip-${sanitizedId}`}
              content={rowData.baseUrl}
              className=" p-2 rounded-lg"
            />
          </>
        );
      },
    },
    {
      field: "createdAt",
      header: "Created At",
      body: (rowData) => new Date(rowData.createdAt).toLocaleDateString(),
      sortable: true,
    },
    // {
    //   field: "status",
    //   header: "Status",
    //   sortable: true,
    //   body: (rowData) => {
    //     return renderStatus(rowData.status);
    //   },
    // },
    {
      header: "View Details",
      body: (rowData) => {
        return (
          <span className="p-4">
            <Button
              label={<i className="pi pi-eye" />}
              type="button"
              className="bg-white p-3 text-xs text-[#3B82F6] mr-5 pulse-view-button"
              onClick={() => handleViewDetails(rowData)}
            />
          </span>
        );
      },
    },
  ];

  return (
    <div className="new-source-setup">
      <div className="w-full h-screen overflow-hidden bg-sidebar flex">
        {/* {!isMobile && <PrimaryNav />} */}
        {/* {!isMobile && <Sidebar />} */}
        {loading ? (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="transition-all duration-500 relative md:ml-[2px] md:mr-[8px] md:my-[16px] md:rounded-[26px] bg-main-gradient p-[18px] h-full overflow-y-scroll animate-pulse "
          >
            <div className="w-full h-full flex justify-center items-center">
              {/* <PreLoader /> */}
            </div>
          </div>
        ) : (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="flex flex-col items-around transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full overflow-y-scroll"
          >
            {/* <SourcePulseBreadcrumb routes={["Pulse", "Stream Data Sources"]} /> */}
            {/* <div
              style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
            ></div> */}
            <div className="source-list">
              {/* <Menu
                  model={getMenuItems()}
                  // onHide={() => {
                  //   setSelectedRow(null);
                  // }}
                  popup
                  ref={menu}
                  id="popup_menu_left"
              /> */}
              {megaMenu()}

              {/* {isFilerModalOpen && (
                <SourceFilterModal
                  handelApplyFilter={() => {}} // Placeholder function
                  loading={innerLoading}
                  filterOption={{}} // Add your filter logic here
                  handleModal={() => setFilterModal(!isFilerModalOpen)}
                  filters={[]} // Pass actual filter data
                  setFilters={() => {}} // Add function to set filters
                />
              )} */}
              {/* {isControlModalOpen && (
                <SourceControlAccessModal
                  source={selectedRow}
                  onClose={() => setControlModal(false)}
                  visible={isControlModalOpen}
                />
              )} */}
              {/* {isUsingInModalOpen && (
                <SourceUsingInModal
                  source={selectedRow}
                  onClose={() => setUsingInModal(false)}
                  visible={isUsingInModalOpen}
                />
              )} */}
              {/* {isDeleteModalOpen && (
                <SourceDeleteModal
                  source={selectedRow}
                  onClose={() => setDeleteModal(false)}
                  visible={isDeleteModalOpen}
                  onAccept={handleDeleteSourceId}
                />
              )} */}
              {/* {viewSource && (
                <SourceViewDialog
                  source={viewSource}
                  onHide={() => {
                    setViewSource(null);
                  }}
                  visible={viewSource !== null}
                />
              )} */}
              <div className="mt-3 sticky-table-header">
                {header}

                <DataTable
                  scrollable
                  scrollHeight="calc(100vh - 45vh)"
                  value={sourceData?.items} // Accessing rows from sourceData
                  // header={header}
                  sortField={tableSortField} // Initial sort field
                  sortOrder={tableSortOrder} // Default sort order (1 = ascending, -1 = descending)
                  onSort={onSort}
                  tableStyle={{
                    minWidth: "50rem",
                  }}
                  loading={innerLoading}
                  className="stream-data-sources border-t-2 border-[#e5e7eb]"
                  emptyMessage={
                    "Data not found." // Display this message when no data is found
                  }
                >
                  {columns.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      body={col.body}
                      className=" border-b-2"
                      style={col.style}
                      headerClassName="border-b-2"
                      sortable={col.sortable}
                    />
                  ))}
                </DataTable>
                <Paginator
                  first={page}
                  rows={limit}
                  template={{
                    layout:
                      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
                  }}
                  totalRecords={sourceData.total}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageChange={onPageChange}
                  className="justify-end"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                />
                <Toast ref={toast} position="top-right" />
              </div>
            </div>
            <Divider />
          </div>
        )}
      </div>
    </div>
  );
};

export default StreamDataSources;
