import React from 'react'

const ActivityComment = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#F2F4F7"/>
        <path d="M14 14.5C14 13.8022 14 13.4533 13.9139 13.1694C13.72 12.5302 13.2198 12.03 12.5806 11.8361C12.2967 11.75 11.9478 11.75 11.25 11.75H8.75C8.05222 11.75 7.70333 11.75 7.41943 11.8361C6.78023 12.03 6.28002 12.5302 6.08612 13.1694C6 13.4533 6 13.8022 6 14.5M12.25 7.75C12.25 8.99264 11.2426 10 10 10C8.75736 10 7.75 8.99264 7.75 7.75C7.75 6.50736 8.75736 5.5 10 5.5C11.2426 5.5 12.25 6.50736 12.25 7.75Z" stroke="#475467" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export default ActivityComment