import React from "react";

const ActivityNeio = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FFE5EC" />
      <rect x="2" y="2" width="20" height="20" rx="10" fill="#EB3568" />
      <path
        d="M15.6025 9.47578C15.6856 9.64627 15.6427 9.78641 15.5221 9.73917C15.3668 9.6782 15.2058 9.33538 15.3334 9.26328C15.4306 9.23148 15.5515 9.37123 15.6025 9.47578Z"
        fill="white"
      />
      <path
        d="M15.5962 10.0244C15.6657 10.2042 15.5986 10.3518 15.4702 10.3014C15.305 10.2367 15.1782 9.8797 15.3335 9.80617C15.4446 9.77529 15.554 9.91516 15.5962 10.0244Z"
        fill="white"
      />
      <path
        d="M15.1225 9.13047C15.21 9.28749 15.1771 9.42304 15.0585 9.39413C14.8815 9.35095 14.71 9.00812 14.8292 8.9355C14.8323 8.93354 14.8371 8.93171 14.8405 8.93001C14.9413 8.90266 15.0684 9.03351 15.1225 9.13047Z"
        fill="white"
      />
      <path
        d="M16.033 9.808C16.113 9.98595 16.0777 10.1363 15.9665 10.0901C15.8254 10.0315 15.6777 9.68186 15.7931 9.60374C15.8785 9.56894 15.9868 9.70502 16.033 9.808Z"
        fill="white"
      />
      <path
        d="M15.1173 9.66341C15.1934 9.84137 15.1226 9.98268 14.9844 9.92864C14.8065 9.8589 14.6718 9.51739 14.8384 9.45C14.9562 9.42265 15.0725 9.55834 15.1173 9.66341Z"
        fill="white"
      />
      <path
        d="M15.5164 8.93668C15.6143 9.08847 15.6108 9.22389 15.5093 9.20008C15.3685 9.16697 15.1458 8.82376 15.2532 8.74826C15.3309 8.71751 15.4538 8.83999 15.5164 8.93668Z"
        fill="white"
      />
      <path
        d="M15.0412 8.62317C15.1383 8.76161 15.1394 8.88631 15.0432 8.87505C14.8826 8.85621 14.6545 8.51888 14.7527 8.44535C14.7553 8.44338 14.7593 8.44155 14.762 8.43985C14.8465 8.41237 14.9762 8.53053 15.0412 8.62317Z"
        fill="white"
      />
      <path
        d="M16.0308 10.3829C16.0932 10.5689 16.0311 10.722 15.9142 10.6703C15.7638 10.6037 15.6526 10.2466 15.7948 10.168C15.8961 10.1333 15.9938 10.2728 16.0308 10.3829Z"
        fill="white"
      />
      <path
        d="M15.951 9.25569C16.0401 9.41153 16.0397 9.55232 15.9502 9.52955C15.8243 9.4975 15.6186 9.14512 15.7123 9.06517C15.7819 9.03037 15.8938 9.15559 15.951 9.25569Z"
        fill="white"
      />
      <path
        d="M15.5077 10.5983C15.5637 10.7947 15.4533 10.9465 15.3108 10.8689C15.1454 10.7789 15.0744 10.4413 15.2483 10.3718C15.3627 10.3453 15.47 10.4662 15.5077 10.5983Z"
        fill="white"
      />
      <path
        d="M14.6182 8.79929C14.7076 8.94898 14.6732 9.07237 14.55 9.04358C14.3716 9.00224 14.1955 8.67132 14.3173 8.60655C14.3205 8.60485 14.3252 8.60328 14.3286 8.60184C14.422 8.58117 14.5516 8.6882 14.6182 8.79929Z"
        fill="white"
      />
      <path
        d="M14.6104 9.30973C14.687 9.47735 14.6132 9.60806 14.4719 9.5552C14.2854 9.48546 14.1704 9.16357 14.3099 9.10194C14.314 9.10024 14.3197 9.09854 14.3238 9.0971C14.444 9.07446 14.5637 9.20767 14.6104 9.30973Z"
        fill="white"
      />
      <path
        d="M15.0267 10.2194C15.0863 10.4089 14.9719 10.5538 14.8231 10.4779C14.6497 10.3895 14.5698 10.0562 14.7497 9.99459C14.8841 9.96868 14.9926 10.1105 15.0267 10.2194Z"
        fill="white"
      />
      <path
        d="M16.4129 10.1487C16.4813 10.3264 16.4519 10.4802 16.3575 10.4372C16.2345 10.3812 16.1047 10.0274 16.2048 9.94395C16.2784 9.9064 16.3726 10.0439 16.4129 10.1487Z"
        fill="white"
      />
      <path
        d="M14.541 8.31973C14.6485 8.4634 14.627 8.56154 14.5368 8.55016C14.3771 8.53014 14.1431 8.2055 14.2421 8.14152C14.2447 8.13995 14.2487 8.13838 14.2516 8.13694C14.3394 8.11378 14.4737 8.22997 14.541 8.31973Z"
        fill="white"
      />
      <path
        d="M15.9456 10.9797C15.9909 11.1795 15.8893 11.3328 15.7616 11.2578C15.6109 11.1693 15.5503 10.8267 15.7109 10.7525C15.8172 10.7225 15.9155 10.8471 15.9456 10.9797Z"
        fill="white"
      />
      <path
        d="M15.3582 8.44365C15.4634 8.57646 15.4874 8.69579 15.4075 8.68925C15.2927 8.67983 15.0102 8.34643 15.0953 8.27014C15.1521 8.24279 15.2737 8.33687 15.3582 8.44365Z"
        fill="white"
      />
      <path
        d="M16.4108 10.7419C16.4623 10.9265 16.4066 11.0829 16.306 11.0354C16.1727 10.9723 16.0785 10.6121 16.2067 10.5277C16.2958 10.4904 16.3797 10.6305 16.4108 10.7419Z"
        fill="white"
      />
      <path
        d="M14.8812 8.15238C14.992 8.2793 15.0157 8.39523 14.9289 8.3866C14.7905 8.37286 14.5275 8.06 14.598 7.99261C14.5997 7.99091 14.6026 7.98921 14.6046 7.98764C14.6731 7.96134 14.8075 8.06811 14.8812 8.15238Z"
        fill="white"
      />
      <path
        d="M15.7945 8.74119C15.8959 8.88539 15.9158 9.0148 15.8333 8.99308C15.7181 8.96259 15.4866 8.64031 15.5541 8.56259C15.6087 8.52883 15.7298 8.64921 15.7945 8.74119Z"
        fill="white"
      />
      <path
        d="M16.3305 9.57443C16.41 9.73171 16.4135 9.87682 16.3373 9.85654C16.2293 9.82802 16.0454 9.47054 16.1234 9.38431C16.1811 9.34715 16.2793 9.47316 16.3305 9.57443Z"
        fill="white"
      />
      <path
        d="M14.5137 9.83652C14.5792 10.0227 14.4629 10.1612 14.3061 10.0833C14.1156 9.98883 14.0543 9.68317 14.2137 9.62272C14.2186 9.62089 14.2255 9.61932 14.2305 9.61775C14.3652 9.59642 14.4772 9.73289 14.5137 9.83652Z"
        fill="white"
      />
      <path
        d="M15.3338 11.1836C15.3745 11.396 15.2249 11.547 15.0758 11.444C14.9202 11.3367 14.8961 11.0161 15.0793 10.9544C15.2192 10.9269 15.3123 11.0719 15.3338 11.1836Z"
        fill="white"
      />
      <path
        d="M14.3801 7.87328C14.4828 7.98254 14.5097 8.08761 14.4355 8.08957C14.3048 8.09284 14.0113 7.77972 14.0865 7.71718C14.0885 7.71561 14.0916 7.71404 14.0938 7.71286C14.1624 7.69166 14.3041 7.79242 14.3801 7.87328Z"
        fill="white"
      />
      <path
        d="M16.3263 11.361C16.3589 11.5624 16.2632 11.7126 16.1525 11.6335C16.0247 11.5419 15.9855 11.2045 16.1249 11.129C16.2208 11.0963 16.3041 11.2239 16.3263 11.361Z"
        fill="white"
      />
      <path
        d="M14.0995 8.47701C14.1839 8.61781 14.1435 8.72916 14.0206 8.6937C13.8408 8.64175 13.676 8.33884 13.7974 8.28336C13.8005 8.28192 13.805 8.28061 13.8083 8.27943C13.9046 8.26229 14.0344 8.36854 14.0995 8.47701Z"
        fill="white"
      />
      <path
        d="M14.8496 10.789C14.8954 10.9952 14.735 11.1385 14.5769 11.0323C14.4118 10.9213 14.3845 10.6134 14.578 10.5564C14.7091 10.5356 14.8211 10.661 14.8496 10.789Z"
        fill="white"
      />
      <path
        d="M15.7736 11.5785C15.8065 11.7889 15.6628 11.9479 15.5296 11.8484C15.3894 11.7436 15.3758 11.4189 15.5463 11.3515C15.6739 11.3211 15.756 11.466 15.7736 11.5785Z"
        fill="white"
      />
      <path
        d="M16.1722 9.03534C16.2649 9.18202 16.2907 9.3185 16.2223 9.3007C16.1268 9.27597 15.9132 8.94087 15.9644 8.85739C16.0028 8.82494 16.1021 8.92438 16.1722 9.03534Z"
        fill="white"
      />
      <path
        d="M14.0187 8.01473C14.1174 8.13733 14.1148 8.24384 14.0133 8.22971C13.855 8.20773 13.624 7.90324 13.7227 7.84646C13.7253 7.84502 13.7291 7.84371 13.7318 7.84253C13.8074 7.82657 13.9411 7.91868 14.0187 8.01473Z"
        fill="white"
      />
      <path
        d="M14.0834 8.95278C14.1615 9.11267 14.0872 9.23528 13.9438 9.18307C13.7565 9.11463 13.6429 8.8091 13.7839 8.75297C13.788 8.75127 13.7937 8.74983 13.7978 8.74865C13.9047 8.7323 14.0292 8.84195 14.0834 8.95278Z"
        fill="white"
      />
      <path
        d="M15.1207 7.976C15.2335 8.09271 15.2789 8.20576 15.2107 8.20027C15.1114 8.19203 14.8048 7.89199 14.8617 7.82303C14.9099 7.79438 15.0447 7.89736 15.1207 7.976Z"
        fill="white"
      />
      <path
        d="M16.7377 10.4989C16.7927 10.6768 16.7691 10.8301 16.6934 10.7866C16.5925 10.7286 16.4869 10.3797 16.568 10.2937C16.6288 10.2531 16.7038 10.3894 16.7377 10.4989Z"
        fill="white"
      />
      <path
        d="M14.6443 7.71351C14.7576 7.81859 14.805 7.91921 14.7441 7.9247C14.6365 7.93412 14.3186 7.63723 14.3663 7.57167C14.3675 7.5701 14.3697 7.5684 14.3712 7.5671C14.4189 7.54511 14.55 7.62611 14.6443 7.71351Z"
        fill="white"
      />
      <path
        d="M16.7362 11.1166C16.7721 11.3016 16.7211 11.4513 16.6389 11.4026C16.5324 11.3396 16.4623 10.9796 16.5671 10.8953C16.638 10.8588 16.7101 10.9818 16.7362 11.1166Z"
        fill="white"
      />
      <path
        d="M13.9829 9.4606C14.0473 9.63934 13.9322 9.76416 13.7742 9.68696C13.587 9.59576 13.5273 9.3007 13.6852 9.24862C13.69 9.24705 13.6967 9.24574 13.7016 9.24444C13.8433 9.22677 13.9494 9.3677 13.9829 9.4606Z"
        fill="white"
      />
      <path
        d="M14.3315 10.3869C14.3805 10.5873 14.2171 10.7247 14.0539 10.6202C13.8852 10.5121 13.8541 10.2093 14.0498 10.1596C14.201 10.14 14.3055 10.2808 14.3315 10.3869Z"
        fill="white"
      />
      <path
        d="M16.6552 9.90208C16.7239 10.0621 16.7317 10.2106 16.6702 10.191C16.5841 10.1634 16.4271 9.80237 16.4853 9.71353C16.5292 9.67453 16.6127 9.8029 16.6552 9.90208Z"
        fill="white"
      />
      <path
        d="M15.554 8.24345C15.6621 8.36684 15.7097 8.48473 15.6498 8.48133C15.563 8.47649 15.2757 8.16402 15.3203 8.08709C15.3555 8.0583 15.4668 8.144 15.554 8.24345Z"
        fill="white"
      />
      <path
        d="M13.8636 7.60295C13.9665 7.70566 13.9901 7.79621 13.914 7.7966C13.7707 7.79726 13.5172 7.51633 13.5639 7.45574C13.5665 7.45221 13.572 7.44907 13.576 7.44711C13.6391 7.43141 13.7757 7.51502 13.8636 7.60295Z"
        fill="white"
      />
      <path
        d="M16.1537 11.9693C16.1745 12.1809 16.0436 12.3395 15.9278 12.2428C15.8053 12.1406 15.8038 11.8155 15.9591 11.7447C16.0748 11.7116 16.1425 11.8562 16.1537 11.9693Z"
        fill="white"
      />
      <path
        d="M14.1419 7.46202C14.2547 7.55885 14.3017 7.65306 14.2383 7.65581C14.1248 7.66091 13.8023 7.38352 13.8548 7.32607C13.8561 7.32463 13.8583 7.32333 13.8599 7.32215C13.9112 7.30331 14.0461 7.38011 14.1419 7.46202Z"
        fill="white"
      />
      <path
        d="M16.646 11.7287C16.6695 11.9246 16.5884 12.0863 16.4989 12.0221C16.3887 11.943 16.3592 11.5946 16.4846 11.5131C16.5785 11.473 16.6335 11.624 16.646 11.7287Z"
        fill="white"
      />
      <path
        d="M16.4978 9.3444C16.5781 9.49266 16.6079 9.63175 16.5559 9.61513C16.4812 9.59131 16.2935 9.2515 16.3263 9.16514C16.3534 9.13034 16.4365 9.23109 16.4978 9.3444Z"
        fill="white"
      />
      <path
        d="M13.5691 8.14793C13.6527 8.27891 13.6161 8.38359 13.4981 8.3514C13.3332 8.30639 13.1708 8.03815 13.2695 7.97364C13.2753 7.96985 13.2843 7.96684 13.2909 7.96474C13.3918 7.95022 13.5165 8.06536 13.5691 8.14793Z"
        fill="white"
      />
      <path
        d="M15.0783 11.7884C15.0968 12.0107 14.906 12.1477 14.7593 12.0175C14.6175 11.8916 14.6347 11.6043 14.8302 11.548C14.9855 11.5228 15.0688 11.6734 15.0783 11.7884Z"
        fill="white"
      />
      <path
        d="M15.9389 8.52307C16.036 8.64973 16.0826 8.76736 16.0345 8.76527C15.9639 8.76213 15.7016 8.43972 15.7302 8.35755C15.7593 8.32274 15.8719 8.43566 15.9389 8.52307Z"
        fill="white"
      />
      <path
        d="M14.5878 11.3672C14.6175 11.5856 14.4174 11.7235 14.2585 11.5943C14.1068 11.4708 14.1182 11.1835 14.3219 11.1326C14.4805 11.1118 14.573 11.2573 14.5878 11.3672Z"
        fill="white"
      />
      <path
        d="M13.5519 8.60171C13.6265 8.75323 13.5527 8.86537 13.4134 8.81211C13.2326 8.74316 13.1256 8.45712 13.2628 8.40936C13.2667 8.40806 13.2723 8.40688 13.2764 8.40583C13.3931 8.39183 13.5078 8.51195 13.5519 8.60171Z"
        fill="white"
      />
      <path
        d="M13.7934 9.98438C13.8444 10.1787 13.6841 10.3064 13.5171 10.2047C13.3417 10.0981 13.3097 9.80643 13.5135 9.76181C13.6626 9.74676 13.7669 9.88297 13.7934 9.98438Z"
        fill="white"
      />
      <path
        d="M15.5206 12.192C15.5343 12.4131 15.3526 12.5627 15.2216 12.4383C15.0945 12.3177 15.118 12.0219 15.2988 11.9612C15.4402 11.9331 15.5135 12.0793 15.5206 12.192Z"
        fill="white"
      />
      <path
        d="M13.4928 7.71849C13.5828 7.825 13.5881 7.91908 13.504 7.91581C13.3644 7.91044 13.1238 7.63187 13.2013 7.56526C13.2054 7.5616 13.2125 7.55885 13.2177 7.55689C13.2921 7.54393 13.4185 7.63069 13.4928 7.71862V7.71849Z"
        fill="white"
      />
      <path
        d="M14.3358 7.31456C14.4537 7.4038 14.5197 7.49591 14.469 7.50062C14.3958 7.50743 14.0208 7.2528 14.0677 7.18947C14.1064 7.16801 14.2481 7.24835 14.3358 7.31456Z"
        fill="white"
      />
      <path
        d="M14.0641 10.9507C14.0969 11.1641 13.8909 11.2934 13.725 11.1633C13.5674 11.0399 13.5775 10.7602 13.7875 10.7146C13.9508 10.6984 14.0475 10.843 14.0641 10.9507Z"
        fill="white"
      />
      <path
        d="M13.447 9.08676C13.5055 9.25242 13.3869 9.3673 13.2354 9.29167C13.0554 9.20191 13.0019 8.9236 13.1561 8.87832C13.1606 8.87688 13.1667 8.87584 13.1714 8.87492C13.2914 8.8638 13.408 8.9762 13.447 9.08676Z"
        fill="white"
      />
      <path
        d="M14.8134 7.54747C14.9269 7.64299 14.9919 7.74021 14.9472 7.7474C14.9243 7.7512 14.8471 7.72464 14.7037 7.60151C14.702 7.60007 14.5369 7.45679 14.5589 7.41426C14.5893 7.3915 14.715 7.46477 14.8134 7.54747Z"
        fill="white"
      />
      <path
        d="M15.2484 7.78862C15.3637 7.89762 15.429 8.00544 15.3778 8.00216C15.3045 7.99759 14.9976 7.71914 15.0177 7.65084C15.0431 7.62231 15.1681 7.71247 15.2484 7.78862Z"
        fill="white"
      />
      <path
        d="M16.9125 10.237C16.9684 10.3981 16.9809 10.5472 16.937 10.527C16.8736 10.4981 16.7467 10.1414 16.7838 10.0493C16.8135 10.0085 16.8769 10.1345 16.9125 10.237Z"
        fill="white"
      />
      <path
        d="M16.9941 10.8539C17.0354 11.0308 17.0188 11.1814 16.9621 11.1416C16.8872 11.0889 16.8053 10.7354 16.8648 10.6482C16.906 10.6106 16.964 10.7256 16.9941 10.8539Z"
        fill="white"
      />
      <path
        d="M16.9871 11.4719C17.0136 11.655 16.9725 11.8142 16.9099 11.7705C16.8265 11.7118 16.7784 11.3553 16.8636 11.2666C16.9226 11.2263 16.9714 11.3622 16.9871 11.4719Z"
        fill="white"
      />
      <path
        d="M13.6231 7.22009C13.7274 7.30278 13.775 7.38352 13.725 7.39307C13.6332 7.41034 13.3028 7.1599 13.3323 7.09513C13.3336 7.09212 13.3376 7.08937 13.3403 7.08741C13.3903 7.07314 13.5294 7.14576 13.6231 7.22009Z"
        fill="white"
      />
      <path
        d="M16.4739 12.3629C16.4805 12.5765 16.3634 12.7318 16.2686 12.6352C16.1678 12.5328 16.1816 12.2156 16.3192 12.1418C16.4144 12.1086 16.47 12.2309 16.4739 12.3629Z"
        fill="white"
      />
      <path
        d="M13.3368 7.33484C13.4481 7.4386 13.457 7.51371 13.3905 7.51148C13.2531 7.50691 13.0071 7.25018 13.0513 7.19863C13.0539 7.19549 13.0591 7.193 13.0628 7.1913C13.1237 7.17913 13.2525 7.25633 13.3368 7.33484Z"
        fill="white"
      />
      <path
        d="M15.9019 12.5989C15.9005 12.8093 15.7486 12.9569 15.6352 12.8578C15.513 12.7509 15.5387 12.4352 15.7116 12.368C15.8426 12.3366 15.9025 12.4872 15.9019 12.5989Z"
        fill="white"
      />
      <path
        d="M16.2618 8.80557C16.3537 8.94231 16.4038 9.07132 16.359 9.0568C16.3002 9.03783 16.0838 8.72078 16.0916 8.6411C16.1054 8.60891 16.1908 8.70024 16.2618 8.80557Z"
        fill="white"
      />
      <path
        d="M13.8308 7.0929C13.9594 7.18044 14.0068 7.25018 13.9704 7.26065C13.8927 7.28263 13.5212 7.0318 13.5518 6.97815C13.5525 6.97684 13.5541 6.97566 13.5551 6.97462C13.5939 6.95813 13.7284 7.02316 13.8308 7.0929Z"
        fill="white"
      />
      <path
        d="M16.8968 12.1097C16.9066 12.3051 16.8312 12.4681 16.7624 12.4005C16.68 12.3195 16.674 11.9807 16.7767 11.899C16.8502 11.8598 16.8912 11.9987 16.8968 12.1097Z"
        fill="white"
      />
      <path
        d="M16.7558 9.66158C16.8235 9.81114 16.8571 9.95049 16.8211 9.93283C16.7706 9.9081 16.6134 9.56685 16.6251 9.4814C16.6397 9.44267 16.7038 9.55442 16.7356 9.61905C16.7419 9.63162 16.75 9.64876 16.7558 9.66158Z"
        fill="white"
      />
      <path
        d="M15.6268 8.03462C15.7328 8.14741 15.8012 8.26138 15.7624 8.26059C15.7102 8.25967 15.4232 7.96906 15.4279 7.89513C15.4418 7.86569 15.5419 7.94459 15.6268 8.03462Z"
        fill="white"
      />
      <path
        d="M13.2488 9.58935C13.2923 9.77018 13.1286 9.88454 12.971 9.78327C12.8005 9.67388 12.7936 9.41179 12.9602 9.37097C12.9655 9.36966 12.9728 9.36861 12.9782 9.3677C13.134 9.35644 13.2268 9.49763 13.2488 9.58935Z"
        fill="white"
      />
      <path
        d="M13.5179 10.5343C13.5447 10.7387 13.3356 10.8547 13.1736 10.7251C13.0333 10.613 13.0184 10.3368 13.2389 10.2967C13.4082 10.2842 13.504 10.4281 13.5179 10.5343Z"
        fill="white"
      />
      <path
        d="M14.7475 12.3811C14.7515 12.5965 14.5421 12.7298 14.3974 12.604C14.2559 12.481 14.2951 12.1927 14.5055 12.1404C14.6688 12.1188 14.7453 12.2682 14.7475 12.3811Z"
        fill="white"
      />
      <path
        d="M13.0571 7.83546C13.1386 7.96056 13.0945 8.03802 13.0021 8.02114C12.8508 7.9934 12.6802 7.72673 12.7766 7.6689C12.7818 7.66589 12.7899 7.66353 12.7959 7.66196C12.8814 7.65254 12.998 7.74466 13.0571 7.83546Z"
        fill="white"
      />
      <path
        d="M13.0351 8.26072C13.1087 8.4112 13.0211 8.49677 12.902 8.45097C12.737 8.38738 12.6362 8.1389 12.7523 8.08172C12.7593 8.07819 12.7697 8.0757 12.7773 8.07387C12.8871 8.06379 12.9941 8.17724 13.0351 8.26072Z"
        fill="white"
      />
      <path
        d="M15.1938 12.8048C15.1865 13.0244 14.982 13.1597 14.8571 13.0275C14.7367 12.9005 14.7854 12.6229 14.9788 12.5678C15.1333 12.5426 15.1975 12.6912 15.1938 12.8048Z"
        fill="white"
      />
      <path
        d="M16.22 13.0036C16.2067 13.2049 16.0678 13.3525 15.9764 13.2623C15.8755 13.1628 15.9168 12.8459 16.0708 12.7763C16.1864 12.743 16.2276 12.8892 16.22 13.0036Z"
        fill="white"
      />
      <path
        d="M14.2513 11.9457C14.2645 12.1634 14.042 12.2952 13.8849 12.1625C13.7373 12.038 13.7733 11.7557 13.9893 11.7096C14.1588 11.6917 14.2446 11.8369 14.2513 11.9457Z"
        fill="white"
      />
      <path
        d="M13.0983 6.98678C13.2029 7.06294 13.2494 7.13333 13.1999 7.14053C13.1088 7.15375 12.7721 6.91037 12.8206 6.8664C12.822 6.86536 12.824 6.86457 12.8255 6.86366C12.8728 6.8528 13.0047 6.91848 13.0983 6.98678Z"
        fill="white"
      />
      <path
        d="M12.9841 7.43703C13.0683 7.53386 13.0728 7.61721 12.9935 7.61185C12.8436 7.60164 12.6474 7.33994 12.7111 7.29114C12.7147 7.28826 12.721 7.28616 12.7257 7.28473C12.8027 7.27426 12.9255 7.36938 12.9841 7.43703Z"
        fill="white"
      />
      <path
        d="M16.7211 12.7544C16.7134 12.9447 16.6169 13.1008 16.546 13.0377C16.4592 12.9603 16.4837 12.6191 16.6076 12.5395C16.6855 12.5058 16.7265 12.6224 16.7211 12.7544Z"
        fill="white"
      />
      <path
        d="M13.3136 6.88485C13.4248 6.95433 13.487 7.02303 13.4467 7.0318C13.3752 7.04737 13.0013 6.81694 13.03 6.77507C13.0307 6.77403 13.0323 6.77311 13.0331 6.77232C13.0719 6.75963 13.2121 6.82139 13.3136 6.88485Z"
        fill="white"
      />
      <path
        d="M13.7183 11.5116C13.7332 11.7209 13.5075 11.8452 13.3468 11.7163C13.1905 11.5911 13.2225 11.3146 13.4467 11.2738C13.6213 11.2602 13.7106 11.4046 13.7183 11.5116Z"
        fill="white"
      />
      <path
        d="M12.9232 8.71856C12.9751 8.87662 12.8584 8.97934 12.7152 8.90175C12.5465 8.81028 12.503 8.55578 12.6499 8.51823C12.6542 8.51705 12.6604 8.51614 12.6648 8.51535C12.7926 8.50658 12.8945 8.63141 12.9232 8.71856Z"
        fill="white"
      />
      <path
        d="M14.4447 7.16369C14.563 7.24534 14.6403 7.32908 14.6038 7.33602C14.5544 7.34531 14.1819 7.11044 14.1949 7.05064C14.2155 7.02996 14.3431 7.09356 14.4447 7.16369Z"
        fill="white"
      />
      <path
        d="M17.1729 11.2077C17.1996 11.3731 17.1932 11.5212 17.1602 11.501C17.1117 11.4715 17.0483 11.1044 17.0893 11.0093C17.1201 10.9646 17.1559 11.1216 17.1599 11.1395C17.1641 11.1584 17.1696 11.1869 17.1729 11.2077Z"
        fill="white"
      />
      <path
        d="M13.9657 6.96192C14.0898 7.03742 14.167 7.11397 14.1244 7.11946C14.0509 7.12875 13.6835 6.90016 13.7016 6.85646C13.7269 6.83932 13.861 6.89833 13.9657 6.96192Z"
        fill="white"
      />
      <path
        d="M15.5762 13.2137C15.5605 13.4283 15.3746 13.5669 15.2656 13.4454C15.161 13.3291 15.2194 13.0436 15.3965 12.9849C15.5339 12.9572 15.5843 13.1022 15.5762 13.2137Z"
        fill="white"
      />
      <path
        d="M12.831 7.08571C12.9188 7.16474 12.9459 7.23513 12.8901 7.23958C12.7751 7.24874 12.5209 7.00026 12.5597 6.95643C12.562 6.95381 12.5667 6.95185 12.57 6.95054C12.6256 6.94203 12.7523 7.01492 12.831 7.08571Z"
        fill="white"
      />
      <path
        d="M17.1647 11.8516C17.1755 12.0316 17.141 12.1822 17.0988 12.1393C17.0445 12.0838 17.0238 11.7279 17.0841 11.643C17.1238 11.6056 17.1569 11.722 17.1647 11.8516Z"
        fill="white"
      />
      <path
        d="M15.9519 8.29579C16.0464 8.4108 16.1171 8.52909 16.092 8.53001C16.0576 8.53131 15.8023 8.23259 15.7881 8.15303C15.7908 8.12228 15.87 8.20092 15.9226 8.26111C15.9315 8.27145 15.9433 8.28532 15.9519 8.29579Z"
        fill="white"
      />
      <path
        d="M16.5175 9.09841C16.579 9.20518 16.6395 9.33381 16.63 9.35513C16.6012 9.36573 16.4007 9.01584 16.389 8.93446C16.3891 8.93223 16.3895 8.92922 16.3905 8.927C16.4064 8.92098 16.4675 9.01166 16.5175 9.09841Z"
        fill="white"
      />
      <path
        d="M14.8792 7.37436C14.9994 7.46765 15.0788 7.56199 15.0365 7.56134C14.9808 7.56042 14.6548 7.31809 14.6546 7.25633C14.6676 7.23291 14.7813 7.29846 14.8792 7.37436Z"
        fill="white"
      />
      <path
        d="M17.0949 10.58C17.1364 10.737 17.1535 10.8854 17.1281 10.8688C17.0935 10.8461 16.9952 10.4841 17.0094 10.3943C17.0117 10.3878 17.0163 10.3742 17.0334 10.4045C17.0512 10.4361 17.0741 10.5016 17.0949 10.58Z"
        fill="white"
      />
      <path
        d="M12.9633 10.1029C12.9909 10.2898 12.8089 10.4034 12.654 10.2959C12.4918 10.1834 12.4928 9.91019 12.701 9.87761C12.8578 9.86963 12.9489 10.0047 12.9633 10.1029Z"
        fill="white"
      />
      <path
        d="M13.4599 6.7735C13.5757 6.83644 13.6529 6.90278 13.622 6.91246C13.5661 6.93013 13.1737 6.71999 13.1845 6.67825C13.1848 6.6772 13.1859 6.67615 13.1864 6.67524C13.215 6.66097 13.3535 6.71554 13.4599 6.7735Z"
        fill="white"
      />
      <path
        d="M17.0689 12.4933C17.0639 12.6724 17.0054 12.8277 16.9594 12.7849C16.8967 12.7266 16.9084 12.3743 16.9942 12.2873C17.0476 12.2498 17.0725 12.3654 17.0689 12.4933Z"
        fill="white"
      />
      <path
        d="M13.1633 11.0696C13.1816 11.2769 12.9549 11.3917 12.7957 11.2556C12.6467 11.1281 12.6811 10.868 12.899 10.8342C13.0564 10.826 13.1527 10.9494 13.1633 11.0696Z"
        fill="white"
      />
      <path
        d="M12.7171 9.18817C12.7567 9.3499 12.619 9.46086 12.4756 9.38274C12.3072 9.29115 12.2894 9.01872 12.4495 8.98339C12.4544 8.98235 12.461 8.98156 12.466 8.98078C12.601 8.97463 12.6956 9.1009 12.7171 9.18817Z"
        fill="white"
      />
      <path
        d="M16.9381 9.98595C16.9733 10.082 17.0225 10.2355 17.0163 10.2594C16.9901 10.2547 16.8587 9.87983 16.8506 9.80734C16.8506 9.80486 16.8508 9.80159 16.8515 9.7991C16.8698 9.80198 16.9269 9.9552 16.9381 9.98595Z"
        fill="white"
      />
      <path
        d="M15.2626 7.59745C15.3716 7.69415 15.4514 7.79281 15.4208 7.7932C15.3795 7.79372 15.0793 7.5387 15.064 7.47158C15.068 7.44528 15.1699 7.51541 15.2626 7.59745Z"
        fill="white"
      />
      <path
        d="M16.4634 13.3893C16.4381 13.5841 16.3116 13.7282 16.2416 13.6421C16.1653 13.5484 16.2233 13.2522 16.3581 13.1807C16.4592 13.1448 16.4764 13.2896 16.4634 13.3893Z"
        fill="white"
      />
      <path
        d="M12.7856 6.68466C12.896 6.74694 12.9577 6.80713 12.9179 6.81341C12.8519 6.82375 12.4879 6.61897 12.512 6.58508C12.5126 6.58416 12.514 6.58364 12.5149 6.58299C12.5507 6.57396 12.6874 6.62931 12.7856 6.68466Z"
        fill="white"
      />
      <path
        d="M15.8938 13.6141C15.8642 13.8227 15.6918 13.9577 15.6056 13.8399C15.5313 13.7384 15.5836 13.4652 15.7517 13.4015C15.8748 13.3713 15.909 13.5076 15.8938 13.6141Z"
        fill="white"
      />
      <path
        d="M12.588 6.76761C12.687 6.83487 12.734 6.89755 12.6888 6.90186C12.6055 6.90985 12.2857 6.69342 12.3286 6.65809C12.3297 6.65731 12.3315 6.65665 12.3327 6.656C12.3749 6.64841 12.501 6.70873 12.588 6.76761Z"
        fill="white"
      />
      <path
        d="M12.5563 7.52208C12.634 7.63815 12.5958 7.70867 12.5112 7.69192C12.373 7.66458 12.2185 7.42277 12.3072 7.37252C12.3121 7.36978 12.3196 7.36794 12.325 7.36651C12.4081 7.3597 12.5103 7.45326 12.5563 7.52208Z"
        fill="white"
      />
      <path
        d="M12.5359 7.92797C12.5966 8.06353 12.509 8.13668 12.4018 8.09036C12.2583 8.02833 12.1737 7.79817 12.2779 7.75316C12.2839 7.75054 12.2927 7.74871 12.2992 7.74727C12.4028 7.74125 12.5017 7.85156 12.5359 7.92797Z"
        fill="white"
      />
      <path
        d="M16.2084 8.56821C16.2774 8.66583 16.3522 8.78451 16.3537 8.8057C16.3314 8.80688 16.1098 8.48421 16.0833 8.42166C16.0826 8.41957 16.0819 8.41682 16.0819 8.4146C16.0988 8.41381 16.1972 8.55238 16.2084 8.56821Z"
        fill="white"
      />
      <path
        d="M12.4903 7.1612C12.5679 7.24835 12.573 7.32176 12.501 7.31508C12.3692 7.30291 12.1891 7.06817 12.2432 7.02656C12.2466 7.02408 12.2522 7.02238 12.2565 7.0212C12.3219 7.01452 12.4343 7.09827 12.4903 7.1612Z"
        fill="white"
      />
      <path
        d="M14.7918 13.4127C14.7717 13.6319 14.5348 13.7562 14.419 13.6083C14.3183 13.4797 14.3895 13.2277 14.5843 13.1801C14.744 13.1588 14.8019 13.3033 14.7918 13.4127Z"
        fill="white"
      />
      <path
        d="M14.3398 12.9727C14.3331 13.1957 14.0894 13.3221 13.9508 13.1745C13.8295 13.0454 13.8916 12.7864 14.1038 12.7419C14.2719 12.7241 14.3429 12.866 14.3398 12.9727Z"
        fill="white"
      />
      <path
        d="M16.8874 13.1416C16.8644 13.3211 16.7778 13.4673 16.73 13.4066C16.6714 13.3323 16.718 13.0146 16.8188 12.9362C16.889 12.8981 16.9012 13.0334 16.8874 13.1416Z"
        fill="white"
      />
      <path
        d="M12.9358 6.59673C13.053 6.65312 13.1275 6.70926 13.0953 6.71711C13.0401 6.73058 12.649 6.54373 12.6596 6.50893C12.6598 6.50814 12.6607 6.50736 12.6612 6.5067C12.6896 6.49637 12.8295 6.5457 12.9358 6.59673Z"
        fill="white"
      />
      <path
        d="M13.8363 12.5308C13.837 12.7499 13.5795 12.8723 13.431 12.7236C13.3003 12.5927 13.3595 12.336 13.5816 12.2958C13.7567 12.2818 13.836 12.4248 13.8363 12.5308Z"
        fill="white"
      />
      <path
        d="M15.5832 7.82918C15.6668 7.91384 15.7541 8.01708 15.7508 8.03592C15.7283 8.04652 15.4523 7.76598 15.4228 7.70252C15.4224 7.70082 15.422 7.6982 15.4226 7.6965C15.437 7.69153 15.5143 7.75944 15.5832 7.82918Z"
        fill="white"
      />
      <path
        d="M16.7922 9.60545C16.809 9.64392 16.8132 9.6553 16.8151 9.66197C16.7987 9.64457 16.6335 9.29089 16.6134 9.23973C16.6126 9.2375 16.6116 9.23449 16.6111 9.23214C16.615 9.23632 16.6186 9.23999 16.6493 9.30096L16.7922 9.60545Z"
        fill="white"
      />
      <path
        d="M13.2947 12.0821C13.2969 12.2904 13.0611 12.4043 12.9067 12.2695C12.7597 12.1413 12.8098 11.8783 13.0371 11.8435C13.213 11.8343 13.2936 11.9775 13.2947 12.0821Z"
        fill="white"
      />
      <path
        d="M12.4369 9.685C12.4607 9.86138 12.2882 9.96371 12.1438 9.85877C11.9968 9.7516 12.0041 9.48965 12.1958 9.46374C12.326 9.46099 12.4223 9.5764 12.4369 9.685Z"
        fill="white"
      />
      <path
        d="M12.6144 10.6207C12.6333 10.8175 12.4142 10.9275 12.2643 10.7964C12.1233 10.6732 12.1569 10.4189 12.3625 10.392C12.5059 10.388 12.6032 10.5052 12.6144 10.6207Z"
        fill="white"
      />
      <path
        d="M12.4142 8.34747C12.4575 8.48369 12.3641 8.57842 12.2417 8.52229C12.0913 8.45333 12.0356 8.21309 12.1591 8.16533C12.1666 8.16245 12.1774 8.16049 12.1853 8.15905C12.2991 8.1546 12.3891 8.26883 12.4142 8.34747Z"
        fill="white"
      />
      <path
        d="M14.0241 6.82846C14.1439 6.89506 14.2286 6.96402 14.1984 6.9703C14.1588 6.97854 13.7818 6.78436 13.7774 6.73425C13.79 6.71658 13.9181 6.76931 14.0241 6.82846Z"
        fill="white"
      />
      <path
        d="M15.1771 13.8171C15.1481 14.0269 14.9371 14.154 14.8351 14.023C14.7437 13.9058 14.819 13.6568 15.0025 13.6048C15.1465 13.5806 15.1912 13.7143 15.1771 13.8171Z"
        fill="white"
      />
      <path
        d="M12.3332 6.83919C12.4261 6.9173 12.4418 6.97396 12.3979 6.97606C12.2992 6.9809 12.0627 6.76146 12.0931 6.72509C12.095 6.72273 12.0991 6.72116 12.1019 6.72012C12.1534 6.7141 12.2693 6.78541 12.3332 6.83919Z"
        fill="white"
      />
      <path
        d="M17.2712 11.5693C17.2799 11.674 17.2862 11.8397 17.2719 11.863C17.2435 11.8534 17.2178 11.4566 17.2323 11.3786C17.233 11.3761 17.2342 11.3728 17.2354 11.3705C17.2366 11.3707 17.2381 11.371 17.2394 11.3711C17.2562 11.3987 17.2689 11.5411 17.2712 11.5693Z"
        fill="white"
      />
      <path
        d="M17.2585 12.2176C17.2566 12.381 17.232 12.5332 17.2106 12.5135C17.1797 12.4853 17.1821 12.1156 17.2235 12.0247C17.2276 12.0182 17.2364 12.0048 17.2453 12.0356C17.2544 12.0676 17.2593 12.1357 17.2585 12.2176Z"
        fill="white"
      />
      <path
        d="M13.5361 6.65613C13.6634 6.71698 13.7404 6.77573 13.7218 6.78567C13.6855 6.80504 13.2781 6.60889 13.2815 6.5737C13.2988 6.55891 13.4295 6.60523 13.5361 6.65613Z"
        fill="white"
      />
      <path
        d="M16.1361 13.9896C16.0976 14.1715 15.9509 14.308 15.883 14.2252C15.8099 14.1359 15.8887 13.8605 16.033 13.7968C16.1379 13.7655 16.1566 13.8933 16.1361 13.9896Z"
        fill="white"
      />
      <path
        d="M17.2037 10.9716L17.2301 11.1349C17.2376 11.1866 17.2399 11.207 17.2394 11.2158C17.2248 11.1881 17.1608 10.8149 17.155 10.7474C17.1548 10.7447 17.1547 10.7413 17.1548 10.7389C17.1582 10.7441 17.1606 10.748 17.1763 10.8252L17.2037 10.9716Z"
        fill="white"
      />
      <path
        d="M14.4511 7.00275C14.5755 7.08113 14.6581 7.15754 14.6382 7.165C14.6089 7.17599 14.2556 6.96663 14.2384 6.9088C14.2434 6.88786 14.3522 6.94046 14.4511 7.00275Z"
        fill="white"
      />
      <path
        d="M12.2741 6.50121C12.3839 6.55708 12.4302 6.60039 12.4101 6.60837C12.3694 6.62434 11.9986 6.43827 12.0172 6.41105C12.0176 6.4104 12.0188 6.41001 12.0195 6.40961C12.0506 6.40373 12.1796 6.45319 12.2741 6.50121Z"
        fill="white"
      />
      <path
        d="M12.7445 11.6212C12.747 11.8225 12.5185 11.9294 12.3684 11.7972C12.2219 11.6681 12.2682 11.4125 12.4918 11.3843C12.6738 11.379 12.7434 11.5267 12.7445 11.6212Z"
        fill="white"
      />
      <path
        d="M12.4074 6.43356C12.5257 6.48354 12.5824 6.52411 12.5671 6.533C12.5355 6.55119 12.1457 6.38907 12.137 6.35413C12.1374 6.35361 12.1379 6.35296 12.1383 6.35243C12.1624 6.34628 12.2957 6.38632 12.4074 6.43356Z"
        fill="white"
      />
      <path
        d="M16.6268 13.7599C16.5884 13.941 16.4754 14.0797 16.4284 14.0032C16.3776 13.9204 16.4521 13.6374 16.563 13.5677C16.634 13.5365 16.6513 13.6432 16.6268 13.7599Z"
        fill="white"
      />
      <path
        d="M17.1573 12.8722C17.1387 13.0402 17.089 13.1848 17.0624 13.1487C17.0278 13.102 17.0658 12.7612 17.1266 12.6783C17.1592 12.6462 17.1633 12.7297 17.1636 12.7671C17.1636 12.7813 17.163 12.8196 17.1573 12.8722Z"
        fill="white"
      />
      <path
        d="M12.2129 8.79654C12.2413 8.9478 12.1155 9.0411 11.9885 8.96298C11.8355 8.86864 11.8283 8.62579 11.9777 8.597C11.9821 8.59608 11.9882 8.59556 11.9927 8.59504C12.118 8.59242 12.1969 8.71123 12.2129 8.79654Z"
        fill="white"
      />
      <path
        d="M13.028 6.50239C13.158 6.55616 13.2313 6.60549 13.21 6.61361C13.1695 6.62918 12.7555 6.45593 12.7626 6.42649C12.7822 6.41524 12.919 6.45724 13.028 6.50239Z"
        fill="white"
      />
      <path
        d="M15.8401 8.0795C15.9158 8.16887 15.9972 8.27093 16.0076 8.28951C15.99 8.28009 15.7399 7.97979 15.7157 7.94603C15.7145 7.9442 15.7128 7.94158 15.7117 7.93949C15.7268 7.94695 15.8065 8.03985 15.8401 8.0795Z"
        fill="white"
      />
      <path
        d="M12.092 6.55917C12.1872 6.61845 12.2241 6.66594 12.1988 6.67314C12.1465 6.68793 11.8463 6.50474 11.8569 6.46457C11.8575 6.46287 11.8601 6.46143 11.8617 6.46064C11.8987 6.4558 12.0209 6.51482 12.092 6.55917Z"
        fill="white"
      />
      <path
        d="M14.8325 7.19392C14.9278 7.26327 15.0285 7.35211 15.0206 7.36755C14.9883 7.37593 14.675 7.14616 14.6481 7.09434C14.6444 7.07445 14.7134 7.11266 14.7789 7.15663C14.7945 7.16696 14.8165 7.18227 14.8325 7.19392Z"
        fill="white"
      />
      <path
        d="M16.5066 9.03416L16.5109 9.04175C16.4454 8.92621 16.3575 8.78411 16.3187 8.72432L16.5066 9.03416Z"
        fill="white"
      />
      <path
        d="M17.1102 10.5341C17.0802 10.4175 17.0368 10.2687 17.0094 10.182L17.1102 10.5341Z"
        fill="white"
      />
      <path
        d="M15.4962 14.2067C15.4556 14.4052 15.2576 14.5296 15.18 14.4053C15.1126 14.2975 15.1995 14.0625 15.361 14.0099C15.4945 13.9823 15.5148 14.116 15.4962 14.2067Z"
        fill="white"
      />
      <path
        d="M16.9726 13.4987C16.9405 13.6582 16.8691 13.7996 16.8383 13.7643C16.7974 13.7172 16.8643 13.3969 16.9458 13.3168C16.9946 13.2816 16.9902 13.3879 16.9801 13.4564C16.9782 13.4691 16.9752 13.486 16.9726 13.4987Z"
        fill="white"
      />
      <path
        d="M12.092 10.1816C12.1014 10.3644 11.8986 10.4599 11.763 10.3365C11.631 10.2165 11.6661 9.97601 11.8615 9.95494C12.0124 9.95481 12.0872 10.0874 12.092 10.1816Z"
        fill="white"
      />
      <path
        d="M12.5045 6.36303C12.6234 6.40556 12.6925 6.44403 12.6825 6.45332C12.6634 6.47124 12.2502 6.32679 12.2315 6.29551C12.2318 6.29512 12.2319 6.29447 12.2322 6.29394C12.2528 6.28609 12.3992 6.32535 12.5045 6.36303Z"
        fill="white"
      />
      <path
        d="M13.3563 13.1085C13.3481 13.3222 13.0838 13.4342 12.9463 13.2824C12.828 13.1516 12.8983 12.9129 13.1162 12.8799C13.2781 12.871 13.3607 12.991 13.3563 13.1085Z"
        fill="white"
      />
      <path
        d="M11.9358 9.2587C11.9538 9.422 11.7964 9.51464 11.6686 9.41611C11.5354 9.31352 11.5482 9.07028 11.7258 9.05052C11.8549 9.05065 11.9263 9.17234 11.9358 9.2587Z"
        fill="white"
      />
      <path
        d="M12.031 6.89807C12.0995 6.97422 12.1057 7.03886 12.0438 7.03101C11.9328 7.01701 11.7736 6.81119 11.8177 6.77703C11.8205 6.77494 11.825 6.77363 11.8284 6.77272C11.8829 6.76918 11.9827 6.84455 12.031 6.89807Z"
        fill="white"
      />
      <path
        d="M12.0957 7.22741C12.1549 7.3194 12.1329 7.39333 12.0524 7.3737C11.9186 7.34138 11.8098 7.12732 11.8789 7.08963C11.8832 7.08728 11.8897 7.08571 11.8945 7.08479C11.9666 7.08073 12.0572 7.16762 12.0957 7.22741Z"
        fill="white"
      />
      <path
        d="M12.0655 7.58921C12.1152 7.69755 12.0629 7.78116 11.9673 7.74623C11.8268 7.69506 11.7626 7.47629 11.8489 7.43782C11.8544 7.43533 11.8624 7.43389 11.8683 7.43285C11.9445 7.43023 12.03 7.51201 12.0655 7.58921Z"
        fill="white"
      />
      <path
        d="M13.8668 13.5671C13.8464 13.7792 13.593 13.8914 13.4622 13.7461C13.3495 13.621 13.4261 13.3765 13.6391 13.3391C13.8127 13.3251 13.8767 13.464 13.8668 13.5671Z"
        fill="white"
      />
      <path
        d="M12.2016 11.1529C12.2044 11.3436 11.9821 11.4452 11.841 11.3177C11.7029 11.1929 11.7493 10.9428 11.9605 10.9207C12.1241 10.9201 12.1999 11.056 12.2016 11.1529Z"
        fill="white"
      />
      <path
        d="M14.326 14.001C14.2961 14.2032 14.055 14.3136 13.9409 14.1773C13.8433 14.0608 13.9278 13.8254 14.1271 13.7839C14.289 13.7667 14.3407 13.9005 14.326 14.001Z"
        fill="white"
      />
      <path
        d="M15.1613 7.40471C15.2446 7.4755 15.3413 7.5671 15.3457 7.58397C15.3215 7.58489 15.0378 7.3385 15.0051 7.29559C15.0042 7.29402 15.0029 7.29179 15.0027 7.29009C15.0244 7.28904 15.1557 7.40013 15.1613 7.40471Z"
        fill="white"
      />
      <path
        d="M12.821 12.6474C12.8104 12.8607 12.551 12.9586 12.4129 12.8014C12.2972 12.6699 12.3649 12.4378 12.5769 12.4112C12.7537 12.4059 12.8261 12.5461 12.821 12.6474Z"
        fill="white"
      />
      <path
        d="M11.9512 7.98607C11.9856 8.10881 11.9039 8.19333 11.8005 8.14191C11.6682 8.07609 11.6241 7.85758 11.7351 7.81701C11.7418 7.81453 11.7514 7.81309 11.7586 7.81204C11.8574 7.81047 11.9307 7.9128 11.9512 7.98607Z"
        fill="white"
      />
      <path
        d="M16.297 14.3492C16.2484 14.5049 16.1192 14.6489 16.0721 14.5669C16.0242 14.484 16.1154 14.2318 16.2379 14.1686C16.3279 14.1358 16.3267 14.2543 16.297 14.3492Z"
        fill="white"
      />
      <path
        d="M11.8845 6.28361C11.9789 6.31789 12.0486 6.3523 12.0502 6.36552C12.0255 6.38868 11.6385 6.22015 11.6364 6.21674C11.6366 6.21648 11.637 6.21596 11.6373 6.2157C11.6572 6.21203 11.7821 6.24632 11.8845 6.28361Z"
        fill="white"
      />
      <path
        d="M15.7389 14.5719C15.6892 14.7438 15.5223 14.8662 15.46 14.7764C15.3982 14.6876 15.4952 14.4456 15.6425 14.3902C15.7418 14.3653 15.7699 14.4651 15.7389 14.5719Z"
        fill="white"
      />
      <path
        d="M13.5478 6.53889C13.677 6.59476 13.7624 6.64985 13.7417 6.65587C13.7068 6.66595 13.3289 6.50055 13.316 6.46954C13.3222 6.45449 13.4433 6.49375 13.5478 6.53889Z"
        fill="white"
      />
      <path
        d="M11.8754 6.61138C11.9573 6.6772 11.9761 6.72666 11.9382 6.72705C11.8576 6.72797 11.6458 6.53889 11.6678 6.50971C11.669 6.50788 11.672 6.50697 11.674 6.50618C11.7138 6.5033 11.8182 6.56545 11.8754 6.61138Z"
        fill="white"
      />
      <path
        d="M17.2943 12.0269L17.2918 12.1905C17.2947 12.0816 17.2931 11.8683 17.2896 11.7766L17.2943 12.0269Z"
        fill="white"
      />
      <path
        d="M11.7762 6.32993C11.8799 6.37677 11.9261 6.4121 11.9095 6.41786C11.8754 6.42976 11.5668 6.28335 11.5431 6.25312C11.5433 6.25233 11.5437 6.25129 11.5442 6.2505C11.5667 6.24802 11.6836 6.28806 11.7762 6.32993Z"
        fill="white"
      />
      <path
        d="M14.7164 14.3968C14.6779 14.59 14.4533 14.7005 14.3578 14.5735C14.2753 14.4637 14.3645 14.2431 14.5512 14.1979C14.7048 14.1769 14.734 14.3088 14.7164 14.3968Z"
        fill="white"
      />
      <path
        d="M13.0658 6.40608C13.158 6.43945 13.2578 6.48498 13.2611 6.5033C13.2443 6.52358 12.825 6.37285 12.8169 6.34354C12.8393 6.33268 12.9786 6.37442 13.0658 6.40608Z"
        fill="white"
      />
      <path
        d="M17.2663 12.5978L17.2651 12.6106L17.2582 12.6766C17.2537 12.717 17.2386 12.8505 17.23 12.8725C17.2207 12.8501 17.2612 12.473 17.2742 12.4125C17.2748 12.4102 17.2757 12.4069 17.2767 12.4047C17.281 12.4155 17.2776 12.4806 17.2663 12.5978Z"
        fill="white"
      />
      <path
        d="M11.9643 6.23454C12.0486 6.25953 12.1381 6.29251 12.1456 6.3069C12.1178 6.32548 11.7059 6.1805 11.7034 6.17801C11.7034 6.17762 11.7036 6.17723 11.7036 6.17697C11.7194 6.17304 11.8486 6.20026 11.9643 6.23454Z"
        fill="white"
      />
      <path
        d="M13.9883 6.68675C14.0807 6.73372 14.1816 6.79627 14.1835 6.8155C14.1662 6.83356 13.8028 6.64907 13.7785 6.60994C13.7785 6.60916 13.7785 6.60837 13.7784 6.60759C13.7903 6.59673 13.8967 6.64017 13.9883 6.68675Z"
        fill="white"
      />
      <path
        d="M16.7064 14.115C16.6569 14.2755 16.5653 14.4001 16.5373 14.3449C16.5046 14.2806 16.5949 14.0039 16.6863 13.9348C16.7475 13.9005 16.7354 14.0115 16.7124 14.0946C16.7106 14.1007 16.7082 14.109 16.7064 14.115Z"
        fill="white"
      />
      <path
        d="M12.277 12.1775C12.2659 12.3751 12.031 12.4645 11.8903 12.3246C11.763 12.1981 11.8274 11.9556 12.0403 11.9345C12.2227 11.9338 12.2823 12.0832 12.277 12.1775Z"
        fill="white"
      />
      <path
        d="M11.7544 8.40622C11.7747 8.54296 11.6605 8.62566 11.5507 8.55382C11.4224 8.46995 11.4246 8.24162 11.5538 8.21911C11.5577 8.21846 11.5629 8.21806 11.5667 8.21767C11.6757 8.21846 11.7432 8.33059 11.7544 8.40622Z"
        fill="white"
      />
      <path
        d="M12.5495 6.28662C12.6337 6.312 12.7311 6.34772 12.7458 6.36643C12.7452 6.36748 12.7444 6.36892 12.7438 6.36997C12.7051 6.37677 12.2961 6.25247 12.2927 6.23284C12.3177 6.22538 12.4603 6.25979 12.5495 6.28662Z"
        fill="white"
      />
      <path
        d="M17.1629 13.2242C17.1435 13.3261 17.1062 13.4779 17.0864 13.4983C17.0853 13.4979 17.0838 13.4973 17.0826 13.4966C17.0704 13.4494 17.135 13.1321 17.1723 13.0556C17.1738 13.0532 17.1761 13.0499 17.1782 13.0478C17.1793 13.0482 17.1809 13.0488 17.182 13.0492C17.1897 13.0772 17.1693 13.1903 17.1629 13.2242Z"
        fill="white"
      />
      <path
        d="M15.599 7.81073C15.5862 7.797 15.5862 7.797 15.2946 7.51122C15.2928 7.50939 15.2903 7.50704 15.2887 7.5052C15.2897 7.50599 15.291 7.50717 15.2918 7.50808L15.599 7.81073Z"
        fill="white"
      />
      <path
        d="M14.3682 6.84599C14.4619 6.90173 14.5659 6.97501 14.5648 6.9894C14.544 6.99804 14.2236 6.80595 14.1882 6.76356C14.1878 6.76277 14.1872 6.76173 14.1868 6.76094C14.1875 6.76016 14.1886 6.75924 14.1893 6.75845C14.2141 6.76016 14.2995 6.80491 14.3682 6.84599Z"
        fill="white"
      />
      <path
        d="M15.0412 14.7667C14.9943 14.9457 14.789 15.0598 14.7134 14.949C14.646 14.8501 14.741 14.6362 14.9092 14.5894C15.0307 14.5684 15.068 14.6651 15.0412 14.7667Z"
        fill="white"
      />
      <path
        d="M11.5958 9.73446C11.5974 9.89972 11.4235 9.98504 11.3007 9.87944C11.1743 9.77071 11.2091 9.53152 11.3931 9.51582C11.5316 9.51882 11.595 9.64549 11.5958 9.73446Z"
        fill="white"
      />
      <path
        d="M11.6839 10.6941C11.678 10.8748 11.4697 10.9601 11.3412 10.8348C11.2166 10.7131 11.2673 10.4769 11.4645 10.4608C11.6209 10.4639 11.687 10.5991 11.6839 10.6941Z"
        fill="white"
      />
      <path
        d="M11.6323 6.36787C11.7194 6.41773 11.7568 6.45646 11.7372 6.46143C11.6981 6.47111 11.4115 6.30494 11.4304 6.28361C11.4308 6.28321 11.4317 6.28295 11.4324 6.28269C11.4576 6.28086 11.5625 6.32783 11.6323 6.36787Z"
        fill="white"
      />
      <path
        d="M12.0161 6.18312C12.1159 6.20732 12.1971 6.23362 12.2091 6.24592C12.2084 6.24671 12.2076 6.24775 12.2069 6.24854C12.1723 6.25181 11.7594 6.1504 11.7563 6.13771C11.7811 6.13418 11.9187 6.15956 12.0161 6.18312Z"
        fill="white"
      />
      <path
        d="M16.9715 13.8538C16.9307 13.9924 16.8736 14.1158 16.8586 14.0985C16.8366 14.0731 16.9252 13.762 16.9865 13.6815C16.9923 13.6757 17.0042 13.6634 17.0046 13.6929C17.005 13.7222 16.9926 13.7824 16.9715 13.8538Z"
        fill="white"
      />
      <path
        d="M11.7403 11.6823C11.7367 11.873 11.5103 11.9663 11.373 11.8335C11.2451 11.7097 11.3052 11.4682 11.5137 11.4512C11.6582 11.4538 11.7423 11.5707 11.7403 11.6823Z"
        fill="white"
      />
      <path
        d="M11.3738 6.14844C11.46 6.17448 11.5297 6.20104 11.5435 6.21308C11.5433 6.21373 11.5433 6.21478 11.5432 6.21544C11.5149 6.21923 11.1654 6.10356 11.156 6.09427C11.1685 6.09388 11.2788 6.11992 11.3738 6.14844Z"
        fill="white"
      />
      <path
        d="M11.4816 8.84313C11.4905 9.00538 11.3354 9.06635 11.2335 8.9779C11.1218 8.88094 11.145 8.65824 11.3018 8.64398C11.4062 8.64672 11.4764 8.74957 11.4816 8.84313Z"
        fill="white"
      />
      <path
        d="M15.9015 14.9119C15.8373 15.0766 15.6877 15.1893 15.6514 15.1003C15.6167 15.0154 15.722 14.8001 15.8465 14.7461C15.9401 14.7175 15.9349 14.8259 15.9015 14.9119Z"
        fill="white"
      />
      <path
        d="M16.3784 14.6806C16.317 14.8358 16.2028 14.9574 16.1782 14.8938C16.1537 14.8303 16.2504 14.5859 16.3571 14.5205C16.4258 14.4893 16.4109 14.5909 16.3854 14.6623C16.3835 14.6678 16.3806 14.6752 16.3784 14.6806Z"
        fill="white"
      />
      <path
        d="M12.8381 13.6802C12.819 13.8829 12.5694 13.979 12.4395 13.8339C12.3365 13.7188 12.3843 13.4844 12.6126 13.4563C12.7927 13.4504 12.8466 13.5906 12.8381 13.6802Z"
        fill="white"
      />
      <path
        d="M11.6585 7.27766C11.6954 7.3737 11.6506 7.442 11.573 7.40825C11.4584 7.35839 11.4108 7.16644 11.483 7.13661C11.4873 7.13477 11.4939 7.1336 11.4988 7.13294C11.5696 7.13255 11.6359 7.21917 11.6585 7.27766Z"
        fill="white"
      />
      <path
        d="M11.4442 6.12567C11.5123 6.14203 11.6155 6.17029 11.6197 6.18089C11.5947 6.18573 11.1962 6.07935 11.1954 6.07765C11.2178 6.07804 11.3425 6.10134 11.4442 6.12567Z"
        fill="white"
      />
      <path
        d="M13.3421 14.1345C13.3186 14.3288 13.0713 14.428 12.947 14.2929C12.8403 14.177 12.9218 13.9546 13.1308 13.9232C13.2856 13.9144 13.3551 14.0255 13.3421 14.1345Z"
        fill="white"
      />
      <path
        d="M14.6911 7.02303C14.781 7.0861 14.8767 7.16029 14.8881 7.17586C14.8875 7.17599 14.8868 7.17625 14.8864 7.17651C14.856 7.16304 14.5805 6.9682 14.5436 6.93405C14.5424 6.93274 14.5407 6.93091 14.5399 6.92934C14.5515 6.92764 14.6438 6.98979 14.6911 7.02303Z"
        fill="white"
      />
      <path
        d="M11.6842 6.94596C11.7336 7.02669 11.7161 7.08963 11.6496 7.07066C11.5459 7.04122 11.455 6.8524 11.5082 6.82244C11.5115 6.82061 11.5166 6.81956 11.5202 6.81891C11.5752 6.81786 11.6521 6.89336 11.6842 6.94596Z"
        fill="white"
      />
      <path
        d="M13.8087 14.5544C13.7718 14.7513 13.5111 14.8464 13.4103 14.6998C13.3397 14.597 13.406 14.3958 13.6145 14.3597C13.7852 14.3452 13.8243 14.4714 13.8087 14.5544Z"
        fill="white"
      />
      <path
        d="M15.2864 15.1196C15.2245 15.2827 15.0385 15.3895 14.9893 15.2901C14.9487 15.208 15.0374 15.0031 15.1928 14.9535C15.3091 14.9291 15.3174 15.0381 15.2864 15.1196Z"
        fill="white"
      />
      <path
        d="M11.6226 6.65378C11.676 6.71436 11.6862 6.76591 11.6444 6.76421C11.5601 6.76107 11.4127 6.57187 11.447 6.54543C11.4491 6.54386 11.4525 6.54295 11.4551 6.54229C11.4984 6.54098 11.5816 6.60732 11.6226 6.65378Z"
        fill="white"
      />
      <path
        d="M11.311 6.17579C11.385 6.20484 11.4364 6.23048 11.4453 6.24278C11.4448 6.24357 11.4443 6.24448 11.4439 6.24527C11.4123 6.24775 11.1146 6.11965 11.1101 6.11023C11.1239 6.10997 11.2277 6.14308 11.311 6.17579Z"
        fill="white"
      />
      <path
        d="M12.3102 13.2052C12.2951 13.4034 12.0433 13.4924 11.9139 13.3451C11.8008 13.2165 11.8724 12.9948 12.0841 12.9735C12.2617 12.9724 12.3171 13.1151 12.3102 13.2052Z"
        fill="white"
      />
      <path
        d="M11.4683 6.40346C11.5361 6.45606 11.5552 6.49244 11.5342 6.49597C11.4778 6.50553 11.2872 6.33267 11.2964 6.3137C11.2971 6.31252 11.299 6.31174 11.3005 6.31122C11.3294 6.31056 11.4192 6.36539 11.4683 6.40346Z"
        fill="white"
      />
      <path
        d="M14.2072 14.9409C14.1577 15.1271 13.9099 15.2184 13.8336 15.0787C13.781 14.9824 13.8567 14.7971 14.0456 14.7592C14.2031 14.7415 14.2283 14.8612 14.2072 14.9409Z"
        fill="white"
      />
      <path
        d="M11.5466 7.64116C11.5711 7.75709 11.488 7.82604 11.3987 7.76415C11.2954 7.69284 11.2827 7.49905 11.38 7.48021C11.3828 7.47969 11.3869 7.4793 11.3898 7.47903C11.47 7.48021 11.5324 7.5739 11.5466 7.64116Z"
        fill="white"
      />
      <path
        d="M11.4771 6.09571C11.5719 6.11311 11.6526 6.13261 11.665 6.14124C11.6541 6.1504 11.2225 6.06156 11.2199 6.0592C11.2402 6.05868 11.3638 6.0749 11.4771 6.09571Z"
        fill="white"
      />
      <path
        d="M16.7045 14.4485C16.6503 14.5838 16.5758 14.6982 16.5596 14.6708C16.5381 14.6346 16.6483 14.3551 16.7226 14.2843C16.7302 14.2787 16.7532 14.2617 16.7445 14.3186C16.7422 14.3332 16.7338 14.3751 16.7045 14.4485Z"
        fill="white"
      />
      <path
        d="M13.0453 6.30834C13.1315 6.33621 13.2298 6.37507 13.2423 6.39273C13.2417 6.39365 13.2409 6.3947 13.2402 6.39574C13.2005 6.40045 12.8408 6.28308 12.8221 6.25927C12.8334 6.24815 12.959 6.2806 13.0453 6.30834Z"
        fill="white"
      />
      <path
        d="M12.5612 6.21216C12.651 6.23532 12.7484 6.26725 12.7556 6.28086C12.7244 6.29905 12.3195 6.17239 12.3177 6.16977C12.335 6.16166 12.4712 6.189 12.5612 6.21216Z"
        fill="white"
      />
      <path
        d="M11.1915 10.2195C11.1815 10.3913 10.9878 10.468 10.8724 10.3457C10.7623 10.2289 10.8172 10.0085 10.9989 9.99773C11.1401 10.0039 11.1965 10.1312 11.1915 10.2195Z"
        fill="white"
      />
      <path
        d="M11.7727 12.7115C11.7616 12.9084 11.5164 12.9936 11.3868 12.8455C11.2763 12.7192 11.3451 12.4984 11.5488 12.4819C11.6969 12.4844 11.779 12.6006 11.7727 12.7115Z"
        fill="white"
      />
      <path
        d="M11.226 11.2002C11.2136 11.3801 11.0031 11.4603 10.8795 11.3321C10.7605 11.2085 10.8203 10.9835 11.0194 10.9719C11.1723 10.978 11.2323 11.1092 11.226 11.2002Z"
        fill="white"
      />
      <path
        d="M13.4851 6.42008C13.5762 6.45593 13.6781 6.50513 13.6848 6.52123C13.66 6.53575 13.3061 6.3841 13.2872 6.3646C13.2871 6.36408 13.2868 6.36329 13.2867 6.36277C13.3058 6.35466 13.4286 6.39797 13.4851 6.42008Z"
        fill="white"
      />
      <path
        d="M11.1434 9.28788C11.1372 9.44189 10.9819 9.51686 10.8755 9.41716C10.7652 9.31405 10.8055 9.09357 10.9722 9.08297C11.0957 9.08873 11.1467 9.20623 11.1434 9.28788Z"
        fill="white"
      />
      <path
        d="M15.0527 7.2982C14.9689 7.2282 14.8795 7.15833 14.8399 7.12836L15.0527 7.2982Z"
        fill="white"
      />
      <path
        d="M11.3514 8.02676C11.3626 8.1478 11.2589 8.21924 11.1698 8.15225C11.0644 8.07282 11.0786 7.87249 11.1908 7.85418C11.1942 7.85378 11.1986 7.85352 11.2018 7.85313C11.2848 7.85601 11.344 7.94551 11.3514 8.02676Z"
        fill="white"
      />
      <path
        d="M17.0214 13.811C17.0514 13.7129 17.1121 13.4901 17.1276 13.4261L17.0214 13.811Z"
        fill="white"
      />
      <path
        d="M12.0356 6.12986C12.1319 6.14844 12.2166 6.17108 12.2327 6.18233C12.2326 6.18299 12.2323 6.18403 12.2322 6.18469C12.2047 6.18953 11.7981 6.10919 11.7882 6.09715C11.8078 6.09244 11.9396 6.11128 12.0356 6.12986Z"
        fill="white"
      />
      <path
        d="M13.8688 6.54766C13.9516 6.58717 14.051 6.64187 14.0667 6.66032C14.0665 6.6611 14.0662 6.66202 14.0659 6.6628C14.0359 6.66137 13.7325 6.51482 13.697 6.48459C13.6966 6.48394 13.6958 6.48302 13.6952 6.48237C13.6958 6.48171 13.6966 6.48093 13.697 6.48027C13.7171 6.48132 13.7878 6.50893 13.8688 6.54766Z"
        fill="white"
      />
      <path
        d="M15.9823 15.2293C15.9147 15.3668 15.7907 15.4752 15.7647 15.4194C15.7362 15.3583 15.8571 15.1352 15.9648 15.0805C16.0305 15.0559 16.027 15.1301 15.9902 15.2123C15.9879 15.2175 15.9848 15.2243 15.9823 15.2293Z"
        fill="white"
      />
      <path
        d="M10.9296 6.03172C11.0332 6.05004 11.0953 6.06496 11.1065 6.06954C11.0995 6.06993 11.0938 6.06941 11 6.04991C10.9416 6.03774 10.8615 6.02126 10.7796 6.00726C10.8127 6.01236 10.8679 6.02073 10.9296 6.03172Z"
        fill="white"
      />
      <path
        d="M14.5387 15.2965C14.4823 15.4667 14.257 15.5615 14.1994 15.4396C14.1585 15.3532 14.2399 15.175 14.4099 15.1348C14.5421 15.1158 14.5649 15.218 14.5387 15.2965Z"
        fill="white"
      />
      <path
        d="M11.2217 6.19633C11.3054 6.2399 11.3343 6.26673 11.3229 6.26934C11.296 6.27549 11.0627 6.13994 11.055 6.12567C11.0551 6.12554 11.0556 6.12528 11.0557 6.12515C11.0685 6.12554 11.1545 6.1614 11.2217 6.19633Z"
        fill="white"
      />
      <path
        d="M10.9135 6.03748C11.0072 6.06025 11.0618 6.07765 11.0706 6.08262C11.0672 6.0838 11.0521 6.08027 10.9706 6.05763C10.8962 6.03696 10.846 6.02322 10.7796 6.00726C10.8076 6.01327 10.8594 6.0244 10.9135 6.03748Z"
        fill="white"
      />
      <path
        d="M15.4525 15.4396C15.386 15.5789 15.2203 15.6977 15.1897 15.6023C15.1662 15.5292 15.2645 15.3413 15.3985 15.2922C15.5019 15.2654 15.4866 15.3684 15.4525 15.4396Z"
        fill="white"
      />
      <path
        d="M11.2402 12.2091C11.2281 12.3919 11.0046 12.4755 10.8781 12.3445C10.7679 12.2308 10.8082 11.9945 11.0255 11.9823C11.1837 11.9883 11.2462 12.1185 11.2402 12.2091Z"
        fill="white"
      />
      <path
        d="M16.376 14.9978C16.3092 15.131 16.216 15.2409 16.2041 15.2004C16.1905 15.1544 16.3021 14.9201 16.3929 14.8528C16.4029 14.847 16.4332 14.8294 16.4182 14.8914C16.4116 14.9185 16.3963 14.9573 16.376 14.9978Z"
        fill="white"
      />
      <path
        d="M11.5018 6.06627C11.5956 6.07909 11.6745 6.09388 11.6893 6.1016C11.6697 6.10919 11.2457 6.04232 11.2435 6.04088C11.2628 6.03958 11.3887 6.0507 11.5018 6.06627Z"
        fill="white"
      />
      <path
        d="M10.944 6.02544C11.0493 6.03853 11.1178 6.0507 11.1308 6.05475C11.1247 6.05528 11.1226 6.05541 11.031 6.0418C10.9601 6.0312 10.8718 6.01799 10.7796 6.00726C10.8101 6.0104 10.8735 6.01668 10.944 6.02544Z"
        fill="white"
      />
      <path
        d="M11.0808 8.43305C11.0795 8.57921 10.9429 8.63011 10.8602 8.54793C10.7679 8.45608 10.8003 8.25954 10.9389 8.24986C11.0322 8.25444 11.0816 8.34774 11.0808 8.43305Z"
        fill="white"
      />
      <path
        d="M16.8902 14.1867C16.8538 14.2814 16.8062 14.3959 16.7927 14.4157C16.7864 14.4026 16.912 14.0862 16.9397 14.0323C16.9408 14.0303 16.9424 14.0276 16.9438 14.0258C16.9473 14.0352 16.9118 14.1307 16.8902 14.1867Z"
        fill="white"
      />
      <path
        d="M14.1869 6.69054C14.2768 6.74223 14.375 6.80438 14.3894 6.81721C14.3742 6.81511 14.0691 6.63533 14.051 6.62263C14.0495 6.62146 14.0475 6.61989 14.0461 6.61871C14.0611 6.61871 14.1741 6.68322 14.1869 6.69054Z"
        fill="white"
      />
      <path
        d="M12.7937 14.6691C12.7646 14.8497 12.5022 14.9285 12.4037 14.7861C12.3343 14.6859 12.3954 14.4992 12.5958 14.4752C12.7453 14.47 12.8093 14.5713 12.7937 14.6691Z"
        fill="white"
      />
      <path
        d="M11.3401 6.68374C11.3791 6.75374 11.3628 6.79732 11.3225 6.78803C11.2421 6.76945 11.1662 6.60118 11.2081 6.57553C11.2105 6.57422 11.2141 6.5733 11.2169 6.57291C11.2573 6.57357 11.3144 6.63768 11.3401 6.68374Z"
        fill="white"
      />
      <path
        d="M12.2957 14.2288C12.2718 14.4084 12.0345 14.4879 11.9166 14.3559C11.8149 14.2419 11.89 14.037 12.0872 14.0176C12.2505 14.0163 12.3074 14.1404 12.2957 14.2288Z"
        fill="white"
      />
      <path
        d="M13.2537 15.0795C13.2147 15.2483 12.9635 15.3273 12.8752 15.1987C12.8115 15.1058 12.8837 14.9263 13.0732 14.899C13.2286 14.8897 13.2722 14.999 13.2537 15.0795Z"
        fill="white"
      />
      <path
        d="M10.8937 6.04258C10.9665 6.06666 11.0169 6.08707 11.0237 6.09296C11.0191 6.09401 10.9985 6.08707 10.9277 6.06051C10.8781 6.04193 10.8311 6.02427 10.7796 6.00726C10.8019 6.01393 10.8481 6.02767 10.8937 6.04258Z"
        fill="white"
      />
      <path
        d="M11.317 6.98024C11.3449 7.06791 11.3003 7.11515 11.2456 7.0878C11.1543 7.0424 11.1289 6.86693 11.1909 6.8528C11.1927 6.85227 11.1952 6.85214 11.1971 6.85188C11.2491 6.85332 11.3001 6.92738 11.317 6.98024Z"
        fill="white"
      />
      <path
        d="M11.2756 6.42819C11.3236 6.48328 11.3268 6.51913 11.2999 6.51691C11.2406 6.51194 11.1229 6.35675 11.1434 6.3349C11.1445 6.33372 11.1465 6.3332 11.148 6.33281C11.1737 6.33333 11.2376 6.38449 11.2756 6.42819Z"
        fill="white"
      />
      <path
        d="M14.7895 15.6293C14.7231 15.7777 14.5362 15.8673 14.4909 15.7723C14.4519 15.6905 14.5579 15.52 14.7012 15.481C14.8169 15.4608 14.8224 15.5555 14.7895 15.6293Z"
        fill="white"
      />
      <path
        d="M11.775 13.7512C11.7559 13.9386 11.5093 14.0142 11.3928 13.8683C11.2956 13.7467 11.3697 13.5425 11.5626 13.5276C11.7077 13.5301 11.7858 13.6456 11.775 13.7512Z"
        fill="white"
      />
      <path
        d="M10.7417 9.74859C10.7258 9.9064 10.5498 9.97313 10.4518 9.85838C10.3507 9.7397 10.4294 9.54656 10.5814 9.54055C10.7055 9.54879 10.7497 9.66708 10.7417 9.74859Z"
        fill="white"
      />
      <path
        d="M11.2068 7.30618C11.2225 7.40851 11.1525 7.45731 11.0907 7.41767C10.9968 7.35761 11.0061 7.179 11.0829 7.165C11.0851 7.16448 11.0882 7.16434 11.0905 7.16408C11.1524 7.16683 11.1978 7.24835 11.2068 7.30618Z"
        fill="white"
      />
      <path
        d="M10.737 10.7027C10.7227 10.8743 10.5226 10.9482 10.4128 10.8226C10.3118 10.7072 10.3767 10.4944 10.5539 10.4876C10.6918 10.4964 10.744 10.6186 10.737 10.7027Z"
        fill="white"
      />
      <path
        d="M10.9416 6.01759C11.0498 6.02597 11.1221 6.03486 11.1368 6.03814C11.131 6.03892 11.1297 6.03918 11.0356 6.02976C10.9481 6.021 10.8684 6.01341 10.7796 6.00726C10.8052 6.0087 10.8728 6.01236 10.9416 6.01759Z"
        fill="white"
      />
      <path
        d="M13.6611 15.4456C13.6139 15.6055 13.3737 15.6878 13.299 15.5696C13.2453 15.4846 13.3184 15.3164 13.5029 15.2852C13.6482 15.2725 13.6829 15.3713 13.6611 15.4456Z"
        fill="white"
      />
      <path
        d="M11.121 6.21544C11.1773 6.25718 11.1975 6.28505 11.184 6.28701C11.1527 6.29172 10.9892 6.15237 10.9905 6.13941C10.9909 6.13928 10.9914 6.13915 10.9915 6.13902C11.0053 6.13994 11.073 6.17998 11.121 6.21544Z"
        fill="white"
      />
      <path
        d="M16.6251 14.7581C16.577 14.8559 16.512 14.9688 16.4971 14.9736C16.4888 14.9484 16.6283 14.675 16.6733 14.6185C16.6783 14.613 16.6792 14.6122 16.6821 14.6114C16.6885 14.6265 16.6444 14.719 16.6251 14.7581Z"
        fill="white"
      />
      <path
        d="M12.5226 6.13666C12.6052 6.15459 12.701 6.18076 12.7156 6.19411C12.7152 6.19476 12.7146 6.19568 12.7142 6.19646C12.6749 6.20157 12.3282 6.11965 12.3129 6.10735C12.3216 6.09898 12.4425 6.11926 12.5226 6.13666Z"
        fill="white"
      />
      <path
        d="M12.0247 6.07661C12.1174 6.09061 12.2197 6.1118 12.2236 6.12149C12.2024 6.12685 11.8177 6.06692 11.8037 6.05606C11.8164 6.0507 11.9329 6.06274 12.0247 6.07661Z"
        fill="white"
      />
      <path
        d="M10.729 11.7082C10.7133 11.8785 10.5086 11.9521 10.3921 11.8291C10.2801 11.7108 10.346 11.4928 10.5383 11.4859C10.6913 11.4952 10.7365 11.6272 10.729 11.7082Z"
        fill="white"
      />
      <path
        d="M11.014 7.65947C11.0166 7.7792 10.9218 7.82539 10.8559 7.76638C10.7785 7.69729 10.7956 7.51358 10.9037 7.50547C10.9818 7.51005 11.0129 7.60229 11.014 7.65947Z"
        fill="white"
      />
      <path
        d="M11.2432 13.2485C11.2229 13.4316 10.9915 13.5033 10.8736 13.363C10.7807 13.2524 10.8261 13.0344 11.0322 13.0231C11.1943 13.0293 11.2532 13.1586 11.2432 13.2485Z"
        fill="white"
      />
      <path
        d="M10.7458 8.84705C10.7342 8.98326 10.5896 9.05222 10.5041 8.96246C10.4158 8.86969 10.4648 8.66831 10.6077 8.66164C10.712 8.66884 10.7518 8.77665 10.7458 8.84705Z"
        fill="white"
      />
      <path
        d="M15.5372 15.7352C15.4614 15.8653 15.3203 15.9645 15.305 15.8985C15.2907 15.838 15.4028 15.656 15.5176 15.6073C15.594 15.5831 15.5751 15.6638 15.5454 15.7205C15.543 15.7249 15.5398 15.7308 15.5372 15.7352Z"
        fill="white"
      />
      <path
        d="M15.9804 15.5266C15.9077 15.6459 15.8057 15.743 15.7922 15.7057C15.7754 15.6595 15.9065 15.4492 15.9999 15.3932C16.043 15.3732 16.0329 15.4152 16.023 15.4421C16.0181 15.4555 16.0059 15.4844 15.9804 15.5266Z"
        fill="white"
      />
      <path
        d="M12.968 6.21282C13.0643 6.24056 13.1553 6.27484 13.1628 6.28622C13.1401 6.29708 12.7998 6.19031 12.7811 6.17644C12.781 6.17566 12.781 6.17461 12.781 6.17383C12.7982 6.16951 12.8897 6.19031 12.968 6.21282Z"
        fill="white"
      />
      <path
        d="M11.5044 6.03552C11.5966 6.04416 11.6768 6.05528 11.6948 6.06208C11.6818 6.07032 11.268 6.02335 11.2651 6.02204C11.2816 6.01969 11.4007 6.02584 11.5044 6.03552Z"
        fill="white"
      />
      <path
        d="M13.3562 6.3069C13.4432 6.33896 13.5298 6.37743 13.5455 6.3909C13.5455 6.39156 13.5454 6.39234 13.5452 6.393C13.5193 6.39247 13.2255 6.28321 13.192 6.26162C13.1913 6.2611 13.1906 6.26032 13.19 6.25979C13.1902 6.25927 13.1908 6.25848 13.191 6.25783C13.2088 6.25757 13.2783 6.27811 13.3562 6.3069Z"
        fill="white"
      />
      <path
        d="M14.9599 15.931C14.8859 16.0638 14.7206 16.1502 14.6958 16.0689C14.6735 15.9963 14.7839 15.8397 14.9087 15.8006C15.0058 15.7795 14.9971 15.8643 14.9599 15.931Z"
        fill="white"
      />
      <path
        d="M10.8723 6.04756C10.9315 6.0749 10.9657 6.09466 10.9688 6.10016C10.9632 6.10107 10.9429 6.09152 10.8821 6.05946C10.8365 6.03539 10.8116 6.02243 10.7796 6.00726C10.8046 6.01785 10.8388 6.03225 10.8723 6.04756Z"
        fill="white"
      />
      <path
        d="M14.001 15.7859C13.945 15.9346 13.73 16.0143 13.6724 15.9077C13.6273 15.8244 13.727 15.6733 13.88 15.6428C14.0132 15.6272 14.0249 15.7223 14.001 15.7859Z"
        fill="white"
      />
      <path
        d="M16.2922 15.298C16.2279 15.4037 16.1533 15.4992 16.1445 15.4873C16.1319 15.4703 16.2761 15.2288 16.3426 15.1675C16.3488 15.1627 16.3582 15.1552 16.3564 15.1687C16.3543 15.1869 16.329 15.2375 16.2922 15.298Z"
        fill="white"
      />
      <path
        d="M10.9496 6.01092C11.0556 6.01458 11.1254 6.01995 11.1409 6.0227C11.136 6.02361 11.1348 6.02387 11.0427 6.01877C10.9425 6.01314 10.8672 6.00948 10.7796 6.00726C10.8066 6.00765 10.878 6.00856 10.9496 6.01092Z"
        fill="white"
      />
      <path
        d="M10.7449 8.03383C10.7339 8.15277 10.6241 8.21165 10.5555 8.1355C10.4824 8.0545 10.5218 7.87838 10.639 7.87249C10.7219 7.87878 10.7508 7.97063 10.7449 8.03383Z"
        fill="white"
      />
      <path
        d="M10.2905 10.2115C10.2669 10.3663 10.0883 10.4295 9.99656 10.3156C9.90832 10.2059 9.97552 10.0091 10.1379 10.0065C10.263 10.017 10.3025 10.1328 10.2905 10.2115Z"
        fill="white"
      />
      <path
        d="M10.7126 12.7305C10.6947 12.8912 10.4823 12.9808 10.3624 12.8353C10.2637 12.7156 10.3304 12.5173 10.5166 12.5111C10.6761 12.5206 10.7215 12.6507 10.7126 12.7305Z"
        fill="white"
      />
      <path
        d="M13.7695 6.463C13.8214 6.48878 13.8569 6.50736 13.8669 6.51416C13.8586 6.51442 13.5663 6.37808 13.5454 6.36643C13.5437 6.36552 13.5417 6.36408 13.5402 6.36303C13.545 6.3629 13.5461 6.3629 13.569 6.3718C13.6366 6.40072 13.7035 6.4312 13.7695 6.463Z"
        fill="white"
      />
      <path
        d="M10.3469 9.28186C10.326 9.42841 10.1685 9.47551 10.0919 9.38955C10.0079 9.29494 10.0713 9.09488 10.2178 9.09148C10.3242 9.10116 10.3574 9.20858 10.3469 9.28186Z"
        fill="white"
      />
      <path
        d="M10.2437 11.1955C10.2222 11.3691 10.0151 11.4121 9.92334 11.2991C9.83155 11.1861 9.90313 10.9831 10.0736 10.9803C10.208 10.9918 10.2539 11.1132 10.2437 11.1955Z"
        fill="white"
      />
      <path
        d="M14.2615 16.0975C14.1934 16.2358 14.0001 16.3094 13.9659 16.2103C13.9403 16.1357 14.0423 16 14.1777 15.9686C14.2809 15.9534 14.2979 16.0237 14.2615 16.0975Z"
        fill="white"
      />
      <path
        d="M11.7447 14.7412C11.7184 14.9022 11.4783 14.9597 11.3827 14.828C11.3059 14.7224 11.3816 14.5584 11.5538 14.5455C11.691 14.5476 11.7596 14.6486 11.7447 14.7412Z"
        fill="white"
      />
      <path
        d="M11.0715 6.4435C11.0998 6.50121 11.0896 6.53484 11.0616 6.52646C11.0045 6.50945 10.9554 6.3629 10.9863 6.35217C10.9871 6.35191 10.9882 6.35178 10.989 6.35165C11.0144 6.35335 11.0532 6.40608 11.0715 6.4435Z"
        fill="white"
      />
      <path
        d="M11.0508 6.70481C11.0673 6.77808 11.0321 6.81393 10.9927 6.79064C10.93 6.75335 10.9202 6.60536 10.9653 6.5945C10.9666 6.59424 10.9684 6.59411 10.9698 6.59398C11.0069 6.59647 11.0407 6.65993 11.0508 6.70481Z"
        fill="white"
      />
      <path
        d="M12.2368 15.1636C12.2104 15.3213 11.9699 15.3874 11.8795 15.2617C11.8149 15.1722 11.8699 15.0084 12.0542 14.9908C12.2005 14.9888 12.2491 15.0906 12.2368 15.1636Z"
        fill="white"
      />
      <path
        d="M12.6911 15.5632C12.6516 15.7172 12.406 15.7751 12.3361 15.6468C12.2906 15.5633 12.3581 15.4194 12.5271 15.3995C12.6705 15.3944 12.7093 15.4922 12.6911 15.5632Z"
        fill="white"
      />
      <path
        d="M11.0047 6.22577C11.0422 6.27 11.0487 6.29695 11.0315 6.29434C10.9957 6.28897 10.9072 6.15695 10.9165 6.1491C10.9169 6.14883 10.9173 6.14883 10.9177 6.1487C10.9302 6.15027 10.9773 6.19345 11.0047 6.22577Z"
        fill="white"
      />
      <path
        d="M15.535 16.0145C15.4547 16.1257 15.3417 16.2117 15.3368 16.1655C15.332 16.1192 15.4553 15.9476 15.5523 15.8995C15.5974 15.8821 15.588 15.9185 15.5783 15.9418C15.5723 15.9559 15.5601 15.98 15.535 16.0145Z"
        fill="white"
      />
      <path
        d="M16.4627 15.0737L16.3696 15.2276C16.4202 15.1461 16.5056 14.9995 16.5385 14.9404L16.4627 15.0737Z"
        fill="white"
      />
      <path
        d="M11.2293 14.2698C11.21 14.4343 10.9915 14.4981 10.8803 14.3717C10.7841 14.2623 10.8508 14.0764 11.0338 14.0667C11.1844 14.0719 11.2387 14.1887 11.2293 14.2698Z"
        fill="white"
      />
      <path
        d="M10.9414 6.99293C10.9463 7.08178 10.8886 7.12103 10.8436 7.0844C10.7806 7.0331 10.7958 6.87792 10.8645 6.87151C10.9125 6.87517 10.9389 6.94609 10.9414 6.99293Z"
        fill="white"
      />
      <path
        d="M15.0476 16.2073C14.9691 16.3219 14.8306 16.4038 14.8161 16.3443C14.802 16.2864 14.9239 16.1318 15.0309 16.0934C15.1073 16.0733 15.0856 16.1425 15.0635 16.182C15.0591 16.1897 15.0527 16.1999 15.0476 16.2073Z"
        fill="white"
      />
      <path
        d="M15.8978 15.8044C15.8263 15.9017 15.739 15.99 15.7341 15.9702C15.7278 15.9453 15.8683 15.7452 15.9468 15.6886C15.9539 15.6844 15.9687 15.6754 15.9633 15.6954C15.958 15.7149 15.933 15.7567 15.8978 15.8044Z"
        fill="white"
      />
      <path
        d="M10.4114 8.42389C10.3906 8.54505 10.2658 8.60106 10.1995 8.51888C10.1321 8.43527 10.1894 8.25732 10.3111 8.25392C10.3898 8.26151 10.4244 8.348 10.4114 8.42389Z"
        fill="white"
      />
      <path
        d="M13.0931 15.9145C13.0434 16.0595 12.8134 16.1178 12.7559 16C12.7152 15.9164 12.7989 15.7913 12.9511 15.7698C13.0861 15.7615 13.1155 15.8494 13.0931 15.9145Z"
        fill="white"
      />
      <path
        d="M10.8473 6.05188C10.8893 6.08119 10.9087 6.09963 10.9089 6.10435C10.9035 6.10735 10.8608 6.07229 10.8352 6.05122C10.818 6.03709 10.7986 6.02126 10.7796 6.00726C10.8139 6.02924 10.828 6.0384 10.8473 6.05188Z"
        fill="white"
      />
      <path
        d="M10.2062 12.2104C10.1893 12.3731 9.98877 12.4387 9.88127 12.3166C9.78156 12.2033 9.84904 11.9998 10.0267 11.9971C10.1647 12.0086 10.2148 12.1287 10.2062 12.2104Z"
        fill="white"
      />
      <path
        d="M11.9871 6.02401C12.0747 6.03408 12.1531 6.04795 12.1656 6.0558C12.1654 6.05632 12.1648 6.05711 12.1644 6.0575C12.1315 6.06234 11.7985 6.01851 11.7876 6.01301C11.7963 6.00752 11.9039 6.01458 11.9871 6.02401Z"
        fill="white"
      />
      <path
        d="M11.486 6.00451C11.5683 6.0087 11.6448 6.01615 11.6641 6.02165C11.6637 6.02217 11.6631 6.02283 11.6627 6.02335C11.6258 6.0261 11.2745 6.00189 11.2733 6.0015C11.2859 5.99875 11.3934 5.9998 11.486 6.00451Z"
        fill="white"
      />
      <path
        d="M10.7499 7.30932C10.743 7.40367 10.6672 7.45614 10.6171 7.40144C10.562 7.34138 10.5907 7.18528 10.6762 7.18031C10.7363 7.18528 10.7533 7.26196 10.7499 7.30932Z"
        fill="white"
      />
      <path
        d="M12.4387 6.06234C12.5089 6.07517 12.6123 6.09937 12.6157 6.10919C12.5973 6.11455 12.286 6.05685 12.2614 6.0435C12.2615 6.04298 12.2615 6.04219 12.2617 6.04154C12.2786 6.0384 12.3596 6.04782 12.4387 6.06234Z"
        fill="white"
      />
      <path
        d="M14.4373 16.3804C14.3644 16.4968 14.1925 16.5693 14.1786 16.4896C14.1671 16.4242 14.2735 16.2983 14.388 16.2677C14.4728 16.2523 14.4797 16.3128 14.4373 16.3804Z"
        fill="white"
      />
      <path
        d="M10.7 13.7652C10.6813 13.937 10.4677 13.9962 10.3588 13.8597C10.276 13.7558 10.3201 13.5637 10.5093 13.5574C10.6637 13.5662 10.7082 13.6896 10.7 13.7652Z"
        fill="white"
      />
      <path
        d="M9.89698 9.72256C9.86884 9.86256 9.70807 9.91883 9.63254 9.81506C9.56615 9.72399 9.62147 9.53256 9.77473 9.53283C9.88305 9.54486 9.91132 9.65098 9.89698 9.72256Z"
        fill="white"
      />
      <path
        d="M13.4403 16.2384C13.3821 16.3702 13.1652 16.4271 13.1267 16.3207C13.1001 16.2469 13.1894 16.1328 13.3279 16.1099C13.4424 16.0994 13.4713 16.1683 13.4403 16.2384Z"
        fill="white"
      />
      <path
        d="M9.7985 10.6827C9.77145 10.8295 9.59661 10.8846 9.51083 10.7735C9.42778 10.6662 9.49963 10.4777 9.65876 10.4781C9.78552 10.4918 9.81175 10.6109 9.7985 10.6827Z"
        fill="white"
      />
      <path
        d="M16.1352 15.5756C16.0809 15.6509 16.0169 15.7352 16.0035 15.749C16.0053 15.7384 16.1759 15.5038 16.2091 15.4636C16.2106 15.4619 16.2125 15.4598 16.214 15.4583C16.2144 15.4652 16.1591 15.5427 16.1352 15.5756Z"
        fill="white"
      />
      <path
        d="M12.9073 6.14164C12.9256 6.14739 12.9868 6.1665 13.001 6.17618C13.0012 6.1767 13.0012 6.17736 13.0012 6.17788C12.9804 6.17984 12.7055 6.10762 12.6743 6.09191C12.6739 6.09165 12.6731 6.09113 12.6725 6.09074C12.6728 6.09021 12.6731 6.08943 12.6732 6.08904C12.6768 6.08864 12.6832 6.08799 12.7187 6.09427C12.7821 6.10906 12.8449 6.12476 12.9073 6.14164Z"
        fill="white"
      />
      <path
        d="M10.1739 13.2387C10.1584 13.3825 9.9732 13.4648 9.85791 13.3402C9.75438 13.2281 9.81339 13.0347 9.99164 13.0322C10.1314 13.0439 10.1823 13.1599 10.1739 13.2387Z"
        fill="white"
      />
      <path
        d="M9.72201 11.6825C9.69796 11.8337 9.51944 11.8889 9.42355 11.7746C9.33203 11.6656 9.40006 11.4743 9.56725 11.4749C9.69469 11.4883 9.73444 11.6044 9.72201 11.6825Z"
        fill="white"
      />
      <path
        d="M10.0143 8.82651C9.98522 8.95408 9.84043 9.00564 9.7832 8.90894C9.72938 8.818 9.79836 8.65706 9.91815 8.65654C10.0079 8.66687 10.0285 8.76409 10.0143 8.82651Z"
        fill="white"
      />
      <path
        d="M10.4815 7.6562C10.4607 7.76481 10.3629 7.80001 10.3191 7.73746C10.2658 7.66144 10.3266 7.51764 10.4133 7.51449C10.4778 7.52117 10.492 7.60138 10.4815 7.6562Z"
        fill="white"
      />
      <path
        d="M15.0532 16.4574C14.976 16.5505 14.8584 16.6285 14.8501 16.5917C14.8419 16.5549 14.968 16.4039 15.0644 16.3636C15.115 16.3474 15.0983 16.388 15.0887 16.4061C15.0814 16.4203 15.068 16.4395 15.0532 16.4574Z"
        fill="white"
      />
      <path
        d="M15.4542 16.2698C15.3804 16.3551 15.289 16.4313 15.2827 16.4127C15.2742 16.3874 15.422 16.2164 15.5008 16.1704C15.5082 16.1668 15.5238 16.1592 15.5195 16.1757C15.5154 16.1921 15.4903 16.2281 15.4542 16.2698Z"
        fill="white"
      />
      <path
        d="M13.7134 16.5239C13.654 16.6367 13.4661 16.7009 13.4342 16.6193C13.4093 16.5561 13.507 16.4399 13.6311 16.4154C13.724 16.4044 13.746 16.462 13.7134 16.5239Z"
        fill="white"
      />
      <path
        d="M14.5279 16.6363C14.4473 16.7372 14.311 16.8012 14.3069 16.7399C14.3032 16.6864 14.4141 16.5685 14.5148 16.5376C14.5885 16.5212 14.5678 16.5781 14.5439 16.6143C14.5395 16.6211 14.5329 16.63 14.5279 16.6363Z"
        fill="white"
      />
      <path
        d="M10.8855 6.22865C10.9049 6.27863 10.8966 6.30088 10.8821 6.29434C10.8524 6.2806 10.8244 6.16741 10.8402 6.15943C10.8404 6.1593 10.841 6.15917 10.8414 6.15917C10.8526 6.16087 10.8747 6.20078 10.8855 6.22865Z"
        fill="white"
      />
      <path
        d="M11.6863 15.6182C11.6637 15.7549 11.457 15.8065 11.3703 15.6969C11.3087 15.6188 11.3546 15.4795 11.5231 15.4677C11.6611 15.4687 11.6963 15.5582 11.6863 15.6182Z"
        fill="white"
      />
      <path
        d="M11.1983 15.2053C11.1792 15.349 10.9653 15.3953 10.8758 15.2751C10.8071 15.1828 10.8709 15.0464 11.0277 15.0384C11.1676 15.043 11.2065 15.1432 11.1983 15.2053Z"
        fill="white"
      />
      <path
        d="M10.8657 6.45371C10.871 6.51102 10.8455 6.53981 10.8218 6.52031C10.7866 6.49139 10.787 6.36918 10.8224 6.3646C10.8452 6.36735 10.8626 6.41917 10.8657 6.45371Z"
        fill="white"
      />
      <path
        d="M15.7385 16.0636C15.6817 16.1293 15.6087 16.2066 15.5963 16.212C15.5955 16.1984 15.7695 16.0005 15.8127 15.962C15.8175 15.9582 15.8184 15.9576 15.8206 15.9568C15.8207 15.9678 15.7579 16.0413 15.7385 16.0636Z"
        fill="white"
      />
      <path
        d="M12.1352 16.0035C12.0983 16.1344 11.8803 16.1747 11.8198 16.0617C11.7824 15.9919 11.8385 15.8739 11.9897 15.8602C12.1184 15.8584 12.1523 15.9429 12.1352 16.0035Z"
        fill="white"
      />
      <path
        d="M10.8153 6.049C10.8415 6.08132 10.8493 6.09741 10.8479 6.10173C10.8444 6.10343 10.8352 6.09191 10.8281 6.08223C10.8106 6.05803 10.7967 6.03172 10.7796 6.00726C10.7915 6.02126 10.8037 6.03486 10.8153 6.049Z"
        fill="white"
      />
      <path
        d="M10.6862 14.7504C10.6727 14.8925 10.4708 14.9442 10.3729 14.8305C10.2893 14.7334 10.348 14.5786 10.5097 14.5736C10.6421 14.5811 10.6926 14.6814 10.6862 14.7504Z"
        fill="white"
      />
      <path
        d="M10.1494 8.01067C10.1224 8.11561 10.016 8.16455 9.96856 8.09363C9.91938 8.02009 9.9818 7.86713 10.0833 7.86595C10.1524 7.8742 10.1636 7.95545 10.1494 8.01067Z"
        fill="white"
      />
      <path
        d="M9.66846 12.7071C9.64893 12.8628 9.45783 12.9132 9.36331 12.7874C9.28108 12.6782 9.34405 12.505 9.5051 12.5057C9.63349 12.5192 9.67789 12.6316 9.66846 12.7071Z"
        fill="white"
      />
      <path
        d="M10.7571 6.70742C10.751 6.77756 10.7064 6.80791 10.6788 6.77847C10.6402 6.73726 10.6679 6.6102 10.7165 6.60628C10.7482 6.60994 10.7604 6.66725 10.7571 6.70742Z"
        fill="white"
      />
      <path
        d="M9.40661 10.1668C9.37629 10.2915 9.22235 10.358 9.14886 10.2462C9.08261 10.1453 9.15474 9.97889 9.29488 9.98242C9.39951 9.99629 9.42314 10.0986 9.40661 10.1668Z"
        fill="white"
      />
      <path
        d="M12.5346 16.3334C12.4973 16.4525 12.288 16.5027 12.2361 16.4051C12.202 16.3408 12.2636 16.2294 12.4041 16.2133C12.5202 16.2086 12.5506 16.2822 12.5346 16.3334Z"
        fill="white"
      />
      <path
        d="M13.8976 16.7832C13.8284 16.8831 13.6708 16.9405 13.6564 16.8709C13.6455 16.8173 13.7441 16.7152 13.8507 16.691C13.9312 16.6787 13.9351 16.7295 13.8976 16.7832Z"
        fill="white"
      />
      <path
        d="M9.56588 9.23881C9.532 9.3762 9.38367 9.40813 9.33231 9.31745C9.28081 9.2269 9.35621 9.06989 9.47737 9.07185C9.55605 9.08245 9.58418 9.16514 9.56588 9.23881Z"
        fill="white"
      />
      <path
        d="M9.27876 11.148C9.24953 11.2985 9.07592 11.3279 9.00407 11.2267C8.93141 11.1243 9.00298 10.9494 9.14873 10.9532C9.2498 10.9668 9.29502 11.0649 9.27876 11.148Z"
        fill="white"
      />
      <path
        d="M10.5656 6.99071C10.548 7.07236 10.4858 7.10272 10.457 7.06163C10.4186 7.00707 10.4675 6.88302 10.5286 6.87988C10.5719 6.88485 10.5742 6.95054 10.5656 6.99071Z"
        fill="white"
      />
      <path
        d="M10.1606 14.2567C10.1512 14.4005 9.95599 14.4553 9.85792 14.3416C9.77855 14.2498 9.81325 14.0769 9.98549 14.0751C10.1139 14.0853 10.1651 14.1875 10.1606 14.2567Z"
        fill="white"
      />
      <path
        d="M12.8784 16.6409C12.8292 16.7511 12.631 16.7963 12.5993 16.7046C12.5777 16.6426 12.6562 16.5494 12.7747 16.5332C12.8725 16.5269 12.9042 16.583 12.8784 16.6409Z"
        fill="white"
      />
      <path
        d="M14.9718 16.6913C14.8959 16.7661 14.8005 16.8318 14.7989 16.8102C14.7972 16.7864 14.9341 16.6483 15.0129 16.6103C15.021 16.607 15.0401 16.5994 15.0311 16.6197C15.0244 16.6345 15.0023 16.6612 14.9718 16.6913Z"
        fill="white"
      />
      <path
        d="M14.5338 16.8658C14.4545 16.9458 14.3432 17.0044 14.3448 16.9651C14.3463 16.9281 14.4597 16.8143 14.55 16.7825C14.5943 16.7706 14.5854 16.7966 14.5762 16.8131C14.5716 16.8215 14.5598 16.8395 14.5338 16.8658Z"
        fill="white"
      />
      <path
        d="M9.75151 8.38948C9.71709 8.49402 9.60194 8.53747 9.55905 8.46223C9.51561 8.38607 9.58842 8.23769 9.69086 8.23926C9.76339 8.24973 9.76995 8.33347 9.75151 8.38948Z"
        fill="white"
      />
      <path
        d="M9.18697 12.154C9.17113 12.2915 9.01077 12.3488 8.91898 12.2499C8.82732 12.1512 8.8877 11.9629 9.04369 11.9678C9.16184 11.9833 9.19421 12.0903 9.18697 12.154Z"
        fill="white"
      />
      <path
        d="M9.63486 13.7361C9.6186 13.8804 9.44172 13.926 9.34801 13.8101C9.26456 13.7069 9.31796 13.544 9.47272 13.5454C9.60495 13.5591 9.64237 13.6697 9.63486 13.7361Z"
        fill="white"
      />
      <path
        d="M15.2973 16.5034C15.2405 16.5587 15.1629 16.6266 15.1481 16.6309C15.148 16.6304 15.1477 16.6298 15.1475 16.6292C15.1596 16.6053 15.3091 16.4593 15.3652 16.42C15.3704 16.4167 15.3713 16.4162 15.3741 16.4156C15.3743 16.4161 15.3748 16.4167 15.375 16.4174C15.3679 16.4335 15.3183 16.4828 15.2973 16.5034Z"
        fill="white"
      />
      <path
        d="M10.2975 7.30003C10.2693 7.38391 10.1901 7.42172 10.1628 7.36415C10.1348 7.30527 10.1916 7.18201 10.2622 7.1807C10.3118 7.18724 10.3126 7.25437 10.2975 7.30003Z"
        fill="white"
      />
      <path
        d="M13.1549 16.9102C13.0956 17.0071 12.9224 17.0489 12.9046 16.9705C12.8926 16.9181 12.9763 16.8348 13.0816 16.8179C13.171 16.8095 13.186 16.8591 13.1549 16.9102Z"
        fill="white"
      />
      <path
        d="M13.9953 17.0143C13.9251 17.0941 13.7934 17.1492 13.7882 17.1009C13.7836 17.0584 13.8867 16.9615 13.9786 16.9371C14.045 16.9247 14.0275 16.9709 14.0091 16.9971C14.0053 17.0024 13.9997 17.0094 13.9953 17.0143Z"
        fill="white"
      />
      <path
        d="M9.0762 9.65949C9.0415 9.77895 8.90586 9.82252 8.85287 9.73106C8.80274 9.64431 8.87786 9.49252 8.99506 9.4975C9.07469 9.51019 9.09627 9.5904 9.0762 9.65949Z"
        fill="white"
      />
      <path
        d="M8.88701 10.6147C8.85328 10.7396 8.70617 10.7797 8.6447 10.6805C8.58706 10.5872 8.65904 10.431 8.7858 10.437C8.88428 10.4526 8.90423 10.5503 8.88701 10.6147Z"
        fill="white"
      />
      <path
        d="M11.1602 16.0307C11.1435 16.1407 10.9639 16.1782 10.8896 16.0873C10.8311 16.0157 10.8869 15.9072 11.0165 15.9009C11.117 15.9039 11.1693 15.9707 11.1602 16.0307Z"
        fill="white"
      />
      <path
        d="M10.7908 6.05397C10.7992 6.09165 10.7952 6.10068 10.7922 6.09898C10.7891 6.09728 10.7862 6.08563 10.7847 6.07556C10.7817 6.05292 10.7821 6.03002 10.7796 6.00726C10.7831 6.02296 10.7874 6.0384 10.7908 6.05397Z"
        fill="white"
      />
      <path
        d="M9.12988 13.198C9.11103 13.3279 8.95053 13.3692 8.86557 13.2657C8.78416 13.1665 8.83811 13.0038 8.98386 13.008C9.08904 13.0218 9.14149 13.1174 9.12988 13.198Z"
        fill="white"
      />
      <path
        d="M10.6758 15.629C10.6642 15.7471 10.4821 15.7853 10.4016 15.6865C10.3432 15.6152 10.38 15.4931 10.5219 15.489C10.637 15.495 10.6813 15.5745 10.6758 15.629Z"
        fill="white"
      />
      <path
        d="M9.96487 7.62349C9.92949 7.71587 9.84125 7.744 9.81448 7.68957C9.78497 7.62925 9.85354 7.50062 9.93168 7.50102C9.98385 7.509 9.98249 7.57743 9.96487 7.62349Z"
        fill="white"
      />
      <path
        d="M10.7694 6.23428C10.7663 6.28099 10.7499 6.29761 10.7404 6.28792C10.7209 6.26804 10.7424 6.17265 10.759 6.16938C10.7686 6.1716 10.7708 6.21216 10.7694 6.23428Z"
        fill="white"
      />
      <path
        d="M11.6075 16.3893C11.5849 16.4975 11.3973 16.5333 11.3401 16.4406C11.3039 16.3819 11.3458 16.2847 11.4754 16.2756C11.5817 16.2762 11.6172 16.3437 11.6075 16.3893Z"
        fill="white"
      />
      <path
        d="M13.3485 17.1457C13.2891 17.2205 13.1475 17.2639 13.135 17.211C13.1248 17.1675 13.2111 17.0867 13.3052 17.0689C13.3765 17.0603 13.3832 17.102 13.3485 17.1457Z"
        fill="white"
      />
      <path
        d="M10.6603 6.4507C10.6459 6.50932 10.6134 6.52725 10.6004 6.50736C10.5817 6.47831 10.62 6.37834 10.6511 6.3752C10.6691 6.37821 10.6672 6.42204 10.6603 6.4507Z"
        fill="white"
      />
      <path
        d="M9.30157 8.77809C9.26196 8.87728 9.14886 8.91562 9.10939 8.84313C9.07155 8.77404 9.13657 8.62343 9.2453 8.62801C9.31947 8.64031 9.32274 8.72484 9.30157 8.77809Z"
        fill="white"
      />
      <path
        d="M10.1642 15.1919C10.1568 15.3133 9.98481 15.34 9.90381 15.2583C9.82322 15.1773 9.86953 15.0428 10.0098 15.041C10.1105 15.0489 10.1683 15.1254 10.1642 15.1919Z"
        fill="white"
      />
      <path
        d="M8.74291 11.6073C8.71791 11.7385 8.5678 11.7791 8.49568 11.674C8.42957 11.578 8.49431 11.4237 8.62845 11.4304C8.73403 11.4465 8.75466 11.545 8.74291 11.6073Z"
        fill="white"
      />
      <path
        d="M12.002 16.7063C11.9751 16.8023 11.793 16.8407 11.749 16.7596C11.7209 16.7075 11.7678 16.6192 11.888 16.6084C11.9862 16.6066 12.0138 16.6646 12.002 16.7063Z"
        fill="white"
      />
      <path
        d="M14.4586 17.069C14.3887 17.1274 14.2977 17.1798 14.2965 17.1624C14.295 17.1431 14.423 17.0333 14.4963 17.0036C14.504 17.0011 14.5221 16.9951 14.5144 17.0111C14.5083 17.0231 14.4876 17.0448 14.4586 17.069Z"
        fill="white"
      />
      <path
        d="M14.8131 16.9018C14.7582 16.9469 14.6828 17.0015 14.668 17.0045C14.6677 17.004 14.6673 17.0033 14.6672 17.0028C14.679 16.9823 14.8222 16.8642 14.8762 16.8338C14.8838 16.83 14.8851 16.8296 14.8874 16.8301C14.889 16.8394 14.8209 16.8955 14.8131 16.9018Z"
        fill="white"
      />
      <path
        d="M14.0084 17.216C13.9394 17.2781 13.8339 17.3276 13.8319 17.2987C13.83 17.2716 13.9351 17.1796 14.017 17.1549C14.0607 17.1448 14.0475 17.1703 14.0398 17.1815C14.0375 17.1852 14.0282 17.198 14.0084 17.216Z"
        fill="white"
      />
      <path
        d="M9.63978 14.7143C9.63267 14.8337 9.47409 14.8729 9.38653 14.7767C9.30813 14.6909 9.35061 14.5558 9.48952 14.5573C9.59894 14.5681 9.64333 14.6557 9.63978 14.7143Z"
        fill="white"
      />
      <path
        d="M10.4685 6.69748C10.4443 6.76173 10.3966 6.78698 10.3824 6.75558C10.364 6.71488 10.4184 6.61544 10.4599 6.61361C10.486 6.61766 10.4806 6.66516 10.4685 6.69748Z"
        fill="white"
      />
      <path
        d="M15.1263 16.6722L14.9673 16.8046C15.0323 16.7518 15.1137 16.6828 15.1456 16.6555C15.1468 16.6545 15.1483 16.6532 15.1495 16.6521L15.1263 16.6722Z"
        fill="white"
      />
      <path
        d="M12.3425 16.9916C12.3047 17.0775 12.1473 17.1122 12.112 17.0423C12.0894 16.9981 12.1397 16.9204 12.2525 16.9079C12.3365 16.9041 12.3615 16.9482 12.3425 16.9916Z"
        fill="white"
      />
      <path
        d="M9.56561 7.97115C9.52586 8.05568 9.43311 8.08931 9.40811 8.02833C9.38244 7.96592 9.45661 7.84188 9.53515 7.84475C9.58924 7.85444 9.58773 7.92431 9.56561 7.97115Z"
        fill="white"
      />
      <path
        d="M8.64688 12.6318C8.62913 12.7552 8.48762 12.7947 8.41044 12.6979C8.32876 12.5955 8.39678 12.4524 8.52395 12.4587C8.61806 12.473 8.65754 12.5586 8.64688 12.6318Z"
        fill="white"
      />
      <path
        d="M10.1972 6.97579C10.1659 7.03781 10.1091 7.06712 10.0942 7.02892C10.0773 6.98613 10.1382 6.88119 10.1916 6.88106C10.2288 6.88721 10.2114 6.94753 10.1972 6.97579Z"
        fill="white"
      />
      <path
        d="M9.11499 14.1986C9.10775 14.3178 8.95818 14.3561 8.87609 14.2598C8.80779 14.1799 8.8317 14.0361 8.97402 14.0407C9.08289 14.054 9.11827 14.1447 9.11499 14.1986Z"
        fill="white"
      />
      <path
        d="M8.55646 10.083C8.52177 10.192 8.39651 10.228 8.35034 10.1422C8.30568 10.059 8.37589 9.92249 8.48461 9.92982C8.55865 9.94356 8.57695 10.0189 8.55646 10.083Z"
        fill="white"
      />
      <path
        d="M13.4564 17.3467C13.3936 17.41 13.2755 17.4506 13.2759 17.4087C13.2761 17.3774 13.357 17.3062 13.4392 17.2883C13.512 17.2772 13.47 17.3314 13.4681 17.3336C13.4649 17.3378 13.4601 17.343 13.4564 17.3467Z"
        fill="white"
      />
      <path
        d="M12.6152 17.2424C12.5679 17.3133 12.4274 17.3442 12.4092 17.2877C12.397 17.2501 12.4555 17.1823 12.5502 17.1696C12.6236 17.1647 12.6411 17.2035 12.6152 17.2424Z"
        fill="white"
      />
      <path
        d="M8.81243 9.16933C8.77159 9.26655 8.66136 9.29874 8.62803 9.22285C8.59457 9.14695 8.66696 9.02173 8.76272 9.02814C8.83224 9.04123 8.83374 9.11856 8.81243 9.16933Z"
        fill="white"
      />
      <path
        d="M8.35048 11.0572C8.3207 11.1615 8.19121 11.2077 8.13494 11.1073C8.08576 11.0198 8.14873 10.8871 8.26197 10.8953C8.35034 10.9113 8.36687 10.9996 8.35048 11.0572Z"
        fill="white"
      />
      <path
        d="M9.86379 7.26628C9.82513 7.33693 9.75848 7.35748 9.74564 7.31718C9.73088 7.2702 9.80027 7.16775 9.85832 7.16879C9.89507 7.1756 9.88359 7.2299 9.86379 7.26628Z"
        fill="white"
      />
      <path
        d="M8.61 13.6629C8.59771 13.7782 8.46426 13.7999 8.39501 13.723C8.31893 13.6387 8.35867 13.4931 8.48407 13.4992C8.57231 13.5128 8.61738 13.5932 8.61 13.6629Z"
        fill="white"
      />
      <path
        d="M14.3041 17.2496C14.2495 17.2873 14.1756 17.3314 14.1673 17.3293C14.1725 17.3153 14.31 17.2215 14.3585 17.1988C14.3653 17.1958 14.3666 17.1957 14.3689 17.1959C14.3679 17.2044 14.3279 17.2332 14.3041 17.2496Z"
        fill="white"
      />
      <path
        d="M8.20269 12.0693C8.17878 12.1745 8.05366 12.2055 7.9937 12.121C7.93291 12.0356 7.98578 11.8969 8.10243 11.9055C8.19353 11.9222 8.21593 12.0113 8.20269 12.0693Z"
        fill="white"
      />
      <path
        d="M13.9277 17.3984C13.8651 17.4419 13.788 17.479 13.7878 17.4655C13.7875 17.4502 13.8991 17.3692 13.9648 17.3467C13.9719 17.3447 13.9885 17.3401 13.9807 17.3527C13.9762 17.3602 13.9599 17.376 13.9277 17.3984Z"
        fill="white"
      />
      <path
        d="M9.11731 8.32536C9.0777 8.40793 8.98577 8.44011 8.96105 8.38005C8.93605 8.31895 9.01145 8.20014 9.09026 8.20472C9.14381 8.21532 9.13835 8.28166 9.11731 8.32536Z"
        fill="white"
      />
      <path
        d="M10.6578 16.4009C10.6518 16.4888 10.507 16.5172 10.4428 16.4432C10.3984 16.3919 10.4238 16.3046 10.5366 16.3017C10.6247 16.3059 10.6605 16.3622 10.6578 16.4009Z"
        fill="white"
      />
      <path
        d="M10.1749 16.0239C10.1683 16.1153 10.0254 16.1399 9.95667 16.0614C9.9019 15.9988 9.93973 15.9094 10.0476 15.9089C10.1408 15.9159 10.1782 15.9804 10.1749 16.0239Z"
        fill="white"
      />
      <path
        d="M10.7638 6.05201C10.7482 6.09636 10.7443 6.08943 10.7443 6.08943C10.7413 6.08145 10.7605 6.04455 10.7791 6.0087C10.7734 6.02296 10.7689 6.03761 10.7638 6.05201Z"
        fill="white"
      />
      <path
        d="M14.4574 17.1767C14.4588 17.1757 14.6363 17.0528 14.6544 17.0408C14.6557 17.0398 14.6575 17.0388 14.6589 17.0379C14.6578 17.0392 14.6571 17.0397 14.6388 17.0528L14.4574 17.1767Z"
        fill="white"
      />
      <path
        d="M11.1027 16.7443C11.0911 16.8254 10.9436 16.8509 10.8922 16.7808C10.8556 16.7308 10.8993 16.6593 10.9942 16.6548C11.0689 16.6568 11.1087 16.7028 11.1027 16.7443Z"
        fill="white"
      />
      <path
        d="M12.8161 17.4517C12.7623 17.5125 12.6414 17.5402 12.6387 17.4923C12.6372 17.4622 12.6941 17.408 12.7764 17.3952C12.8442 17.3885 12.8404 17.4241 12.8161 17.4517Z"
        fill="white"
      />
      <path
        d="M9.6645 15.6031C9.65699 15.6966 9.51643 15.7191 9.44964 15.6374C9.40006 15.5767 9.42232 15.4776 9.53364 15.4795C9.62885 15.4889 9.66805 15.5589 9.6645 15.6031Z"
        fill="white"
      />
      <path
        d="M10.6515 6.23127C10.6306 6.27118 10.6127 6.28112 10.6103 6.27026C10.6053 6.24933 10.6527 6.17893 10.6679 6.1771C10.6758 6.17932 10.6603 6.21452 10.6515 6.23127Z"
        fill="white"
      />
      <path
        d="M13.4731 17.522C13.4159 17.566 13.3211 17.6058 13.3256 17.5784C13.3293 17.556 13.4126 17.4948 13.4804 17.4775C13.5129 17.4712 13.5107 17.4826 13.5032 17.4935C13.5 17.4979 13.492 17.5076 13.4731 17.522Z"
        fill="white"
      />
      <path
        d="M11.4928 17.047C11.4694 17.1181 11.3286 17.1368 11.2947 17.0733C11.2718 17.0304 11.3175 16.9711 11.4018 16.9656C11.4754 16.966 11.5059 17.0074 11.4928 17.047Z"
        fill="white"
      />
      <path
        d="M8.12455 13.0914C8.10926 13.1826 7.99957 13.2247 7.93251 13.1403C7.86913 13.0604 7.90806 12.9309 8.01801 12.9391C8.10147 12.9544 8.13343 13.0373 8.12455 13.0914Z"
        fill="white"
      />
      <path
        d="M10.4567 6.44089C10.4294 6.48315 10.3988 6.49964 10.3958 6.48119C10.3913 6.45371 10.4469 6.3803 10.4729 6.37913C10.4871 6.38227 10.4716 6.41786 10.4567 6.44089Z"
        fill="white"
      />
      <path
        d="M9.46275 7.58123C9.42205 7.64678 9.35361 7.67033 9.34282 7.62284C9.33026 7.5684 9.40497 7.4789 9.46029 7.48191C9.4995 7.49003 9.48515 7.54485 9.46275 7.58123Z"
        fill="white"
      />
      <path
        d="M9.14012 15.1416C9.1337 15.2265 9.015 15.2617 8.93988 15.1775C8.87923 15.1096 8.90955 15.0095 9.01596 15.0131C9.10748 15.0241 9.14354 15.0949 9.14012 15.1416Z"
        fill="white"
      />
      <path
        d="M8.01979 10.5054C7.9896 10.5987 7.88839 10.6283 7.84522 10.5566C7.79769 10.4775 7.8694 10.3593 7.95969 10.3678C8.02334 10.3812 8.03823 10.4488 8.01979 10.5054Z"
        fill="white"
      />
      <path
        d="M8.29325 9.56698C8.25609 9.65268 8.15624 9.68029 8.12633 9.61304C8.09642 9.54565 8.16471 9.4288 8.24872 9.43652C8.31169 9.45039 8.31251 9.52262 8.29325 9.56698Z"
        fill="white"
      />
      <path
        d="M11.8315 17.3049C11.8053 17.3671 11.6809 17.3876 11.6541 17.3341C11.6365 17.2992 11.6851 17.2478 11.7609 17.2413C11.833 17.2398 11.8426 17.2785 11.8315 17.3049Z"
        fill="white"
      />
      <path
        d="M12.9308 17.6275C12.8819 17.6707 12.7856 17.6991 12.7856 17.67C12.7856 17.6476 12.8479 17.5987 12.9146 17.5864C12.9681 17.5795 12.9463 17.6103 12.9437 17.6139C12.9414 17.617 12.9363 17.6225 12.9308 17.6275Z"
        fill="white"
      />
      <path
        d="M8.62694 8.69003C8.58419 8.77574 8.49814 8.79066 8.48052 8.73295C8.46249 8.67328 8.53187 8.57227 8.60386 8.57816C8.65057 8.58836 8.65098 8.64227 8.62694 8.69003Z"
        fill="white"
      />
      <path
        d="M7.81203 11.489C7.79195 11.5823 7.68937 11.6162 7.63801 11.5464C7.58898 11.4802 7.62749 11.3442 7.73527 11.3544C7.8011 11.368 7.82405 11.4339 7.81203 11.489Z"
        fill="white"
      />
      <path
        d="M8.61547 14.6341C8.61602 14.7244 8.5051 14.7551 8.43571 14.684C8.36701 14.6139 8.39979 14.5103 8.49021 14.5108C8.49349 14.5108 8.49773 14.5111 8.50087 14.5113C8.5749 14.5222 8.6152 14.5826 8.61547 14.6341Z"
        fill="white"
      />
      <path
        d="M10.1851 6.67798C10.1524 6.72391 10.1144 6.7404 10.109 6.71959C10.1009 6.68897 10.1669 6.61073 10.2006 6.61086C10.2197 6.61478 10.2027 6.65325 10.1851 6.67798Z"
        fill="white"
      />
      <path
        d="M13.7754 17.549C13.73 17.5746 13.6693 17.6046 13.6617 17.6034C13.6665 17.5931 13.7867 17.5267 13.8242 17.5121C13.83 17.5101 13.831 17.5098 13.8326 17.5102C13.8304 17.5166 13.8004 17.5348 13.7754 17.549Z"
        fill="white"
      />
      <path
        d="M12.1034 17.5272C12.074 17.577 11.9643 17.5986 11.9487 17.5576C11.9381 17.5291 11.9868 17.4842 12.0516 17.4775C12.1035 17.4753 12.1187 17.5011 12.1034 17.5272Z"
        fill="white"
      />
      <path
        d="M13.962 17.4726C13.9629 17.4719 14.1105 17.3878 14.1333 17.3755C14.135 17.3745 14.1367 17.3736 14.1385 17.3728C14.1338 17.3766 14.0962 17.398 14.0849 17.4045L13.962 17.4726Z"
        fill="white"
      />
      <path
        d="M13.401 17.671C13.3484 17.7022 13.2815 17.7289 13.2854 17.7171C13.2894 17.7052 13.3767 17.6535 13.4278 17.6386C13.4332 17.6373 13.4459 17.6342 13.4411 17.6416C13.4383 17.6461 13.4263 17.6561 13.401 17.671Z"
        fill="white"
      />
      <path
        d="M7.67844 12.5107C7.66028 12.5957 7.56452 12.6176 7.5159 12.5478C7.46686 12.4776 7.50593 12.3661 7.59744 12.3751C7.67011 12.3904 7.68855 12.4637 7.67844 12.5107Z"
        fill="white"
      />
      <path
        d="M9.01131 7.90717C8.97279 7.96553 8.90709 7.98673 8.8963 7.94446C8.88415 7.89736 8.95176 7.80812 9.00899 7.8127C9.04765 7.82173 9.03358 7.87328 9.01131 7.90717Z"
        fill="white"
      />
      <path
        d="M9.8474 6.93497C9.8138 6.97854 9.76763 6.99882 9.76367 6.97187C9.75929 6.94059 9.82349 6.86457 9.86119 6.86627C9.88414 6.87151 9.86652 6.91037 9.8474 6.93497Z"
        fill="white"
      />
      <path
        d="M8.10762 14.1035C8.1068 14.1872 8.01788 14.2171 7.95436 14.1549C7.88087 14.0835 7.9224 13.9822 8.00148 13.9849C8.00394 13.9849 8.00736 13.9853 8.00982 13.9856C8.08221 13.9985 8.10789 14.0632 8.10762 14.1035Z"
        fill="white"
      />
      <path
        d="M9.71244 16.378C9.70917 16.4399 9.61068 16.4522 9.55987 16.397C9.5193 16.353 9.54061 16.2931 9.61724 16.2944C9.67857 16.3 9.71449 16.3422 9.71244 16.378Z"
        fill="white"
      />
      <path
        d="M12.3005 17.7125C12.2652 17.7515 12.1744 17.7685 12.1743 17.7362C12.1741 17.7171 12.2144 17.6833 12.2703 17.676C12.3144 17.6727 12.3186 17.6924 12.3005 17.7125Z"
        fill="white"
      />
      <path
        d="M10.1949 16.7363C10.1939 16.7957 10.0946 16.8124 10.0453 16.7614C10.0069 16.7216 10.0311 16.665 10.1047 16.6647C10.1618 16.6685 10.1953 16.7041 10.1949 16.7363Z"
        fill="white"
      />
      <path
        d="M10.6395 17.0565C10.6344 17.1114 10.5347 17.1266 10.4933 17.0787C10.4662 17.0473 10.4847 16.9957 10.5581 16.9939C10.6179 16.9967 10.6417 17.0321 10.6394 17.0565H10.6395Z"
        fill="white"
      />
      <path
        d="M12.9512 17.7749C12.905 17.8046 12.8365 17.8258 12.8416 17.8088C12.8459 17.7944 12.909 17.7558 12.9577 17.7455C12.9751 17.7426 12.9812 17.7455 12.9758 17.7541C12.9724 17.7594 12.9635 17.767 12.9512 17.7749Z"
        fill="white"
      />
      <path
        d="M7.7563 9.96057C7.72611 10.0396 7.64361 10.0528 7.62039 9.99891C7.59608 9.94264 7.65167 9.84699 7.7201 9.85484C7.76395 9.8657 7.77297 9.91713 7.7563 9.96057Z"
        fill="white"
      />
      <path
        d="M8.10489 9.06125C8.07279 9.11935 8.00408 9.14813 7.98482 9.09723C7.96529 9.04581 8.02361 8.95618 8.08617 8.9639C8.13261 8.97541 8.12223 9.02971 8.10489 9.06125Z"
        fill="white"
      />
      <path
        d="M9.20241 15.9712C9.20159 16.03 9.11881 16.0544 9.05967 15.9965C9.01405 15.952 9.02757 15.8844 9.09942 15.8848C9.10174 15.8848 9.10488 15.8851 9.1072 15.8852C9.17727 15.8932 9.20282 15.9422 9.20241 15.9712Z"
        fill="white"
      />
      <path
        d="M7.4767 10.9231C7.45266 10.9927 7.37234 11.0219 7.33997 10.9553C7.31265 10.899 7.35089 10.8011 7.42889 10.8112C7.48762 10.8253 7.48967 10.8858 7.4767 10.9231Z"
        fill="white"
      />
      <path
        d="M10.74 6.04782C10.7337 6.05449 10.7142 6.07517 10.7104 6.07595C10.7094 6.07019 10.7631 6.02165 10.7669 6.01916C10.759 6.02963 10.749 6.03827 10.74 6.04782Z"
        fill="white"
      />
      <path
        d="M11.028 17.3314C11.02 17.3778 10.9293 17.3917 10.8967 17.3514C10.8738 17.3229 10.9015 17.2835 10.9627 17.2809C11.0134 17.282 11.0321 17.3078 11.028 17.3314Z"
        fill="white"
      />
      <path
        d="M13.4821 17.7069C13.5197 17.6902 13.5568 17.6728 13.5943 17.656C13.5955 17.6555 13.5967 17.655 13.598 17.6543C13.5971 17.6548 13.5959 17.6555 13.595 17.6559L13.4821 17.7069Z"
        fill="white"
      />
      <path
        d="M9.44418 7.21328C9.41139 7.25319 9.36809 7.27007 9.36441 7.24429C9.35976 7.21315 9.4185 7.14563 9.45661 7.14864C9.48119 7.15466 9.46207 7.19156 9.44418 7.21328Z"
        fill="white"
      />
      <path
        d="M8.67707 15.5247C8.67871 15.5821 8.60386 15.6016 8.55182 15.5573C8.50428 15.517 8.50811 15.4426 8.57217 15.4381C8.5764 15.438 8.582 15.4381 8.5861 15.4385C8.64675 15.4473 8.67625 15.4952 8.67707 15.5247Z"
        fill="white"
      />
      <path
        d="M10.5394 6.22002C10.5153 6.24409 10.4994 6.25338 10.4995 6.2471C10.4996 6.23572 10.5532 6.18547 10.5661 6.18456C10.5705 6.18626 10.5521 6.20719 10.5394 6.22002Z"
        fill="white"
      />
      <path
        d="M7.61739 13.5313C7.61315 13.6018 7.54062 13.6284 7.49213 13.5771C7.43749 13.519 7.46276 13.4229 7.53215 13.4258C7.53448 13.4259 7.53775 13.4263 7.54021 13.4266C7.60072 13.4386 7.61944 13.4958 7.61739 13.5313Z"
        fill="white"
      />
      <path
        d="M7.2826 11.916C7.26798 11.9849 7.19176 12.0056 7.15721 11.9501C7.12647 11.9009 7.15406 11.805 7.22537 11.8139C7.277 11.8266 7.28997 11.8812 7.2826 11.916Z"
        fill="white"
      />
      <path
        d="M8.52026 8.23704C8.48543 8.29317 8.43202 8.30508 8.42205 8.2708C8.41044 8.23128 8.46822 8.15617 8.51699 8.16167C8.551 8.17057 8.53502 8.21322 8.52026 8.23704Z"
        fill="white"
      />
      <path
        d="M11.3636 17.5706C11.3498 17.6063 11.2655 17.6171 11.2491 17.5853C11.2378 17.5631 11.2659 17.5326 11.3137 17.5297C11.3546 17.5299 11.3711 17.5507 11.3636 17.5706Z"
        fill="white"
      />
      <path
        d="M13.2471 17.7927C13.216 17.8066 13.173 17.824 13.1636 17.8254C13.1635 17.8254 13.1632 17.8252 13.163 17.8251C13.1692 17.8198 13.2506 17.7823 13.2823 17.7719C13.2869 17.7705 13.2878 17.7704 13.289 17.7706C13.2868 17.7743 13.2649 17.7846 13.2471 17.7927Z"
        fill="white"
      />
      <path
        d="M12.4182 17.8613C12.3835 17.8873 12.3182 17.9016 12.3225 17.8821C12.3249 17.8707 12.3596 17.8442 12.4066 17.8372C12.4379 17.834 12.4341 17.8446 12.429 17.8512C12.4274 17.8533 12.4241 17.8569 12.4182 17.8613Z"
        fill="white"
      />
      <path
        d="M12.8838 17.8917C12.85 17.9079 12.8102 17.9211 12.8079 17.917C12.8102 17.9095 12.8722 17.8816 12.9041 17.8739C12.9106 17.8726 12.9127 17.8724 12.9134 17.8737C12.9123 17.8777 12.8967 17.8856 12.8838 17.8917Z"
        fill="white"
      />
      <path
        d="M10.2651 6.41642C10.2413 6.4401 10.2165 6.45397 10.2167 6.44363C10.2173 6.43003 10.2677 6.38174 10.2854 6.382C10.2962 6.38515 10.2684 6.41328 10.2651 6.41642Z"
        fill="white"
      />
      <path
        d="M8.14723 15.0352C8.14996 15.0877 8.09259 15.1079 8.04656 15.0706C7.99985 15.0326 7.99916 14.9614 8.05872 14.9582C8.06295 14.9579 8.06855 14.9583 8.07279 14.9587C8.12893 14.968 8.146 15.0112 8.14723 15.0352Z"
        fill="white"
      />
      <path
        d="M11.6317 17.7667C11.613 17.7943 11.5467 17.802 11.542 17.777C11.5394 17.763 11.5596 17.7417 11.5987 17.7387C11.6347 17.7378 11.6396 17.7548 11.6317 17.7667Z"
        fill="white"
      />
      <path
        d="M8.99001 7.50403C8.96282 7.53726 8.92376 7.55061 8.9213 7.52745C8.91898 7.5052 8.9661 7.44829 8.99752 7.45182C9.01924 7.45771 9.00175 7.4895 8.99001 7.50403Z"
        fill="white"
      />
      <path
        d="M7.1639 12.9427C7.15502 13.001 7.10107 13.0017 7.07361 12.9693C7.03523 12.9241 7.05831 12.853 7.10995 12.8572C7.11145 12.8573 7.11336 12.8575 7.11486 12.8578C7.16212 12.8692 7.168 12.9166 7.1639 12.9427Z"
        fill="white"
      />
      <path
        d="M7.56097 9.43286C7.53926 9.47146 7.49377 9.48336 7.48271 9.45327C7.47028 9.4199 7.50962 9.35854 7.54868 9.36455C7.57655 9.37293 7.57368 9.41035 7.56097 9.43286Z"
        fill="white"
      />
      <path
        d="M7.20693 10.3573C7.1889 10.401 7.14273 10.413 7.12579 10.3785C7.10571 10.3375 7.14396 10.2796 7.18685 10.2859C7.21922 10.2949 7.21635 10.3344 7.20693 10.3573Z"
        fill="white"
      />
      <path
        d="M9.92006 6.63912C9.9004 6.65744 9.87608 6.67066 9.87581 6.65993C9.8754 6.6454 9.91993 6.60327 9.93919 6.60432C9.9508 6.60732 9.92935 6.63048 9.92006 6.63912Z"
        fill="white"
      />
      <path
        d="M7.63583 14.5188C7.63514 14.5604 7.58952 14.5808 7.55251 14.5427C7.51535 14.5047 7.52519 14.45 7.56917 14.45C7.57177 14.45 7.57504 14.4504 7.5775 14.4506C7.62067 14.4595 7.6361 14.4972 7.63583 14.5188Z"
        fill="white"
      />
      <path
        d="M8.2005 15.8363C8.2005 15.8363 8.2005 15.8363 8.20064 15.8363C8.19954 15.8359 8.19845 15.8356 8.19736 15.8354C8.19777 15.8359 8.19818 15.8364 8.19859 15.837C8.19927 15.8367 8.19995 15.8364 8.2005 15.8363ZM8.25377 15.8975V15.8974C8.25322 15.8981 8.25254 15.8985 8.25186 15.899C8.25254 15.8998 8.25322 15.9005 8.25391 15.9013C8.25391 15.9 8.25377 15.8988 8.25377 15.8975Z"
        fill="white"
      />
      <path
        d="M7.14614 13.9594C7.14478 13.9894 7.11527 14.0055 7.08987 13.981C7.06023 13.9523 7.06678 13.9051 7.10052 13.9056C7.10216 13.9056 7.10448 13.906 7.10626 13.9063C7.13699 13.9136 7.14696 13.9429 7.14614 13.9594Z"
        fill="white"
      />
      <path
        d="M6.77584 12.3321C6.76901 12.3652 6.73432 12.3805 6.71465 12.3493C6.69511 12.3186 6.71028 12.2706 6.74606 12.2767C6.77461 12.2848 6.77953 12.3143 6.77584 12.3321Z"
        fill="white"
      />
      <path
        d="M6.93524 11.3239C6.92514 11.3561 6.88962 11.371 6.87364 11.3409C6.85766 11.3109 6.87746 11.2643 6.91189 11.2701C6.93907 11.2779 6.94084 11.3066 6.93524 11.3239Z"
        fill="white"
      />
      <path
        d="M7.99042 8.57737C7.97417 8.60158 7.94343 8.61532 7.93701 8.59543C7.92977 8.57279 7.96119 8.52974 7.98824 8.53406C8.00927 8.54047 7.99684 8.56756 7.99042 8.57737Z"
        fill="white"
      />
      <path
        d="M8.49622 7.80642C8.47806 7.82814 8.44992 7.84031 8.4476 7.82303C8.445 7.80328 8.47901 7.7652 8.50169 7.7686C8.51685 7.77344 8.50674 7.79386 8.49622 7.80642Z"
        fill="white"
      />
      <path
        d="M9.51466 6.88106C9.49813 6.8965 9.476 6.90736 9.476 6.89728C9.47587 6.88407 9.51138 6.85096 9.52777 6.8528C9.5372 6.85567 9.52504 6.87138 9.51466 6.88106Z"
        fill="white"
      />
      <path
        d="M10.718 6.04429C10.7128 6.04756 10.7036 6.05318 10.7015 6.0541C10.7025 6.05188 10.7358 6.03277 10.7361 6.03277C10.7351 6.03369 10.7231 6.04115 10.718 6.04429Z"
        fill="white"
      />
      <path d="M12.6786 17.9592H12.7835V18H12.6786V17.9592Z" fill="white" />
      <path
        d="M12.7358 17.9793C12.7191 17.985 12.6951 17.9925 12.6921 17.9928C12.6948 17.991 12.7391 17.9759 12.7537 17.972C12.7548 17.9717 12.7557 17.9715 12.7568 17.9713C12.7565 17.9717 12.7557 17.9727 12.7358 17.9793Z"
        fill="white"
      />
      <path
        d="M11.8265 17.9228C11.8094 17.9405 11.7654 17.9471 11.7645 17.9322C11.7639 17.9228 11.7839 17.9095 11.8097 17.907C11.8343 17.9058 11.833 17.9161 11.8265 17.9228Z"
        fill="white"
      />
      <path
        d="M10.2172 17.327C10.2169 17.3481 10.1774 17.3564 10.1561 17.3337C10.1439 17.3206 10.1497 17.3009 10.1799 17.3011C10.2075 17.3031 10.2173 17.3176 10.2172 17.327Z"
        fill="white"
      />
      <path
        d="M9.77036 17.0288C9.77159 17.0516 9.73457 17.0606 9.70917 17.0375C9.69236 17.0223 9.6966 17.0005 9.72392 16.999C9.72556 16.9989 9.72761 16.9989 9.72938 16.999C9.75848 17.0018 9.76981 17.0184 9.77036 17.0288Z"
        fill="white"
      />
      <path
        d="M9.28791 16.6876C9.28996 16.7108 9.25609 16.7212 9.22863 16.6991C9.2061 16.6809 9.20992 16.6563 9.23587 16.6537C9.23901 16.6534 9.24175 16.6536 9.24407 16.6537C9.27426 16.6572 9.28682 16.676 9.28791 16.6876Z"
        fill="white"
      />
      <path
        d="M10.6041 17.577C10.6022 17.592 10.5694 17.5978 10.5567 17.581C10.5503 17.5727 10.5556 17.5597 10.5782 17.5594C10.5987 17.5604 10.6049 17.5704 10.6041 17.577Z"
        fill="white"
      />
      <path
        d="M8.78034 16.3028C8.78279 16.3262 8.75261 16.3385 8.7242 16.3176C8.69797 16.2983 8.69783 16.2694 8.72379 16.2651C8.72816 16.2644 8.73144 16.2647 8.73485 16.265C8.76572 16.2694 8.77897 16.2899 8.78034 16.3028Z"
        fill="white"
      />
      <path
        d="M8.26169 15.8765C8.26443 15.9019 8.2341 15.9148 8.20542 15.8918C8.17728 15.8694 8.17878 15.837 8.20801 15.8349C8.21129 15.8346 8.21348 15.8349 8.21607 15.8351C8.24681 15.8405 8.26019 15.8627 8.26169 15.8765Z"
        fill="white"
      />
    </svg>
  );
};

export default ActivityNeio;
