import React from "react";

const ActivityGenLow = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#0195F7" fill-opacity="0.2" />
      <circle cx="12" cy="12" r="10" fill="#0195F7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM11.375 12C11.375 12.3452 11.6548 12.625 12 12.625C12.3452 12.625 12.625 12.3452 12.625 12V9.5C12.625 9.15482 12.3452 8.875 12 8.875C11.6548 8.875 11.375 9.15482 11.375 9.5V12ZM11.375 14.1875C11.375 14.5327 11.6548 14.8125 12 14.8125C12.3452 14.8125 12.625 14.5327 12.625 14.1875C12.625 13.8423 12.3452 13.5625 12 13.5625C11.6548 13.5625 11.375 13.8423 11.375 14.1875Z"
        fill="white"
      />
    </svg>
  );
};

export default ActivityGenLow;
