import React from 'react'

const Raised = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00021 5.00011V7.00011M6.00021 9.00011H6.00521M10.8652 9.50011L6.86521 2.50011C6.77799 2.34621 6.65151 2.21821 6.49867 2.12915C6.34583 2.04009 6.1721 1.99316 5.99521 1.99316C5.81831 1.99316 5.64459 2.04009 5.49175 2.12915C5.33891 2.21821 5.21243 2.34621 5.12521 2.50011L1.12521 9.50011C1.03705 9.65279 0.990823 9.82606 0.991213 10.0024C0.991604 10.1787 1.0386 10.3517 1.12743 10.504C1.21627 10.6563 1.34378 10.7824 1.49706 10.8695C1.65033 10.9566 1.82391 11.0017 2.00021 11.0001H10.0002C10.1757 10.9999 10.348 10.9536 10.4998 10.8658C10.6517 10.7779 10.7778 10.6517 10.8655 10.4997C10.9531 10.3477 10.9992 10.1753 10.9992 9.99986C10.9991 9.82441 10.9529 9.65206 10.8652 9.50011Z" stroke="#C79807" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Raised