import React from 'react'

const ChatNow = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.93759 18.2187C3.83165 18.2205 3.72684 18.1967 3.63213 18.1492C3.53742 18.1017 3.45561 18.032 3.3937 17.946C3.33179 17.86 3.29162 17.7603 3.27663 17.6554C3.26163 17.5505 3.27225 17.4436 3.30759 17.3437L4.70759 12.7499C4.40288 11.9601 4.24856 11.1202 4.25259 10.2737C4.25119 9.34243 4.43562 8.42026 4.79509 7.56118C5.14769 6.7391 5.65237 5.99099 6.28259 5.35618C6.91594 4.72012 7.6681 4.21471 8.49634 3.86868C9.35289 3.50504 10.2739 3.31763 11.2045 3.31763C12.135 3.31763 13.056 3.50504 13.9126 3.86868C15.1674 4.40619 16.2378 5.29889 16.9918 6.4369C17.7458 7.57491 18.1506 8.90856 18.1563 10.2737C18.1509 12.1174 17.4183 13.8845 16.1176 15.1912C15.4828 15.8214 14.7347 16.3261 13.9126 16.6787C12.2581 17.3716 10.4007 17.4029 8.72384 16.7662L4.13009 18.1662C4.06864 18.1925 4.00386 18.2101 3.93759 18.2187ZM11.2001 4.65618C10.0919 4.66097 9.00924 4.98994 8.08569 5.60253C7.16214 6.21512 6.43802 7.08456 6.00259 8.10368C5.42407 9.50464 5.42407 11.0777 6.00259 12.4787C6.05471 12.6229 6.05471 12.7807 6.00259 12.9249L4.92634 16.5737L8.55759 15.4712C8.70177 15.4191 8.85965 15.4191 9.00384 15.4712C9.69721 15.759 10.4406 15.9072 11.1913 15.9072C11.9421 15.9072 12.6855 15.759 13.3788 15.4712C14.062 15.1836 14.6817 14.764 15.2025 14.2365C15.7233 13.709 16.1348 13.0839 16.4136 12.3971C16.6924 11.7102 16.833 10.9751 16.8272 10.2339C16.8215 9.49266 16.6695 8.75985 16.3801 8.07743C15.9456 7.06326 15.2234 6.19868 14.3027 5.59062C13.3821 4.98256 12.3034 4.65769 11.2001 4.65618Z" fill="white"/>
    </svg>
  )
}

export default ChatNow