// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agents-page {
    .app-card {
        border: 1px solid #F1F1F1;
        background-image: linear-gradient(#F9F9F980, #FFFFFF);
    }
    .max-w-6 {
        max-width: 50%;
    }
    .p-chip {
        border-radius: 5px;
        background-color: #F1F1F1;
        color: #4F4D55;
        font-size: 12px;
        .p-chip-text {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Agents.css"],"names":[],"mappings":"AAAA;IACI;QACI,yBAAyB;QACzB,qDAAqD;IACzD;IACA;QACI,cAAc;IAClB;IACA;QACI,kBAAkB;QAClB,yBAAyB;QACzB,cAAc;QACd,eAAe;QACf;YACI,mBAAmB;YACnB,sBAAsB;QAC1B;IACJ;AACJ","sourcesContent":[".agents-page {\n    .app-card {\n        border: 1px solid #F1F1F1;\n        background-image: linear-gradient(#F9F9F980, #FFFFFF);\n    }\n    .max-w-6 {\n        max-width: 50%;\n    }\n    .p-chip {\n        border-radius: 5px;\n        background-color: #F1F1F1;\n        color: #4F4D55;\n        font-size: 12px;\n        .p-chip-text {\n            margin-top: 0.25rem;\n            margin-bottom: 0.25rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
