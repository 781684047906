import React from 'react'

const ActivityAck = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#EBF0FB"/>
        <circle cx="12" cy="12" r="10" fill="#0195F7"/>
        <path d="M13.4561 13.4118C13.7181 13.4992 14.0013 13.3576 14.0886 13.0956C14.1759 12.8336 14.0343 12.5505 13.7724 12.4632L13.4561 13.4118ZM12.208 12.4688H11.708C11.708 12.684 11.8457 12.875 12.0499 12.9431L12.208 12.4688ZM12.708 10.5087C12.708 10.2326 12.4841 10.0087 12.208 10.0087C11.9319 10.0087 11.708 10.2326 11.708 10.5087H12.708ZM13.7724 12.4632L12.3661 11.9944L12.0499 12.9431L13.4561 13.4118L13.7724 12.4632ZM12.708 12.4688V10.5087H11.708V12.4688H12.708ZM15.458 12C15.458 13.7949 14.0029 15.25 12.208 15.25V16.25C14.5552 16.25 16.458 14.3472 16.458 12H15.458ZM12.208 15.25C10.4131 15.25 8.95801 13.7949 8.95801 12H7.95801C7.95801 14.3472 9.8608 16.25 12.208 16.25V15.25ZM8.95801 12C8.95801 10.2051 10.4131 8.75 12.208 8.75V7.75C9.8608 7.75 7.95801 9.65279 7.95801 12H8.95801ZM12.208 8.75C14.0029 8.75 15.458 10.2051 15.458 12H16.458C16.458 9.65279 14.5552 7.75 12.208 7.75V8.75Z" fill="white"/>
    </svg>
  )
}

export default ActivityAck