import useApi from "services/api";
import { API_URL } from "../constants";

const GroupsList = () => {
  const { data, loading, error, post, del, patch, get } = useApi();

  const getGroupDetails = async (order, order_by) => {
    const data = await get(
      `${API_URL}/intelliapp/groups?order_by=${order_by ? order_by : "created_at"}&order=${order ? order : "desc"}`,
    );
    return data;
  };

  const createGroup = async (params) => {
    const data = await post(`${API_URL}/intelliapp/groups`, params);
    return data;
  };

  const deleteGroup = async (id) => {
    const data = await del(`${API_URL}/intelliapp/groups/${id}`);
    return data;
  };
  const editGroup = async (id, params) => {
    const data = await patch(`${API_URL}/intelliapp/groups/${id}`, params);
    return data;
  };

  const editUserGroup = async (id, payload) => {
    const data = await patch(`${API_URL}/user/${id}/groups`, payload);
    return data;
  };

  return {
    getGroupDetails,
    createGroup,
    deleteGroup,
    editGroup,
    editUserGroup,
    data,
    loading,
    error,
  };
};

export default GroupsList;
