import React, { useState, useRef, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import moment from "moment";
import "../../../pages/Admin/CreditSummary/creditSummary.css";
import { FilterMatchMode } from "primereact/api";
import { capitalizeFirstLetter } from "utils/utils";

export default function UserCreditTable({ creditSummary, loading }) {
  const toast = useRef(null);
  const [filters, setFilters] = useState({
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const creditsAllocated = (rowData) => {
    const { transaction_type, credits } = rowData;
    const allocated = transaction_type === "allocation" ? `+${credits}` : `-${credits}`;
    return allocated;
  };

  const createdAtCredit = (rowData) => {
    const { last_credits_allotted_at } = rowData;
    const timestamp = moment(last_credits_allotted_at).format("DD/MM/YYYY");
    const credit = <span className="text-xs text-color-secondary mt-1">{timestamp}</span>;
    return credit;
  };

  const formatTransactionType = (rowData) => {
    const { transaction_type } = rowData;
    return capitalizeFirstLetter(transaction_type);
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="card my-2 creditSummaryTable" style={{}}>
        <DataTable
          value={creditSummary?.transactions}
          rowsPerPageOptions={[5, 10, 20]}
          rows={10}
          tableStyle={{ minWidth: "10rem" }}
          filters={filters}
          loading={loading}
          emptyMessage="No credit found"
          onFilter={(e) => setFilters(e.filters)}
          className="w-full"
          scrollable
          scrollHeight="flex"
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column
            field="created_at"
            header="Transaction Date"
            body={createdAtCredit}
            style={{ width: "12%" }}
          ></Column>
          <Column
            field="credits"
            header="Credits"
            body={creditsAllocated}
            style={{ width: "5%" }}
          ></Column>
          <Column
            field="transaction_type"
            header="Transaction Type"
            body={formatTransactionType}
            style={{ width: "15%" }}
          ></Column>
          <Column
            field="credit_balance"
            body={() => creditSummary?.user_info?.credit_balance}
            header="Balance"
            style={{ width: "5%" }}
          ></Column>
        </DataTable>
      </div>
    </Fragment>
  );
}
