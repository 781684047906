import React, { useState, createContext, useContext } from "react";
import { UploadProgressProvider } from "./uploaderContext";
import { UploadedFilesProvider } from "./upladedFilesContext";
import UserInfoProvider from "./UserInfoContext";
import { UserStatusProvider } from "./userStatusContext";

const SharedStateContext = createContext();

const AppContextProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [activePage, setActivePage] = useState("pulse");
  const [activeAdUsers, setActiveAdUsers] = useState([]);

  return (
    <SharedStateContext.Provider
      value={{ sharedState, setSharedState, refresh, setRefresh, activePage, setActivePage, activeAdUsers, setActiveAdUsers }}
    >
      <UploadProgressProvider>
        <UserInfoProvider>
          <UserStatusProvider>
            <UploadedFilesProvider>{children}</UploadedFilesProvider>
          </UserStatusProvider>
        </UserInfoProvider>
      </UploadProgressProvider>
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => useContext(SharedStateContext);

export const useRefresh = () => {
  const { refresh, setRefresh } = useSharedState();
  return { refresh, setRefresh };
};

export const useActivePage = () => {
  const { activePage, setActivePage } = useSharedState();
  return { activePage, setActivePage };
};


export const useActiveAdUsers = () => {
  const { activeAdUsers, setActiveAdUsers } = useSharedState();
  return { activeAdUsers, setActiveAdUsers };
};

export default AppContextProvider;
