import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import LoginLeftPanel from "../../assets/Login/settlementDemandLogo.svg";
import { Checkbox } from "primereact/checkbox";
import microsoftLogo from "../../assets/Login/microsoftLogo";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Authentication from "hooks/authentication";
import { Toast } from "primereact/toast";
import { useUserStatusProvider } from "context/userStatusContext";
import Disclaimer from "components/Login/Disclaimer";
import { isSettlementDemand } from "constants";

const LoginNeioScreen = ({ onLogin }) => {
    const toast = useRef();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const { keycloak, initialized } = useKeycloak();
    const { emailVerification, getOrganizations } = Authentication();
    const navigate = useNavigate();
    const { setUserStatus } = useUserStatusProvider();

    const fetchOrganizations = async (domain) => {
        try {
            const response = await getOrganizations(domain);
            if (response?.status === 200) {
                return response?.results !== false ? response?.results?.idp_alias : false;
            }
        } catch (error) {
            console.error("Failed to fetch organizations:", error);
        }
    };

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            navigate("/");
        }
    }, [initialized, keycloak.authenticated, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await emailVerification(email);
            if (response?.status !== null) {
                const domain = email.split("@")[1];
                const idpAlias = await fetchOrganizations(domain);
                if (idpAlias) {
                    keycloak.login({
                        idpHint: idpAlias,
                        loginHint: email,
                    });
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "No organization found for this domain. Please contact support.",
                        life: 3000,
                    });
                }
                setUserStatus(response?.data?.status);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: response.message || "Email verification failed. Please try again.",
                    life: 3000,
                });
            }
        } catch (err) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: err?.message,
                life: 3000,
            });
        }
    };

    if (initialized && keycloak.authenticated) {
        return null; // Render nothing while redirecting
    }

    return (
        <div
            className={`flex flex-col lg:flex-row h-screen text-xs ${!isSettlementDemand ? "justify-content-center" : ""}`}
            id="login-container"
        >
            <Toast ref={toast} />
            {isSettlementDemand && <Disclaimer />}
            {isSettlementDemand && (
                <div className="lg:w-1/2 bg-blue-50 flex justify-center items-center overflow-hidden p-0">
                    <img
                        src={LoginLeftPanel}
                        alt="Logo"
                        className="w-full h-full object-cover object-center"
                    />
                </div>
            )}
            <div className="flex flex-col justify-center items-center lg:w-1/2">
                <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                    {isSettlementDemand && (
                        <Button text label="Back" icon="pi pi-angle-left" className="text-sm mb-4 pl-0" />
                    )}
                    <h1 className="text-2xl font-bold mb-4">Account Login</h1>
                    <div>
                        <p className="text-gray-600 mb-4">
                            If you are already a member, you can login with your email address and password.
                        </p>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 mb-2">
                                Email address
                            </label>
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-inputtext p-component"
                                style={{ ':focus': { borderColor: '#3b82f6' } }}
                            />
                        </div>
                        {/* <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 mb-2">
                Password
              </label>
              <InputText
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full"
              />
            </div>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <Checkbox
                  inputId="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.checked)}
                  className="mr-2"
                />
                <label htmlFor="rememberMe" className="text-gray-700 text-sm">
                  Remember me
                </label>
              </div>
            </div> */}
                        <Button
                            label="Next"
                            className="w-full bg-blue-500 p-button"
                            onClick={handleLogin}
                            disabled={!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
                        />
                    </div>
                    {/* <div className="mt-4">
            <Button
              iconPos="right"
              className="w-full"
              icon={microsoftLogo}
              style={{ backgroundColor: "black", borderColor: "black" }}
            />
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default LoginNeioScreen;
