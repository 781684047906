import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import AlertSevere from './icons/AlertSevere';
import AlertLow from './icons/AlertLow';
import AlertModerate from './icons/AlertModerate';
import { TabView, TabPanel } from 'primereact/tabview';
import { Skeleton } from 'primereact/skeleton';
import { InputSwitch } from "primereact/inputswitch";
import { Timeline } from 'primereact/timeline';
import ActivityEscalated from './icons/ActivityEscalated';
import ActivityHighSeverity from './icons/ActivityHighSeverity';
import ActivityResolved from './icons/ActivityResolved';
import { format } from 'date-fns';
import './index.css';
import AlertCritical from "../AlertTab/AlertTabDetails/icons/AlertCritical";
import Raised from '../AlertTab/AlertTabList/icons/Raised';
import Acknowledged from '../AlertTab/AlertTabList/icons/Acknowledged';
import Escalated from '../AlertTab/AlertTabList/icons/Escalated';
import Investigated from '../AlertTab/AlertTabList/icons/Investigated';
import Notified from '../AlertTab/AlertTabList/icons/Notified';
import Resolved from '../AlertTab/AlertTabList/icons/Resolved';
import { usePulseApi } from "hooks/pulseConfig";

const AlertTabDetailsSlider = ({ selectedAlertId, visible, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [alertDetail, setAlertDetail] = useState();
  const [severity, setSevirity] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [stream, setStream] = useState();
  const [streamId,setStreamId]=useState();

  const [ruleId, setRuleId] = useState();
  const [actualVal, setActualVal] = useState();
  const [assetName, setAssetName] = useState();
  const [escalationLevel, setEscalationLevel] = useState();
  const [threshold, setThreshold] = useState();
  const [sourceType, setSourceType] = useState();
  const [nextAction, setNextAction] = useState();
  const [milestones,setMilestones] =useState();
  const [loadingDetails,setLoadingDetails]=useState(false);
  const [loadingActivity,setLoadingActivity]=useState(false);
  const {getPulseAlertDetail, getPulseAlertMilestones} = usePulseApi();

  const mockAlert = [
    { heading: 'Stream', value: stream },
    { heading: 'Actual Value', value: actualVal },
    { heading: 'Asset', value: assetName },
    { heading: 'Escalation Level', value: escalationLevel },
    { heading: 'Threshold', value: threshold },
    { heading: 'Source Type', value: sourceType },
    { heading: 'Next Suggested Action', value: nextAction },
  ];
  
  // Filter out entries with empty or null values
  const filteredAlert = mockAlert.filter(item => item.value !== null && item.value !== '');
  
  // Render filteredAlert items
  filteredAlert.map((item, index) => (
    <div key={index}>
      <strong>{item.heading}:</strong> {item.value}
    </div>
  ));
  
  const DisplayComponent = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handlePrev = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 2);
      }
    };
  
    const handleNext = () => {
      if (currentIndex < filteredAlert.length - 2) {
        setCurrentIndex(currentIndex + 2);
      }
    };
  
    const dataToDisplay = filteredAlert.slice(currentIndex, currentIndex + 2);
  
    return (
      <div className="flex flex-row items-center justify-between w-full mt-5">
        {/* Previous Arrow */}
        <button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          className={`text-xl p-2 w-5 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          ←
        </button>
  
        {/* Data display */}
        <div className="grid grid-cols-2 gap-4">
          {dataToDisplay.map((data, index) => (
            <div key={index} className="flex flex-col items-center"
            style={{margin:'20px', justifyContent:'center', textAlign:'center'}}
            >
              <p>{data.heading}</p>
              <p className="font-bold">{data.value}</p>
            </div>
          ))}
        </div>
  
        {/* Next Arrow */}
        <button
          onClick={handleNext}
          disabled={currentIndex >= filteredAlert.length - 2}
          className={`text-xl p-2 ${currentIndex >= filteredAlert.length - 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          →
        </button> 
      </div>
    );
  };

  useEffect(()=>{
    console.log('selected alertid is ',selectedAlertId)
    const fetchData = async () => {
      try {
        setLoadingDetails(true);
        const response = await getPulseAlertDetail({feed_id:selectedAlertId});
        setAlertDetail(response?.results?.data?.feeds?.[0])
        setSevirity(response?.results?.data?.feeds[0]?.severity)
        setAlertMsg(response?.results?.data?.feeds[0]?.alert_message)
        setStream(response?.results?.data?.feeds[0]?.stream_name)
        setRuleId(response?.results?.data?.feeds[0]?.rule_id)
        setStreamId(response?.results?.data?.feeds[0]?.asset_id)
        setActualVal(response?.results?.data?.feeds[0]?.actual_value)
        setAssetName(response?.results?.data?.feeds[0]?.asset_name)
        setEscalationLevel(response?.results?.data?.feeds[0]?.escalation_level)
        setThreshold(response?.results?.data?.feeds[0]?.threshold)
        setSourceType(response?.results?.data?.feeds[0]?.source_type)
        setNextAction(response?.results?.data?.feeds[0]?.next_suggested_action)
      } catch (error) {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: extractErrorMessage(error),
        //   life: 3000,
        // });
        console.log(' error encountered ',error)
      } finally {
        setLoadingDetails(false)
      }


      try {
        setLoadingActivity(true);
        const response = await getPulseAlertMilestones({feed_id:selectedAlertId});
        setMilestones(response?.results?.data?.milestones)
      } catch (error) {
        console.log(' error encountered ',error)
      } finally {
        setLoadingActivity(false)
      }
    };    

    fetchData(); // Call fetchData on component mount
  },[selectedAlertId])

  const renderSeverityIcon = () => {
    switch (severity) {
      case "HIGH":
        return <AlertSevere />;
      case "MODERATE":
        return <AlertModerate />;
      case "LOW":
        return <AlertLow />;
      default:
        return null;
    }
  };
  const alertDetails = () => {
    return (
      <>
        {!loadingDetails && <div className="flex space-x-4 flex-col items-center mt-3">
          {severity ==='HIGH' && <AlertSevere/>}
          {severity ==='LOW' && <AlertLow/>}
          {severity ==='MODERATE' && <AlertModerate/>}
          {severity ==='CRITICAL' && <AlertCritical/>}
          <p className={`font-semibold mr-[12px] mt-5 ${
      severity === 'HIGH' ? 'text-[#E12120]' : 
      severity === 'MODERATE' ? 'text-[#ec9c14]' : 
      severity === 'LOW' ? 'text-[#0472f6]' : 'text-black'
    }`}
  >
            {severity} Severity</p>
          <p className='text-xl font-semibold mt-5'>{alertMsg}</p>
          {/* {MODERATE - #ec9c14 , LOW - #0472f6, HIGH - #e12120} */}
          <div className='border-2 border-slate-200'></div>
          <DisplayComponent/>
        </div> }
        {loadingDetails && 
        <div className='flex flex-col'>
        <div className='mt-5 ml-[40%]'>
          <Skeleton width="30%" height="5rem"></Skeleton>
        </div>
        <div className='mt-5'>
          <Skeleton width="100%" height="4rem"></Skeleton>
        </div>
      </div>}
      </>
    );
  };
  const getAlertText = (alertDetail) => {
    let alertIcon, alertIconTxt;
    if(alertDetail?.escalated) {
      alertIcon=<Escalated/>
      alertIconTxt='Escalated'
    } else if(alertDetail?.resolved) {
      alertIcon=<Resolved/>
      alertIconTxt='Resolved'
    } else if(alertDetail?.investigated) {
      alertIcon=<Investigated/>
      alertIconTxt='Investigated'
    } else if(alertDetail?.acknowledged) {
      alertIcon=<Acknowledged/>
      alertIconTxt='Acknowledged'
    } else if(alertDetail?.notified) {
      alertIcon=<Notified/>
      alertIconTxt='Notified'
    } else {
      alertIcon=<Raised/>
      alertIconTxt='Raised'
    }
    return (<>
            <span className='ml-2'>
              {/* {getAlertText(alertDetail)} */}
                {alertIcon}
              </span>
              <span className='bg-[#F5F5F5] text-sm font-semibold py-1 px-2 rounded-full text-gray-600'>
              {/* {selectedAlert.type.charAt(0).toUpperCase() + selectedAlert.type.slice(1)} */}
                {alertIconTxt}
              </span>
            </>)
  };

  function formatDateAlertDetails(inputDate) {
    const date = new Date(inputDate);
  
    // Format the date part as DD/MM/YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
  
    // Format the time part as HH:MM in 24-hour format
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }

  const tabContent = (index) => {
    if (index === 0) {
      return alertDetails();
      // return ruleData();
    } else if (index === 1) {
      return ActivityTimeline();
      // return alertData();
    }
  };

  const ActivityTimeline = () => {
    const [checked, setChecked] = useState(false);
  
  
    const customizedMarker = (item) => {
      const milestoneColor = item.milestone_type === 'HIGH_SEVERITY' ? '#9C27B0' : '#673AB7'; // Color based on milestone type
      // const milestoneIcon = item.milestone_type === 'HIGH_SEVERITY' ? 'pi pi-exclamation-triangle' : 'pi pi-info-circle'; // Icon based on milestone type
      const milestoneIcon=item.milestone_type === 'HIGH_SEVERITY' ? <ActivityHighSeverity/> : <ActivityEscalated/>
  
      return (
        <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" >
          {/* <i className={milestoneIcon}></i> */}
          {milestoneIcon}
        </span>
      );
    };
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
  
      const options = { 
          month: 'short', 
          day: 'numeric', 
          year: 'numeric', 
          hour: 'numeric', 
          minute: 'numeric', 
          hour12: true 
      };
  
      const datePart = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
      const timePart = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  
      return `${datePart} at ${timePart}`;
  }
  
    const customizedContent = (item) => {
      return (
        <div className='flex flex-col'>
          <span>{item.title}</span>
          <span className='bg-[#F1F1F1] p-4 w-[80%] mt-3 rounded-xl'>{item.sub_title}</span>
          <span className='text-[#757575] mt-3'>
            {formatDate(format(new Date(item.created_at), 'MM/dd/yyyy HH:mm:ss'))} {/* Using date-fns to format the date */}
          </span>
        </div>
      )
    };
  
    return (
      <>
        {!loadingActivity && (<>
          <div className="flex space-x-4 flex-row items-center justify-start">
          <InputSwitch
            checked={checked}
            onChange={(e) => setChecked(e.value)}
            style={{ marginLeft: '1px' }}
          />
          <span className="font-extralight"> See All Actions</span>
        </div>
  
        <div className="timeline-card h-[300px] overflow-y-scroll mt-3">
          <Timeline value={milestones} align="left" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
        </div></>)}
        {loadingActivity && <div className='flex flex-col'>
          <div className='mt-5'>
            <Skeleton width="30%" height="2rem"></Skeleton>
          </div>
          <div className='mt-5 flex flex-row'>
            <Skeleton width="5%" height="17rem"></Skeleton>
            <div className='flex flex-col ml-5'>
              <Skeleton width="15rem" height="2rem"></Skeleton>
              <div className='mt-[10px]'>
                <Skeleton width="25rem" height="3rem"></Skeleton>
              </div>
              <div className='mt-[10px]'>
                <Skeleton width="19rem" height="2rem"></Skeleton>
              </div>
              <div className='mt-[10px]'>
                <Skeleton width="15rem" height="2rem"></Skeleton>
              </div>
              <div className='mt-[10px]'>
                <Skeleton width="25rem" height="3rem"></Skeleton>
              </div>
              <div className='mt-[10px]'>
                <Skeleton width="19rem" height="2rem"></Skeleton>
              </div>
            </div>
          </div>
        </div>}
      </>
    );
  };


  return (
    <Sidebar
      visible={visible}
      position="right" // You can change this to "left" or "right" as needed
      onHide={onClose}
      style={{ width: '35vw' }} // Adjust the width of the sidebar
      header={<div className='flex flex-row items-center'>
        {/* <p className='text-xl'> Alert ID {alertDetail?.id}</p> */}
        <span className='text-xl font-semibold sans-serif'> {alertDetail?.alert_message}</span>
      </div>}
    >
<div className='flex-1 p-4 mt-[20px]'>
      {/* <h2 className='text-xl'>Alert Details</h2> */}
      <div className="border-[1px]"></div>
      <div className='flex flex-row items-center mt-2'>
        {/* <p className='text-lg font-light'> Date Generated → 3/23/24 00:{selectedAlert.time}</p> */}
      </div>
      <div className='flex flex-row items-center mt-2 justify-between'>
          <div className='flex flex-row items-center mt-2'>
            <p className='text-xl font-sans'> ID {alertDetail?.id}</p>
            <div className='flex flex-row bg-[#F5F5F5] items-center ml-[20px] p-[4px] rounded-2xl'>
              {getAlertText(alertDetail)}
            </div>
          </div>
          <div>
            <span className='font-thin font-sans'>
              {alertDetail && formatDateAlertDetails(format(new Date(alertDetail?.created_at), 'MM/dd/yyyy HH:mm:ss'))}
            </span>
          </div>
        </div>
      {/* <div className='flex flex-col justify-center bg-[#FAFAFA] p-5 rounded-lg mt-2'>
        <p className='text-md text-[#495057] font-light'> Alert details</p>
        <div className='flex flex-col mt-3'>
          <p className="font-normal"> Rule ID - {ruleId}</p>
          <p className="font-normal"> Stream ID - {streamId}</p>
          <div className='flex flex-row items-center rounded-2xl mt-5'>
            <p>Stage</p>
            {getAlertText(alertDetail)}
          </div>
        </div>
      </div> */}
      <div className="card">
            <TabView
                      activeIndex={activeIndex}
                      onTabChange={(e) => setActiveIndex(e.index)}
                    >
                      {["Alert Details", "Activity"].map((header, index) => (
                        <TabPanel
                          key={index}
                          header={
                            <span
                              className={`py-2 px-4 transition-colors duration-200 ${
                                activeIndex === index
                                  ? "border-b-2 border-[#3B82F6] !text-[#3B82F6]"
                                  : ""
                              }`}
                            >
                              {header}
                            </span>
                          }
                        >
                          {tabContent(index)}{" "}
                        </TabPanel>
                      ))}
                    </TabView>
        </div>
    </div>
    </Sidebar>
  );
};

export default AlertTabDetailsSlider;
