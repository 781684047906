import React from "react";

const DocumentSourcesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33366 18.3332H15.0003C15.4424 18.3332 15.8663 18.1576 16.1788 17.845C16.4914 17.5325 16.667 17.1085 16.667 16.6665V5.83317L12.5003 1.6665H5.00033C4.5583 1.6665 4.13437 1.8421 3.82181 2.15466C3.50925 2.46722 3.33366 2.89114 3.33366 3.33317V6.6665M11.667 1.6665V4.99984C11.667 5.44186 11.8426 5.86579 12.1551 6.17835C12.4677 6.49091 12.8916 6.6665 13.3337 6.6665H16.667M1.66699 12.4998H10.0003M10.0003 12.4998L7.50033 14.9998M10.0003 12.4998L7.50033 9.99984"
        stroke="#495057"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DocumentSourcesIcon;
