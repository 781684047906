import React from 'react'

const Gdrive = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.895125 13.2083C0.891815 13.502 0.966095 13.7965 1.11797 14.0598L3.14998 17.5827C3.44762 18.0987 3.99801 18.4166 4.5937 18.4166H15.4061C16.0018 18.4166 16.5522 18.0987 16.8498 17.5827L18.8819 14.0598C19.0337 13.7965 19.108 13.502 19.1047 13.2083H0.895125Z" fill="#4285F4"/>
<path d="M16.2467 18.1894L7.13184 2.40197C7.38573 2.25056 7.67965 2.16675 7.9855 2.16675H12.0149C12.6104 2.16675 13.1606 2.48441 13.4583 3.00008L18.8818 12.3939C19.1794 12.9093 19.1795 13.5444 18.8821 14.06L16.8501 17.5828C16.7026 17.8385 16.4931 18.0456 16.2467 18.1894Z" fill="#FBBC04"/>
<path d="M12.8683 2.40197C12.6144 2.25056 12.3205 2.16675 12.0146 2.16675H7.9852C7.38976 2.16675 6.83955 2.48441 6.54182 3.00008L1.11831 12.3939C0.82071 12.9093 0.820581 13.5444 1.11797 14.06L3.14998 17.5828C3.29747 17.8385 3.50703 18.0456 3.7534 18.1894L12.8683 2.40197Z" fill="#34A853"/>
<path d="M19.1047 13.2083C19.108 13.502 19.0337 13.7965 18.8818 14.0598L16.8498 17.5827C16.7023 17.8384 16.4928 18.0454 16.2464 18.1893L13.3706 13.2083H19.1047Z" fill="#EA4335"/>
<path d="M12.8686 2.40197C12.6147 2.25056 12.3208 2.16675 12.0149 2.16675H7.9855C7.67965 2.16675 7.38573 2.25056 7.13184 2.40197L10.0002 7.37014L12.8686 2.40197Z" fill="#188038"/>
<path d="M0.895125 13.2083C0.891815 13.502 0.966095 13.7965 1.11797 14.0598L3.14998 17.5827C3.29747 17.8384 3.50703 18.0454 3.7534 18.1893L6.62919 13.2083H0.895125Z" fill="#1967D2"/>
</svg>

  )
}

export default Gdrive