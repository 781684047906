import React, { useRef, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { connect, StringCodec } from "nats.ws";
import bellIcon from "../../assets/Header/BellIcon.svg";
import GetNotifications from "../../hooks/useNotification";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import moment from "moment";

/**
 * Component for displaying and managing user notifications.
 * Fetches notifications periodically and allows users to mark them as read or delete them.
 *
 * @param {Object} props - Component props
 * @param {Object} props.userInfo - User information object
 * @param {boolean} props.authenticated - Indicates if the user is authenticated
 * @returns {JSX.Element} The Notifications component
 */
const Notifications = ({ userInfo, authenticated }) => {
  const notificationPanel = useRef(null);
  const { getNotification, markNotificationAsRead, deleteNotification } = GetNotifications();
  const toast = useRef(null);
  const navigate = useNavigate();

  const [notificationList, setNotificationList] = useState([]);

  /**
   * Fetches notifications from the server.
   */
  const fetchNotifications = async () => {
    const data = await getNotification();
    if (data.success && data.results) {
      setNotificationList(data.results.notifications);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const natsConnection = useRef(null);

  useEffect(() => {
    let isSubscribed = true;
    let retryCount = 0;
    const connectToNats = async () => {
      try {
        if (!natsConnection.current) {
          natsConnection.current = await connect({
            servers: process.env.REACT_APP_NATS_WS_URL || "ws://localhost:4222",
            reconnect: true,
            maxReconnectAttempts: -1,
            reconnectTimeWait: 2000,
          });

          console.log("Connected to NATS server");

          natsConnection.current.closed().then(() => {
            console.log("NATS connection closed");
            natsConnection.current = null;
          });
        }

        const sc = StringCodec();
        const sub = natsConnection.current.subscribe(`notifications.${userInfo?.email}.>`, {
          durable: `notifications-${userInfo?.email}-${Date.now()}`,
          manualAck: false,
          ackWait: 20000,
          maxDeliver: 5,
          idleHeartbeat: 15000,
        });

        (async () => {
          for await (const msg of sub) {
            if (!isSubscribed) break;

            try {
              const notification = JSON.parse(sc.decode(msg.data));

              fetchNotifications();

              toast.current.show({
                severity: "info",
                summary: notification.header,
                detail: notification.body,
                life: 3000,
              });
            } catch (error) {
              console.error("Error processing notification:", error);
            }
          }
        })();

        return () => {
          isSubscribed = false;
          sub.unsubscribe();
        };
      } catch (error) {
        console.error("NATS connection error:", error);

        if (retryCount < 3) {
          setTimeout(connectToNats, 5000);
          retryCount++;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Connection Error",
            detail: "Failed to connect to notification service. Retrying...",
            life: 3000,
          });
        }
      }
    };

    if (authenticated && userInfo?.sub) {
      connectToNats();
    }

    return () => {
      isSubscribed = false;
      if (natsConnection.current) {
        natsConnection.current.close();
        natsConnection.current = null;
      }
    };
  }, [authenticated, userInfo?.sub]);

  /**
   * Handles the removal of a notification.
   *
   * @param {string} notificationId - The ID of the notification to remove
   */
  const handleRemoveNotification = (notificationId) => {
    deleteNotification(notificationId).then(() => {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Notification removed successfully",
        life: 3000,
      });
      setNotificationList((prev) =>
        prev.filter((notification) => notification.notification_id !== notificationId),
      );
    });
  };
  const handleReadNotification = async (notificationId) => {
    await markNotificationAsRead(notificationId);
    fetchNotifications();
  };

  /**
   * Marks all notifications as read.
   */
  const markAllNotificationsAsRead = () => {
    Promise.all(
      notificationList
        .filter((notification) => !notification.is_read)
        .map((notification) => markNotificationAsRead(notification.notification_id)),
    ).then(() => {
      setNotificationList((prev) =>
        prev.map((notification) => ({ ...notification, is_read: true })),
      );
    });
  };

  const notificationUnreadCount = useMemo(() => {
    return notificationList.filter((notification) => !notification.is_read).length;
  }, [notificationList]);

  const handleNotificationClick = (notification) => {
    handleReadNotification(notification.notification_id);
    notificationPanel.current.hide();
    navigate(`/intelliapp/workspaces?job_id=${notification.job_id}`);
  };

  return (
    <div>
      <Toast ref={toast} />
      <button
        onClick={(e) => notificationPanel.current.toggle(e)}
        style={{
          textDecoration: "none",
          position: "relative",
          display: "inline-block",
          borderRadius: "2px",
        }}
        className="mr-3 p-link inline-flex justify-content-center align-items-center h-1rem w-3rem hover:bg-white-alpha-10 transition-all transition-duration-200 relative border-none"
      >
        <img src={bellIcon} alt="notifications" />
        {notificationList.length > 0 && !!notificationUnreadCount && (
          <span
            style={{
              position: "absolute",
              top: "-10px",
              right: "-1px",
              padding: "2px 5px",
              borderRadius: "50%",
              backgroundColor: "red",
              color: "white",
              fontSize: "10px",
            }}
          >
            {notificationList > 99 ? "99+" : notificationUnreadCount}
          </span>
        )}
      </button>
      <OverlayPanel ref={notificationPanel}>
        <div className="flex justify-content-between items-center">
          <div className="font-medium text-base text-black my-2 pl-2">
            {notificationList.length > 0 ? "Notifications" : "No Notifications"}
          </div>
          <Button
            icon="pi pi-times"
            className="p-button-text p-button-plain p-button-sm"
            onClick={() => {
              notificationPanel.current.hide();
            }}
          />
        </div>

        <div
          className="flex flex-column gap-2"
          style={{
            maxHeight: "22vw",
            overflowY: "auto",
            overflowX: "hidden",
            width: "28vw",
          }}
        >
          {notificationList.map((notification, index) => (
            <div key={index}>
              <NotificationTemplate
                notification={notification}
                index={index}
                notificationsLength={notificationList.length}
                handleRemoveNotification={handleRemoveNotification}
                handleNotificationClick={handleNotificationClick}
              />
            </div>
          ))}
        </div>
      </OverlayPanel>
    </div>
  );
};

/**
 * Template component for rendering individual notifications.
 *
 * @param {Object} props - Component props
 * @param {Object} props.notification - Notification data
 * @param {number} props.index - Index of the notification in the list
 * @param {number} props.notificationsLength - Total number of notifications
 * @param {Function} props.handleRemoveNotification - Function to handle notification removal
 * @param {Function} props.handleNotificationClick - Function to handle notification click
 * @returns {JSX.Element} The notification template component
 */
const NotificationTemplate = ({
  notification,
  index,
  notificationsLength,
  handleRemoveNotification,
  handleNotificationClick,
}) => {
  const menu = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const isNotificationDateToday =
    new Date(notification.created_at).toDateString() === new Date().toDateString();
  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={`pl-2 cursor-pointer ${!notification.is_read ? "bg-blue-50" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        handleNotificationClick(notification);
      }}
    >
      <div
        className="flex items-center"
        style={{ justifyContent: "space-between", alignItems: "baseline" }}
      >
        <div
          className={`my-1 text-black ${!notification.is_read ? "font-bold" : "font-normal"}`}
        >{`${notification.notification_json.header}`}</div>
        <Menu
          model={[
            {
              label: "Remove",
              icon: "pi pi-times",
              command: () => handleRemoveNotification(notification.notification_id),
            },
          ]}
          popup
          ref={menu}
          id={`menu-${notification.job_id}`}
        />
        {isHovering ? (
          <Button
            icon="pi pi-ellipsis-h"
            className="p-button-text p-button-plain p-button-sm"
            onClick={(e) => {
              e.stopPropagation();
              menu.current.toggle(e);
              setTimeout(() => {
                menu?.current
                  ?.getElement()
                  ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
              }, 100);
            }}
            aria-controls={`menu-${notification.job_id}`}
            aria-haspopup
          />
        ) : (
          <Button className="p-button-text p-button-plain p-button-sm cursor-default text-xs">
            {moment(notification.created_at).format(
              isNotificationDateToday ? "HH:mm" : "DD/MM/YYYY",
            )}
          </Button>
        )}
      </div>
      <div className="my-2 mr-2 font-thin text-sm text-gray-500 leading-loose">
        {`Task - ID ${notification.job_id}`}
      </div>
      <div className="my-2 mr-2 font-thin text-sm text-gray-500 leading-loose">
        {notification.notification_json.body}
      </div>
      {index !== notificationsLength - 1 && (
        <hr className="hr-popup-border border-gray-200 my-2 -mx-4" />
      )}
    </div>
  );
};

Notifications.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    sub: PropTypes.string,
  }),
  authenticated: PropTypes.bool.isRequired,
};

export default Notifications;
