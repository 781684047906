// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* *:focus,
*:focus-visible,
*:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
} */
.source-filter-modal-dialog {
p:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.p-focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

/* Remove outline from all focusable elements */
:focus {
  outline: none;
  box-shadow: none;
}

/* div[data-p-highlight='true']{
  background-color: #3c82f6
} */

div[class="p-multiselect-label-container"] {
  /* display: flex; */
  width: max-content;
}

div[class*="source-filter-modal"] div[data-pc-section*="label"] {
  max-width: 23vw;
}
}
/* source-filter-modal-dialog */

span[id*="created-start"] input{
  border: 0px;
}

span[id*="created-end"] input{
  border: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/SourceConfig/StructuredSources/SourceFilterModal/index.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;AACA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA,+CAA+C;AAC/C;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;;GAEG;;AAEH;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;AACA;AACA,+BAA+B;;AAE/B;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* *:focus,\n*:focus-visible,\n*:active {\n    outline: none !important;\n    box-shadow: none !important;\n    border-color: transparent !important;\n} */\n.source-filter-modal-dialog {\np:focus {\n  outline: none !important;\n  box-shadow: none !important;\n  border-color: transparent !important;\n}\n\n.p-focus {\n  outline: none !important;\n  box-shadow: none !important;\n  border-color: transparent !important;\n}\n\n/* Remove outline from all focusable elements */\n:focus {\n  outline: none;\n  box-shadow: none;\n}\n\n/* div[data-p-highlight='true']{\n  background-color: #3c82f6\n} */\n\ndiv[class=\"p-multiselect-label-container\"] {\n  /* display: flex; */\n  width: max-content;\n}\n\ndiv[class*=\"source-filter-modal\"] div[data-pc-section*=\"label\"] {\n  max-width: 23vw;\n}\n}\n/* source-filter-modal-dialog */\n\nspan[id*=\"created-start\"] input{\n  border: 0px;\n}\n\nspan[id*=\"created-end\"] input{\n  border: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
