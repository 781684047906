import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Chip } from "primereact/chip";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import { useKeycloak } from "@react-keycloak/web";
import GroupsList from "hooks/useGroupsTask";
import Authentication from "hooks/authentication";
import GetUsersList from "hooks/getUsers";
const AddNewGroups = ({
  visible,
  setVisible,
  sidebarTitle,
  setSidebarTitle,
  selectedGroups,
  setSelectedGroups,
  data,
  setData,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const { getUsers } = GetUsersList();
  const {
    getGroupDetails,
    createGroup,
    editGroup,
    data: submitNewGroup,
    loading: errorNewGroupLoading,
  } = GroupsList();
  const { getOrganizations } = Authentication();
  const [groupNameError, setGroupNameError] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const { isMobile } = useMediaQuerry();
  const { keycloak } = useKeycloak();
  const userInfo = keycloak?.tokenParsed;
  const toast = useRef(null);

  // fetches the organization, through which it is getting accessed from
  const fetchOrganizations = async (domain) => {
    try {
      const response = await getOrganizations(domain);
      if (response?.status === 200) {
        return response?.results !== false ? response?.results?.idp_alias : false;
      }
    } catch (error) {
      console.error("Failed to fetch organizations:", error);
    }
  };

  const getUserList = async () => {
    try {
      const user = await getUsers();
      const sortedUsers = user?.results?.users?.sort((a, b) => a.email.localeCompare(b.email));
      setUsersList(sortedUsers);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Unable to fetch users",
      });
    }
  };

  useEffect(() => {
    validateForm();
  }, [description, groupName, selectedUsers]);

  // updated the form, when the user is in edit mode
  useEffect(() => {
    if (selectedGroups?.length !== 0 && visible) {
      setDescription(selectedGroups?.description || "");
      setGroupName(selectedGroups?.name || "");
      const users = selectedGroups?.users
        ?.map((userName) => {
          const user = usersList.find((user) => {
            return user?.email === userName?.email;
          });
          return user
            ? {
              name: user.name,
              email: user.email,
              id: user.id,
            }
            : null;
        })
        .filter(Boolean);
      setSelectedUsers(users);
    }
  }, [selectedGroups, visible]);

  // Validation of the forms with relevant user cases - special characters, length and mandate fields
  const validateForm = () => {
    const specialCharPattern = /[^a-zA-Z0-9\s]/g;
    const isValidDescription =
      description.length > 0 && description.length <= 100 && !specialCharPattern.test(description);
    const isValidGroupName =
      groupName.length > 0 && groupName.length <= 50 && !specialCharPattern.test(groupName);
    setGroupNameError(groupName.length > 0 && !isValidGroupName);
    setDescriptionError(description.length > 0 && !isValidDescription);
    setIsUploadDisabled(!isValidDescription || !isValidGroupName || selectedUsers?.length === 0);
  };

  // when the user submits the group details for updation and creation.
  const handleSubmitTemplate = async () => {
    try {
      const selectedUsersList = selectedUsers?.map((user) => user.id);
      const formData = {
        name: groupName,
        description: description,
        user_ids: selectedUsersList,
      };

      if (sidebarTitle === "Add New Group") {
        const response = await createGroup(formData);
        handleSideBarClose();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Group added successfully.",
          life: 1000,
        });
        const post = await getGroupDetails("desc", "updated_at");
        setData(post?.results?.data);
      } else {
        const response = await editGroup(selectedGroups?.id, formData);
        handleSideBarClose();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Group updated successfully.",
          life: 1000,
        });
        const patch = await getGroupDetails("desc", "updated_at");
        setData(patch?.results?.data);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.detail,
        life: 1000,
      });
    }
  };

  const handleUserRemove = (userToRemove) => {
    setSelectedUsers(selectedUsers.filter((user) => user !== userToRemove));
  };

  const handleSideBarClose = () => {
    setVisible(false);
    setGroupName("");
    setDescription("");
    setSidebarTitle("Add New Group");
    setSelectedUsers([]);
    setSelectedGroups([]);
  };

  const handleOnToastClose = () => {
    if (submitNewGroup?.status === 200) {
      handleSideBarClose();
      setVisible(false);
    }
  };

  return (
    <>
      <Toast ref={toast} position="top-right" onHide={handleOnToastClose} />
      <div className="flex grid mr-3 templateHeader flex-col h-full">
        <Sidebar
          visible={visible}
          position="right"
          onHide={() => {
            handleSideBarClose();
            setSidebarTitle("Add New Group");
            setVisible(false);
          }}
          header={<h2 className="text-2xl font-semibold m-0">{sidebarTitle}</h2>}
          className="bg-white w-full md:w-20rem lg:w-30rem "
        >
          <Divider />
          <div className="flex flex-column gap-2 mt-5">
            <label htmlFor="description" className="text-sm">
              Group Name
            </label>
            <InputText
              className={`text-sm ${descriptionError ? "p-invalid" : ""}`}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              id="description"
              aria-describedby="description-help"
              placeholder="Enter Group Name"
              maxLength={500}
            />
            {groupNameError && (
              <small className="p-error">
                Group name must be within 50 characters and cannot contain special characters.
              </small>
            )}
          </div>
          <div className="flex flex-column gap-2 mt-5">
            <label htmlFor="description" className="text-sm">
              Group Description
            </label>
            <InputText
              className={`text-sm ${descriptionError ? "p-invalid" : ""}`}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              aria-describedby="description-help"
              placeholder="Description"
              maxLength={500}
            />
            {descriptionError && (
              <small className="p-error">
                Description must be within 100 characters and cannot contain special characters.
              </small>
            )}
          </div>
          <div className="flex flex-column flex-grow mt-5">
            <label htmlFor="description" className="text-sm">
              <p>Add Users to the Group</p>
            </label>
            <div className="flex" onClick={getUserList}>
              <MultiSelect
                value={selectedUsers}
                onChange={(e) => {
                  setSelectedUsers(e.value);
                }}
                selectedItemTemplate={(value) => {
                  if (!selectedUsers?.length)
                    return (
                      <p className="m-0 flex gap-2 text-500">
                        <span className="pi pi-search"></span>
                        Search Groups
                      </p>
                    );
                  return value === selectedUsers[0] ? (
                    <p className="m-0 flex gap-2 text-500">
                      <span className="pi pi-search"></span>
                      Search Groups
                    </p>
                  ) : (
                    ""
                  );
                }}
                selectedItemsLabel={`${selectedUsers?.length} users selected`}
                virtualScrollerOptions={{ itemSize: 38 }}
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                options={
                  usersList?.length ? usersList : [{ email: "Loading...", value: null }]
                }
                fixedPlaceholder={true}
                optionLabel="email"
                filter
                placeholder="Search Users"
                maxSelectedLabels={3}
                className="w-full md:w-full text-sm"
              />
            </div>
            <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {selectedUsers?.map((user) => (
                <Chip
                  key={user.id}
                  label={user.email}
                  className="text-sm"
                  removable
                  onRemove={() => {
                    handleUserRemove(user);
                  }}
                />
              ))}
            </div>
          </div>

          <div
            className={`w-full flex justify-content-end gap-2  ${isMobile ? "sidebar-mobile" : "sidebar-desktop"}`}
          >
            <Button
              label="Cancel"
              onClick={handleSideBarClose}
              className="text-sm text-gray-500 bg-white border-0"
            />
            <Button
              label={sidebarTitle === "Add New Group" ? "Add Group" : "Update"}
              className="text-sm bg-blue-500"
              onClick={handleSubmitTemplate}
              loading={errorNewGroupLoading}
              disabled={isUploadDisabled}
            />
          </div>
        </Sidebar>
      </div>
    </>
  );
};

export default AddNewGroups;
