import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "primereact/hooks";
import { TabView, TabPanel } from "primereact/tabview";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./index.css";
import ApplyRuleSidebar from "./ApplyRuleSidebar";
// import applyMasterRule from "models/applyRuleMaster";
import { useSourceApi } from "hooks/sourceConfig";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
// import PreLoader from "components/Preloader";

export default function ApplyRules({ setAppliedRules, setImported }) {
  const [filterNodesData, setFilterNodesData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [onRowData, setOnRowData] = useState();
  const navigate = useNavigate();
  const [searchText, searchDebouncedValue, setSearchInput] = useDebounce(
    "",
    300
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [sourceIdData, setSourceIdData] = useState([]);
  const [globalFilters, setGlobalFilters] = useState("");
  const [globalFiltersData, setGlobalFiltersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {getSourceById,postApplyRule}=useSourceApi()

  const tabs = ["cleansing", "formatting", "standardizing", "validation"];
  const sourceId = localStorage.getItem("source_id");

  const groupDataBySchemaAndTable = (data) => {
    const groupedData = {};

    data?.forEach((item) => {
      const schemaName = item.schemaName;
      const tableName = item.tableName;
      const sourceFieldId = item?.id;
      if (!groupedData[schemaName]) {
        groupedData[schemaName] = {
          key: schemaName,
          data: { schemaName: schemaName },
          tables: {},
        };
      }
      if (!groupedData[schemaName].tables[tableName]) {
        groupedData[schemaName].tables[tableName] = {
          key: tableName,
          data: { tableName: tableName },
          children: [],
        };
      }

      const rulesApplied =
        item.rules && item.rules.length > 0
          ? item.rules.map((rule) => ({
              id: rule.id,
              ruleId: rule?.rule?.id,
              ruleLabel: rule?.rule?.ruleLabel,
              ruleName: rule?.rule?.ruleName,
              category: rule?.rule?.category,
              config: rule?.config,
              isPredefined: rule?.isPredefined,
            }))
          : [{ id: null, ruleLabel: "No Rules Applied", ruleId: null }];

      groupedData[schemaName].tables[tableName].children.push({
        key: sourceFieldId,
        data: {
          tableName: item.sourceEntityField?.fieldLabel,
          rulesApplied: rulesApplied,
        },
      });
    });

    return Object.keys(groupedData).map((schemaName) => ({
      ...groupedData[schemaName],
      tables: Object.values(groupedData[schemaName].tables),
    }));
  };

  async function getSourceIdData() {
    try {
      setLoading(true);
      const sourceData = await getSourceById(sourceId);
      // if (sourceData) {
      //   localStorage.setItem(
      //     "sourceDetails",
      //     JSON.stringify(sourceData?.source)
      //   );
      // }
      setSourceIdData(sourceData?.fieldRules);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      setSourceIdData([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    getSourceIdData();
  }, []);

  useEffect(() => {
    const filteredData = sourceIdData?.map((item) => ({
      ...item,
      rules: item?.rules?.filter(
        (rule) => rule.rule.category === tabs[activeIndex]
      ),
    }));

    const filterNodesData = [];
    const groupedData = groupDataBySchemaAndTable(filteredData);

    groupedData?.forEach((schema) => {
      const schemaNode = {
        key: schema.key,
        data: schema.data,
        children: Object.values(schema.tables).map((table) => ({
          key: table.key,
          data: table.data,
          children: table.children,
        })),
      };

      filterNodesData.push(schemaNode);
    });
    setFilterNodesData(filterNodesData);
  }, [sourceIdData, activeIndex]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex < 3 ? prevIndex + 1 : 3));
  };

  const actionBodyTemplate = (node) => {
    if (!node.children) {
      const actionBtn =
        node?.data?.rulesApplied?.[0].ruleLabel === "No Rules Applied"
          ? "Apply Rule"
          : "Edit Rule";
      return (
        <>
          <Button
            label={<i className="pi pi-eye" />}
            type="button"
            className="mt-5 bg-white p-3 text-xs text-[#3B82F6] border border-solid border-[#3B82F6] mr-5"
            style={{color:'#3B82F6'}}
          />
          <Button
            label={actionBtn}
            onClick={() => setVisible(true)}
            type="button"
            className="mt-5 bg-white p-3 text-xs text-[#3B82F6] border border-solid border-[#3B82F6] mr-5"
            style={{color:'#3B82F6'}}
          />
        </>
      );
    }
    return null;
  };

  const rulesBodyTemplate = (node) => {
    const maxVisibleRules = 2;
    const rulesApplied = node.data.rulesApplied || [];
    const visibleRules = rulesApplied.slice(0, maxVisibleRules);
    const remainingRules =
      rulesApplied.length - maxVisibleRules > 0
        ? rulesApplied.slice(maxVisibleRules)
        : [];

    return (
      <div className="flex flex-wrap">
        {rulesApplied.length === 1 &&
        rulesApplied[0].ruleLabel === "No Rules Applied" ? (
          <span className="text-sm text-gray-500">
            {rulesApplied[0].ruleLabel}
          </span>
        ) : (
          <>
            {visibleRules.map((rule, index) => (
              <span
                key={rule.id || index}
                style={{
                  backgroundColor: '#E5E7EB',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  marginLeft: '0.5rem',
                  marginBottom: '0.5rem',
                  border: '1px solid #E5E7EB',
                  borderRadius: '7px',
                  padding: '1px',
                  fontSize: '0.875rem'
                }}
              >
                {rule.ruleLabel}
              </span>
            ))}

            {remainingRules.length > 0 && (
              <>
                <span
                  style={{
                    backgroundColor: '#E5E7EB',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    marginLeft: '0.5rem',
                    marginBottom: '0.5rem',
                    border: '1px solid #E5E7EB',
                    borderRadius: '16px',
                    fontSize: '0.875rem'
                  }}
                  className="more-rules-chip"
                  data-pr-tooltip={remainingRules
                    .map((r) => r.ruleLabel)
                    .join(", ")}
                  data-pr-position="top"
                  data-pr-my="left bottom"
                >
                  +{remainingRules.length}
                </span>
                <Tooltip target=".more-rules-chip" />{" "}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const handleRowClick = (e) => {
    const clickedRowData = e.node;
    setOnRowData(clickedRowData);
  };

  const generatePayload = (data, deleteItems) => {
    const updatedRules = data.map((dataItem) => {
      const ruleObject = {
        config: dataItem?.multiInfoData
          ? [
              dataItem.multiInfoData.inputTextValue,
              dataItem.multiInfoData.dropdownValue,
            ]
          : dataItem?.info
          ? [dataItem?.info]
          : null,
        operation: "added",
        orderId: dataItem.order,
        rule: {
          id: dataItem.ruleId,
          ruleLabel: dataItem.label,
          ruleName: dataItem.ruleName,
          category: dataItem.category,
        },
      };
      if (dataItem.id) {
        ruleObject.id = dataItem.id;
        ruleObject.isPredefined = dataItem.isPredefined;
      }
      return ruleObject;
    });

    const PayloadBody = {
      mapRules: [
        {
          sourceTargetMapId: onRowData?.key,
          rules: {
            added: updatedRules,
            updated: [],
          },
        },
      ],
      deletedRules: deleteItems,
    };

    return PayloadBody;
  };
  const handleApplyRulesFromParent = async (data) => {
    const payload = generatePayload(data?.items, data?.storeDeleteItem);
    console.log('sourceId is ', sourceId)
    console.log('payload is ', payload)
    try {
      const responseData = await postApplyRule(
        sourceId,
        payload
      );
      console.log('responseData is ', responseData)
      if (responseData === 201 || responseData?.error === null || responseData === undefined) {
        getSourceIdData();
        setVisible(false);
        setOnRowData();
        setGlobalFilters("");
        setGlobalFiltersData([]);
      }
    } catch (error) {
      console.error("Error posting rules:", error);
    }
  };

  const nameBodyTemplate = (node) => {
    if (node.data && node.data.schemaName) {
      return node.data.schemaName;
    }
    return node.data.tableName || node.data.fieldName;
  };

  const filterTreeData = (nodes, filter) => {
    return nodes
      .map((node) => {
        const isMatch =
          node.data.schemaName?.toLowerCase().includes(filter.toLowerCase()) ||
          node.data.tableName?.toLowerCase().includes(filter.toLowerCase()) ||
          (node.data.rulesApplied || []).some((rule) =>
            rule.ruleLabel?.toLowerCase().includes(filter.toLowerCase())
          );

        if (node.children) {
          const filteredChildren = filterTreeData(node.children, filter);
          if (filteredChildren.length || isMatch) {
            return { ...node, children: filteredChildren };
          }
        }

        return isMatch ? node : null;
      })
      .filter((node) => node !== null);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilters(value);
    const filteredData = filterTreeData(filterNodesData, value);
    setGlobalFiltersData(filteredData);
  };

  return (
    <div className="flex flex-col h-[60vh]">
      <ApplyRuleSidebar
        visible={visible}
        onRowClickData={onRowData}
        onHide={() => {
          setVisible(false);
          setOnRowData();
        }}
        activeIndex={activeIndex}
        onApplyRules={handleApplyRulesFromParent}
      />
      <div className="card ml-5">
        <label className="w-[300px] text-lg text-md font-semibold ml-2">
          Apply Rules
        </label>
        <div className="flex flex-row justify-between items-center mb-2 mt-2">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            {[
              "Cleansing Rules",
              "Formatting Rules",
              "Standardization",
              "Validation Rules",
            ].map((header, index) => (
              <TabPanel
                key={index}
                header={
                  <span
                    className={`py-2 px-4 transition-colors duration-200 ${
                      activeIndex === index
                        ? "border-b-2 border-[#3B82F6] !text-[#3B82F6]"
                        : ""
                    }`}
                  >
                    {header}
                  </span>
                }
              />
            ))}
          </TabView>
          <div className="flex flex-row mb-10">
            <Button
              icon="pi pi-chevron-left"
              onClick={handlePrev}
              disabled={activeIndex === 0}
              style={{backgroundColor:'white', color:'#666666', border:'0px'}}
            />
            <Button
              icon="pi pi-chevron-right"
              onClick={handleNext}
              disabled={activeIndex === 3}
              style={{backgroundColor:'white', color:'#666666', border:'0px'}}
            />
          </div>
        </div>
      </div>
      <>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          backgroundColor: '#F9FAFB',
          borderRadius: '8px',
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: '12px',
            marginTop: '12px',
            border: '1px solid #ced4da',
            backgroundColor: 'white',
            borderRadius: '5px'
          }}>
            <span className="p-input-icon-left">
              <i style={{
                paddingLeft: '12px',
                fontWeight: '200',
                color: '#6C757D',
                opacity: '0.8'
              }} className="pi pi-search" />
            </span>
            <InputText
              type="search"
              value={globalFilters}
              onChange={handleFilterChange}
              placeholder="Search by Tables or Data fields."
              style={{
                width: '270px',
                paddingLeft: '20px',
                fontSize:'14px',
                paddingRight: '20px',
                paddingTop: '12px',
                paddingBottom: '12px',
                marginLeft: '20px',
                border: '0'
              }}
              className="pulse-search-component"
            />
          </div>
          <Divider style={{ borderColor: '#e5e7eb', marginBottom: '0' }} />
        </div>
        {globalFilters.trim() !== "" ? (
          globalFiltersData.length > 0 ? (
            globalFiltersData.map((schema, index) => (
              <div key={index}>
                <div className="bg-gray-50 rounded-lg shadow-md">
                  <h3 className="font-semibold py-2 pl-5 pt-3">
                    {schema?.key}
                  </h3>
                  <Divider className="border !mb-0" />
                </div>
                <div>
                  <TreeTable
                    value={schema.children}
                    onRowClick={(e) => handleRowClick(e)}
                  >
                    <Column
                      body={nameBodyTemplate}
                      header="Table/Data Field"
                      expander
                    />
                    <Column
                      field="rulesApplied"
                      header="Rules Applied"
                      body={rulesBodyTemplate}
                    />
                    <Column body={actionBodyTemplate} header="Action" />
                  </TreeTable>
                </div>
              </div>
            ))
          ) : (
            <div>
              <TreeTable
                value={[{ data: { message: "No options available" } }]}
              >
                <Column
                  header="Table/Data Field"
                  body={() => <p className="p-3">No options available</p>}
                />
                <Column header="Rules Applied" />
                <Column header="Action" />
              </TreeTable>
            </div>
          )
        ) : filterNodesData.length > 0 ? (
          filterNodesData.map((schema, index) => (
            <div key={index}>
              <div className="bg-gray-50 rounded-lg shadow-md">
                <h3 className="font-semibold py-2 pl-5 pt-3">{schema?.key}</h3>
                <Divider className="border !mb-0" />
              </div>
              <div>
                <TreeTable
                  value={schema.children}
                  onRowClick={(e) => handleRowClick(e)}
                >
                  <Column
                    body={nameBodyTemplate}
                    header="Table/Data Field"
                    expander
                  />
                  <Column
                    field="rulesApplied"
                    header="Rules Applied"
                    body={rulesBodyTemplate}
                  />
                  <Column body={actionBodyTemplate} header="Action" />
                </TreeTable>
              </div>
            </div>
          ))
        ) : loading ? (
          <div className="w-full h-full flex justify-center items-center">
            {/* <PreLoader /> */}
          </div>
        ) : (
          <div>
            <p className="p-3">No options available</p>
          </div>
        )}
      </>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-end',
        marginRight: '1.25rem',
        marginBottom: '6rem',
        position: 'absolute',
        bottom: 0,
        right: 0
      }}>
        <Button
          label="Back"
          type="button"
          className="mt-5 bg-white p-3 text-xs text-[#3B82F6] border border-solid border-[#3B82F6] mr-5"
          onClick={() => {
            setImported(false);
            setAppliedRules(false);
          }}
          style={{color:'#3B82F6'}}
        />
        <Button
          label="Next Step"
          type="button"
          className="mt-5 bg-[#3B82F6] p-3 text-xs text-white"
          onClick={() => {
            setAppliedRules(true);
            setImported(true);
          }}
        />
      </div>
    </div>
  );
}
