const ChatIcon = ({ selectedItem }) => {
    return selectedItem === "chat" ? (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.42871 6.63721H9.24732H12.0659" stroke="#3b82f6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.42871 9.45581H7.83802H9.24732" stroke="#3b82f6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.2477 15.0931C13.1394 15.0931 16.2942 11.9382 16.2942 8.04653C16.2942 4.15484 13.1394 1 9.2477 1C5.35601 1 2.20117 4.15484 2.20117 8.04653C2.20117 9.32998 2.54432 10.5333 3.14387 11.5698L1.20117 16.5L5.72444 14.1504C6.76089 14.7499 7.96425 15.0931 9.2477 15.0931Z" stroke="#3b82f6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.201 17.2192C11.239 18.1892 12.6331 18.7828 14.1658 18.7828C15.2238 18.7828 16.2158 18.5 17.0702 18.0058L20.7988 19.9426L19.1974 15.8785C19.6916 15.0241 19.9745 14.0322 19.9745 12.9742C19.9745 11.4369 19.3773 10.039 18.4022 9" stroke="#3b82f6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    ) : (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.42871 6.63721H9.24732H12.0659" stroke="#DADAFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.42871 9.45581H7.83802H9.24732" stroke="#DADAFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.2477 15.0931C13.1394 15.0931 16.2942 11.9382 16.2942 8.04653C16.2942 4.15484 13.1394 1 9.2477 1C5.35601 1 2.20117 4.15484 2.20117 8.04653C2.20117 9.32998 2.54432 10.5333 3.14387 11.5698L1.20117 16.5L5.72444 14.1504C6.76089 14.7499 7.96425 15.0931 9.2477 15.0931Z" stroke="#DADAFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.201 17.2192C11.239 18.1892 12.6331 18.7828 14.1658 18.7828C15.2238 18.7828 16.2158 18.5 17.0702 18.0058L20.7988 19.9426L19.1974 15.8785C19.6916 15.0241 19.9745 14.0322 19.9745 12.9742C19.9745 11.4369 19.3773 10.039 18.4022 9" stroke="#DADAFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default ChatIcon;
