import React from 'react'

const Resolved = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 3.5L3.5 9L1 6.5M11 5.5L7.25 9.25L6.5 8.5" stroke="#188A42" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
  )
}

export default Resolved