import React from 'react'

const Notified = () => {
  return (
  <svg width="14" height="16" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.15 10C4.23369 10.1522 4.35672 10.2792 4.50625 10.3676C4.65577 10.456 4.82629 10.5027 5 10.5027C5.17371 10.5027 5.34423 10.456 5.49375 10.3676C5.64328 10.2792 5.76631 10.1522 5.85 10M2 3.5C2 2.70435 2.31607 1.94129 2.87868 1.37868C3.44129 0.81607 4.20435 0.5 5 0.5C5.79565 0.5 6.55871 0.81607 7.12132 1.37868C7.68393 1.94129 8 2.70435 8 3.5C8 7 9.5 8 9.5 8H0.5C0.5 8 2 7 2 3.5Z" stroke="#1581ED" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default Notified