import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import SourceTablesFieldsModal from "../StructuredSources/SourceTablesFieldsModal";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useSourceApi } from "hooks/sourceConfig";
import { Divider } from "primereact/divider";

const SourceCredentials = ({ setImported }) => {
  const [sourceId, setSourceId] = useState();
  const [sourceName, setSourceName] = useState("");
  const [connectionType, setConnectionType] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedConnectionOption, setSelectedConnectionOption] =
    useState(null);
  const [placeholderText, setPlaceholderText] = useState("Select an Option");
  const [isTablesFieldsModalOpen, setTablesFieldsModal] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [useSSH, setUseSSH] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const [testSuccessful, setTestSuccessful] = useState(false);
  const [schemaOptions, setSchemaOptions] = useState([]);
  const sourceData = {
    name: sourceName,
    id: sourceId,
    clientId: 1, // Replace with actual client ID
    connectionType: connectionType,
    runEveryMinutes: 60, // Replace with actual value if needed
    uuid: "e1db318c-b8ce-47d0-954b-87fe204c0be7", // Replace with actual UUID
  };
  const [errors, setErrors] = useState({});
  const { id: routeId } = useParams();
  const { testDbConnection, getSourceById, getSchemas } = useSourceApi();

  useEffect(() => {
    const prefetchSource = async () => {
      const response = await getSourceById(routeId);
      const { source } = response;
      const { id, name, connectionType, definition } = source;
      const sourceDetails = await JSON.parse(
        localStorage.getItem("sourceDetails")
      );
      const { database, databaseHost, databaseType, port, username } =
        definition;
      setSourceId(id);
      setSourceName(name);
      setConnectionType(connectionType);
      setSelectedConnectionOption(databaseType);
      setHost(databaseHost);
      setDatabaseName(database);
      setPort(port);
      setUsername(username);
    };
    prefetchSource();
  }, []);

  const getConnectionDetails = () => {
    return {
      databaseHost: host,
      port,
      databaseType: selectedConnectionOption,
      database: databaseName,
      username,
      password,
      // useSSH
    };
  };

  const validateForm = () => {
    const newErrors = {};
    if (!sourceName || sourceName.trim().length === 0) newErrors.sourceName = "Source Name is required";
    if (!host || host.trim().length === 0) newErrors.host = "Host is required";
    if (!port || port.trim().length === 0) newErrors.port = "Port is required";
    if (!username || username.trim().length === 0) newErrors.username = "Username is required";
    if (!password || password.trim().length === 0) newErrors.password = "Password is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleTestConnection = async () => {
    if (validateForm()) {
      const connectionDetails = getConnectionDetails();
      setTestingConnection(true);
      try {
        const response = await testDbConnection(connectionDetails);
        if (response === true) {
          toast.current.show({
            severity: "success",
            summary: "Connection Successful",
            detail: "The connection to the database was successful.",
            life: 3000,
          });
          setTestSuccessful(true);

          const schemas = await getSchemas(connectionDetails);
          if (schemas && schemas.length > 0) {
            const schemaOptions = schemas.map((schema) => ({
              label: schema,
              value: schema,
            }));
            setSchemaOptions(schemaOptions);
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Connection Failed",
            detail: "Failed to connect to the database.",
            life: 3000,
          });
          setTestSuccessful(false);
        }
      } catch (error) {
        console.error("Error during connection test:", error);
      } finally {
        setTestingConnection(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setTablesFieldsModal(true);
    } catch (error) {
      console.error("Error during source edit:", error);
    }
  };

  const renderPostgresForm = () => {
    return (
      <div className="mt-7 bg-[#F5F5F5] w-[28vw] ml-[320px] pl-[20px] pt-[20px] pb-[20px] rounded-md">
        <div className="flex flex-col">
          <label className="w-[300px] text-sm font-semibold">
            Set Database Credentials
          </label>
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="host">
            Host <span className="text-red-500">*</span>
          </label>
          <InputText
            id="host"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.host
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            placeholder="Enter Host"
            value={host}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 50) {
                // Max length 50
                setHost(value);
                setTestSuccessful(false);
              }
            }}
            required // Mandatory field
          />
          {errors.host && <small className="p-error">{errors.host}</small>}
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="port">
            Port <span className="text-red-500">*</span>
          </label>
          <InputText
            id="port"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.port
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            placeholder="Enter Port"
            value={port}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
              if (value.length <= 5) {
                // Max length 5
                setPort(value);
                setTestSuccessful(false);
              }
            }}
            required // Mandatory field
          />
          {errors.port && <small className="p-error">{errors.port}</small>}
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="databaseName">
            Database <span className="text-red-500">*</span>
          </label>
          <InputText
            id="databaseName"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.databaseName
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            placeholder="Enter Database Name"
            value={databaseName}
            disabled={true}
            // onChange={(e) => {
            //   const value = e.target.value;
            //   if (value.length <= 50) {
            //     // Max length 50
            //     setDatabaseName(value);
            //     setTestSuccessful(false);
            //   }
            // }}
            required // Mandatory field
          />
          {errors.databaseName && (
            <small className="p-error">{errors.databaseName}</small>
          )}
        </div>
        <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />
        <div className="flex flex-col mt-5">
          <label className="w-[300px] text-sm font-semibold">
            Authentication
          </label>
        </div>

        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="username">
            Username <span className="text-red-500">*</span>
          </label>
          <InputText
            id="username"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.username
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            value={username}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 50) {
                // Max length 50
                setUsername(value);
                setTestSuccessful(false);
              }
            }}
            placeholder="Enter UserName"
            required // Mandatory field
          />
          {errors.username && (
            <small className="p-error">{errors.username}</small>
          )}
        </div>
        <div className="flex flex-col mt-3">
          <label className="w-[300px] text-sm" htmlFor="password">
            Password <span className="text-red-500">*</span>
          </label>
          <InputText
            id="password"
            type="password"
            className={`w-[95%] h-[6vh] mt-2 p-3 text-sm ${errors.username
                ? "border-red-500 border-solid border-[1px]"
                : "border-solid border-[1px] border-gray-300"
              }`}
            value={password}
            placeholder="Enter Password"
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 50) {
                setPassword(value);
                setTestSuccessful(false);
              }
            }}
            required // Mandatory field
          />
          {errors.password && (
            <small className="p-error">{errors.password}</small>
          )}
        </div>
        <Divider layout="horizontal" className="pr-4 text-gray-500 border-gray-500" />
        <Button
          label="Test Connection"
          type="button"
          className="mt-5 bg-[#A855F7] p-3 text-xs text-white"
          style={{ backgroundColor: '#A855F7', border:'0px' }}
          onClick={handleTestConnection}
          loading={testingConnection}
        />
        <Toast ref={toast} position={"top-right"} />
      </div>
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col ml-[30px] new-source-form relative"
      style={{ height: '100%' }}
    >
      <div className="flex flex-col overflow-y-auto pb-[80px]"> {/* Adjusted height with padding */}
        {isTablesFieldsModalOpen && (
          <SourceTablesFieldsModal
            visible={isTablesFieldsModalOpen}
            onClose={() => setTablesFieldsModal(false)}
            setImported={setImported}
            connectionDetails={getConnectionDetails()}
            sourceData={sourceData}
            editMode={true}
          />
        )}
        <label className="w-[300px] text-lg text-md font-semibold">
          Edit Source Details
        </label>
        <div className="flex flex-row mt-5">
          <label className="w-[300px]" htmlFor="sourceName">
            Source Name <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col">
            <InputText
              id="sourceName"
              className={`ml-5 w-[28vw] h-[6vh] border-solid border-[1px] border-gray-300 p-4 ${!sourceName && "border-red-300 border-[2px]"
                }`}
              value={sourceName}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 50) {
                  setSourceName(value);
                }
              }}
              required
            />
            <div className="h-[10px]">
              {!sourceName && (
                <small className="p-error ml-[25px]">
                  Source Name is required.
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-5">
          <label className="w-[300px]" htmlFor="connectionType">
            Connection Type <span className="text-red-500">*</span>
          </label>
          <Dropdown
            id="connectionType"
            className="ml-5 w-[28vw] h-[6vh] border-solid border-[1px] border-gray-300 new-source-form"
            placeholder={connectionType}
            disabled={true}
          />
        </div>
        <div className="flex flex-row mt-5 ml-[300px]">
          <Dropdown
            id="specificOption"
            className="ml-5 w-[28vw] h-[6vh] border-solid border-[1px] border-gray-300 ml-[320px] new-source-form"
            placeholder={selectedConnectionOption}
            disabled={true}
          />
        </div>
        {selectedConnectionOption === "postgres" && renderPostgresForm()}
      </div>

      <div className="fixed bottom-0 right-0 left-0 bg-white py-4 px-5 flex justify-end border-t border-gray-200">
        <Button
          label="Back"
          type="button"
          className="bg-white text-xs"
          onClick={() => {
            setTimeout(() => {
              navigate("/source-configuration/source");
            }, 100);
          }}
          style={{ height: '5vh', color: '#3B82F6' }}
        />
        <Button
          label="Next Step"
          type="submit"
          className="bg-[#3B82F6] text-xs text-white ml-5"
          disabled={!testSuccessful}
          style={{ height: '5vh' }}
        />
      </div>
    </form>
  );

};

export default SourceCredentials;
