  export const ALERTS_LIST = [
    {
        "id": 1,
        "time": "2024-11-12T08:15:00Z",
        "created_at": "2024-11-12T08:15:00Z",
        "alert_message": "CPU usage exceeded threshold",
        "severity": "HIGH",
        "stream_name": "System Monitoring",
        "rule_id": "CPU_Threshold_01",
        "asset_id": "Server_01",
        "actual_value": "95%",
        "asset_name": "Main Server",
        "escalation_level": 2,
        "threshold": "80%",
        "source_type": "System",
        "next_suggested_action": "Scale up resources",
        "raised": true,
        "notified": false,
        "acknowledged": false,
        "investigated": false,
        "resolved": false,
        "escalated": false,
        "milestones": [
            {
                "title": "Alert Raised",
                "sub_title": "CPU usage exceeded threshold",
                "created_at": "2024-11-12T08:15:00Z",
                "milestone_type": "HIGH_SEVERITY"
            }
        ]
    },
    {
        "id": 2,
        "time": "2024-11-11T08:45:00Z",
        "created_at": "2024-11-11T08:45:00Z",
        "alert_message": "Memory usage at CRITICAL level",
        "severity": "CRITICAL",
        "stream_name": "System Monitoring",
        "rule_id": "Memory_Critical_02",
        "asset_id": "Server_01",
        "actual_value": "90%",
        "asset_name": "Main Server",
        "escalation_level": 3,
        "threshold": "75%",
        "source_type": "System",
        "next_suggested_action": "Restart application",
        "raised": true,
        "notified": true,
        "acknowledged": true,
        "investigated": false,
        "resolved": false,
        "escalated": false,
        "milestones": [
            {
                "title": "Alert Raised",
                "sub_title": "Memory usage at CRITICAL level",
                "created_at": "2024-11-11T08:45:00Z",
                "milestone_type": "HIGH_SEVERITY"
            },
            {
                "title": "Acknowledged",
                "sub_title": "Alert acknowledged by team",
                "created_at": "2024-11-11T09:00:00Z",
                "milestone_type": "ESCALATED"
            }
        ]
    },
    {
        "id": 3,
        "time": "2024-11-09T09:00:00Z",
        "created_at": "2024-11-09T09:00:00Z",
        "alert_message": "Disk space LOW on server X",
        "severity": "MODERATE",
        "stream_name": "Storage Monitoring",
        "rule_id": "Disk_Space_01",
        "asset_id": "Server_X",
        "actual_value": "10GB",
        "asset_name": "Server X",
        "escalation_level": 1,
        "threshold": "20GB",
        "source_type": "Storage",
        "next_suggested_action": "Free up space",
        "raised": true,
        "notified": true,
        "acknowledged": true,
        "investigated": true,
        "resolved": false,
        "escalated": true,
        "milestones": [
            {
                "title": "Alert Raised",
                "sub_title": "Disk space LOW on server X",
                "created_at": "2024-11-09T09:00:00Z",
                "milestone_type": "ESCALATED"
            },
            {
                "title": "Acknowledged",
                "sub_title": "Alert acknowledged by team",
                "created_at": "2024-11-09T09:15:00Z",
                "milestone_type": "HIGH_SEVERITY"
            },
            {
                "title": "Investigated",
                "sub_title": "Disk space usage investigated",
                "created_at": "2024-11-09T09:30:00Z",
                "milestone_type": "HIGH_SEVERITY"
            }
        ]
    },
    {
        "id": 4,
        "time": "2024-11-10T09:30:00Z",
        "created_at": "2024-11-12T08:15:00Z",
        "alert_message": "Network latency detected",
        "severity": "LOW",
        "stream_name": "Network Monitoring",
        "rule_id": "Net_Latency_01",
        "asset_id": "Router_01",
        "actual_value": "300ms",
        "asset_name": "Office Router",
        "escalation_level": 1,
        "threshold": "100ms",
        "source_type": "Network",
        "next_suggested_action": "Investigate network paths",
        "raised": true,
        "notified": true,
        "acknowledged": true,
        "investigated": true,
        "resolved": true,
        "escalated": false
    },
    {
        "id": 5,
        "time": "2024-11-13T10:00:00Z",
        "created_at": "2024-11-12T08:15:00Z",
        "alert_message": "Unauthorized access attempt",
        "severity": "CRITICAL",
        "stream_name": "Security",
        "rule_id": "Sec_Unauth_Acc_01",
        "asset_id": "Firewall_01",
        "actual_value": "5 attempts",
        "asset_name": "Main Firewall",
        "escalation_level": 4,
        "threshold": "1 attempt",
        "source_type": "Security",
        "next_suggested_action": "Review access logs",
        "raised": true,
        "notified": true,
        "acknowledged": true,
        "investigated": true,
        "resolved": false,
        "escalated": true
    }
];

  