import * as React from "react";
import { useState, useEffect } from "react";
import ChevronRight from "./icons/ChevronRight";
import SourceConfigBookIcon from "./icons/SourceConfigBookIcon";
// import SettingsCog from "./icons/SettingsBreadcrumbCog";
// import HeaderRight from "../WorkspaceChat/ChatUi/ChatHeader/HeaderRight";

export function SourceConfigBreadcrumb({ sourceConfig }) {
  const [openUserProfile, setOpenUserProfile] = React.useState(false);

  return (
    <div
      className={`flex relative gap-5 items-start justify-between pb-2 items-center ml-5`}
    >
      <div className="flex flex-row items-center">
        <div>
          <SourceConfigBookIcon />
        </div>
        <p className="text-md whitespace-nowrap text-black overflow-hidden ml-3">
          Source Configuration
        </p>
        <div className="ml-3">
          <span>/</span>
        </div>
        <p className="text-md whitespace-nowrap text-[#6C757D] overflow-hidden ml-3">
          {sourceConfig}
        </p>
      </div>
      {/* <HeaderRight /> */}
    </div>
  );
}
