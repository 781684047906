import React, { useState, useEffect } from "react";
import Raised from "../AlertTabList/icons/Raised";
import Acknowledged from "../AlertTabList/icons/Acknowledged";
import Escalated from "../AlertTabList/icons/Escalated";
// import Investigated from '../AlertTabList/icons/Investigated';
import Notified from "../AlertTabList/icons/Notified";
import Resolved from "../AlertTabList/icons/Resolved";
import { ALERTS_LIST } from "../AlertTabList/mockAlerts";
import { TabView, TabPanel } from "primereact/tabview";
import { InputSwitch } from "primereact/inputswitch";
import AlertSevere from "./icons/AlertSevere";
import AlertLow from "./icons/AlertLow";
import ActivityGenLow from "./icons/ActivityGenLow";
import ActivityGenMedium from "./icons/ActivityGenMedium";
import ActivityNeio from "./icons/ActivityNeio";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Timeline } from "primereact/timeline";
import { Carousel } from "primereact/carousel";
import ActivityEscalated from "./icons/ActivityEscalated";
import ActivityHighSeverity from "./icons/ActivityHighSeverity";
import ActivityResolved from "./icons/ActivityResolved";
import { format } from "date-fns";
import "./index.css";
import AlertModerate from "./icons/AlertModerate";
import { Skeleton } from "primereact/skeleton";
import AlertCritical from "./icons/AlertCritical";
import ChevronRight from "./icons/ChevronRight";
import ChevronLeft from "./icons/ChevronLeft";
import ActivityAck from "./icons/ActivityAck";
import ActivityComment from "./icons/ActivityComment";
import { Divider } from "primereact/divider";
import { usePulseApi } from "hooks/pulseConfig";

const AlertTabDetails = ({ selectedAlertId }) => {
  // Find the alert based on the alertId passed as a prop
  // const selectedAlert = ALERTS.find((alert) => alert.id === alertId);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollableTabs = Array.from({ length: 50 }, (_, i) => ({
    title: `Tab ${i + 1}`,
    content: `Tab ${i + 1} Content`,
  }));
  const [checked, setChecked] = useState(false);
  const [alertDetail, setAlertDetail] = useState();
  const [severity, setSevirity] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [stream, setStream] = useState();
  const [streamId, setStreamId] = useState();
  const [ruleId, setRuleId] = useState();
  const [actualVal, setActualVal] = useState();
  const [assetName, setAssetName] = useState();
  const [escalationLevel, setEscalationLevel] = useState();
  const [threshold, setThreshold] = useState();
  const [sourceType, setSourceType] = useState();
  const [nextAction, setNextAction] = useState();
  const [milestones, setMilestones] = useState();
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const { getPulseAlertDetail, getPulseAlertMilestones } = usePulseApi();

  const mockAlert = [
    // { heading: 'Stream', value: stream },
    // { heading: 'Actual Value', value: actualVal },
    { heading: "Asset", value: assetName },
    // { heading: 'Escalation Level', value: escalationLevel },
    // { heading: 'Threshold', value: threshold },
    // { heading: 'Source Type', value: sourceType },
    // { heading: 'Next Suggested Action', value: nextAction },
  ];

  // Filter out entries with empty or null values
  const filteredAlert = mockAlert.filter((item) => item.value !== null && item.value !== "");

  // Render filteredAlert items
  filteredAlert.map((item, index) => (
    <div key={index}>
      <strong>{item.heading}:</strong> {item.value}
    </div>
  ));

  const DisplayComponent = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 2);
      }
    };

    const handleNext = () => {
      if (currentIndex < filteredAlert.length - 2) {
        setCurrentIndex(currentIndex + 2);
      }
    };

    const dataToDisplay = filteredAlert.slice(currentIndex, currentIndex + 2);

    return (
      <div className="flex flex-row items-center justify-between w-full border-t border-gray-300">
        {/* Previous Arrow */}
        {/* <Button
        disabled={currentIndex === 0}
        onClick={handlePrev}
        type="button"
        className={`rounded-full bg-[#F5F5F5] p-1 ${currentIndex === 0 ? 'cursor-not-allowed' : ''}`}
        label={
          <div className="flex items-center justify-center w-10 h-10">
            <ChevronLeft />
          </div>
        }
      /> */}

        {/* Data display */}
        <div className="flex flex-col w-full mt-5">
          {dataToDisplay.map((data, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <p className="text-sm text-[#191A1C]">{data.heading}</p>
              <p className="font-bold text-lg text-[#191A1C] mt-2">{data.value}</p>
            </div>
          ))}
        </div>

        {/* Next Arrow */}
        {/* <Button
        disabled={currentIndex >= filteredAlert.length - 2}
        onClick={handleNext}
        type="button"
        className={`rounded-full bg-[#F5F5F5] p-1 ${currentIndex >= filteredAlert.length - 2 ?'cursor-not-allowed' : ''}`}
        label={
          <div className="flex items-center justify-center w-10 h-10">
            <ChevronRight />
          </div>
        }
      /> */}
      </div>
    );
  };

  useEffect(() => {
    console.log("selected alertid is ", selectedAlertId);
    const fetchData = async () => {
      try {
        setLoadingDetails(true);
        const response = await getPulseAlertDetail({ feed_id: selectedAlertId });
        setAlertDetail(response?.results?.data?.feeds?.[0]);
        setSevirity(response?.results?.data?.feeds[0]?.severity);
        setAlertMsg(response?.results?.data?.feeds[0]?.alert_message);
        setStream(response?.results?.data?.feeds[0]?.stream_name);
        setRuleId(response?.results?.data?.feeds[0]?.rule_id);
        setStreamId(response?.results?.data?.feeds[0]?.asset_id);
        setActualVal(response?.results?.data?.feeds[0]?.actual_value);
        setAssetName(response?.results?.data?.feeds[0]?.asset_name);
        setEscalationLevel(response?.results?.data?.feeds[0]?.escalation_level);
        setThreshold(response?.results?.data?.feeds[0]?.threshold);
        setSourceType(response?.results?.data?.feeds[0]?.source_type);
        setNextAction(response?.results?.data?.feeds[0]?.next_suggested_action);
      } catch (error) {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: extractErrorMessage(error),
        //   life: 3000,
        // });
        console.log(" error encountered ", error);
      } finally {
        setLoadingDetails(false);
      }

      try {
        setLoadingActivity(true);
        const response = await getPulseAlertMilestones({ feed_id: selectedAlertId });
        setMilestones(response?.results?.data?.milestones);
      } catch (error) {
        console.log(" error encountered ", error);
      } finally {
        setLoadingActivity(false);
      }
    };

    fetchData();
  }, [selectedAlertId]);

  const getAlertText = (alertDetail) => {
    let alertIcon, alertIconTxt;
    if (alertDetail?.resolved) {
      alertIcon = <Resolved />;
      alertIconTxt = "Resolved";
    }
    //  else if(alertDetail?.investigated) {
    //   alertIcon=<Investigated/>
    //   alertIconTxt='Investigated'
    // }
    else if (alertDetail?.acknowledged) {
      alertIcon = <Acknowledged />;
      alertIconTxt = "Acknowledged";
    } else if (alertDetail?.escalated) {
      alertIcon = <Escalated />;
      alertIconTxt = "Escalated";
    } else if (alertDetail?.notified) {
      alertIcon = <Notified />;
      alertIconTxt = "Notified";
    } else if (alertDetail?.raised) {
      alertIcon = <Raised />;
      alertIconTxt = "Raised";
    } else {
      alertIcon = <Raised />;
      alertIconTxt = "Raised";
    }
    return (
      <>
        <span className="ml-2">
          {/* {getAlertText(alertDetail)} */}
          {alertIcon}
        </span>
        <span className="bg-[#F5F5F5] text-sm font-semibold py-1 px-2 rounded-full text-gray-600">
          {/* {selectedAlert.type.charAt(0).toUpperCase() + selectedAlert.type.slice(1)} */}
          {alertIconTxt}
        </span>
      </>
    );
  };

  /**
   * Converts a string to title case format
   *
   * @param {string} str - Input string to convert
   * @returns {string} Title cased string
   */
  const toTitleCase = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  /**
   * Formats alert titles with appropriate HTML markup for severity and status
   *
   * @param {string} input - Raw title text
   * @returns {string} HTML formatted title string
   */
  const formatTitle = (input) => {
    if (/HIGH_SEVERITY|MEDIUM_SEVERITY|LOW_SEVERITY|CRITICAL_SEVERITY/.test(input)) {
      return input.replace(
        /HIGH_SEVERITY|MEDIUM_SEVERITY|LOW_SEVERITY|CRITICAL_SEVERITY/,
        (match) => {
          const formatted = match
            .replace("HIGH_SEVERITY", "High Severity")
            .replace("MEDIUM_SEVERITY", "Medium Severity")
            .replace("LOW_SEVERITY", "Low Severity")
            .replace("CRITICAL_SEVERITY", "Critical Severity");
          return `<b>${formatted}</b>`;
        },
      );
    }

    if (/Alert Escalated to Level \d+/.test(input)) {
      return input.replace(/Alert Escalated|Level \d+/g, (match) => `<b>${match}</b>`);
    }

    if (/Acknowledged|Resolved/.test(input)) {
      return input
        .replace(/^(.*?)(?=\sMarked|Acknowledged|Resolved)/, (match) => `<b>${match.trim()}</b>`)
        .replace(/Acknowledged|Resolved/, (match) => `<b>${match}</b>`);
    }

    if (/Commented/.test(input)) {
      return input.replace(/(.*)(?=\sCommented)/, (match) => `<b>${match}</b>`); // Bold all text before "Commented"
    }

    return input; // Return unchanged if no match
  };

  /**
   * Formats subtitle text with colored highlighting for specific patterns
   *
   * @param {string} input - Raw subtitle text
   * @returns {string} HTML formatted subtitle string
   */
  const formatSubtitle = (input) => {
    return input.replace(/^(.*?)(?=failed to respond)/gi, (match) => {
      return `<span style="color: #0195F7; font-weight: bold;">${match}</span>`;
    });
  };

  /**
   * Formats comment text with special styling for mentions and line breaks
   *
   * @param {string} input - Raw comment text
   * @returns {string} HTML formatted comment string
   */
  const formatSubtitleForComments = (input) => {
    const formattedInput = input.replace(/\n/g, "<br />");
    return formattedInput.replace(/(@[\w\s]+?)(?=\s|$)/g, (match) => {
      return `<span style="color: #0195F7; font-weight: bold;">${match}</span>`;
    });
  };

  const alertDetails = () => {
    return (
      <div className="flex flex-col h-[40vh] pb-3 overflow-y-scroll mt-3">
        {!loadingDetails && (
          <div className="flex space-x-3 ml-5 flex-col items-center mt-3">
            <div className="ml-2">
              {severity === "HIGH" && <AlertSevere />}
              {severity === "LOW" && <AlertLow />}
              {(severity === "MODERATE" || severity === "MEDIUM") && <AlertModerate />}
              {severity === "CRITICAL" && <AlertCritical />}
            </div>
            <p
              className={`font-semibold mr-[12px] mt-3 ${
                severity === "HIGH" || severity === "CRITICAL"
                  ? "text-[#E12120]"
                  : severity === "MODERATE" || severity === "MEDIUM"
                    ? "text-[#ec9c14]"
                    : severity === "LOW"
                      ? "text-[#0472f6]"
                      : "text-black"
              }`}
            >
              {toTitleCase(severity)} Severity
            </p>
            <p className="text-2xl font-semibold mt-3 text-[#191A1C]">{alertMsg}</p>
            {/* {MODERATE - #ec9c14 , LOW - #0472f6, HIGH - #e12120} */}
            {/* <div className='border-2 border-slate-200'></div>  */}
            <Divider
              style={{
                margin: "0.5rem 0", // my-2
                backgroundColor: "#d1d5db", // border-gray-300
              }}
            />
            <DisplayComponent />
          </div>
        )}
        {loadingDetails && (
          <div className="flex flex-col">
            <div className="mt-5 ml-[40%]">
              <Skeleton width="30%" height="5rem"></Skeleton>
            </div>
            <div className="mt-5">
              <Skeleton width="100%" height="4rem"></Skeleton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ActivityTimeline = () => {
    const [checked, setChecked] = useState(false);

    /**
     * Creates custom marker icons for timeline events
     *
     * Usage:
     * const marker = customizedMarker({
     *   milestone_type: "HIGH_SEVERITY",
     *   title: "Alert Created"
     * });
     *
     * Marker types:
     * - CRITICAL_SEVERITY/HIGH_SEVERITY: ActivityHighSeverity
     * - LOW_SEVERITY: ActivityGenLow
     * - MEDIUM_SEVERITY: ActivityGenMedium
     * - ACKNOWLEDGED: ActivityAck
     * - RESOLVED: ActivityResolved
     * - Comment types: ActivityComment/ActivityNeio
     * - ESCALATED: ActivityEscalated
     *
     * @param {Object} item - Timeline item data
     * @param {string} item.milestone_type - Type of milestone
     * @param {string} item.title - Title of the milestone
     * @returns {JSX.Element} Icon component for timeline marker
     */
    const customizedMarker = (item) => {
      const milestoneColor = item.milestone_type === "HIGH_SEVERITY" ? "#9C27B0" : "#673AB7"; // Color based on milestone type
      const milestoneIcon = (type, title) => {
        if (type === "CRITICAL_SEVERITY" || type === "HIGH_SEVERITY") {
          return <ActivityHighSeverity />;
        } else if (type === "LOW_SEVERITY") {
          return <ActivityGenLow />;
        } else if (type === "MEDIUM_SEVERITY") {
          return <ActivityGenMedium />;
        } else if (type === "ACKNOWLEDGED") {
          return <ActivityAck />;
        } else if (type === "RESOLVED") {
          return <ActivityResolved />;
        } else if (title.includes("NeIO Commented")) {
          return <ActivityNeio />;
        } else if (title.includes("Commented")) {
          return <ActivityComment />;
        } else if (title.includes("Alert Escalated")) {
          return <ActivityEscalated />;
        }
      };

      return (
        <span className="flex w-2rem mt-[2px] h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
          {milestoneIcon(item?.milestone_type, item?.title)}
        </span>
      );
    };

    /**
     * Formats date string into readable format
     *
     * Usage:
     * const formattedDate = formatDate("2024-03-20T10:00:00Z");
     * // Returns: "Mar 20, 2024 at 10:00 AM"
     *
     * Features:
     * - Converts ISO date to locale string
     * - Includes month, day, year
     * - Shows time in 12-hour format
     *
     * @param {string} inputDate - ISO date string
     * @returns {string} Formatted date string with time
     */
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const datePart = date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      const timePart = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return `${datePart} at ${timePart}`;
    }

    /**
     * Creates custom content for timeline items
     *
     * Features:
     * - Formats title with HTML markup
     * - Handles special styling for comments
     * - Displays formatted date
     * - Supports subtitles with mentions
     * - Different background colors for different types
     *
     * @param {Object} item - Timeline item data
     * @param {string} item.title - Title text
     * @param {string} item.sub_title - Optional subtitle
     * @param {string} item.created_at - Timestamp
     * @returns {JSX.Element} Formatted timeline content
     */
    const customizedContent = (item) => {
      const { title, subtitleHTML } = processTitleAndSubtitle(item.title);

      return (
        <div className="flex flex-col pb-3 mt-1">
          {subtitleHTML ? (
            <span className="font-sans" dangerouslySetInnerHTML={{ __html: title }}></span>
          ) : (
            <span
              className="font-sans"
              dangerouslySetInnerHTML={{ __html: formatTitle(item.title) }}
            ></span>
          )}

          {subtitleHTML ? (
            <span
              className={`${
                title.substring(0, 21).includes("<b>NeIO</b> Commented")
                  ? "bg-[#ffe6ec]"
                  : "bg-[#F1F1F1]"
              } p-3 w-full mt-3 rounded-[19px] font-sans`}
              dangerouslySetInnerHTML={{ __html: subtitleHTML }}
            ></span>
          ) : (
            item.sub_title && (
              <span
                className="bg-[#F1F1F1] p-3 w-full mt-3 rounded-[19px]"
                style={{ fontFamily: "sans-serif" }}
                dangerouslySetInnerHTML={{
                  __html: formatSubtitle(item.sub_title),
                }}
              ></span>
            )
          )}

          <span className="text-[#757575] mt-3 font-sans">
            {formatDate(format(new Date(item.created_at), "MM/dd/yyyy HH:mm:ss"))}
          </span>
        </div>
      );
    };

    /**
     * Processes title and subtitle for timeline items
     *
     *
     * Features:
     * - Separates title from subtitle content
     * - Formats mentions in comments
     * - Handles HTML content in subtitles
     * - Applies special formatting for NeIO comments
     * - Maintains HTML structure
     *
     * @param {string} input - Raw title/subtitle text
     * @returns {Object} Processed title and subtitle HTML
     * @returns {string} .title - Formatted title HTML
     * @returns {string} .subtitleHTML - Formatted subtitle HTML or null
     */
    const processTitleAndSubtitle = (input) => {
      const titleMatch = input.match(/^(.*?)(?=<p>)/); // Matches text before <p>
      const subtitleMatch = input.match(/<p>([\s\S]*?)<\/p>/); // Matches content inside <p>

      const formattedTitle = titleMatch ? formatTitle(titleMatch[1]) : formatTitle(input);

      const formattedSubtitle = subtitleMatch ? subtitleMatch[1] : null;

      return {
        title: formattedTitle,
        subtitleHTML: formattedSubtitle ? formatSubtitleForComments(formattedSubtitle) : null,
      };
    };

    return (
      <>
        {!loadingActivity && (
          <>
            <div className="timeline-card h-[45vh] overflow-y-scroll mt-3 pb-5">
              <Timeline
                value={milestones}
                align="left"
                className="customized-timeline"
                marker={customizedMarker}
                content={customizedContent}
              />
            </div>
          </>
        )}
        {loadingActivity && (
          <div className="flex flex-col">
            <div className="mt-5">
              <Skeleton width="30%" height="2rem"></Skeleton>
            </div>
            <div className="mt-5 flex flex-row">
              <Skeleton width="5%" height="17rem"></Skeleton>
              <div className="flex flex-col ml-5">
                <Skeleton width="15rem" height="2rem"></Skeleton>
                <div className="mt-[10px]">
                  <Skeleton width="25rem" height="3rem"></Skeleton>
                </div>
                <div className="mt-[10px]">
                  <Skeleton width="19rem" height="2rem"></Skeleton>
                </div>
                <div className="mt-[10px]">
                  <Skeleton width="15rem" height="2rem"></Skeleton>
                </div>
                <div className="mt-[10px]">
                  <Skeleton width="25rem" height="3rem"></Skeleton>
                </div>
                <div className="mt-[10px]">
                  <Skeleton width="19rem" height="2rem"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const tabContent = (index) => {
    if (index === 0) {
      return alertDetails();
    } else if (index === 1) {
      return ActivityTimeline();
    }
  };

  function formatDateAlertDetails(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}/${day}/${year}, ${hours}:${minutes}`;
  }

  return (
    <div
      style={{
        flex: "1",
        flexDirection: "column",
        padding: "1rem",
        marginTop: "10px",
        height: "80vh",
        borderLeft: "2px solid #dee2e6",
      }}
    >
      {/* <h2 className='text-xl'>Alert Details</h2> */}
      <div className="flex flex-col">
        <div>
          <span className="text-xl font-semibold sans-serif text-[#191A1C]">
            {" "}
            {alertDetail?.alert_message}
          </span>
        </div>
        <div className="flex flex-row items-center mt-2 justify-between">
          <div className="flex flex-row items-center mt-2">
            <p className="text-md font-sans"> ID {alertDetail?.id}</p>
            <div
              className="flex flex-row bg-[#F5F5F5] items-center ml-[20px] p-[1px] pl-[4px] pr-[4px]"
              style={{ borderRadius: "15px", fontWeight: "500" }}
            >
              {getAlertText(alertDetail)}
            </div>
          </div>
          <div>
            <span className="font-thin font-sans text-sm">
              {alertDetail &&
                formatDateAlertDetails(
                  format(new Date(alertDetail?.created_at), "MM/dd/yyyy HH:mm:ss"),
                )}
            </span>
          </div>
        </div>
      </div>
      <div className="card">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="alert-details-tabview"
        >
          {["Alert Details", "Activity"].map((header, index) => (
            <TabPanel
              key={index}
              header={
                <span
                  className={`py-2 px-4 transition-colors duration-200 ${
                    activeIndex === index
                      ? "border-b-2 border-[#3B82F6] !text-[#3B82F6]"
                      : "text-[#6C757D]"
                  }`}
                >
                  {header}
                </span>
              }
            >
              {tabContent(index)}{" "}
            </TabPanel>
          ))}
        </TabView>
      </div>
    </div>
  );
};

export default AlertTabDetails;
