import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import "../../../pages/Admin/CreditSummary/OrgTransactionChart.css";
import UserCreditTable from "./UserCreditTable";
import { format } from "date-fns";
import UserCreditInfo from "hooks/useCreditInfo";

function UserCreditHistory({ userInfo }) {
  const [selectedRange, setSelectedRange] = useState("7D");
  const [dateRange, setDateRange] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { userCreditHistory, loading } = UserCreditInfo();
  const [tableData, setTableData] = useState();
  const toast = useRef(null);
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  const calendarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dateRange && Array.isArray(dateRange)) {
      if (dateRange[0]) {
        setShowDatePicker(false);
      }
    }
  }, [dateRange]);

  const fetchCreditHistory = async () => {
    try {
      sevenDaysAgo.setDate(today.getDate() - 6);
      const formatDate = (date) => format(date, "yyyy-MM-dd");
      if (selectedRange === "7D") {
        const weekDataVal = await userCreditHistory(userInfo?.id, "last_7_days");
        // console.log("weekDataVal", weekDataVal);

        setTableData(weekDataVal?.results);
      } else if (selectedRange === "custom" && dateRange?.length > 1) {
        const customData = await userCreditHistory(
          userInfo?.id,
          "specific_date",
          formatDate(dateRange[0]),
        );
        setTableData(customData?.results);
      } else {
        const oneDayDataVal = await userCreditHistory(userInfo?.id, "last_1_day");
        setTableData(oneDayDataVal?.results);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    if (selectedRange === "custom") {
      fetchCreditHistory();
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedRange === "custom") {
      setShowDatePicker(true);
      fetchCreditHistory();
    } else {
      setDateRange(null);
      setShowDatePicker(false);
      fetchCreditHistory();
    }
  }, [selectedRange]);

  return (
    <div className="border-round-xs border-300 border-1 h-full p-3">
      <Toast ref={toast} />
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0 text-lg text-700 font-semibold">{"Transaction History"}</h2>
        <SelectButton
          pt={{
            button: {
              className: "text-500 font-semibold text-sm",
            },
          }}
          className="bg-gray-200"
          size={"small"}
          value={selectedRange}
          onChange={(e) => {
            if (e.value === "custom" || selectedRange === "custom") {
              setShowDatePicker(true);
            }
            if (e.value === null) {
              return;
            }

            setSelectedRange(e.value);
          }}
          options={[
            {
              label: "1D",
              value: "1D",
            },
            {
              label: "7D",
              value: "7D",
            },
            { icon: "pi pi-calendar", value: "custom" },
          ]}
          itemTemplate={(option) =>
            option?.icon ? <i className="pi pi-calendar" /> : <span>{option.label}</span>
          }
        />
      </div>
      {showDatePicker && (
        <div
          style={{
            position: "relative",
            zIndex: 1000,
            left: "15%",
            bottom: "12%",
            height: "0px",
            width: "350px",
          }}
          ref={calendarRef}
        >
          <Calendar
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            className="ml-2 credit"
            dateFormat="d/m/y"
            inline
          />
        </div>
      )}
      <UserCreditTable creditSummary={tableData} loading={loading} />
    </div>
  );
}

export default UserCreditHistory;
