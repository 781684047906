import CustomerInsightsList from "components/Spaces/CustomerInsights"; // Assume this is the list component
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useRef, useState, Fragment } from "react";
import useMediaQuery from "hooks/useMediaQuerry"; // Custom hook for media query
// import "./customerInsights.css";
import NewCustomerInsightSetup from "components/Spaces/CustomerInsights"; // Modal component for new insights
import { TabView, TabPanel } from "primereact/tabview";
import ChatNow from "assets/SpaceIcons/ChatNow";
import "./CustomerInsights.css";

const CustomerInsights = () => {
  const [visible, setVisible] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const { isMobile } = useMediaQuery();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);

  const renderSourceInfo = () => (
    <Fragment>
      <CustomerInsightsList visible={visible} setVisible={setVisible} toast={toast} />
    </Fragment>
  );

  return (
    <div className={`surface-0`}>
      <div
        className={`flex ${!isMobile && "align-items-center"} flex-column lg:justify-content-between lg:flex-row`}
        style={{ marginLeft: "1vw" }}
      >
        <Toast ref={toast} />
        <div className="templatesHeader" style={{ width: "70vw" }}>
          <p className="font-semibold" style={{ fontSize: "1.313rem" }}>
            Customer_Insights
          </p>
          <p className="font-thin" style={{ fontWeight: 300, lineHeight: "4vh" }}>
            Explore the vast universe of customer insights, where every interaction reveals a galaxy
            of preferences and behaviors. Dive deep into the data to uncover trends that illuminate
            the path to better engagement and satisfaction. With our tools, you can navigate through
            the stars of feedback, transforming raw information into actionable strategies that
            drive success.
          </p>
        </div>
        <div className="mt-3 lg:mt-0 chat-now-btn">
          <Button
            label="Chat Now"
            icon={<ChatNow />}
            className="text-sm"
            onClick={() => setVisible(true)}
          />

          <span className="pi pi-ellipsis-v text-500 cursor-pointer text-lg ml-5" />
        </div>
      </div>
      <span style={{ color: "#6C757D", marginLeft: "1vw" }}>Created at: 4/03/2023</span>

      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        style={{ marginTop: "1vh" }}
      >
        <TabPanel header="Source Info">{renderSourceInfo()}</TabPanel>
        <TabPanel header="Data">
          <p>Data tab content goes here...</p>
        </TabPanel>
        <TabPanel header="Facts">
          <p>Facts tab content goes here...</p>
        </TabPanel>
        <TabPanel header="Action Log">
          <p>Action Log tab content goes here...</p>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default CustomerInsights;
