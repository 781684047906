export const GET_SOURCES = {
  count: 10,
  rows: [
    {
      id: 451,
      cloneReferenceId: null,
      name: "OfferHistoryViewUAT",
      airbyteConnectionId: null,
      airbyteSourceId: "",
      mageSchedulerId: null,
      runEveryMinutes: 1440,
      streamName: "offerhistoryviewuat_451_OfferHistoryView",
      uuid: "0e981828-e0e7-4905-806e-749c49a1c656",
      step: 3,
      clientId: 31,
      contractId: null,
      status: "active",
      connectionType: "database",
      dataFormat: null,
      sourceFilePath: null,
      sourceSystem: "client",
      sourceFileKey: null,
      definition: {
        port: 5432,
        database: "mmc_core_uat",
        password: "1b7005f92bc6f55bac6b93039b0c695b",
        username: "uatuser",
        tableName: "OfferHistoryView",
        schemaName: "rp_mart_red_point_test",
        databaseHost: "10.193.17.100",
        databaseType: "postgres",
        updateMethod: "standarad",
      },
      mappedEntities: [
        {
          id: 234,
          type: "master",
          instance: 1,
        },
        {
          id: 6,
          type: "master",
          instance: 1,
        },
        {
          id: 223,
          type: "attribute",
          instance: 1,
          parentEntity: 6,
        },
      ],
      batchApprovalNeeded: false,
      confidenceLevel: 5,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-02T17:15:43.877Z",
      updatedAt: "2024-08-13T12:39:01.728Z",
      client: {
        name: "codvo",
      },
    },
    {
      id: 458,
      cloneReferenceId: null,
      name: "New SF Imp 32421",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: 60,
      streamName: "new_sf_imp_32421_458_Lead",
      uuid: "54e66504-a559-4df7-a7b1-daae49bae65a",
      step: 1,
      clientId: 35,
      contractId: null,
      status: "active",
      connectionType: "salesforce",
      dataFormat: null,
      sourceFilePath: null,
      sourceSystem: "client_erp",
      sourceFileKey: null,
      definition: {
        password: "f59d10957bdf8d536c9b4efdaf5e3e8b",
        username: "proddev_integration@mmcglobal.com.supsystem",
        tableName: "Lead",
        securityToken:
          "3c6844684ded6389c21f24f3869150f9f4c198b666b184f0219a608f6280a27c",
        isSalesforceSandbox: true,
      },
      mappedEntities: null,
      batchApprovalNeeded: false,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-13T10:52:20.518Z",
      updatedAt: "2024-08-13T10:52:20.962Z",
      usingIn: 6,
      client: {
        name: "ClientMartTest",
      },
    },
    {
      id: 457,
      cloneReferenceId: null,
      name: "New SF SQL Imp 3242",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: 120,
      streamName: "new_sf_sql_imp_3242_457",
      uuid: "5e773d74-f781-4d0b-8b81-16c9c0360e24",
      step: 1,
      clientId: 35,
      contractId: null,
      status: "inactive",
      connectionType: "sf_sql",
      dataFormat: null,
      sourceFilePath: null,
      sourceSystem: "client",
      sourceFileKey: null,
      definition: {
        query:
          "SELECT Id,ProcessCode__c,OperationType__c,Account__r.BillingStreet,Account__r.BillingCountry,Account__r.Fax,Account__r.Phone,Account__r.BillingCity,Account__r.Name,Account__r.BillingPostalCode,Account__r.Id,Account__r.BillingState,Account__r.Website FROM MMC_Export_Account__c",
        password: "f59d10957bdf8d536c9b4efdaf5e3e8b",
        username: "proddev_integration@mmcglobal.com.supsystem",
        securityToken:
          "3c6844684ded6389c21f24f3869150f9f4c198b666b184f0219a608f6280a27c",
        isSalesforceSandbox: true,
      },
      mappedEntities: null,
      batchApprovalNeeded: false,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-13T10:17:15.672Z",
      updatedAt: "2024-08-13T10:17:16.109Z",
      usingIn: 11,
      client: {
        name: "ClientMartTest",
      },
    },
    {
      id: 456,
      cloneReferenceId: null,
      name: "New SF Imp 3242",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: 60,
      streamName: "new_sf_imp_3242_456_Account",
      uuid: "1da2d278-c1d7-49da-9811-3c455c6a03e5",
      step: 1,
      clientId: 35,
      contractId: null,
      status: "inactive",
      connectionType: "salesforce",
      dataFormat: null,
      sourceFilePath: null,
      sourceSystem: "anteriad",
      sourceFileKey: null,
      definition: {
        password: "f59d10957bdf8d536c9b4efdaf5e3e8b",
        username: "proddev_integration@mmcglobal.com.supsystem",
        tableName: "Account",
        securityToken:
          "3c6844684ded6389c21f24f3869150f9f4c198b666b184f0219a608f6280a27c",
        isSalesforceSandbox: true,
      },
      mappedEntities: null,
      batchApprovalNeeded: false,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-13T09:59:18.055Z",
      updatedAt: "2024-08-13T09:59:18.496Z",
      usingIn: 5,
      client: {
        name: "ClientMartTest",
      },
    },
    {
      id: 449,
      cloneReferenceId: null,
      name: "TestSource0429",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: null,
      streamName: "testsource0429_449",
      uuid: "0e14d722-3fe7-466f-9ea5-5b0f0996d9a7",
      step: 4,
      clientId: 31,
      contractId: null,
      status: "error",
      connectionType: "ui_upload",
      dataFormat: "csv",
      sourceFilePath: "source-setup/TestSource0429-449/",
      sourceSystem: "client",
      sourceFileKey: "person_1721386818348.csv",
      definition: {
        csvSeparator: ",",
      },
      mappedEntities: [
        {
          id: 6,
          type: "master",
          instance: 1,
        },
      ],
      batchApprovalNeeded: false,
      confidenceLevel: 5,
      createdBy: 115,
      updatedBy: null,
      createdAt: "2024-07-19T11:00:17.635Z",
      updatedAt: "2024-08-12T14:45:46.667Z",
      usingIn: 2,
      client: {
        name: "codvo",
      },
    },
    {
      id: 455,
      cloneReferenceId: null,
      name: "Unified source 01",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: null,
      streamName: "unified_source_01_455",
      uuid: "84c948a7-405e-49da-a562-bf30c13ce462",
      step: 1,
      clientId: 35,
      contractId: null,
      status: "inactive",
      connectionType: "ui_upload",
      dataFormat: "csv",
      sourceFilePath: "source-setup/Unified source 01-455/",
      sourceSystem: "undefined",
      sourceFileKey: "person_company_SF_Mart(new)_1_1723200041252.csv",
      definition: {
        csvSeparator: ",",
      },
      mappedEntities: null,
      batchApprovalNeeded: false,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-09T10:40:41.242Z",
      updatedAt: "2024-08-09T10:40:41.252Z",
      usingIn: 9,
      client: {
        name: "ClientMartTest",
      },
    },
    {
      id: 83,
      cloneReferenceId: null,
      name: "SourceMySQL",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: 1440,
      streamName: "sourcemysql_83_person",
      uuid: "cdfaae32-23c4-42d8-8506-694df4396296",
      step: 4,
      clientId: 1,
      contractId: null,
      status: "active",
      connectionType: "database",
      dataFormat: "csv",
      sourceFilePath: null,
      sourceSystem: null,
      sourceFileKey: null,
      definition: {
        port: 3306,
        database: "mysql_source_db",
        password: "faf132e7335593c34eb89eb39fa0ac40",
        username: "uat_user",
        tableName: "person",
        cursorField: "person_id",
        databaseHost: "167.71.226.187",
        databaseType: "mysql",
      },
      mappedEntities: null,
      batchApprovalNeeded: null,
      confidenceLevel: null,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-01-18T16:25:31.136Z",
      updatedAt: "2024-08-08T09:54:05.553Z",
      usingIn: 1,
      client: {
        name: "mmc",
      },
    },
    {
      id: 454,
      cloneReferenceId: null,
      name: "test",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: null,
      streamName: "test_454",
      uuid: "29b01eb7-9ca1-4720-89e7-39eca1227e3c",
      step: 1,
      clientId: 31,
      contractId: null,
      status: "active",
      connectionType: "ui_upload",
      dataFormat: "csv",
      sourceFilePath: "source-setup/test-454/",
      sourceSystem: "anteriad",
      sourceFileKey: "sample_1723104731613.csv",
      definition: {
        csvSeparator: ",",
      },
      mappedEntities: null,
      batchApprovalNeeded: false,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-08T08:12:11.601Z",
      updatedAt: "2024-08-08T08:12:11.613Z",
      usingIn: 4,
      client: {
        name: "codvo",
      },
    },
    {
      id: 453,
      cloneReferenceId: null,
      name: "postgres",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: 60,
      streamName: "postgres_453_company_order_line_item_mapping",
      uuid: "6810b1fb-dd6b-4093-a658-5bc81e63c2be",
      step: 1,
      clientId: 31,
      contractId: null,
      status: "error",
      connectionType: "database",
      dataFormat: null,
      sourceFilePath: null,
      sourceSystem: "anteriad",
      sourceFileKey: null,
      definition: {
        port: 5432,
        database: "mmc_core_qa",
        password: "dbb03cb24881f821623c9ebfa93e743c",
        username: "qauser",
        tableName: "company_order_line_item_mapping",
        schemaName: "updb",
        databaseHost: "qa-db.supersystem.pro",
        databaseType: "postgres",
        updateMethod: "Xmin",
      },
      mappedEntities: null,
      batchApprovalNeeded: true,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-07T10:58:55.783Z",
      updatedAt: "2024-08-07T10:58:55.793Z",
      usingIn: 0,
      client: {
        name: "codvo",
      },
    },
    {
      id: 452,
      cloneReferenceId: null,
      name: "Test db",
      airbyteConnectionId: null,
      airbyteSourceId: null,
      mageSchedulerId: null,
      runEveryMinutes: 60,
      streamName: "test_db_452_pg_type",
      uuid: "7056fd32-02da-492e-87d0-19c44d180582",
      step: 1,
      clientId: 1,
      contractId: null,
      status: "inactive",
      connectionType: "database",
      dataFormat: null,
      sourceFilePath: null,
      sourceSystem: "anteriad",
      sourceFileKey: null,
      definition: {
        port: 5432,
        database: "mmc_core_qa",
        password: "dbb03cb24881f821623c9ebfa93e743c",
        username: "qauser",
        tableName: "pg_type",
        schemaName: "pg_catalog",
        cursorField: "oid",
        databaseHost: "qa-db.supersystem.pro",
        databaseType: "postgres",
        updateMethod: "Standard",
      },
      mappedEntities: null,
      batchApprovalNeeded: true,
      confidenceLevel: 1,
      createdBy: 1,
      updatedBy: null,
      createdAt: "2024-08-07T09:48:15.360Z",
      updatedAt: "2024-08-07T09:51:41.919Z",
      usingIn: 5,
      client: {
        name: "mmc",
      },
    },
  ],
};

export const RULES = {
  cleansing_rules: [
    {
      key: "1",
      data: {
        name: "Customers",
      },
      children: [
        {
          key: "1-1",
          data: {
            field: "customer_id",
            rulesApplied: ["Case Conversions"],
            category: "Customers",
          },
        },
        {
          key: "1-2",
          data: {
            field: "first_name",
            rulesApplied: ["Trimming", "Case Conversions", "Nan Default"],
            category: "Customers",
          },
        },
        {
          key: "1-3",
          data: {
            field: "last_name",
            rulesApplied: ["Trimming"],
            category: "Customers",
          },
        },
        {
          key: "1-4",
          data: { field: "email", rulesApplied: [], category: "Customers" },
        },
      ],
    },
    {
      key: "2",
      data: {
        name: "Products",
      },
      children: [
        {
          key: "2-1",
          data: {
            field: "product_id",
            rulesApplied: ["Case Conversions"],
            category: "Products",
          },
        },
        {
          key: "2-2",
          data: {
            field: "product_name",
            rulesApplied: ["Trimming", "Case Conversions"],
            category: "Products",
          },
        },
        {
          key: "2-3",
          data: { field: "category", rulesApplied: [], category: "Products" },
        },
      ],
    },
    {
      key: "3",
      data: {
        name: "Sales",
      },
      children: [
        {
          key: "3-1",
          data: {
            field: "invoice_number",
            rulesApplied: ["Case Conversions"],
            category: "Sales",
          },
        },
      ],
    },
  ],
};
