export const MultiselectHeader = ({ filterText, setFilterText }) => {
  return (
    <div className="w-full bg-[#f1f1f1]">
      <div className="flex flex-row justify-left items-center px-2.5 py-1 bg-white rounded-lg border border-solid shadow-sm border-[color:var(--Global-Neutral-Grey-400,#ECECED)]">
        {/* <SearchIcon/>
         */}
        <i className="pi pi-search" />
        <input
          type="text"
          placeholder="Search"
          className="w-full ml-2"
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
          value={filterText}
        ></input>
      </div>
    </div>
  );
};
