import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import { TabView, TabPanel } from "primereact/tabview";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import BasicInfoTab from "./BasicInfoTab";
import GetUsersList from "hooks/getUsers";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import CreditInfoTab from "./CreditInfoTab";
import { DeactivateUserDialog } from "components/Dashboard/UserManagement/UsersPanels";

const UserProfile = () => {
  const { id } = useParams();
  const { getUserDetails, data } = GetUsersList();
  const [userData, setUserData] = useState([]);
  const { deactivateUser } = GetUsersList();
  const [dialogVisible, setDialogVisible] = useState(false);
  const toast = useRef(null);
  const getStatusColor = (status) => {
    return status ? "green" : "red";
  };

  const fetchUserInfo = async () => {
    try {
      const data = await getUserDetails(id);
      if (data.results) {
        setUserData(data.results);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Could not fetch user information, Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserInfo();
    }
  }, []);

  const handleDeactivateUser = async () => {
    try {
      const result = await deactivateUser(id);

      if (result?.status) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "User deactivated successfully!",
        });
        setDialogVisible(false);
        fetchUserInfo();
      }
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: e?.response?.data?.message || "Something went wrong. Please try again later.",
      });
    }
  };

  const statusTemplate = (
    <div className="flex align-items-center">
      <span
        className="mr-2"
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(data?.results?.is_active),
          display: "inline-block",
        }}
      ></span>
      {data?.results?.is_active ? "Active" : "Inactive"}
    </div>
  );

  const avatarLabel = data?.results?.email ? data.results.email.charAt(0).toUpperCase() : "";

  return (
    <div className="mt-2">
      <DeactivateUserDialog
        visible={dialogVisible}
        setIsVisible={setDialogVisible}
        handleOnClick={handleDeactivateUser}
      />
      <Toast ref={toast} position="top-right" />
      <div className="flex justify-content-between align-items-center mb-4">
        <div className="flex align-items-center">
          <Avatar label={avatarLabel} size="xlarge" shape="circle" />
          <div className="flex-col ml-3">
            <p className="m-0 text-2xl font-bold">{data?.results?.email}</p>
            <p className="m-0 text-sm mt-2">Credit Balance: {data?.results?.credit_balance}</p>
            <Chip label={statusTemplate} className="text-xs py-1 mt-2" />
            {data?.results?.is_active && (
              <Button
                rounded
                text
                icon="pi pi-pencil"
                className="text-blue-500"
                onClick={() => setDialogVisible(true)}
                pt={{
                  root: { className: "shadow-none" },
                }}
              />
            )}
          </div>
        </div>
      </div>
      <TabView className="text-sm">
        <TabPanel header="Basic Info" className="text-sm">
          <BasicInfoTab userInfo={userData} setUserData={setUserData} />
        </TabPanel>
        <TabPanel header="Credit Info">
          <CreditInfoTab userInfo={userData} fetchUserInfo={fetchUserInfo} />
        </TabPanel>
        <TabPanel header="Access to Data"></TabPanel>
        <TabPanel header="Access to Agent"></TabPanel>
        <TabPanel header="Pulse Notification Info"></TabPanel>
      </TabView>
    </div>
  );
};

export default UserProfile;
