import useApi from "services/api";
import { API_URL } from "../constants";

/**
 * Custom hook for managing notification-related API operations.
 * Provides functions to fetch, mark as read, and delete notifications.
 *
 * @returns {Object} An object containing functions to interact with notifications API
 */
const GetNotifications = () => {
  const { patch, get, del } = useApi();

  /**
   * Fetches notifications from the server.
   *
   * @returns {Promise<Object>} A promise that resolves to the fetched notifications data
   */
  const getNotification = async () => {
    const data = await get(`${API_URL}/notifications`);
    return data;
  };

  /**
   * Marks a notification as read by its ID.
   *
   * @param {string} id - The ID of the notification to mark as read
   * @returns {Promise<void>} A promise that resolves when the operation is complete
   */
  const markNotificationAsRead = async (id) => {
    await patch(`${API_URL}/notifications/${id}`);
  };

  /**
   * Deletes a notification by its ID.
   *
   * @param {string} id - The ID of the notification to delete
   * @returns {Promise<void>} A promise that resolves when the operation is complete
   */
  const deleteNotification = async (id) => {
    await del(`${API_URL}/notifications/${id}`);
  };

  return { getNotification, markNotificationAsRead, deleteNotification };
};

export default GetNotifications;
