import React from "react";
import NoTask from "assets/WorkSpaceIcons/NoResults.svg";
import useTask from "hooks/useTask";
import moment from "moment";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { Menu } from "primereact/menu";
import { useEffect, useRef, useState } from "react";
import { capitalizeFirstLetter } from "utils/utils";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import TaskProgressBar from "./TaskProgressBar";
import "./WorkspaceStyle.css";
import PDFViewerComponent from "components/DocumentEngine/FileViewer/FileViewerComponent";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { TieredMenu } from "primereact/tieredmenu";
import useTemplateList from "hooks/useTemplateList";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Timeline } from "primereact/timeline";
import "./StatusTab.css";
import { ProgressSpinner } from 'primereact/progressspinner';

const STATUS = {
  ongoing: "Synthesizing Document ...",
  completed: "Task Completed",
  error: "Error",
  terminated: "Task Terminated",
};

function StatusTab({
  taskList,
  templates,
  setIsShowTaskListContainer,
  setSelectedTask,
  task,
  loading,
  setTask,
  terminateJob,
  onPdfViewerStateChange,
  outputFormat,
}) {
  const [outputFormats, setOutputFormats] = useState([]);
  const [templateName, setTemplateName] = useState([]);
  const { getOutputTypes, getAuditLog } = useTask();
  const { isMobile } = useMediaQuerry();
  const toast = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const { getMyTemplatesIds } = useTemplateList();
  const [terminateProcess, setTerminateProcess] = useState(false);

  const accept = () => {
    terminateJob(task.id)
      .then((res) => {
        if (res) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.message,
            life: 3000,
          });
          setTerminateProcess(false);
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please try again later",
          life: 3000,
        });
      })
      .finally(() => {
        setTerminateProcess(false);
      });
  };

  useEffect(() => {
    if (outputFormat) {
      setOutputFormats(Array.isArray(outputFormat) ? outputFormat : []);
    }
  }, [outputFormat]);

  useEffect(() => {
    (async () => {
      const data = await getMyTemplatesIds();
      if (data.success && data.results && data.results.Templates) {
        const templateNames = data.results.Templates.map((template) => ({
          id: template.id,
          templateName: template.templateName,
        }));
        const updatedTemplateNames = [
          { id: "custom", templateName: "Freeflow Text" },
          { id: "generic", templateName: "General Settlement Demand Template" },
          ...templateNames,
        ];
        setTemplateName(updatedTemplateNames);
      }
    })();
  }, []);

  const previewFile = () => {
    if (!task?.preview_presigned_url) {
      return;
    }

    setFileUrl(task?.preview_presigned_url);
    onPdfViewerStateChange?.(true);
  };

  const handleDownload = async () => {
    const fileUrl = task?.download_presigned_url;

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const formattedDate = moment().format("MMDDYYYY");
      const formattedTime = moment().format("HH_mm");
      const fileName = `${formattedDate}_${formattedTime}_Template Name.docx`;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const closeOverlay = () => {
    setFileUrl(null);
    onPdfViewerStateChange?.(false);
  };

  return (
    <section
      className={`surface-0  p-3 ${isMobile ? "card-box h-screen" : "h-full"} ${taskList.length === 0 ? "border-round-top-12" : "border-round-top-right-12"}`}
    >
      <Toast ref={toast} />
      <ConfirmDialog
        group="declarative"
        visible={terminateProcess}
        onHide={() => setTerminateProcess(false)}
        message="Are you sure that you want to terminate the process?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        acceptIcon={"pi pi-check"}
        rejectIcon={"pi pi-times"}
        reject={() => {
          setTerminateProcess(false);
        }}
        acceptClassName="p-accept-button"
        rejectClassName="p-reject-button"
        style={{ width: "50vw" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
      />
      {fileUrl && (
        <PDFViewerComponent fileUrl={fileUrl} fileName="file.docx" onClose={() => closeOverlay()} />
      )}
      {taskList.length === 0 && (
        <div className="flex justify-content-center h-screen">
          <div className="block text-center noTaskContainer">
            <img src={NoTask} alt="empty" className="w-3" />
            <div className="text-900 inter-bold text-xl mb-1">No Tasks</div>
            <span className="text-700 text-sm">Your tasks will appear here</span>
          </div>
        </div>
      )}

      {taskList.length !== 0 && (
        loading ? (
          <div className="flex justify-content-center align-items-center h-full">
            <ProgressSpinner
              style={{ width: '50px', height: '50px' }}
              strokeWidth="4"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        ) : (
          task && (
            <>
              <div className="flex justify-content-between align-items-center">
                <p className="text-xl m-0 font-bold">{STATUS[task?.status?.toLowerCase()]}</p>
                {isMobile && (
                  <button
                    className="text-xl doc-close-btn mt-1"
                    onClick={() => {
                      setIsShowTaskListContainer(true);
                      setSelectedTask({});
                    }}
                  >
                    X
                  </button>
                )}
              </div>
              <div className="flex justify-content-between align-items-center">
                <p className="font-light text-sm">
                  ID {task.id}
                  <Chip
                    label={task?.status && capitalizeFirstLetter(task?.status)}
                    icon={`pi ${task?.status?.toLowerCase() === "ongoing" ? "pi-clock text-blue-500" : task?.status?.toLowerCase() === "completed" ? "pi-check-circle text-green-500" : task?.status?.toLowerCase() === "error" ? "pi-exclamation-circle text-red-500" : task?.status?.toLowerCase() === "terminated" ? "pi-times-circle text-red-500" : ""}`}
                    className={`text-xs  font-semibold ml-2`}
                    pt={{
                      label: {
                        className: "text-xs font-semibold m-0 p-1",
                      },
                    }}
                  />
                </p>
                <p className="text-700 text-xs font-light">
                  {task.status?.toLowerCase() === "ongoing"
                    ? moment(task.created_at).format("MM/DD/YYYY, HH:mm")
                    : moment(task.updated_at).format("MM/DD/YYYY, HH:mm")}
                </p>
              </div>
              <Divider className="mt-2 mb-5" />
              {task?.status?.toLowerCase() === "ongoing" && (
                <Inprogress task={task} setTerminateProcess={setTerminateProcess} />
              )}
              {task?.status?.toLowerCase() === "completed" && (
                <Completed
                  task={task}
                  setTask={setTask}
                  previewFile={previewFile}
                  handleDownload={handleDownload}
                />
              )}
              {task?.status?.toLowerCase() === "error" && task?.error?.length !== 0 && (
                <ErrorComponent task={task?.errors} />
              )}
              {task?.status?.toLowerCase() === "terminated" && task?.error?.length !== 0 && (
                <TerminatedProcess />
              )}

              <Divider />
              <InputDocumentInfo
                task={task}
                outputFormats={outputFormats}
                templates={templates}
                templateName={templateName}
              />
            </>
          )
        )
      )}
    </section>
  );
}

const Inprogress = ({ task, setTerminateProcess }) => {
  const { isMobile } = useMediaQuerry();

  return (
    <div className={`${isMobile ? "w-full" : "w-8"}`}>
      <div className="flex justify-content-between align-items-center w-full">
        <p className="flex flex-1 text-sm font-light">Steps {task.progress || 0}/5 Completed</p>
      </div>
      <TaskProgressBar task={task} className="w-full" />
      <Button
        outlined
        size="small"
        label="Terminate Process"
        className="mb-2 mt-4"
        onClick={() => setTerminateProcess(true)}
        pt={{
          root: {
            className: "border-300",
          },
          label: {
            className: "text-500",
          },
        }}
      />
    </div>
  );
};

const TerminatedProcess = () => {
  return (
    <div className=" w-full">
      <p className="flex flex-1 text-sm font-light">
        This task was terminated. No further actions are required.
      </p>
    </div>
  );
};

const Completed = ({ task, setTask, previewFile, handleDownload }) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const toast = useRef(null);
  const menuLeft = useRef(null);
  const { isMobile } = useMediaQuerry();
  const items = [
    {
      label: "Report Bad Response",
      icon: task?.has_feedback ? "pi pi-thumbs-down-fill" : "pi pi-thumbs-down",
      disabled: task?.has_feedback,
      className: "text-sm",
      command: () => setIsFeedbackOpen(true),
    },
    {
      separator: true,
    },
    {
      label: (
        <p className="text-sm m-0">
          Share Document
          <Chip
            className="surface-100 ml-2"
            label="Coming Soon"
            pt={{ label: { className: "text-xs font-bold p-0" } }}
          />
        </p>
      ),
      icon: "pi pi-share-alt",
      disabled: true,
    },
  ];
  return (
    <div className=" w-full">
      <Toast ref={toast} />
      <p className="flex flex-1 text-sm font-light">
        Your document is ready for preview or download.
      </p>
      <div className="flex justify-content-between align-items-center">
        <div className="flex gap-2">
          <Button
            size="small"
            outlined
            label="Preview"
            className="my-3 border-300"
            severity="primary"
            onClick={() => {
              previewFile();
            }}
          />
          <Button
            size="small"
            outlined
            label="Download"
            className="my-3 border-300"
            severity="primary"
            onClick={() => {
              handleDownload();
            }}
          />
          <TieredMenu
            model={items}
            popup
            ref={menuLeft}
            popupAlignment="right"
            style={{ width: isMobile ? "61%" : "22%" }}
          />
          <Button
            size="small"
            icon="pi pi-ellipsis-h"
            className="my-3 border-300"
            outlined
            onClick={(e) => {
              menuLeft.current.toggle(e);
            }}
          />
        </div>
        {/* hidden for now */}
        {/* <Chip
          className="surface-100"
          label={"25 Credits Used"}
          pt={{
            label: {
              className: "text-md font-light",
            },
          }}
        /> */}
      </div>

      {isFeedbackOpen && (
        <FeedbackSection
          task={task}
          setTask={setTask}
          toast={toast}
          setIsFeedbackOpen={setIsFeedbackOpen}
        />
      )}
    </div>
  );
};

const FeedbackSection = ({ task, setTask, setIsFeedbackOpen, toast }) => {
  const [feedbackText, setFeedbackText] = useState("");
  const { provideFeedback } = useTask();
  const [loading, setLoading] = useState(false);

  const handleSubmitFeedback = async () => {
    if (!feedbackText)
      return toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Feedback cannot be empty",
        life: 2000,
      });
    try {
      setLoading(true);
      await provideFeedback({
        job_id: task.id,
        feedback: feedbackText,
      });
      setTask((prev) => ({ ...prev, has_feedback: true }));
      setIsFeedbackOpen(false);
      toast.current.show({
        severity: "success",
        summary: "Thank You!",
        detail: "Your feedback has been submitted",
        life: 2000,
      });
    } catch (err) {
      // console.log(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.response?.data?.detail || "Failed to submit feedback",
        life: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="surface-100 px-3 pt-2 pb-2 border-round-lg mt-2">
      <div className="flex justify-content-between align-items-center mt-2 mb-2">
        <p className="m-0 flex flex-1 text-sm font-light">Help us understand the issue</p>
        <p className="m-0 font-light">
          <span
            className="text-sm pi pi-times mr-1 cursor-pointer"
            onClick={() => setIsFeedbackOpen(false)}
          ></span>
        </p>
      </div>

      <InputTextarea
        value={feedbackText}
        onChange={(e) => {
          // if (e.target.value.length > 1000) return;
          setFeedbackText(e.target.value);
        }}
        placeholder="Provide details here..."
        rows={3}
        className={`w-full text-sm ${feedbackText.length > 1000 ? "border-red-500	" : ""}`}
      />
      <div className="flex gap-2 justify-content-end mb-1">
        <small
          className={`text-500 ${feedbackText.length > 1000 ? "text-red-500	" : ""} text-xs ml-auto`}
        >
          {feedbackText.length}/1000 character(s)
        </small>
      </div>
      <div className="flex justify-content-end">
        <Button
          disabled={!feedbackText || feedbackText.length > 1000}
          size="small"
          label="Submit"
          className="my-2 py-2"
          onClick={handleSubmitFeedback}
          loading={loading}
        />
      </div>
    </div>
  );
};

const ErrorComponent = ({ task }) => {
  // console.log("task", task);
  const failedDocuments = task.reduce((acc, error) => {
    const marker = error.error_object.marker;
    if (Array.isArray(marker)) {
      marker.forEach((item) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
      });
    } else if (typeof marker === "string") {
      if (!acc.includes(marker)) {
        acc.push(marker);
      }
    }
    return acc;
  }, []);

  return (
    <div className=" w-full">
      <p className="flex flex-1 text-sm font-light">
        The following error occurred while processing your document:
      </p>
      <div className="mt-2 text-sm font-light">
        <p className="mb-1 font-medium">{task[0].error_object.header}</p>
        <p className="text-gray-600">{task[0].error_object.body}</p>
      </div>
      <div className="flex flex-column gap-2 mb-4 w-max">
        <div className="flex flex-column gap-2 mb-4 w-max">
          {failedDocuments.map((doc, index) => (
            <FailedDocument key={index} doc={doc} />
          ))}
        </div>
      </div>

      {/* <div className="w-full surface-100 border-round-sm p-3">
        <p className="flex flex-1 text-sm font-light m-0 mt-2">
          Do you still want to proceed or re-upload the input documents?
        </p>
        <div className="flex justify-content-between align-items-center">
          <div className="flex gap-2 my-3">
            <Button
              size="small"
              outlined
              label="Reupload"
              className=" bg-white border-300"
              icon="pi pi-refresh"
              severity="primary"
            />
            <Button
              size="small"
              outlined
              label="Proceed"
              className="  bg-white border-300"
              severity="primary"
              icon="pi pi-chevron-right"
              iconPos="right"
            />
          </div>
          <Button
            size="small"
            icon="pi pi-share-alt    "
            className="m bg-white border-300"
            outlined
          />
        </div>
        <p className="text-500 text-xs font-light">
          Note: If you choose to proceed, system will only process the readable portion of the
          documents.
        </p>
      </div> */}
    </div>
  );
};

const FailedDocument = ({ doc }) => {
  const menuLeft = useRef(null);
  const menuModel = [{ label: doc }];

  return (
    <div className="flex border-1 border-300 px-2 border-round-lg align-items-center">
      <Menu
        model={menuModel}
        popup
        ref={menuLeft}
        id="popup_menu_left"
        className="max-h-20rem overflow-scroll"
      />
      <p className="m-0 font-light text-sm border-300 p-1 pr-2">{doc}</p>

      {/* <div className="flex gap-2 font-light align-items-center cursor-pointer p-1 pl-2">
        <p className="m-0 text-xs">[Page 1], [Page 15]...</p>
        <div
          className="flex surface-500 gap-1 p-1 align-items-center text-white border-round-md"
          onClick={(event) => {
            menuLeft.current.toggle(event);
          }}
        >
          <p className="m-0 text-xs">20+</p>
          <i className="pi pi-chevron-down text-xs"></i>
        </div>
      </div> */}
    </div>
  );
};

const InputDocumentInfo = ({ task, outputFormats, templates, templateName }) => {
  const toast = useRef(null);
  const { getAuditLog } = useTask();
  const [isOpen, setIsOpen] = useState(false);
  const [auditLogOpen, setAuditLogOpen] = useState(false);
  const { isMobile } = useMediaQuerry();
  const [auditLog, setAuditLog] = useState([]);

  const fetchAuditLog = async () => {
    try {
      const data = await getAuditLog(task.id);
      if (data.success && data.results && data.results.data) {
        setAuditLog(data?.results?.data);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Audit Log could not be generated, Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchAuditLog();
  }, [auditLogOpen, task]);

  const customizedContent = (item) => {
    return (
      <div>
        <div className="text-xs text-gray-500 mb-2">
          {moment(item.created_at).format("DD/MM/YYYY, h:mm:ss a")}
        </div>
        <h3 className="text-sm font-bold mb-2">{item.display_name}</h3>
        {item.display_description && <p className="text-sm mb-2">{item.display_description}</p>}
        {Object.keys(item.audit_log_data).length > 0 && (
          <div className="text-sm text-blue-500 cursor-pointer">View Details</div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      <Toast ref={toast} />
      <div className="flex align-items-center" style={{ gap: "120px" }}>
        <Button
          label="Input Document Info"
          iconPos="right"
          icon={`pi ${isOpen ? "pi-angle-down" : "pi-angle-right"}`}
          className="text-800 font-bold text-sm border-none focus:outline-none focus:ring-0 active:bg-transparent"
          onClick={() => setIsOpen((prev) => !prev)}
          style={{
            backgroundColor: "transparent",
            padding: "0",
          }}
        />
        <Button
          label="Audit Log"
          iconPos="right"
          icon={`pi ${auditLogOpen ? "pi-angle-down" : "pi-angle-right"}`}
          className="text-800 font-bold text-sm border-none focus:outline-none focus:ring-0 active:bg-transparent"
          onClick={() => setAuditLogOpen((prev) => !prev)}
          style={{
            backgroundColor: "transparent",
            padding: "0",
          }}
        />
      </div>
      <div className="flex w-full relative">
        <div className="input-doc-info w-8">
          {isOpen && (
            <>
              <div className="flex w-full mt-2">
                <div className="flex flex-column flex-1">
                  <p className="m-0 mb-2 text-500 font-light text-sm">Output Type</p>
                  <p className="text-sm">
                    {outputFormats.find((e) => e.id === task.output_format_id)?.output_format}
                  </p>
                  <p className="text-xs font-light">
                    {task.template_id === "generic"
                      ? "General Settlement Demand Template"
                      : templateName.find((e) => e.id === task.template_id)?.templateName || "Freeflow Text"}
                  </p>
                  <div className="flex flex-column">
                    <p className="font-light text-500 mt-0 text-sm">Input Documents</p>
                    <div className="flex flex-column gap-3 h-15rem overflow-y-auto pr-3">
                      {task.input_docs.map((doc) => (
                        <div
                          key={doc.name}
                          className="surface-100 border-round-lg flex gap-1 align-items-center pl-2 pr-5 py-2 max-w-14rem"
                        >
                          <i className="pi pi-file-pdf file-icon text-md"></i>

                          <div className="file-name ml-0">
                            <p
                              className="m-0 font-light text-sm white-space-nowrap overflow-hidden text-ellipsis"
                              title={doc.file_name}
                            >
                              {doc.file_name}
                            </p>
                            {/*will be implemented later */}
                            {/* <p className="m-0 font-light text-500 text-xs">{doc.size}</p> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {task.template_id !== null && (
                    <Chip
                      className="surface-100 border-round-md w-max"
                      pt={{
                        label: {
                          className: "text-sm font-light",
                        },
                      }}
                      label={(templates || []).find((e) => e.id === task.template_id)?.name}
                    />
                  )}
                </div>
              </div>
              {isMobile && (
                <>
                  <Divider layout="horizontal" className="pr-4" />
                  <div className="flex flex-column">
                    <p className="font-light text-500 mt-0 text-sm">Input Documents</p>
                    <div className="flex flex-column gap-3 h-15rem overflow-y-auto">
                      {task.input_docs.map((doc) => (
                        <div
                          key={doc.name}
                          className="surface-100 border-round-lg flex gap-1 align-items-center pl-2 pr-5 py-2"
                        >
                          <i className="pi pi-file-pdf file-icon text-md"></i>

                          <div>
                            <p className="m-0 font-light text-sm">{doc.file_name}</p>
                            {/*will be implemented later */}
                            {/* <p className="m-0 font-light text-500 text-xs">{doc.size}</p> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {!isMobile && auditLogOpen && (
          <div className="flex w-full mt-5">
            <Timeline value={auditLog} content={customizedContent} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusTab;
