import React, { useState } from "react";
import CoinIcon from "../../../assets/CreditOverview/CreditsIcon.svg";
import { Divider } from "primereact/divider";
import UserCreditHistory from "./UserCreditSummary";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { format } from "date-fns";
import ManageAllocationSidebar from "components/Dashboard/UserManagement/ManageAllocationSidebar";
import { Toast } from "primereact/toast";

const SummaryCard = ({ title, value, allotedValue, style, icon, iconSize, loading }) => {
  return (
    <div
      className="surface-50 w-4 border-round-xl h-8rem flex align-items-center gap-3"
      style={{ paddingLeft: 32 }}
    >
      {icon && <img src={icon} style={{ height: iconSize, width: iconSize }} alt="Coin Icon" />}
      <div className="flex flex-column justify-content-center">
        {loading ? (
          <div className=" h-4 w-4 bg-white opacity-50 z-5">
            <div className="flex">
              <ProgressSpinner />
            </div>
          </div>
        ) : (
          <>
            <h2 className={`font-semibold ${style} text-700 text-xl m-0`}>{value}</h2>
            <p className="text-500 m-0 mt-1 text-sm">{title}</p>
            {allotedValue && (
              <p className="text-500 m-0 mt-1 text-xs">
                {`Allocation date: ${format(new Date(allotedValue), "dd/MM/yyyy")}`}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const CreditInfoTab = ({ userInfo, fetchUserInfo }) => {
  const [visible, setIsVisible] = useState(false);

  const getCreditDetails = (userInfo) => {
    if (!userInfo?.allocation_type) return "-";

    switch (userInfo.allocation_type) {
      case "recurring":
        return `${userInfo.last_credits_allotted || 0} (Every ${userInfo.frequency} ${userInfo.frequency_unit})`;
      case "one_time":
        return `${userInfo.last_credits_allotted || 0} (One Time)`;
      case "unlimited":
        return `${userInfo.last_credits_allotted || 0}  (Unlimited)`;
      default:
        return "-";
    }
  };

  return (
    <div className="flex flex-col w-full">
      <ManageAllocationSidebar
        isVisible={visible}
        setVisible={setIsVisible}
        onHide={(shouldRefresh) => {
          setIsVisible(false);
          if (shouldRefresh) {
            fetchUserInfo();
          }
        }}
        userId={userInfo?.id}
      />
      <Toast />
      <div className="flex w-full">
        <div className="flex-1">
          <div className="flex gap-3 w-full justify-center">
            <SummaryCard
              title={"Credits Balance"}
              value={userInfo?.credit_balance}
              icon={CoinIcon}
              iconSize={32}
            />
            <SummaryCard
              title={"Last 7 days Usage"}
              value={`- ${userInfo?.balance ? userInfo?.balance : 0}`}
              style="text-red-500"
              iconSize={28}
            />
            <SummaryCard
              title={`Last allocated credits`}
              value={`+ ${userInfo?.last_credits_allotted}`}
              allotedValue={userInfo?.last_credits_allotted_at}
              style="text-green-500"
              iconSize={28}
            />
          </div>
          <Divider layout="horizontal" />
          <div
            className="flex w-full mt-6"
            style={{ justifyContent: "space-between", alignItems: "self-end" }}
          >
            <p className="font-bold text-lg"> Allocation Type</p>
            <Button
              label="Manage"
              outlined
              size="small"
              className="text-sm h-3rem"
              onClick={() => setIsVisible(true)}
            />
          </div>
          <p className="text-md">Allocation Type:</p>
          <p className="font-bold">Recurring Credits : {getCreditDetails(userInfo)}</p>
        </div>
        <Divider layout="vertical" className="h-full mx-4" />
      </div>
      <div className="w-8" style={{ height: "402px" }}>
        <UserCreditHistory userInfo={userInfo} />
      </div>
    </div>
  );
};

export default CreditInfoTab;
