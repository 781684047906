import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import LocalDrive from "assets/SpaceIcons/LocalDrive.svg";
import useSpaces from 'hooks/useSpace';
import { useUploadProgress } from "context/uploaderContext";
import UploaderComponent from "components/UploaderComponent";
import { Dialog } from 'primereact/dialog';
import useFileUpload from 'hooks/useFileUpload';
import { Toast } from 'primereact/toast';

// Integration Card Component
function IntegrationCard({ title, icon, actionIcon, slug }) {
  const [showDialog, setShowDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { id: spaceId } = useParams();
  const { uploadFiles } = useFileUpload();
  const toast = useRef(null);
  const { uploadProgress, setUploadProgress } = useUploadProgress();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleHide = () => {
    setShowDialog(false);
    setFiles([]);
    setIsUploading(false);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select files to upload'
      });
      return;
    }
    
    setIsUploading(true);
    try {
      const response = await uploadFiles({
        files,
        spaceId,
        connectorSlug: slug,
        connectionSourceType: 'unstructured'
      }, onUploadProgress);
      
      // Show success toast with response message
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: response.message || 'Files uploaded successfully'
      });
      
      handleHide();
    } catch (error) {
      // Show error toast with error message from response
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.response?.data?.message || 'Failed to upload files'
      });
      console.error('Upload failed:', error);
    } finally {
      setIsUploading(false);
      setUploadProgress({});
    }
  };

  const renderFooter = () => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem',
        padding: '1rem 0 0'
      }}>
        <Button
          label="Cancel"
          onClick={handleHide}
          className="p-button-text text-gray-400"
          disabled={isUploading}
        />
        <Button
          label="Upload"
          onClick={handleUpload}
          loading={isUploading}
          disabled={files.length === 0 || isUploading}
          className="p-button-primary"
        />
      </div>
    );
  };

  const onUploadProgress = (event) => {
    const { loaded, total } = event;
    const percentCompleted = Math.round((loaded * 100) / total);
    setUploadProgress((prev) => ({
      ...prev,
      ...files.reduce((acc, file, index) => {
        acc[file.name] = percentCompleted;
        return acc;
      }, {}),
    }));
  };

  return (
    <>
      <Toast ref={toast} />
      <div 
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1.25rem',
          borderRadius: '0.5rem',
          border: '1px solid rgb(229 229 229)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          minWidth: '240px',
          width: '27vw',
          backgroundColor: 'white',
        }}
      >
        <div style={{
          display: 'flex',
          gap: '2.5rem',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%'
        }}>
          <div style={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
            fontSize: '1rem',
            fontWeight: 600,
            letterSpacing: 'normal',
            lineHeight: 'none',
            color: 'rgb(9 9 11)',
            width: '183px'
          }}>
            <img
              loading="lazy"
              src={icon}
              alt={`${title} icon`}
              style={{
                objectFit: 'contain',
                flexShrink: 0,
                alignSelf: 'stretch',
                marginTop: 'auto',
                marginBottom: 'auto',
                width: '2rem',
                borderRadius: '0.375rem',
                aspectRatio: '1 / 1'
              }}
            />
            <div style={{
              alignSelf: 'stretch',
              marginTop: 'auto',
              marginBottom: 'auto'
            }}>{title}</div>
          </div>
          <div style={{
            display: 'flex',
            overflow: 'hidden',
            gap: '0.5rem',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: '0.625rem',
            paddingLeft: '0.5rem',
            backgroundColor: 'white',
            borderRadius: '0.375rem',
            border: '1px solid rgb(229 229 229)',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            minHeight: '32px',
            width: '38px'
          }}>
            <img
              loading="lazy"
              src={actionIcon}
              alt=""
              style={{
                objectFit: 'contain',
                alignSelf: 'stretch',
                marginTop: 'auto',
                marginBottom: 'auto',
                width: '1.25rem',
                aspectRatio: '1 / 1'
              }}
            />
          </div>
        </div>
      </div>

      <Dialog 
        visible={showDialog} 
        onHide={handleHide}
        header={`Upload files`}
        footer={renderFooter()}
        style={{ width: '25vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        closable={!isUploading}
        closeOnEscape={!isUploading}
        dismissableMask={!isUploading}
        draggable={false}
      >
        <UploaderComponent
          files={files}
          setFiles={setFiles}
          fileSize={30 * 1024 * 1024}
          disableFileLimit={10}
          isCancelTrue={!isUploading}
          uploaderComponent="dialog"
          showProgress={true}
          fileSizeValue="30"
        //   cancelRequest={() => {
        //     // Add cancel logic if needed
        //   }}
        />
      </Dialog>
    </>
  );
}

// Integration Section Component
function IntegrationSection({ title, items }) {
    {{console.log(items ,' are items')}}
  return (
    <>
      <div style={{
        gap: "8px",
        alignSelf: "stretch",
        padding: "0 12px",
        fontSize: "14px",
        fontWeight: 500,
        letterSpacing: "normal",
        lineHeight: "1",
        borderRadius: "8px",
        minHeight: "40px",
        color: "#52525B",
        marginTop: '3rem'
      }}>
        {title}
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
        gridAutoFlow: 'column',
        '@media (max-width: 768px)': {
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridAutoFlow: 'row',
          maxWidth: '100%'
        }
      }}>
        {items.map((item, index) => (
          <IntegrationCard
            key={index}
            title={item.title}
            icon={item.icon}
            actionIcon={item.actionIcon}
            slug={item.slug}
          />
        ))}
      </div>
    </>
  );
}

// Integration Data
const integrationData = {
  dataDrive: [
    {
      title: "Google Drive",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/48707b8fc904bc53189c646cdd4b6267a072cb55d91b110d5d0418af5e34220c?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/313cf2beec62987280a2a1c3fdb340c42dea396e75ef2ef23b8764580c3ed155?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61"
    },
    {
      title: "One Drive",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/72f48068669d542d81d9bc6d594867544e5f8d967d97743340d982431dd58752?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/87af521c718d8fd9f03a38dc7ace3ed38bad7d0a071c1d6e5a0fb364af21c2d4?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61"
    },
    {
      title: "Sharepoint",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fba50c90880a1f3a47aeff4670d2da5c7c7f45f92fa8f90e54ede39e6cb93d3c?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1c57cade285fe1515baac5061571f5b07afa48703e8d0909a8fdeb4e9aeb86b4?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61"
    }
  ],
  emailAccount: [
    {
      title: "Outlook",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d4b887bcad225bee24d18741ab78652ae8f43d3f5df7f02b3374828cd53b53fa?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/313cf2beec62987280a2a1c3fdb340c42dea396e75ef2ef23b8764580c3ed155?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61"
    },
    {
      title: "Gmail",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f93da6fac13a0411da3038ed585a8b210582ea77aaeed0434618bca070b725c5?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/87af521c718d8fd9f03a38dc7ace3ed38bad7d0a071c1d6e5a0fb364af21c2d4?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61"
    }
  ],
  cloudStorage: [
    {
      title: "AWS S3",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2b5074d6a5995b483f061adb40a52b474a389c152300cb3e48254d853fe44236?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/313cf2beec62987280a2a1c3fdb340c42dea396e75ef2ef23b8764580c3ed155?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
    },
    {
      title: "Azure Blob",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2215e0fee639f6e6fb9d7ce413a88ec03216bd5d8a4100ee58fc8c805e97f877?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61",
      actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/87af521c718d8fd9f03a38dc7ace3ed38bad7d0a071c1d6e5a0fb364af21c2d4?placeholderIfAbsent=true&apiKey=e86eb51bab724d1c8de9a3428a165d61"
    }
  ]
};

// Main Component
const NewSpaceConnectors = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [connectors, setConnectors] = useState([]);
  const [categories, setCategories] = useState(new Set());
  const {id}=useParams();
  const { getConnectors } = useSpaces();

  useEffect(() => {
    const fetchConnectors = async () => {
      try {
        const connectorData = await getConnectors();
        setConnectors(connectorData);
        // Extract unique categories from the response
        const uniqueCategories = new Set(
          connectorData.map(connector => connector.category.category_slug)
        );
        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching connectors:', error);
      }
    };

    fetchConnectors();
  }, []);

  // Helper function to group connectors by category
  const getConnectorsByCategory = (categorySlug) => {
    return connectors
      .filter(connector => connector.category.category_slug === categorySlug)
      .map(connector => ({
        title: connector.name,
        icon: connector.icon_url || LocalDrive,
        actionIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/313cf2beec62987280a2a1c3fdb340c42dea396e75ef2ef23b8764580c3ed155",
        id: connector.id,
        slug: connector.slug
      }));
  };

  // Get category name for display
  const getCategoryName = (categorySlug) => {
    const connector = connectors.find(c => c.category.category_slug === categorySlug);
    return connector?.category.category_name || categorySlug;
  };

  const tabs = [
    { id: "all", label: "All" },
    { id: "data_drive", label: "Data Drive" },
    { id: "email_account", label: "Email Account" },
    { id: "cloud_storage", label: "Cloud Storage" },
    { id: "others", label: "Others" }
  ];
  

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div style={{
      padding: "24px",
      height: "100vh",
      overflow: "auto",
      backgroundColor: "#fff"
    }}>
      {/* Header */}
      {/* <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "24px"
      }}>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: "16px"
        }}>
          <Button
            icon="pi pi-arrow-left"
            className="p-button-text p-button-rounded"
            onClick={() => navigate(-1)}
          />
          <h2 style={{
            fontSize: "24px",
            fontWeight: 600,
            margin: 0
          }}>Connect Source</h2>
        </div>
      </div> */}

      {/* Main Content */}
      <div style={{
        display: "flex", 
        flexDirection: "column",
        marginBottom: '6rem'
      }}>
        {/* Filter Tabs and Search */}
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          alignSelf: 'stretch',
          marginTop: '0.5rem',
          width: '100%',
          '@media (max-width: 768px)': {
            maxWidth: '100%'
          }
        }}>
          <div style={{ 
            display: "flex", 
            alignItems: "start", 
            fontWeight: 500, 
            minWidth: "240px", 
            color: "#52525B"
          }}>
            {tabs.map(tab => (
              <div
                key={tab.id}
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignSelf: 'stretch',
                  padding: '0 12px',
                  borderRadius: '8px',
                  minHeight: '40px',
                  cursor: 'pointer',
                  alignItems: 'center',
                  fontWeight: activeTab === tab.id ? 600 : 400,
                  backgroundColor: activeTab === tab.id ? '#F5F5F5' : 'transparent',
                  color: activeTab === tab.id ? '#18181B' : '#52525B',
                  '&:hover': {
                    backgroundColor: activeTab === tab.id ? '#F5F5F5' : '#F9FAFB'
                  }
                }}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          
          {/* Search Bar */}
          <div style={{
            display: "flex",
            overflow: "hidden",
            gap: "8px",
            justifyContent: "center",
            padding: "0 10px",
            whiteSpace: "nowrap",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #E5E7EB",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            minHeight: "40px",
            minWidth: "240px",
            width: "291px",
            color: "#71717A",
            alignItems:'center'
          }}>
            <div style={{
              display: "flex",
              flex: "1 1 0",
              gap: "4px",
              alignItems: "center",
              minWidth: "240px",
              width: "100%",
              height: "100%"
            }}>
              <i className="pi pi-search" />
              <input
                type="text"
                style={{
                  border: "none",
                  width: "100%",
                  outline: "none",
                  padding:'15px',
                  paddingLeft:'8px',
                  fontSize:'18px',
                }}
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Integration Sections */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '2rem',
          width: '100%',
          '@media (max-width: 768px)': {
            maxWidth: '100%'
          }
        }}>
          {categories.has("data_drive") && 
            (activeTab === "all" || activeTab === "data_drive") && (
            <IntegrationSection 
              title={getCategoryName("data_drive")} 
              items={getConnectorsByCategory("data_drive")} 
            />
          )}
          {categories.has("email_account") && 
            (activeTab === "all" || activeTab === "email_account") && (
            <IntegrationSection 
              title={getCategoryName("email_account")} 
              items={getConnectorsByCategory("email_account")} 
            />
          )}
          {categories.has("cloud_storage") && 
            (activeTab === "all" || activeTab === "cloud_storage") && (
            <IntegrationSection 
              title={getCategoryName("cloud_storage")} 
              items={getConnectorsByCategory("cloud_storage")} 
            />
          )}
          {categories.has("others") && 
            (activeTab === "all" || activeTab === "others") && (
            <IntegrationSection 
              title={getCategoryName("others")} 
              items={getConnectorsByCategory("others")} 
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSpaceConnectors;