import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const isLoggedIn = atomWithStorage("isLoggedIn", false);
export const sourceFieldsAtom = atom([]);
//updb_entity_mapping
export const mappingTablesAtom = atom([]);
export const mappedTargetEntitiesAndInstanceAtom = atom([]);
export const labelsMappingForTargetFieldAtom = atom({});
export const labelsMappingForEntityAtom = atom({});
export const relationshipConfigAtom = atom([]);
export const fieldRulesAtom = atom([]);
export const sourceProcessingRulesAtom = atom([]);
export const fieldDeletedRulesAtom = atom([]);
export const contractAtom = atom(null);
export const sourceAtom = atom({});
export const targetEntityFieldsAtom = atom([]);
export const dataProcessingRulesAtom = atom([]);
export const clientsAtom = atom([]);
// object with id as key and name as value
export const clientsMappingAtom = atom([]);
export const contractMastersAtom = atom([]);
export const contractEntityAtom = atom([]);
export const importDataFormAtom = atom({});
export const toastAtom = atom();
export const targetEntityMappingAtom = atom();

//source
export const sourceLoadingAtom = atom(true);

//data browser
export const dataBrowserFiltersAtom = atom({});
export const dataBrowserDataListAtom = atom([]);
export const dataBrowserSourceListAtom = atom([]);
export const dataBrowserCountAtom = atom(0);

export const shouldRefreshSourceAtom = atom(true);

export const lookupValuesAtom = atom([]);
export const sourceFieldMappingsDraftAtom = atomWithStorage(
  "sourceFieldMappingsDraft",
  {}
);

export const navigationHistoryAtom = atomWithStorage("navigationHistory", []);
