import useApi from "services/api";
import { API_NEIO_ING } from "../constants";
import moment from "moment";

/**
 * Custom hook for managing space-related API operations.
 * Provides functions to fetch and delete spaces.
 *
 * @returns {Object} An object containing functions to interact with spaces API
 */
const useSpaces = () => {
  const { patch, post, get, del } = useApi();

  /**
   * Fetches spaces from the server with optional pagination, search, sorting, and filtering.
   *
   * @param {number} page - The page number to fetch
   * @param {number} limit - The number of items per page
   * @param {string} search - Search query to filter spaces by name
   * @param {string} sortBy - Field to sort the results by
   * @param {string} sortOrder - Order of sorting (asc/desc)
   * @param {Object} filters - Additional filters to apply
   * @returns {Promise<Object>} A promise that resolves to the fetched spaces data
   */
  const getSpaces = async (page, limit, search, sortBy, sortOrder, filters) => {
    let url = `${API_NEIO_ING}/spaces`;

    const params = { page, limit };

    if (search) {
      params.filter = { name: search };
    }

    if (filters?.created_at?.length) {
      //
      const fromDate = moment(filters.created_at[0]).startOf("day").utc().toISOString();
      const toDate = moment(filters.created_at[1] || filters.created_at[0])
        .endOf("day")
        .utc()
        .toISOString();

      params.filter = { ...(params.filter || {}), created_at: [fromDate, toDate] };
    }

    if (filters?.created_by && filters.created_by?.length > 0) {
      params.filter = { ...(params.filter || {}), created_by: filters.created_by.join(",") };
    }

    if (sortBy && sortOrder) {
      params.sortBy = sortBy;
      params.sortOrder = sortOrder;
    }

    if (params.filter) {
      params.filter = JSON.stringify(params.filter);
    }

    const queryString = Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    const data = await get(`${url}?${queryString}`);
    return data;
  };

  /**
   * Deletes a space by its ID.
   *
   * @param {string} id - The ID of the space to delete
   * @returns {Promise<Object>} A promise that resolves to the response of the delete operation
   */
  const deleteSpace = async (id) => {
    const data = await del(`${API_NEIO_ING}/spaces/${id}`, []);
    return data;
  };

  /**
   * Creates a new space.
   *
   * @param {Object} body - The request body for creating a space
   * @returns {Promise<Object>} A promise that resolves to the response of the create operation
   */
  const createSpace = async (body) => {
    const data = await post(`${API_NEIO_ING}/spaces`, body);
    return data;
  };

  const getConnectors = async () => {
    const data = await get(`${API_NEIO_ING}/connection-setups/connectors`);
    return data;
  };

  return { getSpaces, deleteSpace, createSpace, getConnectors };
};

export default useSpaces;