import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SidebarToggleIcon from "./icons/SidebarToggleIcon";
import StructuredSourcesIcon from "./icons/StructuredSourcesIcon";
import DocumentSourcesIcon from "./icons/DocumentSourcesIcon";
import PulseSourcesIcon from "./icons/PulseSourcesIcon";
import { useSharedState } from "context/ContextWrapper";
import { paths } from "constants/SidebarConfig";

export default function InnerSidebar({ menuOption }) {
  const sidebarRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showBgOverlay, setShowBgOverlay] = useState(false);
  const { sharedState, setSharedState } = useSharedState();

  useEffect(() => {
    function handleBg() {
      if (showSidebar) {
        setTimeout(() => {
          setShowBgOverlay(true);
        }, 300);
      } else {
        setShowBgOverlay(false);
      }
    }
    handleBg();
  }, [showSidebar]);

  return (
    <>
      <div
        ref={sidebarRef}
        className={`transition-all duration-500 ease-in-out relative flex ${showSidebar ? "w-15rem" : "w-[5vw]"
          } bg-[#F5F5FC] justify-center`}
        id="inner-sidebar"
      >
        <div className="relative h-[100vh] fixed top-0 left-0 bg-[#F5F5FC] p-3 w-full">
          <div className="flex flex-row items-center mt-1 text-lg font-semibold tracking-tight leading-6 text-zinc-950 mb-3 justify-between w-full">
            <div className={`transition-all duration-500 ease-in-out capitalize overflow-hidden ${showSidebar ? 'w-auto opacity-100' : 'w-0 opacity-0'}`}>
              {menuOption}
            </div>
            <button
              onClick={() => setShowSidebar(!showSidebar)}
              className={`rounded-md p-2 flex items-center justify-center text-slate-200 transition-all duration-500 ease-in-out transform ${showSidebar ? "translate-x-0" : "-translate-x-2"}`}
            >
              <SidebarToggleIcon opened={showSidebar} />
            </button>
          </div>

          <div className="flex flex-col w-full justify-between overflow-y-hidden">
            <div className="h-auto sidebar-items overflow-y-auto overflow-x-hidden">
              <div className="flex flex-col gap-y-2 pb-8 no-scroll">
                {(() => {
                  const menuConfig = {
                    "pulse": [
                      {
                        href: paths.pulse.streamSource(),
                        btnText: "Stream Data Sources",
                        icon: <PulseSourcesIcon className="h-5 w-5 flex-shrink-0" />
                      }
                    ],
                    "source": [
                      {
                        href: paths.sourceConfig.structured(),
                        btnText: "Structured Sources",
                        icon: <StructuredSourcesIcon className="h-5 w-5 flex-shrink-0" />
                      },
                      {
                        href: paths.sourceConfig.document(),
                        btnText: "Document Sources",
                        icon: <DocumentSourcesIcon className="h-5 w-5 flex-shrink-0" />
                      }
                    ],
                    "space": [
                      {
                        href: paths.space.spaceList(),
                        btnText: "Data System",
                        icon: <StructuredSourcesIcon className="h-5 w-5 flex-shrink-0" />
                      },
                      {
                        href: paths.space.fileData(),
                        btnText: "File Data",
                        icon: <DocumentSourcesIcon className="h-5 w-5 flex-shrink-0" />
                      },
                      {
                        href: paths.space.streamData(),
                        btnText: "Stream Data",
                        icon: <PulseSourcesIcon className="h-5 w-5 flex-shrink-0" />
                      }
                    ]
                  };

                  const options = menuConfig[menuOption] || [];

                  return options.map((option, index) => (
                    <Option
                      key={index}
                      href={option.href}
                      btnText={showSidebar ? option.btnText : ""}
                      customIcon={option.icon}
                    />
                  ));
                })()}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

const Option = ({ btnText, icon, href, customIcon, activeText = `#1651d` }) => {
  const navigation = useNavigate();
  const isActive = window.location.pathname.includes(href);
  return (
    <div className="flex gap-x-2 items-center justify-between text-sidebar-text cursor-pointer bg-[#F5F5FC]">
      <a
        onClick={() => navigation(href)}
        className={`
          transition-all duration-500 ease-in-out
          flex flex-grow w-[100%] h-[45px] gap-x-2 py-[5px] px-2 rounded justify-start items-center border border-round
          ${isActive
            ? "bg-white border-slate-100 border-opacity-50 font-medium"
            : "hover:bg-blue-100 hover:border-slate-100 hover:border-opacity-50 border-transparent"
          }
        `}
      >
        {icon
          ? React.cloneElement(icon, {
            weight: "fill",
            color: isActive ? "#1651d0" : "#7f7d82",
          })
          : React.cloneElement(customIcon, {
            color: isActive ? "#1651d0" : "#7f7d82",
          })}
        <div className={`transition-all duration-500 ease-in-out overflow-hidden ${btnText ? 'w-auto opacity-100' : 'w-0 opacity-0'}`}>
          {btnText && (
            <p
              className={`text-sm leading-loose whitespace-nowrap ${isActive ? `text-[${activeText}]` : "#1651d"
                } font-medium`}
            >
              {btnText}
            </p>
          )}
        </div>
      </a>
    </div>
  );
};
