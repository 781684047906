import React, { useState } from 'react';
import AlertTabList from './AlertTabList';
import AlertTabDetails from './AlertTabDetails';

const AlertTab = ({streamId}) => {
  const [selectedAlertId, setSelectedAlertId] = useState(null); // State to hold selected alert ID
  return (
    <div className='flex flex-row h-[500px]'>
      <div
        className={selectedAlertId ? 'w-[40%] mr-5' : 'w-full'} // Full width if no alert is selected, half width otherwise
      >
        <AlertTabList selectedAlertId={selectedAlertId} setSelectedAlertId={setSelectedAlertId} />
      </div>

      {selectedAlertId && (
        <div className="w-[60%]"> {/* Occupies half width when alert is selected */}
          <AlertTabDetails selectedAlertId={selectedAlertId} />
        </div>
      )}
    </div>
  );
};

export default AlertTab;
