import moment from "moment";

export const toCamelCase = (inputString) => {
  const words = inputString.split(/[\s_]+/);
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  const camelCaseString = words.join("");
  return camelCaseString;
};

export const extractInitialsFromFullName = (fullName) => {
  var words = fullName ? fullName.split(" ") : "";
  var initials = "";

  for (var i = 0; i < words.length; i++) {
    initials += words[i][0];
  }

  return initials;
};

export const getLabelForDropdownValue = (
  value,
  array,
  valueField = "value",
  labelField = "name"
) => {
  const findArr = array?.find((v) => v[valueField] === value);
  return findArr ? findArr[labelField] : "";
};

export const isObjectWithNonEmptyValues = (obj) => {
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).some(
      (key) =>
        obj[key] !== null &&
        obj[key] !== undefined &&
        !(Array.isArray(obj[key]) && obj[key].length === 0) &&
        !(
          typeof obj[key] === "object" &&
          !(obj[key] instanceof Date) &&
          Object.keys(obj[key]).length === 0
        )
    );
  }

  return false;
};

export const capitalizeFirstLetter = (str) => {
  if (str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
  return str || "";
};

export const isValidPattern = (pattern) => {
  try {
    const regex = new RegExp(pattern);
    const isValidFormat = /^\/.*\/[gimuy]*$/.test(pattern);
    return isValidFormat && regex;
  } catch (error) {
    return false;
  }
};

export const calculateDateDifferenceFromToday = (date) => {
  const startDate = moment(date);
  const endDate = moment();

  // Calculate the difference in days
  const daysDifference = Math.max(0, startDate.diff(endDate, "days"));

  return `${daysDifference} ${daysDifference > 1 ? "days" : "day"}`;
};

export const extractErrorMessage = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else {
    return "Something went wrong please try again";
  }
};

export const getStatusClassName = (status) => {
  switch (status) {
    case "active":
      return "bg-green-100 text-green-700";
    case "inactive":
      return "bg-yellow-100 text-yellow-700";
    default:
      return "";
  }
};

export const getSeverity = (status) => {
  switch (status) {
    case "active":
      return "success";
    case "inactive":
      return "warning";
    default:
      return "";
  }
};
export const getEmptyKeys = (obj) => {
  const keys = [];
  function getKeys(innerObj) {
    for (const key in innerObj) {
      if (innerObj.hasOwnProperty(key)) {
        if (typeof innerObj[key] === "object" && innerObj[key] !== null) {
          // Recurse into sub-objects
          getKeys(innerObj[key]);
        } else if (!innerObj[key]) {
          // Add non-empty keys to the list
          keys.push(key);
        }
      }
    }
  }
  getKeys(obj);
  return keys;
};
export const findMissingElements = function (a, b) {
  // Create a Set from array b for faster lookups
  const bSet = new Set(b);

  // Filter elements from array a that are not present in array b
  const missingElements = a.filter((element) => !bSet.has(element));

  return missingElements;
};
export const combinations = function (array, size) {
  const results = [];
  function generateCombinations(current, start) {
    if (current.length === size) {
      results.push([...current]);
      return;
    }

    for (let i = start; i < array.length; i++) {
      current.push(array[i]);
      generateCombinations(current, i + 1);
      current.pop();
    }
  }

  generateCombinations([], 0);
  return results;
};
export const removeAfterLastUnderscore = function (str) {
  let lastUnderscoreIndex = str.lastIndexOf("_");
  if (lastUnderscoreIndex === -1) {
    return str; // Return the original string if no underscore is found
  }
  return str.substring(0, lastUnderscoreIndex);
};
