import React, { useState, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import "../DocumentEngine/TemplateSection/newTemplate.css";
import "./index.css";
import { Tag } from "primereact/tag";
import Gdrive from "assets/SpaceIcons/Gdrive";
import FilterIcon from "assets/SpaceIcons/FilterIcon";
import ConnectionInfoPanel from "./ConnectionInfoPanel";
import { Dialog } from "primereact/dialog";
import { useNavigate, useParams } from "react-router-dom";

/**
 * Main component for displaying and managing customer data source connections.
 * Handles the display of source data in a table format with filtering, pagination, and actions.
 *
 * @returns {JSX.Element} A complete view of customer data sources with table and filters
 */
const CustomerInsights = () => {
  const { spaceId } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const [sourceData, setSourceData] = useState([
    {
      sourceType: "G Drive",
      syncType: "One-time",
      connectionInfo: "Click to view",
      status: "Syncing",
      lastSync: "4/3/2024, 11:31 AM",
      syncFrequency: "Hourly",
      connectedOn: "2024-12-01",
    },
    {
      sourceType: "Dropbox",
      syncType: "Scheduled",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "4/2/2024, 9:15 AM",
      syncFrequency: "Daily",
      connectedOn: "2024-11-30",
    },
    {
      sourceType: "OneDrive",
      syncType: "Real-time",
      connectionInfo: "Click to view",
      status: "Error",
      lastSync: "4/1/2024, 2:45 PM",
      syncFrequency: "Every 10 minutes",
      connectedOn: "2024-11-28",
    },
    {
      sourceType: "AWS S3",
      syncType: "Manual",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/31/2024, 4:30 PM",
      syncFrequency: "Weekly",
      connectedOn: "2024-11-27",
    },
    {
      sourceType: "Google Cloud Storage",
      syncType: "One-time",
      connectionInfo: "Click to view",
      status: "Syncing",
      lastSync: "3/30/2024, 10:00 AM",
      syncFrequency: "Monthly",
      connectedOn: "2024-11-25",
    },
    {
      sourceType: "Azure Blob",
      syncType: "Scheduled",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/29/2024, 6:20 PM",
      syncFrequency: "Daily",
      connectedOn: "2024-11-20",
    },
    {
      sourceType: "Box",
      syncType: "Manual",
      connectionInfo: "Click to view",
      status: "Error",
      lastSync: "3/28/2024, 7:10 AM",
      syncFrequency: "Weekly",
      connectedOn: "2024-11-15",
    },
    {
      sourceType: "iCloud",
      syncType: "Real-time",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/27/2024, 8:45 PM",
      syncFrequency: "Every 5 minutes",
      connectedOn: "2024-11-12",
    },
    {
      sourceType: "FTP Server",
      syncType: "Scheduled",
      connectionInfo: "Click to view",
      status: "Syncing",
      lastSync: "3/26/2024, 11:50 AM",
      syncFrequency: "Daily",
      connectedOn: "2024-11-10",
    },
    {
      sourceType: "SharePoint",
      syncType: "One-time",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/25/2024, 3:15 PM",
      syncFrequency: "Hourly",
      connectedOn: "2024-11-08",
    },
    {
      sourceType: "GitHub",
      syncType: "Manual",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/24/2024, 5:30 PM",
      syncFrequency: "Every 2 weeks",
      connectedOn: "2024-11-07",
    },
    {
      sourceType: "Bitbucket",
      syncType: "Real-time",
      connectionInfo: "Click to view",
      status: "Error",
      lastSync: "3/23/2024, 9:40 AM",
      syncFrequency: "Every 15 minutes",
      connectedOn: "2024-11-05",
    },
    {
      sourceType: "Google Sheets",
      syncType: "Scheduled",
      connectionInfo: "Click to view",
      status: "Syncing",
      lastSync: "3/22/2024, 1:20 PM",
      syncFrequency: "Daily",
      connectedOn: "2024-11-03",
    },
    {
      sourceType: "MySQL Database",
      syncType: "Manual",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/21/2024, 4:10 PM",
      syncFrequency: "Monthly",
      connectedOn: "2024-11-01",
    },
    {
      sourceType: "PostgreSQL Database",
      syncType: "Real-time",
      connectionInfo: "Click to view",
      status: "Active",
      lastSync: "3/20/2024, 8:35 PM",
      syncFrequency: "Every 30 minutes",
      connectedOn: "2024-10-30",
    },
  ]);

  const [selectedSources, setSelectedSources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedSpaces, setSelectedSpaces] = useState(null);
  const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(false);

  const op = useRef(null);
  const menu = useRef(null);

  const navigate = useNavigate();

  const handleFilterApply = (filters) => {
    op.current.hide();
  };

  const actionTemplate = (rowData) => {
    const items = [
      {
        label: (
          <>
            Edit
          </>
        ),
        command: () => {},
        icon: "pi pi-pen-to-square",
      },
      //divider
      {
        separator: true,
      },
      {
        label: "Delete",
        command: () => {},
        icon: "pi pi-trash",
        className: "p-delete-menuitem",
      },
    ];

    const moreMenu = (
      <div className="card flex justify-content-center">
        <TieredMenu
          model={items}
          popup
          ref={menu}
          breakpoint="767px"
          className="max-w-10rem text-sm"
        />
        <Button
          type="button"
          icon={"pi pi-ellipsis-v"}
          onClick={(e) => {
            menu.current.toggle(e);
            setTimeout(() => {
              menu?.current
                ?.getElement()
                ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
            }, 100);
            setSelectedSpaces(rowData);
          }}
          className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
        />
      </div>
    );

    return (
      <div className="text-right inline-flex">
        {moreMenu}
      </div>
    );
  };

  /**
   * Renders the status indicator icon based on the current status.
   * 
   * @param {string} status - The current status ('Active', 'Error', or 'Syncing')
   * @returns {JSX.Element} An icon representing the status
   */
  const renderStatusIcon = (status) => {
    switch (status) {
      case "Active":
        return (
          <i className="pi pi-check-circle" style={{ cursor: "pointer", fontSize: "12px" }}></i>
        );
        break;
      case "Error":
        return (
          <i
            className="pi pi-exclamation-circle text-red-500"
            style={{ cursor: "pointer", fontSize: "12px" }}
          ></i>
        );
        break;
      case "Syncing":
        return (
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="6.3999" cy="6" r="4.5" stroke="#1581ED" />
            <circle cx="6.3999" cy="6" r="2" fill="#2080F7" />
          </svg>
        );
    }
  };

  const header = (
    <div className="table-header">
      <span className="table-title">Customer Insights</span>
    </div>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog className="w-5" />

      <div
        className="customer-insights-header"
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Button
          icon="pi pi-trash"
          label="Delete"
          className="text-sm text-[#495057] ml-2 bg-white border-0"
        />
        <div>
          <Button
            onClick={(e) => {
              console.log(op);
              op.current.toggle(e);
            }}
            text
            icon={<FilterIcon />}
            pt={{
              label: {
                className: "text-sm font-semibold",
              },
            }}
            label="Filter"
          />
          <Filters
            filters={filters}
            setFilters={setFilters}
            op={op}
            handleFilterApply={handleFilterApply}
          />

          <Button
            icon="pi pi-plus"
            label="Connect Source"
            className="text-sm text-blue-500 bg-white ml-2"
            // onClick={() => navigate('/spaces/file-data/new-space-setup')}
            onClick={() => navigate(`/spaces/file-data/${spaceId}/new-space-setup`)}
          />
        </div>
      </div>
      <div className="card mt-3 customerInsightsTable" style={{ height: "50vh" }}>
        <DataTable
          value={sourceData}
          paginator
          first={(currentPage - 1) * rowsPerPage || 0}
          rows={rowsPerPage}
          totalRecords={sourceData.length}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={[5, 10, 20, 30]}
          onPage={(event) => {
            setCurrentPage(event.page + 1); // Update the current page state
            setRowsPerPage(event.rows); // Update the rows per page state
          }}
          emptyMessage="No Sources found"
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedSources}
          onSelectionChange={(e) => setSelectedSources(e.value)}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

          <Column
            field="sourceType"
            header="Source Type"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Gdrive />
                  <span className="text-label" style={{ marginLeft: "2px" }}>
                    {rowData.sourceType}
                  </span>
                </div>
                <span style={{ fontSize: "10px" }}>alfrey.david@email.com</span>
              </div>
            )}
          ></Column>

          <Column
            field="syncType"
            header="Sync Type"
            style={{ width: "15%" }}
            body={(rowData) => <span className="text-label">{rowData.syncType}</span>}
          ></Column>

          <Column
            field="connectionInfo"
            header="Connection Info"
            body={(rowData) => (
              <>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(true);
                  }}
                  style={{ color: "#3B82F6" }}
                >
                  {rowData.connectionInfo}
                </a>
                <Dialog
                  visible={visible}
                  onHide={() => setVisible(false)}
                  modal
                  closeOnEscape
                  dismissableMask
                  className="w-[630px] connection-info-dialog"
                  breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                  showHeader={false}
                  maskClassName="bg-black-alpha-20"
                  keepInViewport={true}
                  pt={{
                    root: { style: { borderRadius: "16px" } },
                    content: { style: { borderRadius: "16px" } },
                  }}
                >
                  <ConnectionInfoPanel onHide={() => setVisible(false)} />
                </Dialog>
              </>
            )}
            style={{ width: "25%" }}
          />

          <Column
            field="status"
            header="Status"
            sortable
            style={{ width: "15%" }}
            body={(rowData) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Tag
                  style={{
                    backgroundColor: "#F5F5F5",
                    display: "flex",
                    alignSelf: "baseline",
                    color: "#191A1C",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                    }}
                  >
                    {renderStatusIcon(rowData.status)}
                    <span className="text-label" style={{ fontSize: "11px", marginLeft: "3px" }}>
                      {rowData.status}
                    </span>
                  </div>
                </Tag>
                <span style={{ fontSize: "10px", marginTop: "5px" }}>{rowData.lastSync}</span>
              </div>
            )}
          ></Column>

          <Column
            field="syncFrequency"
            header="Sync Frequency"
            style={{ width: "15%" }}
            body={(rowData) => <span className="text-label">{rowData.syncFrequency}</span>}
          ></Column>

          <Column
            field="connectedOn"
            header="Connected On"
            sortable
            style={{ width: "20%" }}
            body={(rowData) => <span className="text-label">{rowData.connectedOn}</span>}
          ></Column>

          <Column
            body={(rowData) => actionTemplate(rowData)}
            header="Actions"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    </Fragment>
  );
};

/**
 * Filter component that provides filtering options for the data table.
 *
 * @param {Object} props - Component properties
 * @param {Object} props.filters - Current filter state
 * @param {Function} props.setFilters - Function to update filter state
 * @param {React.RefObject} props.op - Reference to the overlay panel
 * @param {Function} props.handleFilterApply - Function to apply selected filters
 * @returns {JSX.Element} Filter panel with various filtering options
 */
const Filters = ({ filters, setFilters, op, handleFilterApply }) => {
  /**
   * Handles input changes in the filter form.
   *
   * @param {Object} e - Event object from the input change
   * @param {string} e.target.name - Name of the input field
   * @param {string} e.target.value - New value of the input field
   */
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <OverlayPanel
      ref={op}
      className="w-19rem filterPopup"
      pt={{ content: { className: "px-4 py-3" } }}
    >
      <div className="flex flex-column gap-4">
        <div className="flex justify-content-between gap-2 align-items-center">
          <p className="text-base font-semibold p-0 m-0">Filter Space</p>
          <span className="text-sm font-semibold cursor-pointer" onClick={() => op.current.hide()}>
            <i className="pi pi-times" />
          </span>
        </div>

        <div className="flex justify-content-center flex-column">
          <label htmlFor="dateRange" className="block mb-2 text-label">
            Select Date Range
          </label>
          <Calendar
            id="dateRange"
            name="dateRange"
            value={filters.dateRange}
            onChange={onInputChange}
            showIcon
            selectionMode="range"
            placeholder="dd/mm/yyyy - dd/mm/yyyy"
            maxDate={new Date()}
          />
        </div>
        <div className="flex justify-content-center flex-column">
          <label htmlFor="Status" className="block mb-2 text-label">
            Status
          </label>
          <IconField iconPosition="right">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              id="owner"
              className="text-label w-full"
              placeholder="Search"
              name="owner"
              value={filters.owner}
              onChange={onInputChange}
            />
          </IconField>
        </div>
        {/* <div className="flex justify-content-center flex-column">
          <label htmlFor="referredBy" className="block mb-2 text-label">
            Referred By
          </label>
          <IconField iconPosition="right">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              id="referredBy"
              className="text-label w-full"
              placeholder="Search"
              onChange={onInputChange}
              value={filters.referredBy}
              name="referredBy"
            />
          </IconField>
        </div> */}

        <div className="flex justify-content-between gap-2 align-items-center">
          <Button
            label="Reset"
            severity="danger"
            text
            onClick={() => {
              setFilters({});
              handleFilterApply({});
            }}
          />
          <Button
            label="Apply"
            className="text-sm"
            onClick={() => {
              handleFilterApply(filters);
            }}
          />
        </div>
      </div>
    </OverlayPanel>
  );
};

/**
 * Handles the preview action for a row.
 * 
 * @param {Object} rowData - The data for the row being previewed
 */
const handlePreviewClick = (rowData) => {};

export default CustomerInsights;
