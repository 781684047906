import React from 'react'

const AlertLow = () => {
  return (<svg width="54" height="54" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="8" fill="#0172F7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 32.3996C25.953 32.3996 32.4001 25.9525 32.4001 17.9996C32.4001 10.0467 25.953 3.59961 18.0001 3.59961C10.0472 3.59961 3.6001 10.0467 3.6001 17.9996C3.6001 25.9525 10.0472 32.3996 18.0001 32.3996ZM16.2001 17.9996C16.2001 18.9937 17.006 19.7996 18.0001 19.7996C18.9942 19.7996 19.8001 18.9937 19.8001 17.9996V10.7996C19.8001 9.8055 18.9942 8.99961 18.0001 8.99961C17.006 8.99961 16.2001 9.8055 16.2001 10.7996V17.9996ZM16.2001 24.2996C16.2001 25.2937 17.006 26.0996 18.0001 26.0996C18.9942 26.0996 19.8001 25.2937 19.8001 24.2996C19.8001 23.3055 18.9942 22.4996 18.0001 22.4996C17.006 22.4996 16.2001 23.3055 16.2001 24.2996Z" fill="white"/>
    </svg>
    )
}

export default AlertLow