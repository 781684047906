import React from "react";

const LowAlertInfo = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00016 7.63845C4.8901 7.63702 4.78496 7.59265 4.70713 7.51482C4.6293 7.43699 4.58493 7.33185 4.5835 7.22179V4.44401C4.5835 4.3335 4.62739 4.22752 4.70554 4.14938C4.78368 4.07124 4.88966 4.02734 5.00016 4.02734C5.11067 4.02734 5.21665 4.07124 5.29479 4.14938C5.37293 4.22752 5.41683 4.3335 5.41683 4.44401V7.22179C5.41539 7.33185 5.37103 7.43699 5.2932 7.51482C5.21537 7.59265 5.11022 7.63702 5.00016 7.63845Z"
        fill="#1581ED"
      />
      <path
        d="M5.00016 3.47244C4.8901 3.471 4.78496 3.42664 4.70713 3.34881C4.6293 3.27098 4.58493 3.16583 4.5835 3.05577V2.77799C4.5835 2.66749 4.62739 2.56151 4.70554 2.48337C4.78368 2.40523 4.88966 2.36133 5.00016 2.36133C5.11067 2.36133 5.21665 2.40523 5.29479 2.48337C5.37293 2.56151 5.41683 2.66749 5.41683 2.77799V3.05577C5.41539 3.16583 5.37103 3.27098 5.2932 3.34881C5.21537 3.42664 5.11022 3.471 5.00016 3.47244Z"
        fill="#1581ED"
      />
      <path
        d="M5 10C4.0111 10 3.0444 9.70676 2.22215 9.15735C1.39991 8.60794 0.759043 7.82705 0.380605 6.91342C0.0021664 5.99979 -0.0968502 4.99445 0.0960758 4.02455C0.289002 3.05465 0.765206 2.16373 1.46447 1.46447C2.16373 0.765206 3.05465 0.289002 4.02455 0.0960758C4.99445 -0.0968502 5.99979 0.0021664 6.91342 0.380605C7.82705 0.759043 8.60794 1.39991 9.15735 2.22215C9.70676 3.0444 10 4.0111 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47322 6.32608 10 5 10ZM5 0.833336C4.17591 0.833336 3.37033 1.07771 2.68513 1.53555C1.99992 1.99338 1.46587 2.64413 1.1505 3.40549C0.835139 4.16685 0.752625 5.00462 0.913397 5.81288C1.07417 6.62113 1.471 7.36356 2.05372 7.94628C2.63644 8.529 3.37887 8.92583 4.18713 9.08661C4.99538 9.24738 5.83316 9.16486 6.59452 8.8495C7.35587 8.53413 8.00662 8.00008 8.46446 7.31488C8.9223 6.62967 9.16667 5.82409 9.16667 5C9.16667 3.89493 8.72768 2.83513 7.94628 2.05372C7.16488 1.27232 6.10507 0.833336 5 0.833336Z"
        fill="#1581ED"
      />
    </svg>
  );
};

export default LowAlertInfo;
