import useApi from "services/api";
import { API_URL } from "../constants";
import { useKeycloak } from "@react-keycloak/web";

export const useWorkspaceApi = () => {
  const { get, post, del, patch, loading, error } = useApi();
  const { keycloak } = useKeycloak();
  const domainKey = keycloak?.idTokenParsed?.domain_key?.split('=')[1]?.replace('}', '');

  const getAllRootKnowledgeSpaces = async () => {
    try {
      // Define the two URLs for fetching knowledge spaces
      const myKnowledgeSpacesUrl = `${API_URL}/knowledge_spaces/?knowledge_space_id=-1`;
      const sharedKnowledgeSpacesUrl = `${API_URL}/knowledge_spaces/?knowledge_space_id=-1&shared=true`;

      // Fetch both the my and shared knowledge spaces concurrently using the `get` method from the `useApi` hook
      const [myKnowledgeSpacesResponse, sharedKnowledgeSpacesResponse] = await Promise.all([
        get(myKnowledgeSpacesUrl),
        get(sharedKnowledgeSpacesUrl)
      ]);

      // Extract the results and flatten them
      const allKnowledgeSpaces = [
        ...myKnowledgeSpacesResponse?.results?.knowledge_spaces || [],
        ...sharedKnowledgeSpacesResponse?.results?.knowledge_spaces || []
      ];

      return allKnowledgeSpaces;
    } catch (error) {
      console.error("Error fetching root knowledge spaces:", error);
      return [];
    }
  };

  const userProfilDetailsUpdate = async (data) => {
    try {
      const url = `${API_URL}/users/${data?.idValue}`;
      const response = await patch(url, data?.data);

      return response; // Return the API response
    } catch (error) {
      console.error("Error updating user profile details:", error);
      return { success: false, error: error.message }; // Return structured error
    }
  };

  const getTenantId = async () => {
    try {
      const url = `${API_URL}/organizations/domain?domain=${domainKey}`;
      const response = await get(url);

      if (!response) {
        throw new Error(`Failed to fetch tenant ID for domain ${domainKey}`);
      }

      return response?.results?.tenant_id; // Return the API response
    } catch (error) {
      console.error("Error fetching tenant ID:", error);
      return null; // Return null or handle fallback logic
    }
  }

  return {
    getAllRootKnowledgeSpaces,
    userProfilDetailsUpdate,
    getTenantId,
    loading,
    error,
  };
};