import React from 'react'

const AlertSevere = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="36" height="36" rx="8" fill="#E12121"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4996 32.3996C26.4525 32.3996 32.8996 25.9525 32.8996 17.9996C32.8996 10.0467 26.4525 3.59961 18.4996 3.59961C10.5467 3.59961 4.09961 10.0467 4.09961 17.9996C4.09961 25.9525 10.5467 32.3996 18.4996 32.3996ZM16.6996 17.9996C16.6996 18.9937 17.5055 19.7996 18.4996 19.7996C19.4937 19.7996 20.2996 18.9937 20.2996 17.9996V10.7996C20.2996 9.8055 19.4937 8.99961 18.4996 8.99961C17.5055 8.99961 16.6996 9.8055 16.6996 10.7996V17.9996ZM16.6996 24.2996C16.6996 25.2937 17.5055 26.0996 18.4996 26.0996C19.4937 26.0996 20.2996 25.2937 20.2996 24.2996C20.2996 23.3055 19.4937 22.4996 18.4996 22.4996C17.5055 22.4996 16.6996 23.3055 16.6996 24.2996Z" fill="white"/>
    </svg>
  )
}

export default AlertSevere