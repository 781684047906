// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}

.timeline-card .p-timeline-event-opposite{
    display: none;
}

.timeline-card .p-timeline-event{
    margin-top: 10px;
    margin-left: 20px;
}

.timeline-card .p-timeline-event-connector{
    width: 1px;
}

.timeline-card .p-timeline-event-content{
    margin-top: 4px;
}

div[class*="alert-details-tabview"] div[class*="p-tabview-nav-container"]{
    border-bottom: 1px solid #DEE2E6;
}

div[class*="alert-details-tabview"] div[class*="p-tabview-nav-container"] span{
    border: 0px;
}

div[class*="alert-details-tabview"] li[class*="p-tabview-selected p-highlight"]{
    padding-bottom: 2px;
    border-bottom: 2px solid #3B82F6;
}

div[class*="timeline-card"] div[class*="p-timeline-event-connector"] {
    margin-top: 1vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Pulse/AlertTab/AlertTabDetails/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".dots-container {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n}\n\n.dot {\n    height: 10px;\n    width: 10px;\n    margin: 0 5px;\n    background-color: #bbb;\n    border-radius: 50%;\n    display: inline-block;\n    cursor: pointer;\n}\n\n.dot.active {\n    background-color: #717171;\n}\n\n.timeline-card .p-timeline-event-opposite{\n    display: none;\n}\n\n.timeline-card .p-timeline-event{\n    margin-top: 10px;\n    margin-left: 20px;\n}\n\n.timeline-card .p-timeline-event-connector{\n    width: 1px;\n}\n\n.timeline-card .p-timeline-event-content{\n    margin-top: 4px;\n}\n\ndiv[class*=\"alert-details-tabview\"] div[class*=\"p-tabview-nav-container\"]{\n    border-bottom: 1px solid #DEE2E6;\n}\n\ndiv[class*=\"alert-details-tabview\"] div[class*=\"p-tabview-nav-container\"] span{\n    border: 0px;\n}\n\ndiv[class*=\"alert-details-tabview\"] li[class*=\"p-tabview-selected p-highlight\"]{\n    padding-bottom: 2px;\n    border-bottom: 2px solid #3B82F6;\n}\n\ndiv[class*=\"timeline-card\"] div[class*=\"p-timeline-event-connector\"] {\n    margin-top: 1vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
