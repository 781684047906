export const GET_SOURCES = {
  count: 5,
  rows: [
    [
      {
        "name": "MyAPIConnection1",
        "baseUrl": "https://api1.example.com/",
        "createdAt": "2023-04-03T22:10:38",
        "status": "Active"
      },
      {
        "name": "MyAPIConnection2",
        "baseUrl": "https://api2.example.com/",
        "createdAt": "2023-05-15T10:20:45",
        "status": "Inactive"
      },
      {
        "name": "MyAPIConnection3",
        "baseUrl": "https://api3.example.com/",
        "createdAt": "2023-06-22T12:34:56",
        "status": "Active"
      },
      {
        "name": "MyAPIConnection4",
        "baseUrl": "https://api4.example.com/",
        "createdAt": "2023-07-30T08:45:12",
        "status": "Pending"
      },
      {
        "name": "MyAPIConnection5",
        "baseUrl": "https://api5.example.com/",
        "createdAt": "2023-08-10T16:28:22",
        "status": "Active"
      }
    ]    
  ],
};

export const GET_SOURCE_DETAILS={
  count:5,
  rows: [[{
    "streamid": "Stream ID -002",
    "rules": 18,
    "status": "Inactive",
    "activeAlerts": 0,
    "lastAlert": "3/15/2023, 11:45:12"
  },
  {
    "streamid": "Stream ID -003",
    "rules": 30,
    "status": "Active",
    "activeAlerts": 5,
    "lastAlert": "6/22/2023, 08:25:49"
  },
  {
    "streamid": "Stream ID -004",
    "rules": 12,
    "status": "Active",
    "activeAlerts": 1,
    "lastAlert": "7/10/2023, 16:14:27"
  },
  {
    "streamid": "Stream ID -005",
    "rules": 25,
    "status": "Inactive",
    "activeAlerts": 0,
    "lastAlert": "2/11/2023, 14:03:15"
  },
  {
    "streamid": "Stream ID -006",
    "rules": 28,
    "status": "Active",
    "activeAlerts": 7,
    "lastAlert": "9/03/2023, 21:30:12"
  }]]  
}

export const RULES = {
  cleansing_rules: [
    {
      key: "1",
      data: {
        name: "Customers",
      },
      children: [
        {
          key: "1-1",
          data: {
            field: "customer_id",
            rulesApplied: ["Case Conversions"],
            category: "Customers",
          },
        },
        {
          key: "1-2",
          data: {
            field: "first_name",
            rulesApplied: ["Trimming", "Case Conversions", "Nan Default"],
            category: "Customers",
          },
        },
        {
          key: "1-3",
          data: {
            field: "last_name",
            rulesApplied: ["Trimming"],
            category: "Customers",
          },
        },
        {
          key: "1-4",
          data: { field: "email", rulesApplied: [], category: "Customers" },
        },
      ],
    },
    {
      key: "2",
      data: {
        name: "Products",
      },
      children: [
        {
          key: "2-1",
          data: {
            field: "product_id",
            rulesApplied: ["Case Conversions"],
            category: "Products",
          },
        },
        {
          key: "2-2",
          data: {
            field: "product_name",
            rulesApplied: ["Trimming", "Case Conversions"],
            category: "Products",
          },
        },
        {
          key: "2-3",
          data: { field: "category", rulesApplied: [], category: "Products" },
        },
      ],
    },
    {
      key: "3",
      data: {
        name: "Sales",
      },
      children: [
        {
          key: "3-1",
          data: {
            field: "invoice_number",
            rulesApplied: ["Case Conversions"],
            category: "Sales",
          },
        },
      ],
    },
  ],
};
